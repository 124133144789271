/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface MainStemAPIControllersGlobalNotesCreateAPIRequest {
  content?: string
  context?: string
  /** @format int32 */
  documentID?: number
  documentType?: string
  isPrivate?: boolean
}

export interface MainStemAPIControllersGlobalNotesCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersGlobalNotesDeleteAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemSharpModelsBaseResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersGlobalNotesListAPIRequest {
  allowPrivate?: boolean
  /** @format int32 */
  documentID?: number
  documentType?: string
}

export interface MainStemAPIControllersGlobalNotesListAPIResponse {
  notes?: MainStemAPIControllersGlobalNotesListNoteDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersGlobalNotesListNoteDetail {
  content?: string
  context?: string
  createdBy?: MainStemSharpModelsUsersUserDetails
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  isPrivate?: boolean
  isStarred?: boolean
  isDeleted?: boolean
}

export interface MainStemSharpModelsUsersUserDetails {
  id?: string
  userName?: string
  name?: string
  email?: string
}

export interface MainStemAPIControllersGlobalNotesUpdateAPIRequest {
  content?: string
  context?: string
  /** @format int32 */
  id?: number
  isPrivate?: boolean
  isStarred?: boolean
}

export interface MainStemAPIControllersPurchasingAccountDetailsAPIResponse {
  filePDFLogoURL?: string
  headerInformation?: string
  logoHistory?: MainStemAPIControllersPurchasingAccountDetailsAPIResponseFileDetail[]
  logoURL?: string
  pdfLogoHistory?: MainStemAPIControllersPurchasingAccountDetailsAPIResponseFileDetail[]
  privateSupplierNewOrderEmailNotificationCC?: string
  privateSupplierNewOrderEmailNotificationTemplate?: string
  tech?: MainStemAPIControllersPurchasingAccountDetailsAPIResponseTechDetails[]
  termsAndConditions?: string
  titleInvoice?: string
  titlePurchaseOrder?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingAccountDetailsAPIResponseFileDetail {
  createdBy?: string
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  logoURL?: string
}

export interface MainStemAPIControllersPurchasingAccountDetailsAPIResponseTechDetails {
  /** @format int32 */
  count?: number
  /** @format int32 */
  id?: number
  logo?: string
  name?: string
  type?: string
}

export interface MainStemAPIControllersPurchasingAccountUpdateAPIRequest {
  headerInformation?: string
  privateSupplierNewOrderEmailNotificationCC?: string
  privateSupplierNewOrderEmailNotificationTemplate?: string
  termsAndConditions?: string
  titleInvoice?: string
  titlePurchaseOrder?: string
}

export interface MainStemAPIControllersPurchasingAccountUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingAccountUpgradeAPIRequest {
  programType?: 'Archived' | 'PurchaseProPaid' | 'PurchaseProFree'
}

export interface MainStemAPIControllersPurchasingLocationsApprovalWorkflowAllowableUsersAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingLocationsApprovalWorkflowAllowableUsersAPIResponse {
  users?: MainStemAPIControllersPurchasingLocationsApprovalWorkflowAllowableUsersUserDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsApprovalWorkflowAllowableUsersUserDetails {
  id?: string
  userName?: string
}

export interface MainStemAPIControllersPurchasingLocationsApprovalWorkflowListAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingLocationsApprovalWorkflowListAPIResponse {
  rulesetType?: 'Tier' | 'Workflow'
  steps?: MainStemAPIControllersPurchasingLocationsApprovalWorkflowListWorkflowStep[]
  superApprovers?: MainStemAPIControllersPurchasingLocationsApprovalWorkflowListUserDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsApprovalWorkflowListWorkflowStep {
  /** @format int32 */
  id?: number
  /** @format double */
  maxAmount?: number
  maxAmountMessage?: string
  maxAmountValid?: boolean
  /** @format double */
  minAmount?: number
  minAmountMessage?: string
  minAmountValid?: boolean
  /** @format int32 */
  numberOfRequiredApprovers?: number
  /** @format int32 */
  rank?: number
  stepValid?: boolean
  title?: string
  users?: MainStemAPIControllersPurchasingLocationsApprovalWorkflowListUserDetails[]
  usersMessage?: string
  usersValid?: boolean
  validationMessage?: string
}

export interface MainStemAPIControllersPurchasingLocationsApprovalWorkflowListUserDetails {
  id?: string
  userName?: string
}

export interface MainStemAPIControllersPurchasingLocationsApprovalWorkflowSaveAPIRequest {
  /** @format int32 */
  locationId?: number
  rulesetType?: string
  steps?: MainStemAPIControllersPurchasingLocationsApprovalWorkflowListWorkflowStep[]
}

export interface MainStemAPIControllersPurchasingLocationsApprovalWorkflowValidateAPIRequest {
  /** @format int32 */
  locationId?: number
  steps?: MainStemAPIControllersPurchasingLocationsApprovalWorkflowListWorkflowStep[]
}

export interface MainStemAPIControllersPurchasingAuthCurrentUserAPIResponse {
  accountSize?: 'Fish' | 'Dolphin' | 'SmallWhale' | 'BigWhale'
  affiliateURL?: string
  allowPurchasingApp?: boolean
  friendlyName?: string
  /** @format date-time */
  goLiveDate?: string
  groupPermissions?: MainStemAPIControllersPurchasingAuthCurrentUserAPIResponsePermissionDetails[]
  organization?: MainStemAPIControllersPurchasingAuthCurrentUserAPIResponseOrganizationDetails
  permissionGroup?: MainStemAPIControllersPurchasingAuthCurrentUserAPIResponsePermissionGroupDetail
  permissions?: MainStemAPIControllersPurchasingAuthCurrentUserAPIResponsePermissionDetails[]
  programType?: 'Archived' | 'PurchaseProPaid' | 'PurchaseProFree'
  username?: string
  userPermissions?: MainStemAPIControllersPurchasingAuthCurrentUserAPIResponsePermissionDetails[]
  uuid?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingAuthCurrentUserAPIResponsePermissionDetails {
  group?: string
  shortCode?: string
  value?: string
}

export interface MainStemAPIControllersPurchasingAuthCurrentUserAPIResponseOrganizationDetails {
  allowPunchOutInMainNav?: boolean
  /** @format double */
  available?: number
  /** @format double */
  balance?: number
  /** @format double */
  due?: number
  /** @format int32 */
  id?: number
  integrations?: MainStemAPIControllersPurchasingAuthCurrentUserAPIResponseOrganizationDetailsIntegrationDetails
  /** @format double */
  limit?: number
  logoURL?: string
  name?: string
  uuid?: string
}

export interface MainStemAPIControllersPurchasingAuthCurrentUserAPIResponsePermissionGroupDetail {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingAuthCurrentUserAPIResponseOrganizationDetailsIntegrationDetails {
  d6?: boolean
  dutchie?: boolean
  leafLogix?: MainStemAPIControllersPurchasingAuthCurrentUserAPIResponseOrganizationDetailsLeafLogixDetail
  powerBI?: boolean
  quantumLeaf?: boolean
  quickbooks?: boolean
}

export interface MainStemAPIControllersPurchasingAuthCurrentUserAPIResponseOrganizationDetailsLeafLogixDetail {
  /** @format int32 */
  customerIntegrationID?: number
  customerIntegrationIDs?: number[]
  enabled?: boolean
  integrationDetails?: MainStemAPIControllersPurchasingAuthCurrentUserAPIResponseIntegrationDetail[]
}

export interface MainStemAPIControllersPurchasingAuthCurrentUserAPIResponseIntegrationDetail {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIHelpersAuthorizationRequest {
  password?: string
  username?: string
}

export interface MainStemAPIHelpersAuthorizationResponse {
  appType?: string
  appURL?: string
  name?: string
  urlLogo?: string
  error?: string
  token?: string
  role?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingAuthPassswordResetProcessAPIRequest {
  password?: string
  userID?: string
}

export interface MainStemAPIControllersPurchasingAuthPassswordResetProcessAPIResponse {
  errorMessage?: string
  username?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingAuthPassswordResetRequestAPIRequest {
  username?: string
}

export interface MainStemAPIControllersPurchasingAuthPassswordResetValidateAPIRequest {
  userID?: string
}

export interface MainStemAPIControllersPurchasingAuthPassswordResetValidateAPIResponse {
  errorMessage?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingCatalogCreateAPIRequest {
  name?: string
  baseRequest?: object
  /** @format int32 */
  customerID?: number
  affiliateProgramUUID?: string
  existingAffiliateProgram?: MainStemSharpDataModelsAffiliateProgram
  /** @format int32 */
  businessPartnerId?: number
  businessPartners?: MainStemSharpDataModelsBusinessPartner[]
  /** @format int32 */
  locationID?: number
  isAdmin?: boolean
}

export interface MainStemSharpDataModelsAffiliateProgram {
  affiliate?: MainStemSharpDataModelsAffiliate
  /** @format int32 */
  affiliateID?: number
  appSettings?: string
  customCSS?: string
  customerPriceList?: MainStemSharpDataModelsCustomerPriceList
  /** @format int32 */
  customerPriceListID?: number
  domain?: string
  emailStylesSettings?: string
  favicon?: MainStemSharpDataModelsFile
  /** @format int32 */
  faviconFileID?: number
  freeShippingEnabled?: boolean
  /** @format double */
  freeShippingLimit?: number
  /** @format double */
  freeShippingPercent?: number
  /** @format int32 */
  id?: number
  logo?: MainStemSharpDataModelsFile
  /** @format int32 */
  logoFileID?: number
  menu?: MainStemSharpDataModelsMenu
  /** @format int32 */
  menuID?: number
  name?: string
  type?: string
  uiVersion?: string
  usesAPI?: boolean
  usesPriceList?: boolean
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  uuid?: string
}

export interface MainStemSharpDataModelsBusinessPartner {
  accountManagers?: MainStemSharpDataModelsApplicationUser[]
  address1?: string
  address2?: string
  /** @format double */
  advertisingBudgetDaily?: number
  /** @format double */
  advertisingBudgetMonthly?: number
  allowCashPayments?: boolean
  allowDelivery?: boolean
  allowFullIntegrationSync?: boolean
  allowPayQwickPayments?: boolean
  anonymousDataOptOut?: boolean
  approvalRuleset?: 'Tier' | 'Workflow'
  approvalWorkflows?: MainStemSharpDataModelsLocationApprovalWorkflow[]
  authorizeNetCustomerID?: string
  avalaraID?: string
  billToAddress1?: string
  billToAddress2?: string
  billToAddressIsResidential?: boolean
  billToCity?: string
  billToCountry?: string
  billToName?: string
  billToState?: string
  billToZip?: string
  budgets?: MainStemSharpDataModelsLocationBudget[]
  businessPartnerGroups?: MainStemSharpDataModelsBusinessPartnerGroup[]
  businessPartnerIntegrationSettings?: MainStemSharpDataModelsIntegrationSetting[]
  canBuy?: boolean
  canSell?: boolean
  city?: string
  contacts?: MainStemSharpDataModelsContact[]
  country?: string
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  daysToFulfill?: number
  defaultAddressIsResidential?: boolean
  defaultAffiliateProgram?: MainStemSharpDataModelsAffiliateProgram
  /** @format int32 */
  defaultAffiliateProgramID?: number
  defaultBillToAddress?: MainStemSharpDataModelsBusinessPartnerAddress
  /** @format int32 */
  defaultBillToAddressID?: number
  defaultPhysicalAddress?: MainStemSharpDataModelsBusinessPartnerAddress
  /** @format int32 */
  defaultPhysicalAddressID?: number
  /** @format double */
  defaultProfitMarginEnterprise?: number
  /** @format double */
  defaultProfitMarginPriceAgreements?: number
  /** @format double */
  defaultProfitMarginRetail?: number
  /** @format double */
  defaultProfitMarginVolumePricing?: number
  /** @format double */
  defaultProfitMarginWholesale?: number
  defaultShipToAddress?: MainStemSharpDataModelsBusinessPartnerAddress
  /** @format int32 */
  defaultShipToAddressID?: number
  departments?: MainStemSharpDataModelsDepartment[]
  description?: string
  doNotCall?: boolean
  email?: string
  enableSendInvoiceEmails?: boolean
  enableSendSupplierOrderEmails?: boolean
  federalBusinessNumber?: string
  /** @format double */
  freeShippingLimit?: number
  /** @format double */
  freeShippingPercent?: number
  freightContactEmail?: string
  freightContactFirstName?: string
  freightContactLastName?: string
  freightContactPhone?: string
  freightLiftGateRequired?: boolean
  freightNotes?: string
  freightShippingRequired?: boolean
  glCode?: string
  hasBandedShipping?: boolean
  headerImage?: MainStemSharpDataModelsFile
  /** @format int32 */
  headerImageID?: number
  hubSpot_CompanyID?: string
  /** @format int32 */
  id?: number
  integrationAPIKey?: string
  integrationSettings?: string
  integrationType?: string
  integrationUrl?: string
  isBrand?: boolean
  isDeleted?: boolean
  /** @format double */
  latitude?: number
  localBusinessNumber?: string
  logo?: MainStemSharpDataModelsFile
  /** @format int32 */
  logoFileID?: number
  logoPDF?: MainStemSharpDataModelsFile
  /** @format int32 */
  logoPDFID?: number
  /** @format double */
  longitude?: number
  manualOrderAcceptanceRequired?: boolean
  mapIcon?: MainStemSharpDataModelsFile
  /** @format int32 */
  mapIconID?: number
  /** @format double */
  minimumOrderAmount?: number
  name?: string
  news?: MainStemSharpDataModelsBusinessPartnerNews[]
  notificationEmailNewOrder?: string
  notificationEmailReviewRequest?: string
  notificationEmails?: string
  notificationEmailShipmentUpdate?: string
  parentBusinessPartner?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  parentBusinessPartnerID?: number
  paymentAgreement?: 'DueUponReceipt' | 'PrePay' | 'Net15' | 'Net30' | 'Net45' | 'Net30_110' | 'Net30_210'
  payQwickEid?: string
  phone?: string
  phoneExtension?: string
  previousPriorLeadStatus?: string
  priorLeadStatus?: string
  privateSupplierDomain?: string
  privateSupplierOrganization?: MainStemSharpDataModelsOrganization
  /** @format int32 */
  privateSupplierOrganizationID?: number
  productFinancialDimensions?: MainStemSharpDataModelsLocationProductFinancialDimension[]
  quickbooks_ClientID?: string
  quickbooks_ClientSecret?: string
  quickBooksID?: string
  quickBooksVendorID?: string
  refundPolicy?: string
  requiresManualProcessing?: boolean
  requiresPrePayment?: boolean
  resellerPermit?: MainStemSharpDataModelsFile
  /** @format date-time */
  resellerPermitExpires?: string
  /** @format int32 */
  resellerPermitID?: number
  /** @format date-time */
  resellerPermitIssued?: string
  rfqCategories?: MainStemSharpDataModelsRFQCategory[]
  sage_DepartmentId?: string
  sage_LocationId?: string
  sage_TransactionType?: string
  sage_TransactionTypeApproval?: string
  sage_TransactionTypeInvoice?: string
  salesPersonID?: string
  sendInvoiceEmailsAddresses?: string
  sendSupplierOrderEmailsAddresses?: string
  shipToAddress1?: string
  shipToAddress2?: string
  shipToAddressIsResidential?: boolean
  shipToAppointmentRequired?: boolean
  shipToCity?: string
  shipToCountry?: string
  shipToDriverAssistanceRequired?: boolean
  shipToEquipmentLength?: string
  shipToEquipmentType?: string
  shipToForkliftOnSite?: boolean
  shipToGatedFacility?: boolean
  shipToInsideDelivery?: boolean
  shipToName?: string
  shipToState?: string
  shipToZip?: string
  shortCodeIdentifier?: string
  snowflake_AccountId?: string
  snowflake_Password?: string
  snowflake_Username?: string
  socialMediaFacebook?: string
  socialMediaInstagram?: string
  socialMediaLinkedIn?: string
  socialMediaTwitter?: string
  socialMediaYouTube?: string
  squareFootage?: string
  state?: string
  supplierFreightShippingAccountNumber?: string
  supplierFreightShippingCarrier?: string
  /** @format double */
  supplierFreightShippingFreeMinimum?: number
  supplierParcelShippingAccountNumber?: string
  supplierParcelShippingCarrier?: string
  /** @format double */
  supplierParcelShippingFreeMinimum?: number
  supplierPaymentTerms?: string
  /** @format double */
  supplierPaymentTermsCreditLimit?: number
  /** @format int32 */
  supplierPaymentTermsDays?: number
  /** @format int32 */
  supplierPaymentTermsDiscountDays?: number
  /** @format double */
  supplierPaymentTermsDiscountPercent?: number
  /** @format double */
  supplierPaymentTermsPercent?: number
  /** @format double */
  supplierPaymentTermsPrePaymentPercent?: number
  /** @format int32 */
  supplierShippingFreightWeightLimit?: number
  /** @format int32 */
  supplierShippingMaxBundleWeight?: number
  taxExempt?: boolean
  tech?: MainStemSharpDataModelsCustomerTech[]
  termsAndConditions?: string
  tier?: string
  usesDirectPay?: boolean
  usesPreferredShipping?: boolean
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  uuid?: string
  viewWholesalePricing?: boolean
  website?: string
  zip?: string
}

export interface MainStemSharpDataModelsAffiliate {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemSharpDataModelsCustomerPriceList {
  affiliatePrograms?: MainStemSharpDataModelsAffiliateProgram[]
  customerPriceListLineItems?: MainStemSharpDataModelsCustomerPriceListLineItem[]
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemSharpDataModelsFile {
  altText?: string
  cloudFileType?: 'csv' | 'txt' | 'html' | 'png' | 'jpg' | 'gif' | 'json' | 'pdf' | 'xls' | 'pbix' | 'jfif' | 'jpeg'
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  documentID?: number
  documentIDString?: string
  documentType?: string
  fileExtension?: string
  fileLocation?: string
  fileName?: string
  /** @format int64 */
  fileSize?: number
  /** @format int32 */
  id?: number
  originalFileName?: string
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  userIDCreatedBy?: string
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  uuid?: string
}

export interface MainStemSharpDataModelsMenu {
  algolia_APIKeySearch?: string
  algolia_AppId?: string
  algolia_IndexName?: string
  algolia_IndexNameSorted?: string
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemSharpDataModelsApplicationUser {
  approvals?: MainStemSharpDataModelsOrderApprovals[]
  businessPartner?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  businessPartnerID?: number
  copyUserOnApprovedOrders?: boolean
  copyUserOnCreatedOrders?: boolean
  dashboards?: MainStemSharpDataModelsDashboard[]
  /** @format date-time */
  dateCreated?: string
  departments?: MainStemSharpDataModelsDepartment[]
  friendlyName?: string
  isDeleted?: boolean
  locationApprovalWorkflows?: MainStemSharpDataModelsLocationApprovalWorkflow[]
  /** @format date-time */
  passwordResetExpires?: string
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  passwordResetUUID?: string
  permissionGroup?: MainStemSharpDataModelsPermissionGroup
  /** @format int32 */
  permissionGroupID?: number
  permissions?: MainStemSharpDataModelsUserPermissions[]
  profileImage?: MainStemSharpDataModelsFile
  /** @format int32 */
  profileImageID?: number
  quickBooksID?: string
  sage_EmployeeId?: string
  title?: string
  type?: string
  email?: string
  emailConfirmed?: boolean
  passwordHash?: string
  securityStamp?: string
  phoneNumber?: string
  phoneNumberConfirmed?: boolean
  twoFactorEnabled?: boolean
  /** @format date-time */
  lockoutEndDateUtc?: string
  lockoutEnabled?: boolean
  /** @format int32 */
  accessFailedCount?: number
  roles?: MicrosoftAspNetIdentityEntityFrameworkIdentityUserRole[]
  claims?: MicrosoftAspNetIdentityEntityFrameworkIdentityUserClaim[]
  logins?: MicrosoftAspNetIdentityEntityFrameworkIdentityUserLogin[]
  id?: string
  userName?: string
}

export interface MainStemSharpDataModelsLocationApprovalWorkflow {
  approvals?: MainStemSharpDataModelsOrderApprovals[]
  customerLocation?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  customerLocationID?: number
  /** @format int32 */
  id?: number
  isDeleted?: boolean
  /** @format double */
  maxAmount?: number
  /** @format double */
  minAmount?: number
  /** @format int32 */
  numberOfRequiredApprovers?: number
  users?: MainStemSharpDataModelsApplicationUser[]
}

export interface MainStemSharpDataModelsLocationBudget {
  /** @format double */
  budget?: number
  /** @format int32 */
  id?: number
  location?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  locationID?: number
  /** @format int32 */
  month?: number
  /** @format int32 */
  year?: number
}

export interface MainStemSharpDataModelsBusinessPartnerGroup {
  businessPartners?: MainStemSharpDataModelsBusinessPartner[]
  /** @format int32 */
  id?: number
  name?: string
  products?: MainStemSharpDataModelsProduct[]
  type?: 'None' | 'LocationType'
}

export interface MainStemSharpDataModelsIntegrationSetting {
  businessPartner?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  businessPartnerID?: number
  /** @format int32 */
  id?: number
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  uuid?: string
  integrationType?: string
  settings?: string
  status?: string
}

export interface MainStemSharpDataModelsContact {
  altEmail?: string
  altPhone?: string
  businessPartners?: MainStemSharpDataModelsBusinessPartner[]
  email?: string
  firstName?: string
  /** @format int32 */
  id?: number
  lastName?: string
  nickName?: string
  phone?: string
  phoneExtension?: string
  title?: string
}

export interface MainStemSharpDataModelsBusinessPartnerAddress {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  isDeleted?: boolean
  isResidential?: boolean
  /** @format double */
  latitude?: number
  /** @format double */
  longitude?: number
  name?: string
  owner?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  ownerBusinessPartnerID?: number
  shared?: boolean
  state?: string
  zip?: string
}

export interface MainStemSharpDataModelsDepartment {
  customerLocation?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  customerLocationID?: number
  /** @format int32 */
  id?: number
  name?: string
  users?: MainStemSharpDataModelsApplicationUser[]
  isDeleted?: boolean
}

export interface MainStemSharpDataModelsBusinessPartnerNews {
  businessPartner?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  businessPartnerID?: number
  content?: string
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  title?: string
  url?: string
}

export interface MainStemSharpDataModelsOrganization {
  accountSize?: 'Fish' | 'Dolphin' | 'SmallWhale' | 'BigWhale'
  allowCreditCards?: boolean
  allowOrderRecommendations?: boolean
  allowPunchOutInMainNav?: boolean
  allowTermPayments?: boolean
  customFields?: MainStemSharpDataModelsCustomerCustomField[]
  dataPackageEnabled?: boolean
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateGoLive?: string
  /** @format date-time */
  dateTrialExpires?: string
  email?: string
  filePDFLogo?: MainStemSharpDataModelsFile
  /** @format int32 */
  filePDFLogoID?: number
  headerInformation?: string
  hubSpot_CompanyID?: string
  /** @format int32 */
  id?: number
  internalTeamsChannelId?: string
  logo?: MainStemSharpDataModelsFile
  /** @format int32 */
  logoFileID?: number
  /** @format int32 */
  maxNumberOfLocations?: number
  /** @format int32 */
  maxNumberOfUsers?: number
  /** @format double */
  monthlySpendBaseline?: number
  /** @format double */
  monthlySpendCapturable?: number
  /** @format double */
  monthlySpendTotal?: number
  name?: string
  payQwick_AccountId?: string
  phone?: string
  pricingType?: 'Retail' | 'Wholesale' | 'Enterprise'
  privateSupplierNewOrderEmailNotificationCC?: string
  privateSupplierNewOrderEmailNotificationTemplate?: string
  programType?: 'Archived' | 'PurchaseProPaid' | 'PurchaseProFree'
  /** @format double */
  saaSFee?: number
  saaSFeeEnabled?: boolean
  slack_Channel_InvoiceShipped?: string
  slack_Channel_OrderApproved?: string
  slack_Channel_OrderSubmittedForApproval?: string
  slack_Channel_PaymentProcessed?: string
  snowflake_AccountId?: string
  snowflake_Password?: string
  snowflake_Username?: string
  /** @format int32 */
  termDays?: number
  termsAndConditions?: string
  /** @format double */
  termsCreditLimit?: number
  titleInvoice?: string
  titlePurchaseOrder?: string
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  uuid?: string
  zohoCompanyID?: string
}

export interface MainStemSharpDataModelsLocationProductFinancialDimension {
  financialDimension?: MainStemSharpDataModelsFinancialDimension
  /** @format int32 */
  financialDimensionID: number
  /** @format int32 */
  id?: number
  location?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  locationID: number
  showOnInvoicePDF?: boolean
  showOnOrderPDF?: boolean
}

export interface MainStemSharpDataModelsRFQCategory {
  /** @format int32 */
  id?: number
  name?: string
  rfQs?: MainStemSharpDataModelsRFQ[]
  suppliers?: MainStemSharpDataModelsBusinessPartner[]
}

export interface MainStemSharpDataModelsCustomerTech {
  customerLocations?: MainStemSharpDataModelsBusinessPartner[]
  /** @format int32 */
  id?: number
  logo?: string
  name?: string
  type?: string
}

export interface MainStemSharpDataModelsCustomerPriceListLineItem {
  businessPartner?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  businessPartnerID?: number
  customerPriceList?: MainStemSharpDataModelsCustomerPriceList
  /** @format int32 */
  customerPriceListID?: number
  /** @format int32 */
  id?: number
  /** @format double */
  price?: number
  product?: MainStemSharpDataModelsProduct
  /** @format int32 */
  productID?: number
}

export interface MainStemSharpDataModelsOrderApprovals {
  approvalType?: 'Pending' | 'Approved' | 'Rejected'
  approvalWorkflow?: MainStemSharpDataModelsLocationApprovalWorkflow
  /** @format int32 */
  approvalWorkflowID?: number
  /** @format date-time */
  dateNotified?: string
  /** @format date-time */
  dateOfDecision?: string
  /** @format int32 */
  id?: number
  order?: MainStemSharpDataModelsOrder
  /** @format int32 */
  orderID?: number
  /** @format int32 */
  sequence?: number
  userDecidedBy?: MainStemSharpDataModelsApplicationUser
  userIDDecidedBy?: string
}

export interface MainStemSharpDataModelsDashboard {
  /** @format int32 */
  id?: number
  name?: string
  /** @format int32 */
  order?: number
  rows?: MainStemSharpDataModelsDashboardRow[]
  user?: MainStemSharpDataModelsApplicationUser
  userID?: string
}

export interface MainStemSharpDataModelsPermissionGroup {
  /** @format int32 */
  id?: number
  name?: string
  organization?: MainStemSharpDataModelsOrganization
  /** @format int32 */
  organizationID?: number
}

export interface MainStemSharpDataModelsUserPermissions {
  /** @format int32 */
  id?: number
  permission?: MainStemSharpDataModelsPermission
  /** @format int32 */
  permissionID?: number
  user?: MainStemSharpDataModelsApplicationUser
  userID?: string
  value?: string
}

export interface MicrosoftAspNetIdentityEntityFrameworkIdentityUserRole {
  userId?: string
  roleId?: string
}

export interface MicrosoftAspNetIdentityEntityFrameworkIdentityUserClaim {
  /** @format int32 */
  id?: number
  userId?: string
  claimType?: string
  claimValue?: string
}

export interface MicrosoftAspNetIdentityEntityFrameworkIdentityUserLogin {
  loginProvider?: string
  providerKey?: string
  userId?: string
}

export interface MainStemSharpDataModelsProduct {
  advertisingBids?: MainStemSharpDataModelsAdvertisingBid[]
  approvalStatus?: 'Rejected' | 'Pending' | 'Approved'
  attributeValues?: MainStemSharpDataModelsAttributeValue[]
  brand?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  brandID?: number
  brandNew?: MainStemSharpDataModelsBrand
  /** @format int32 */
  brandNewID?: number
  collectionClassifications?: MainStemSharpDataModelsCollectionClassification[]
  /** @format double */
  customerRetailPrice?: number
  customsDescription?: string
  customsHarmonizedTariffCodes?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateEstimatedBackInStock?: string
  /** @format date-time */
  dateLastUpdated?: string
  /** @format date-time */
  datePriceLastUpdated?: string
  /** @format int32 */
  daysToFulfill?: number
  /** @format double */
  defaultProfitMarginEnterprise?: number
  /** @format double */
  defaultProfitMarginPriceAgreements?: number
  /** @format double */
  defaultProfitMarginRetail?: number
  /** @format double */
  defaultProfitMarginVolumePricing?: number
  /** @format double */
  defaultProfitMarginWholesale?: number
  description?: string
  distributorID?: string
  dropShipBusinessPartner?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  dropShipBusinessPartnerID?: number
  dropShippedItem?: boolean
  externalId?: string
  externalSource?: string
  /** @format double */
  flatShippingCost?: number
  freeShipping?: boolean
  freightShippingRequired?: boolean
  hasMaximumPurchaseRequirement?: boolean
  hasMinimumPurchaseRequirement?: boolean
  hasVolumePricing?: boolean
  /** @format double */
  hazmatFee?: number
  /** @format double */
  height?: number
  /** @format int32 */
  id?: number
  isApproved?: boolean
  isClean?: boolean
  isCustomizable?: boolean
  isCustomProduct?: boolean
  isDeleted?: boolean
  isNonCancellable?: boolean
  isNonReturnable?: boolean
  isOutOfStock?: boolean
  isPunchOutGenerated?: boolean
  isRFQGenerated?: boolean
  isShippable?: boolean
  /** @format double */
  length?: number
  mainImage?: MainStemSharpDataModelsFile
  /** @format int32 */
  mainImageID?: number
  manufacturer?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  manufacturerBusinessPartnerID?: number
  manufacturerNew?: MainStemSharpDataModelsManufacturer
  /** @format int32 */
  manufacturerNewID?: number
  /** @format int32 */
  maximumPurchasableQuantity?: number
  menuItems?: MainStemSharpDataModelsMenuItem[]
  /** @format int32 */
  minimumPurchasableQuantity?: number
  name?: string
  parentProduct?: MainStemSharpDataModelsProduct
  /** @format int32 */
  parentProductID?: number
  parentProductNew?: MainStemSharpDataModelsParentProduct
  /** @format int32 */
  parentProductNewID?: number
  potentialImages?: string[]
  potentialImagesAsString?: string
  /** @format double */
  priceMSRP?: number
  /** @format double */
  pricePurchaseEnterprise?: number
  /** @format double */
  pricePurchaseRetail?: number
  /** @format double */
  pricePurchaseWholesale?: number
  /** @format double */
  priceSellEnterprise?: number
  /** @format double */
  priceSellRetail?: number
  /** @format double */
  priceSellWholesale?: number
  privateProductOrganization?: MainStemSharpDataModelsOrganization
  /** @format int32 */
  privateProductOrganizationID?: number
  productGroups?: MainStemSharpDataModelsProductGroup[]
  productImages?: MainStemSharpDataModelsFile[]
  quickBooksID?: string
  showInStore?: boolean
  skuInternal?: string
  skuManufacturer?: string
  smallText?: string
  /** @format int32 */
  soldInQuantitiesOf?: number
  supplierPartNumber?: string
  taxCode?: string
  taxExempt?: boolean
  type?: string
  unitOfMeasureLabel?: string
  unitOfMeasureValue?: string
  unspscClass?: MainStemSharpDataModelsUNSPSCClass
  /** @format int64 */
  unspscClassID?: number
  unspscCommodity?: MainStemSharpDataModelsUNSPSCCommodity
  /** @format int64 */
  unspscCommodityID?: number
  unspscFamily?: MainStemSharpDataModelsUNSPSCFamily
  /** @format int64 */
  unspscFamilyID?: number
  unspscSegment?: MainStemSharpDataModelsUNSPSCSegment
  /** @format int64 */
  unspscSegmentID?: number
  variantID?: string
  /** @format double */
  weight?: number
  /** @format double */
  width?: number
}

export interface MainStemSharpDataModelsCustomerCustomField {
  customer?: MainStemSharpDataModelsOrganization
  /** @format int32 */
  customerID?: number
  dataModel?:
    | 'User'
    | 'Location'
    | 'Supplier'
    | 'Order'
    | 'Invoice'
    | 'Shipment'
    | 'Product'
    | 'Department'
    | 'OrderSupplier'
  fieldType?: 'ShortText' | 'LongText' | 'Number' | 'Date' | 'Boolean' | 'Currency'
  /** @format int32 */
  id?: number
  isDeleted?: boolean
  name?: string
  showOnPDF?: boolean
  values?: MainStemSharpDataModelsCustomerCustomFieldValue[]
}

export interface MainStemSharpDataModelsFinancialDimension {
  customer?: MainStemSharpDataModelsOrganization
  /** @format int32 */
  customerID?: number
  /** @format int32 */
  id?: number
  isDeleted?: boolean
  label?: string
  /** @format int32 */
  rankOrder?: number
  type?: 'Product' | 'Location' | 'Department' | 'InvoiceLineItemAllocation' | 'Invoice' | 'Supplier'
}

export interface MainStemSharpDataModelsRFQ {
  addressBillTo?: MainStemSharpDataModelsBusinessPartnerAddress
  /** @format int32 */
  addressBillToID?: number
  addressShipTo?: MainStemSharpDataModelsBusinessPartnerAddress
  /** @format int32 */
  addressShipToID?: number
  categories?: MainStemSharpDataModelsRFQCategory[]
  customer?: MainStemSharpDataModelsOrganization
  /** @format int32 */
  customerID?: number
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateDeliveryDueBy?: string
  /** @format date-time */
  dateDueBy?: string
  /** @format date-time */
  dateInitiated?: string
  /** @format date-time */
  dateRFQAwardActual?: string
  /** @format date-time */
  dateRFQAwardExpected?: string
  description?: string
  /** @format int32 */
  id?: number
  isPublic?: boolean
  location?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  locationID?: number
  preferredPaymentMethods?: string[]
  preferredPaymentMethodsAsString?: string
  rfqStatus?: string
  status?: 'Cancelled' | 'Draft' | 'InProgress' | 'Awarded' | 'PartiallyAwarded' | 'Ordered' | 'Finalized'
  title?: string
  userCreatedBy?: MainStemSharpDataModelsApplicationUser
  userCreatedByID?: string
  userFinalizedBy?: MainStemSharpDataModelsApplicationUser
  userFinalizedByID?: string
  userInitiatedBy?: MainStemSharpDataModelsApplicationUser
  userIntiatedByID?: string
  userInitiatedByID?: string
  wantsHelp?: boolean
}

export interface MainStemSharpDataModelsOrder {
  /** @format double */
  actualShippingCost?: number
  affiliateProgram?: MainStemSharpDataModelsAffiliateProgram
  /** @format int32 */
  affiliateProgramID?: number
  approvals?: MainStemSharpDataModelsOrderApprovals[]
  approvalStatus?: 'SavedCart' | 'AwaitingApproval' | 'Approval' | 'ApprovalWithEdits' | 'Rejected'
  /** @format date-time */
  approvalStatusAsOfDate?: string
  billToAddress?: MainStemSharpDataModelsBusinessPartnerAddress
  billToAddress1?: string
  billToAddress2?: string
  /** @format int32 */
  billToAddressID?: number
  billToCity?: string
  billToCountry?: string
  billToName?: string
  billToState?: string
  billToZip?: string
  customerAccountSizeAtTimeOfApproval?: 'Fish' | 'Dolphin' | 'SmallWhale' | 'BigWhale'
  customerGLCode?: string
  /** @format int32 */
  customerIntegrationSessionID?: number
  customerPONumber?: string
  customerPricingTypeAtTimeOfApproval?: 'Retail' | 'Wholesale' | 'Enterprise'
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateDue?: string
  /** @format date-time */
  dateOfLastAction?: string
  /** @format date-time */
  datePickedAndPacked?: string
  /** @format date-time */
  dateReceived?: string
  /** @format date-time */
  dateShipped?: string
  /** @format date-time */
  dateSubmittedForApproval?: string
  /** @format double */
  discount?: number
  email?: string
  /** @format int32 */
  id?: number
  invoices?: MainStemSharpDataModelsFulfillmentMethod[]
  isDeleted?: boolean
  needsElf?: boolean
  orderGeneratedBy?: string
  orderStatus?:
    | 'Open'
    | 'Approved'
    | 'CantFulfill'
    | 'Cancelled'
    | 'Return'
    | 'Fulfillment'
    | 'Shipping'
    | 'Closed'
    | 'Complete'
  owner?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  ownerBusinessPartnerID?: number
  parentOrder?: MainStemSharpDataModelsOrder
  /** @format int32 */
  parentOrderID?: number
  paymentStatus?: string
  phase?: 'Requisition' | 'Submission' | 'Approval' | 'Processing' | 'Shipping' | 'Completed' | 'Cancelled' | 'Rejected'
  phone?: string
  purchaseOrders?: MainStemSharpDataModelsPurchaseOrder[]
  quickBooksID?: string
  requestedShippingType?: string
  requisitionStatus?: 'New' | 'PendingApproval' | 'Returned' | 'Approved' | 'Rejected' | 'Cancelled'
  sage_OrderID?: string
  sage_RequisitionID?: string
  salesPersonID?: string
  /** @format double */
  salesTax?: number
  /** @format date-time */
  salesTaxAsOfDate?: string
  savedPaymentMethod?: string
  /** @format double */
  shippingCost?: number
  shippingMethod?: string
  shippingNote?: string
  shipToAddress?: MainStemSharpDataModelsBusinessPartnerAddress
  shipToAddress1?: string
  shipToAddress2?: string
  /** @format int32 */
  shipToAddressID?: number
  shipToCity?: string
  shipToCountry?: string
  shipToName?: string
  shipToState?: string
  shipToZip?: string
  status?: string
  suppliers?: MainStemSharpDataModelsOrderSupplier[]
  /** @format int32 */
  termLedgerID?: number
  type?: string
  userApprovedBy?: MainStemSharpDataModelsApplicationUser
  userCreatedBy?: MainStemSharpDataModelsApplicationUser
  userIDApprovedBy?: string
  userIDCreatedBy?: string
  userIDSubmittedForApproval?: string
  userSubmittedForApproval?: MainStemSharpDataModelsApplicationUser
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  uuid?: string
}

export interface MainStemSharpDataModelsDashboardRow {
  dashboard?: MainStemSharpDataModelsDashboard
  /** @format int32 */
  dashboardID?: number
  /** @format int32 */
  id?: number
  /** @format int32 */
  order?: number
  panels?: MainStemSharpDataModelsDashboardPanel[]
}

export interface MainStemSharpDataModelsPermission {
  application?: 'Admin' | 'Purchasing' | 'Supplier' | 'Ecommerce'
  applicationAppliedTo?: string
  displayName?: string
  group?: string
  /** @format int32 */
  id?: number
  /** @format int32 */
  rank?: number
  shortCode?: string
  isDeleted?: boolean
}

export interface MainStemSharpDataModelsAdvertisingBid {
  /** @format double */
  bidAsHighAs?: number
  /** @format double */
  bidAsLowAs?: number
  businessPartner?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  businessPartnerID?: number
  /** @format int32 */
  id?: number
  product?: MainStemSharpDataModelsProduct
  /** @format int32 */
  productID?: number
}

export interface MainStemSharpDataModelsAttributeValue {
  attribute?: MainStemSharpDataModelsAttribute
  /** @format int32 */
  attributeID?: number
  /** @format int32 */
  id?: number
  products?: MainStemSharpDataModelsProduct[]
  value?: string
}

export interface MainStemSharpDataModelsBrand {
  description?: string
  /** @format int32 */
  id?: number
  name?: string
  products?: MainStemSharpDataModelsProduct[]
  website?: string
}

export interface MainStemSharpDataModelsCollectionClassification {
  collectionSubCategory?: MainStemSharpDataModelsCollectionSubCategory
  /** @format int32 */
  collectionSubCategoryID?: number
  customer?: MainStemSharpDataModelsOrganization
  /** @format int32 */
  customerID?: number
  /** @format int32 */
  id?: number
  name?: string
  /** @format int32 */
  oldMenuId?: number
  parentProducts?: MainStemSharpDataModelsParentProduct[]
}

export interface MainStemSharpDataModelsManufacturer {
  description?: string
  /** @format int32 */
  id?: number
  name?: string
  products?: MainStemSharpDataModelsProduct[]
  website?: string
}

export interface MainStemSharpDataModelsMenuItem {
  amazonFeedProductType?: string
  configurators?: MainStemSharpDataModelsConfigurator[]
  description?: string
  externalURL?: string
  icon?: string
  /** @format int32 */
  id?: number
  image?: MainStemSharpDataModelsFile
  /** @format int32 */
  imageFileID?: number
  isActive?: boolean
  isQuickHit?: boolean
  menu?: MainStemSharpDataModelsMenu
  /** @format int32 */
  menuID?: number
  metaDescription?: string
  metaKeywords?: string
  name?: string
  /** @format int32 */
  order?: number
  parentMenuItem?: MainStemSharpDataModelsMenuItem
  /** @format int32 */
  parentMenuItemID?: number
  position?: string
  privateMenuItemOrganization?: MainStemSharpDataModelsOrganization
  /** @format int32 */
  privateMenuItemOrganizationID?: number
  products?: MainStemSharpDataModelsProduct[]
  quickHitName?: string
  seoH1?: string
  seoSlug?: string
  titleTags?: string
}

export interface MainStemSharpDataModelsParentProduct {
  children?: MainStemSharpDataModelsProduct[]
  classifications?: MainStemSharpDataModelsCollectionClassification[]
  description?: string
  /** @format int32 */
  id?: number
  isDeleted?: boolean
  mainImage?: MainStemSharpDataModelsFile
  /** @format int32 */
  mainImageID?: number
  name?: string
  /** @format int32 */
  oldProductID?: number
  parentProductType?: MainStemSharpDataModelsParentProductType
  /** @format int32 */
  parentProductTypeID?: number
  showInStore?: boolean
  type?: string
}

export interface MainStemSharpDataModelsProductGroup {
  /** @format int32 */
  id?: number
  name?: string
  parentProductGroup?: MainStemSharpDataModelsProductGroup
  /** @format int32 */
  parentProductGroupID?: number
  products?: MainStemSharpDataModelsProduct[]
}

export interface MainStemSharpDataModelsUNSPSCClass {
  /** @format int64 */
  code?: number
  commodities?: MainStemSharpDataModelsUNSPSCCommodity[]
  definition?: string
  family?: MainStemSharpDataModelsUNSPSCFamily
  /** @format int64 */
  familyID?: number
  title?: string
}

export interface MainStemSharpDataModelsUNSPSCCommodity {
  class?: MainStemSharpDataModelsUNSPSCClass
  /** @format int64 */
  classID?: number
  /** @format int64 */
  code?: number
  definition?: string
  products?: MainStemSharpDataModelsProduct[]
  title?: string
}

export interface MainStemSharpDataModelsUNSPSCFamily {
  classes?: MainStemSharpDataModelsUNSPSCClass[]
  /** @format int64 */
  code?: number
  definition?: string
  segment?: MainStemSharpDataModelsUNSPSCSegment
  /** @format int64 */
  segmentID?: number
  title?: string
}

export interface MainStemSharpDataModelsUNSPSCSegment {
  /** @format int64 */
  code?: number
  definition?: string
  families?: MainStemSharpDataModelsUNSPSCFamily[]
  title?: string
}

export interface MainStemSharpDataModelsCustomerCustomFieldValue {
  customField?: MainStemSharpDataModelsCustomerCustomField
  /** @format int32 */
  customFieldID?: number
  /** @format int32 */
  documentID?: number
  documentIDString?: string
  /** @format int32 */
  id?: number
  isDeleted?: boolean
  value?: string
}

export interface MainStemSharpDataModelsFulfillmentMethod {
  acceptanceStatus?: 'Cancelled' | 'Pending' | 'Accepted' | 'Rejected'
  acknowledgedBy?: string
  acknowledgedByUser?: MainStemSharpDataModelsApplicationUser
  acknowledgedByUserId?: string
  acknowledgementType?: 'MainStemPixel' | 'SupplierCoreOrderDetails' | 'Integration'
  avalaraID?: string
  avalaraTaxBreakDown?: AvalaraAvaTaxRestClientTransactionSummary[]
  /** @format int32 */
  customerIntegrationSessionID?: number
  customerQuickBooksBillID?: string
  /** @format date-time */
  dateAccepted?: string
  /** @format date-time */
  dateAcknowledged?: string
  /** @format date-time */
  dateDeliveryRangeEnd?: string
  /** @format date-time */
  dateDeliveryRangeStart?: string
  /** @format date-time */
  dateNotified?: string
  /** @format date-time */
  datePickedOn?: string
  /** @format date-time */
  datePromisedBy?: string
  /** @format date-time */
  dateReceived?: string
  /** @format date-time */
  dateRejected?: string
  /** @format date-time */
  dateShippedOn?: string
  /** @format double */
  discount?: number
  dropShipUniqueID?: string
  dropShipVendor?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  dropShipVendorID?: number
  /** @format double */
  fees?: number
  fulfillmentMethodLineItems?: MainStemSharpDataModelsFulfillmentMethodLineItem[]
  hasProblem?: boolean
  /** @format int32 */
  id?: number
  integrationType?: string
  isFreightShipping?: boolean
  notes?: string
  order?: MainStemSharpDataModelsOrder
  /** @format int32 */
  orderID?: number
  /** @format double */
  paymentProcessingFee?: number
  purchaseOrder?: MainStemSharpDataModelsPurchaseOrder
  /** @format int32 */
  purchaseOrderID?: number
  quickBooksID?: string
  quickBooksInvoiceState?: 'Open' | 'Partial' | 'Closed' | 'Ignore'
  quickBooksItemReceiptID?: string
  quickBooksPaymentIDs?: string
  quickBooksPaymentLinks?: string[]
  quickBooksPurchaseOrderID?: string
  sage_InvoiceID?: string
  /** @format double */
  salesTax?: number
  shipmentType?: string
  /** @format double */
  shippingCost?: number
  subType?: 'Shipment' | 'WillCall' | 'Delivery' | 'Freight'
  /** @format double */
  supplierSalesTax?: number
  /** @format double */
  supplierShippingCost?: number
  taxBreakdownJSON?: string
  type?: 'DirectToCustomer' | 'DropShip' | 'BackOrder'
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  uuid?: string
}

export interface MainStemSharpDataModelsPurchaseOrder {
  /** @format int32 */
  customerIntegrationSessionID?: number
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateReleased?: string
  /** @format date-time */
  dateSalesTaxCalculated?: string
  /** @format date-time */
  dateShippingCalculated?: string
  discounts?: MainStemSharpDataModelsPurchaseOrderDiscount[]
  fees?: MainStemSharpDataModelsPurchaseOrderFee[]
  freightCollect?: boolean
  fulfillmentMethods?: MainStemSharpDataModelsFulfillmentMethod[]
  /** @format int32 */
  id?: number
  lineItems?: MainStemSharpDataModelsPurchaseOrderLineItem[]
  order?: MainStemSharpDataModelsOrder
  /** @format int32 */
  orderID?: number
  /** @format double */
  salesTaxAmount?: number
  salesTaxDescription?: string
  /** @format double */
  shippingAmount?: number
  shippingCarrier?: string
  shippingDescription?: string
  shippingMethod?: string
  status?: 'Draft' | 'Released' | 'Cancelled'
  supplier?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  supplierID?: number
}

export interface MainStemSharpDataModelsOrderSupplier {
  /** @format int32 */
  id?: number
  order?: MainStemSharpDataModelsOrder
  /** @format int32 */
  orderID?: number
  supplier?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  supplierID?: number
}

export interface MainStemSharpDataModelsDashboardPanel {
  dashboardRow?: MainStemSharpDataModelsDashboardRow
  /** @format int32 */
  dashboardRowID?: number
  /** @format int32 */
  id?: number
  widgets?: MainStemSharpDataModelsDashboardPanelWidgets[]
  /** @format int32 */
  width?: number
}

export interface MainStemSharpDataModelsAttribute {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemSharpDataModelsCollectionSubCategory {
  classifications?: MainStemSharpDataModelsCollectionClassification[]
  collectionCategory?: MainStemSharpDataModelsCollectionCategory
  /** @format int32 */
  collectionCategoryID?: number
  customer?: MainStemSharpDataModelsOrganization
  /** @format int32 */
  customerID?: number
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemSharpDataModelsConfigurator {
  attribute?: MainStemSharpDataModelsAttribute
  /** @format int32 */
  attributeID?: number
  configuratorOptions?: MainStemSharpDataModelsConfiguratorOption[]
  /** @format int32 */
  id?: number
  menuItem?: MainStemSharpDataModelsMenuItem
  /** @format int32 */
  menuItemID?: number
  /** @format int32 */
  order?: number
  type?: 'checkbox' | 'radio' | 'select' | 'image' | 'range'
}

export interface MainStemSharpDataModelsParentProductType {
  /** @format int32 */
  id?: number
  name?: string
  parentProducts?: MainStemSharpDataModelsParentProduct[]
}

export interface AvalaraAvaTaxRestClientTransactionSummary {
  country?: string
  region?: string
  jurisType?: 'Country' | 'State' | 'County' | 'City' | 'Special'
  jurisCode?: string
  jurisName?: string
  /** @format int32 */
  taxAuthorityType?: number
  stateAssignedNo?: string
  taxType?: string
  taxSubType?: string
  taxName?: string
  taxGroup?: string
  rateType?:
    | 'ReducedA'
    | 'ReducedB'
    | 'Food'
    | 'General'
    | 'IncreasedStandard'
    | 'LinenRental'
    | 'Medical'
    | 'Construction'
    | 'Parking'
    | 'SuperReduced'
    | 'ReducedR'
    | 'Standard'
    | 'Leasing'
    | 'LeasingLow'
    | 'Services'
    | 'Zero'
  rateTypeCode?: string
  /** @format double */
  taxable?: number
  /** @format double */
  rate?: number
  /** @format double */
  tax?: number
  /** @format double */
  taxCalculated?: number
  /** @format double */
  nonTaxable?: number
  /** @format double */
  exemption?: number
}

export interface MainStemSharpDataModelsFulfillmentMethodLineItem {
  allocations?: MainStemSharpDataModelsInvoiceLineItemAllocation[]
  fulfillmentMethod?: MainStemSharpDataModelsFulfillmentMethod
  /** @format int32 */
  fulfillmentMethodID?: number
  /** @format int32 */
  id?: number
  orderLineItem?: MainStemSharpDataModelsOrderLineItem
  /** @format int32 */
  orderLineItemID?: number
  /** @format int32 */
  quantity?: number
  /** @format double */
  supplierPricePerItemActual?: number
  /** @format double */
  supplierPricePerItemExpected?: number
  /** @format double */
  taxAmount?: number
  taxJarJSON?: string
  /** @format double */
  taxRate?: number
}

export interface MainStemSharpDataModelsPurchaseOrderDiscount {
  /** @format double */
  amount?: number
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  notes?: string
  purchaseOrder?: MainStemSharpDataModelsPurchaseOrder
  /** @format int32 */
  purchaseOrderID?: number
  reason?: string
}

export interface MainStemSharpDataModelsPurchaseOrderFee {
  /** @format double */
  amount?: number
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  notes?: string
  purchaseOrder?: MainStemSharpDataModelsPurchaseOrder
  /** @format int32 */
  purchaseOrderID?: number
  reason?: string
}

export interface MainStemSharpDataModelsPurchaseOrderLineItem {
  /** @format double */
  costPerItem?: number
  /** @format date-time */
  dateCreated?: string
  /** @format double */
  discountsPerItem?: number
  /** @format double */
  feesPerItem?: number
  /** @format int32 */
  id?: number
  /** @format double */
  pricePerItem?: number
  pricingType?: 'Retail' | 'Wholesale' | 'Enterprise'
  product?: MainStemSharpDataModelsProduct
  /** @format int32 */
  productID?: number
  purchaseOrder?: MainStemSharpDataModelsPurchaseOrder
  /** @format int32 */
  purchaseOrderID?: number
  /** @format int32 */
  quantity?: number
  /** @format double */
  salesTaxPerItem?: number
  /** @format double */
  shippingPerItem?: number
}

export interface MainStemSharpDataModelsDashboardPanelWidgets {
  dashboardPanel?: MainStemSharpDataModelsDashboardPanel
  /** @format int32 */
  dashboardPanelID?: number
  dashboardWidget?: MainStemSharpDataModelsDashboardWidget
  /** @format int32 */
  dashboardWidgetID?: number
  /** @format int32 */
  id?: number
  /** @format int32 */
  order?: number
}

export interface MainStemSharpDataModelsCollectionCategory {
  customer?: MainStemSharpDataModelsOrganization
  /** @format int32 */
  customerID?: number
  /** @format int32 */
  id?: number
  image?: MainStemSharpDataModelsFile
  /** @format int32 */
  imageFileID?: number
  name?: string
  subCategories?: MainStemSharpDataModelsCollectionSubCategory[]
}

export interface MainStemSharpDataModelsConfiguratorOption {
  attributeValue?: MainStemSharpDataModelsAttributeValue
  /** @format int32 */
  attributeValueID?: number
  configurator?: MainStemSharpDataModelsConfigurator
  /** @format int32 */
  configuratorID?: number
  /** @format int32 */
  id?: number
  image?: MainStemSharpDataModelsFile
  /** @format int32 */
  imageID?: number
  name?: string
  /** @format int32 */
  order?: number
  value?: string
}

export interface MainStemSharpDataModelsInvoiceLineItemAllocation {
  /** @format date-time */
  dateCreated?: string
  department?: MainStemSharpDataModelsDepartment
  /** @format int32 */
  departmentID?: number
  /** @format int32 */
  id?: number
  invoiceLineItem?: MainStemSharpDataModelsFulfillmentMethodLineItem
  /** @format int32 */
  invoiceLineItemID?: number
  isDeleted?: boolean
  /** @format int32 */
  quantity?: number
  userCreatedBy?: MainStemSharpDataModelsApplicationUser
  userCreatedByID?: string
}

export interface MainStemSharpDataModelsOrderLineItem {
  /** @format double */
  discountPerItem?: number
  /** @format int32 */
  id?: number
  note?: string
  order?: MainStemSharpDataModelsOrder
  /** @format int32 */
  orderID?: number
  /** @format double */
  pricePerItem?: number
  pricePerItemReason?: string
  product?: MainStemSharpDataModelsProduct
  /** @format int32 */
  productID?: number
  /** @format int32 */
  quantity?: number
  rfqBidLineItem?: MainStemSharpDataModelsRFQBidLineItem
  /** @format int32 */
  rfqBidLineItemID?: number
  /** @format double */
  supplierPricePerItem?: number
}

export interface MainStemSharpDataModelsDashboardWidget {
  description?: string
  /** @format int32 */
  id?: number
  image?: MainStemSharpDataModelsFile
  /** @format int32 */
  imageID?: number
  name?: string
  panels?: MainStemSharpDataModelsDashboardPanelWidgets[]
  type?: 'Marketplace' | 'Finance' | 'Approvals' | 'Organization' | 'Procurement' | 'Shipments'
}

export interface MainStemSharpDataModelsRFQBidLineItem {
  awardStatus?: 'Lost' | 'Undecided' | 'Won' | 'Withdrawn' | 'PartialWin' | 'PartialLoss' | 'PartialDecisionsMade'
  bidAwardStatus?: string
  /** @format double */
  costSalesTax?: number
  /** @format double */
  costShipping?: number
  generatedProduct?: MainStemSharpDataModelsProduct
  /** @format int32 */
  generatedProductID?: number
  /** @format int32 */
  id?: number
  isFreight?: boolean
  /** @format int32 */
  leadTimeInDays?: number
  lineType?: string
  name?: string
  note?: string
  priceOption?: string
  /** @format double */
  pricePerItem?: number
  /** @format int32 */
  quantity?: number
  rfqBid?: MainStemSharpDataModelsRFQBid
  /** @format int32 */
  rfqBidID?: number
  rfqLineItem?: MainStemSharpDataModelsRFQLineItem
  /** @format int32 */
  rfqLineItemID?: number
  sku?: string
}

export interface MainStemSharpDataModelsRFQBid {
  agreedToAllDates?: boolean
  agreedToAllPreferredPayments?: boolean
  awardStatus?: 'Lost' | 'Undecided' | 'Won' | 'Withdrawn' | 'PartialWin' | 'PartialLoss' | 'PartialDecisionsMade'
  bidAwardStatus?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateEstimatedDelivery?: string
  generatedOrder?: MainStemSharpDataModelsOrder
  /** @format int32 */
  generatedOrderID?: number
  /** @format int32 */
  id?: number
  lineItems?: MainStemSharpDataModelsRFQBidLineItem[]
  preferredPaymentMethods?: string[]
  preferredPaymentMethodsAsString?: string
  prefp?: string
  rfq?: MainStemSharpDataModelsRFQ
  /** @format int32 */
  rfqid?: number
  rfqSupplier?: MainStemSharpDataModelsRFQSupplier
  /** @format int32 */
  rfqSupplierID?: number
}

export interface MainStemSharpDataModelsRFQLineItem {
  awardedBid?: MainStemSharpDataModelsRFQBid
  /** @format int32 */
  awardedBidID?: number
  /** @format int32 */
  id?: number
  name?: string
  note?: string
  /** @format double */
  pricePerItem?: number
  /** @format int32 */
  quantity?: number
  rfq?: MainStemSharpDataModelsRFQ
  /** @format int32 */
  rfqid?: number
  sku?: string
}

export interface MainStemSharpDataModelsRFQSupplier {
  /** @format date-time */
  dateInviteAccepted?: string
  /** @format date-time */
  dateInvited?: string
  /** @format int32 */
  id?: number
  invitationType?: 'Direct' | 'Suggested' | 'Opportunity'
  inviteAccepted?: boolean
  rfq?: MainStemSharpDataModelsRFQ
  /** @format int32 */
  rfqid?: number
  rfqSupplierInvitationType?: string
  supplier?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  supplierID?: number
}

export interface MainStemAPIControllersPurchasingCatalogCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingCatalogDeleteAPIRequest {
  /** @format int32 */
  catalogId?: number
}

export interface MainStemAPIControllersPurchasingCatalogDeleteAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingCatalogDetailsAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingCatalogDetailsAPIResponse {
  /** @format int32 */
  id?: number
  isPrivate?: boolean
  name?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingCatalogDuplicateAPIRequest {
  /** @format int32 */
  catalogId?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingCatalogDuplicateAPIResponse {
  /** @format int32 */
  catalogId?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingCatalogListAPIResponse {
  catalogs?: MainStemAPIControllersPurchasingCatalogListAPIResponseCatalogDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingCatalogListAPIResponseCatalogDetails {
  /** @format int32 */
  id?: number
  isPrivate?: boolean
  name?: string
  /** @format int32 */
  productCount?: number
  /** @format int32 */
  userCount?: number
  userRelated?: boolean
}

export interface MainStemAPIControllersPurchasingCatalogLocationsAddAPIRequest {
  locationIds?: number[]
  /** @format int32 */
  productListId?: number
}

export interface MainStemAPIControllersPurchasingCatalogLocationsAddAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingCatalogLocationsListAPIRequest {
  /** @format int32 */
  productListId?: number
}

export interface MainStemAPIControllersPurchasingCatalogLocationsListAPIResponse {
  locations?: MainStemAPIControllersPurchasingCatalogLocationsListAPIResponseLocationDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingCatalogLocationsListAPIResponseLocationDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingCatalogLocationsRemoveAPIRequest {
  /** @format int32 */
  locationId?: number
  /** @format int32 */
  productListId?: number
}

export interface MainStemAPIControllersPurchasingCatalogLocationsRemoveAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingCatalogProductsAddAPIRequest {
  catalogIds?: number[]
  productIds?: number[]
  baseRequest?: object
  /** @format int32 */
  customerID?: number
  affiliateProgramUUID?: string
  existingAffiliateProgram?: MainStemSharpDataModelsAffiliateProgram
  /** @format int32 */
  businessPartnerId?: number
  businessPartners?: MainStemSharpDataModelsBusinessPartner[]
  /** @format int32 */
  locationID?: number
  isAdmin?: boolean
}

export interface MainStemAPIControllersPurchasingCatalogProductsAddAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingCatalogProductsListAPIRequest {
  /** @format int32 */
  productListId?: number
}

export interface MainStemAPIControllersPurchasingCatalogProductsListAPIResponse {
  products?: MainStemAPIControllersPurchasingCatalogProductsListAPIResponseProductDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingCatalogProductsListAPIResponseProductDetails {
  /** @format int32 */
  id?: number
  imgURL?: string
  name?: string
}

export interface MainStemAPIControllersPurchasingCatalogProductsRemoveAPIRequest {
  /** @format int32 */
  productId?: number
  /** @format int32 */
  productListId?: number
}

export interface MainStemAPIControllersPurchasingCatalogProductsRemoveAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingCatalogSuppliersListAPIRequest {
  /** @format int32 */
  catalogId?: number
}

export interface MainStemAPIControllersPurchasingCatalogSuppliersListAPIResponse {
  suppliers?: MainStemAPIControllersPurchasingCatalogSuppliersListAPIResponseSupplierDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingCatalogSuppliersListAPIResponseSupplierDetails {
  /** @format int32 */
  id?: number
  image?: string
  inNetwork?: boolean
  name?: string
  usesDirectPay?: boolean
  usesPreferredShipping?: boolean
}

export interface MainStemAPIControllersPurchasingCatalogUpdateAPIRequest {
  /** @format int32 */
  id?: number
  isPrivate?: boolean
  name?: string
}

export interface MainStemAPIControllersPurchasingCatalogUsersAddAPIRequest {
  /** @format int32 */
  productListId?: number
  userIds?: string[]
}

export interface MainStemAPIControllersPurchasingCatalogUsersAddAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingCatalogUsersListAPIRequest {
  /** @format int32 */
  productListId?: number
}

export interface MainStemAPIControllersPurchasingCatalogUsersListAPIResponse {
  users?: MainStemAPIControllersPurchasingCatalogUsersListAPIResponseUserDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingCatalogUsersListAPIResponseUserDetails {
  role?: string
  username?: string
  uuid?: string
}

export interface MainStemAPIControllersPurchasingCatalogUsersRemoveAPIRequest {
  /** @format int32 */
  productListId?: number
  userId?: string
}

export interface MainStemAPIControllersPurchasingCatalogUsersRemoveAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingChangeLogListAPIRequest {
  documentId?: string
  documentType?: 'Requisition' | 'PurchaseOrder' | 'Invoice' | 'Shipment' | 'Payment'
}

export interface MainStemAPIControllersPurchasingChangeLogListAPIResponse {
  changeLogs?: MainStemSharpHelpersFactoriesChangeLogsModelsChangeLogRecord[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemSharpHelpersFactoriesChangeLogsModelsChangeLogRecord {
  content?: string
  documentID?: string
  documentType?: 'Requisition' | 'PurchaseOrder' | 'Invoice' | 'Shipment' | 'Payment'
  env?: string
  eTag?: AzureETag
  partitionKey?: string
  reason?: string
  rowKey?: string
  /** @format date-time */
  timestamp?: string
  userIDCreatedBy?: string
}

export type AzureETag = object

export interface MainStemAPIControllersPurchasingContactSendMessageAPIRequest {
  comment?: string
  email?: string
  phone?: string
  reason?: string
}

export interface MainStemAPIControllersPurchasingPrivateMenuItemCreateAutoCompleteAPIRequest {
  query?: string
}

export interface MainStemAPIControllersPurchasingPrivateMenuItemCreateAutoCompleteAPIResponse {
  menuItems?: MainStemAPIControllersPurchasingPrivateMenuItemCreateAutoCompleteAPIResponseMenuItemDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPrivateMenuItemCreateAutoCompleteAPIResponseMenuItemDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingCustomFieldCreateAPIRequest {
  dataModel?:
    | 'User'
    | 'Location'
    | 'Supplier'
    | 'Order'
    | 'Invoice'
    | 'Shipment'
    | 'Product'
    | 'Department'
    | 'OrderSupplier'
  name?: string
  showOnPDF?: boolean
  type?: 'ShortText' | 'LongText' | 'Number' | 'Date' | 'Boolean' | 'Currency'
}

export interface MainStemAPIControllersPurchasingCustomFieldCreateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingCustomFieldDeleteAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingCustomFieldDeleteAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingCustomFieldListAPIResponse {
  customFields?: MainStemAPIControllersPurchasingCustomFieldListAPIResponseCustomFieldDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingCustomFieldListAPIResponseCustomFieldDetails {
  dataModel?:
    | 'User'
    | 'Location'
    | 'Supplier'
    | 'Order'
    | 'Invoice'
    | 'Shipment'
    | 'Product'
    | 'Department'
    | 'OrderSupplier'
  /** @format int32 */
  id?: number
  name?: string
  showOnPDF?: boolean
  type?: 'ShortText' | 'LongText' | 'Number' | 'Date' | 'Boolean' | 'Currency'
}

export interface MainStemAPIControllersPurchasingCustomFieldUpdateAPIRequest {
  /** @format int32 */
  id?: number
  name?: string
  showOnPDF?: boolean
}

export interface MainStemAPIControllersPurchasingCustomFieldUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingCustomFieldValueAddAPIRequest {
  /** @format int32 */
  customFieldID?: number
  /** @format int32 */
  documentID?: number
  documentIDString?: string
  value?: string
}

export interface MainStemAPIControllersPurchasingCustomFieldValueAddAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingCustomFieldValueListAPIRequest {
  dataModel?:
    | 'User'
    | 'Location'
    | 'Supplier'
    | 'Order'
    | 'Invoice'
    | 'Shipment'
    | 'Product'
    | 'Department'
    | 'OrderSupplier'
  /** @format int32 */
  documentId?: number
  documentIdString?: string
}

export interface MainStemAPIControllersPurchasingCustomFieldValueListAPIResponse {
  customFieldValues?: MainStemAPIControllersPurchasingCustomFieldValueListAPIResponseCustomFieldValueDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingCustomFieldValueListAPIResponseCustomFieldValueDetails {
  field?: MainStemAPIControllersPurchasingCustomFieldValueListAPIResponseCustomFieldValueDetailsCustomFieldDetails
  /** @format int32 */
  id?: number
  value?: string
}

export interface MainStemAPIControllersPurchasingCustomFieldValueListAPIResponseCustomFieldValueDetailsCustomFieldDetails {
  /** @format int32 */
  id?: number
  name?: string
  type?: 'ShortText' | 'LongText' | 'Number' | 'Date' | 'Boolean' | 'Currency'
}

export interface MainStemAPIControllersPurchasingCustomFieldValueUpdateAPIRequest {
  /** @format int32 */
  customFieldValueId?: number
  value?: string
}

export interface MainStemAPIControllersPurchasingCustomFieldValueUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardApprovalApprovalsByLocationDataAPIRequest {
  /** @format date-time */
  date?: string
}

export interface MainStemAPIControllersPurchasingDashboardApprovalApprovalsByLocationDataAPIResponse {
  data?: MainStemAPIControllersPurchasingDashboardApprovalApprovalsByLocationDataAPIResponseDataDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardApprovalApprovalsByLocationDataAPIResponseDataDetails {
  /** @format int32 */
  count?: number
  location?: string
}

export interface MainStemAPIControllersPurchasingDashboardApprovalApprovalsByLocationOptionsAPIResponse {
  options?: MainStemAPIControllersPurchasingDashboardApprovalApprovalsByLocationOptionsAPIResponseOptionDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardApprovalApprovalsByLocationOptionsAPIResponseOptionDetails {
  text?: string
  /** @format date-time */
  value?: string
}

export interface MainStemAPIControllersPurchasingDashboardApprovalOrdersMyApprovalAPIResponse {
  approvals?: MainStemAPIControllersPurchasingDashboardApprovalOrdersMyApprovalApprovalDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardApprovalOrdersMyApprovalApprovalDetails {
  approvalLevel?: MainStemAPIControllersPurchasingDashboardApprovalOrdersMyApprovalApprovalWorkflowDetails
  approvalStatus?: 'Pending' | 'Approved' | 'Rejected'
  location?: MainStemAPIControllersPurchasingDashboardApprovalOrdersMyApprovalLocation
  /** @format int32 */
  locationID?: number
  /** @format int32 */
  orderID?: number
  uuid?: string
}

export interface MainStemAPIControllersPurchasingDashboardApprovalOrdersMyApprovalApprovalWorkflowDetails {
  /** @format int32 */
  id?: number
  levelRange?: string
  /** @format double */
  maxAmount?: number
  /** @format double */
  minAmount?: number
  users?: MainStemAPIControllersPurchasingDashboardApprovalOrdersMyApprovalUserDetails[]
}

export interface MainStemAPIControllersPurchasingDashboardApprovalOrdersMyApprovalLocation {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingDashboardApprovalOrdersMyApprovalUserDetails {
  approvalType?: 'Pending' | 'Approved' | 'Rejected'
  /** @format date-time */
  dateNotified?: string
  /** @format date-time */
  dateOfDecision?: string
  friendlyName?: string
  id?: string
  userName?: string
}

export interface MainStemAPIControllersPurchasingDashboardApprovalOrdersOthersApprovalAPIResponse {
  approvals?: MainStemAPIControllersPurchasingDashboardApprovalOrdersOthersApprovalApprovalDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardApprovalOrdersOthersApprovalApprovalDetails {
  approvalLevel?: MainStemAPIControllersPurchasingDashboardApprovalOrdersOthersApprovalApprovalWorkflowDetails
  approvalStatus?: 'Pending' | 'Approved' | 'Rejected'
  location?: MainStemAPIControllersPurchasingDashboardApprovalOrdersOthersApprovalLocation
  /** @format int32 */
  locationID?: number
  /** @format int32 */
  orderID?: number
  uuid?: string
}

export interface MainStemAPIControllersPurchasingDashboardApprovalOrdersOthersApprovalApprovalWorkflowDetails {
  /** @format int32 */
  id?: number
  levelRange?: string
  /** @format double */
  maxAmount?: number
  /** @format double */
  minAmount?: number
  users?: MainStemAPIControllersPurchasingDashboardApprovalOrdersOthersApprovalUserDetails[]
}

export interface MainStemAPIControllersPurchasingDashboardApprovalOrdersOthersApprovalLocation {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingDashboardApprovalOrdersOthersApprovalUserDetails {
  approvalType?: 'Pending' | 'Approved' | 'Rejected'
  /** @format date-time */
  dateNotified?: string
  /** @format date-time */
  dateOfDecision?: string
  friendlyName?: string
  id?: string
  userName?: string
}

export interface MainStemAPIControllersPurchasingDashboardApprovalTotalApprovalsDataAPIRequest {
  /** @format date-time */
  date?: string
}

export interface MainStemAPIControllersPurchasingDashboardApprovalTotalApprovalsDataAPIResponse {
  data?: MainStemAPIControllersPurchasingDashboardApprovalTotalApprovalsDataAPIResponseDataDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardApprovalTotalApprovalsDataAPIResponseDataDetails {
  /** @format int32 */
  count?: number
  /** @format date-time */
  date?: string
}

export interface MainStemAPIControllersPurchasingDashboardApprovalTotalApprovalsOptionsAPIResponse {
  options?: MainStemAPIControllersPurchasingDashboardApprovalTotalApprovalsOptionsAPIResponseOptionDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardApprovalTotalApprovalsOptionsAPIResponseOptionDetails {
  text?: string
  /** @format date-time */
  value?: string
}

export interface MainStemAPIControllersPurchasingDashboardBudgetsAPIRequest {
  /** @format int32 */
  month?: number
  /** @format int32 */
  year?: number
}

export interface MainStemAPIControllersPurchasingDashboardBudgetsAPIResponse {
  locations?: MainStemAPIControllersPurchasingDashboardBudgetsAPIResponseLocationBudgetDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardBudgetsAPIResponseLocationBudgetDetails {
  /** @format double */
  budget?: number
  location?: MainStemAPIControllersPurchasingDashboardBudgetsAPIResponseLocationBudgetDetailsLocationDetails
  /** @format double */
  spend?: number
}

export interface MainStemAPIControllersPurchasingDashboardBudgetsAPIResponseLocationBudgetDetailsLocationDetails {
  address?: MainStemAPIControllersPurchasingDashboardBudgetsAPIResponseLocationBudgetDetailsLocationDetailsAddressDetails
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingDashboardBudgetsAPIResponseLocationBudgetDetailsLocationDetailsAddressDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingDashboardCanoldAPIResponse {
  reports?: MainStemSharpModelsIntegrationsSettingsCanoldEmbeddedReportDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemSharpModelsIntegrationsSettingsCanoldEmbeddedReportDetails {
  description?: string
  name?: string
  url?: string
}

export interface MainStemAPIControllersPurchasingDashboardCustomCreateAPIRequest {
  name?: string
  type?: 'Custom' | 'SupplyManagement' | 'Approvers' | 'Finance'
}

export interface MainStemAPIControllersPurchasingDashboardCustomCreateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardCustomDeleteAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingDashboardCustomDeleteAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardCustomListAPIResponse {
  dashboards: MainStemAPIControllersPurchasingDashboardCustomListAPIResponseDashboardDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardCustomListAPIResponseDashboardDetails {
  /** @format int32 */
  id: number
  name: string
  /** @format int32 */
  order: number
  rows: MainStemAPIControllersPurchasingDashboardCustomListAPIResponseDashboardDetailsDashboardRowDetails[]
}

export interface MainStemAPIControllersPurchasingDashboardCustomListAPIResponseDashboardDetailsDashboardRowDetails {
  /** @format int32 */
  id: number
  /** @format int32 */
  order: number
  panels: MainStemAPIControllersPurchasingDashboardCustomListAPIResponseDashboardDetailsDashboardRowDetailsDashboardPanelDetails[]
}

export interface MainStemAPIControllersPurchasingDashboardCustomListAPIResponseDashboardDetailsDashboardRowDetailsDashboardPanelDetails {
  /** @format int32 */
  id: number
  widgets: MainStemAPIControllersPurchasingDashboardCustomListAPIResponseDashboardDetailsDashboardRowDetailsDashboardPanelDetailsDashboardWiddgetDetails[]
  /** @format int32 */
  width: number
}

export interface MainStemAPIControllersPurchasingDashboardCustomListAPIResponseDashboardDetailsDashboardRowDetailsDashboardPanelDetailsDashboardWiddgetDetails {
  description?: string
  /** @format int32 */
  id: number
  image?: string
  name: string
  /** @format int32 */
  order: number
  type: 'Marketplace' | 'Finance' | 'Approvals' | 'Organization' | 'Procurement' | 'Shipments'
}

export interface MainStemAPIControllersPurchasingDashboardCustomRowCreateAPIRequest {
  /** @format int32 */
  dashboardId?: number
  panelType?: 'Single' | 'Split' | 'Left' | 'Right' | 'FourColumns'
}

export interface MainStemAPIControllersPurchasingDashboardCustomRowCreateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardCustomRowDeleteAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingDashboardCustomRowDeleteAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardCustomRowUpdateAPIRequest {
  /** @format int32 */
  id?: number
  /** @format int32 */
  order?: number
}

export interface MainStemAPIControllersPurchasingDashboardCustomRowUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardCustomUpdateAPIRequest {
  /** @format int32 */
  id?: number
  name?: string
  /** @format int32 */
  order?: number
}

export interface MainStemAPIControllersPurchasingDashboardCustomUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardCustomWidgetAddAPIRequest {
  /** @format int32 */
  panelId?: number
  /** @format int32 */
  widgetId?: number
}

export interface MainStemAPIControllersPurchasingDashboardCustomWidgetAddAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardCustomWidgetListAPIResponse {
  approvals: MainStemAPIControllersPurchasingDashboardCustomWidgetListAPIResponseDashboardWidgetDetails[]
  finance: MainStemAPIControllersPurchasingDashboardCustomWidgetListAPIResponseDashboardWidgetDetails[]
  marketplace: MainStemAPIControllersPurchasingDashboardCustomWidgetListAPIResponseDashboardWidgetDetails[]
  organization: MainStemAPIControllersPurchasingDashboardCustomWidgetListAPIResponseDashboardWidgetDetails[]
  procurement: MainStemAPIControllersPurchasingDashboardCustomWidgetListAPIResponseDashboardWidgetDetails[]
  shipments: MainStemAPIControllersPurchasingDashboardCustomWidgetListAPIResponseDashboardWidgetDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardCustomWidgetListAPIResponseDashboardWidgetDetails {
  description?: string
  /** @format int32 */
  id: number
  image?: string
  name?: string
  type: 'Marketplace' | 'Finance' | 'Approvals' | 'Organization' | 'Procurement' | 'Shipments'
}

export interface MainStemAPIControllersPurchasingDashboardCustomWidgetRelocateAPIRequest {
  /** @format int32 */
  newPanelId?: number
  /** @format int32 */
  oldPanelId?: number
  /** @format int32 */
  widgetId?: number
}

export interface MainStemAPIControllersPurchasingDashboardCustomWidgetRelocateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardCustomWidgetRemoveAPIRequest {
  /** @format int32 */
  panelId?: number
  /** @format int32 */
  widgetId?: number
}

export interface MainStemAPIControllersPurchasingDashboardCustomWidgetRemoveAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardFinanceOrderApprovalsAPIResponse {
  approvals?: MainStemAPIControllersPurchasingDashboardFinanceOrderApprovalsApprovalDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardFinanceOrderApprovalsApprovalDetails {
  approvalLevel?: MainStemAPIControllersPurchasingDashboardFinanceOrderApprovalsApprovalWorkflowDetails
  approvalStatus?: 'Pending' | 'Approved' | 'Rejected'
  location?: MainStemAPIControllersPurchasingDashboardFinanceOrderApprovalsLocation
  /** @format int32 */
  locationID?: number
  /** @format int32 */
  orderID?: number
  userRelated?: boolean
  uuid?: string
}

export interface MainStemAPIControllersPurchasingDashboardFinanceOrderApprovalsApprovalWorkflowDetails {
  /** @format int32 */
  id?: number
  levelRange?: string
  /** @format double */
  maxAmount?: number
  /** @format double */
  minAmount?: number
  users?: MainStemAPIControllersPurchasingDashboardFinanceOrderApprovalsUserDetails[]
}

export interface MainStemAPIControllersPurchasingDashboardFinanceOrderApprovalsLocation {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingDashboardFinanceOrderApprovalsUserDetails {
  approvalType?: 'Pending' | 'Approved' | 'Rejected'
  /** @format date-time */
  dateNotified?: string
  /** @format date-time */
  dateOfDecision?: string
  friendlyName?: string
  id?: string
  userName?: string
}

export interface MainStemAPIControllersPurchasingDashboardFinanceOpenInvoicesAPIResponse {
  invoices?: MainStemAPIControllersPurchasingDashboardFinanceOpenInvoicesAPIResponseInvoiceDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardFinanceOpenInvoicesAPIResponseInvoiceDetails {
  /** @format int32 */
  customerIntegrationSessionID?: number
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateDue?: string
  /** @format date-time */
  dateShipped?: string
  /** @format double */
  discount?: number
  /** @format date-time */
  dueAsOf?: string
  hasTermsApplied?: boolean
  /** @format int32 */
  id?: number
  invoiceId?: string
  /** @format double */
  invoiceSubTotal?: number
  invoiceTermsIsDue?: boolean
  /** @format double */
  invoiceTotal?: number
  isPrivateSupplier?: boolean
  isPunchOut?: boolean
  isRFQ?: boolean
  location?: MainStemAPIControllersPurchasingDashboardFinanceOpenInvoicesAPIResponseInvoiceDetailsLocationDetails
  /** @format int32 */
  orderId?: number
  orderUUID?: string
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  status?: string
  supplier?: MainStemAPIControllersPurchasingDashboardFinanceOpenInvoicesAPIResponseInvoiceDetailsSupplierDetails
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  /** @format int32 */
  termLedgerID?: number
  /** @format double */
  totalDue?: number
  /** @format double */
  totalPaid?: number
  /** @format double */
  totalReturned?: number
  userApprovedBy?: string
  userRelated?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardFinanceOpenInvoicesAPIResponseInvoiceDetailsLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingDashboardFinanceOpenInvoicesAPIResponseInvoiceDetailsSupplierDetails {
  /** @format int32 */
  id: number
  name: string
}

export interface MainStemAPIControllersPurchasingDashboardFinanceOpenOrdersAPIResponse {
  orders?: MainStemAPIControllersPurchasingDashboardFinanceOpenOrdersAPIResponseOrderDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardFinanceOpenOrdersAPIResponseOrderDetails {
  /** @format date-time */
  dateApproved?: string
  /** @format double */
  discount?: number
  hasTermsApplied?: boolean
  /** @format int32 */
  id?: number
  location?: MainStemAPIControllersPurchasingDashboardFinanceOpenOrdersAPIResponseOrderDetailsLocationDetails
  /** @format double */
  orderTotal?: number
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  status?: string
  /** @format double */
  subTotal?: number
  /** @format double */
  totalDue?: number
  /** @format double */
  totalPaid?: number
  /** @format double */
  totalReturned?: number
  userApprovedBy?: string
  userRelated?: boolean
  uuid?: string
}

export interface MainStemAPIControllersPurchasingDashboardFinanceOpenOrdersAPIResponseOrderDetailsLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingDashboardFinancePaymentsAPIResponse {
  payments?: MainStemAPIControllersPurchasingDashboardFinancePaymentsAPIResponsePaymentDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardFinancePaymentsAPIResponsePaymentDetails {
  /** @format double */
  amount?: number
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  location?: MainStemAPIControllersPurchasingDashboardFinancePaymentsAPIResponsePaymentDetailsLocationDetails
  orderID?: string
  orderUUID?: string
  status?: string
  type?: string
}

export interface MainStemAPIControllersPurchasingDashboardFinancePaymentsAPIResponsePaymentDetailsLocationDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingDashboardFinanceSalesByLocationDataAPIRequest {
  /** @format date-time */
  date?: string
}

export interface MainStemAPIControllersPurchasingDashboardFinanceSalesByLocationDataAPIResponse {
  data?: MainStemAPIControllersPurchasingDashboardFinanceSalesByLocationDataAPIResponseDataDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardFinanceSalesByLocationDataAPIResponseDataDetails {
  /** @format double */
  amount?: number
  location?: string
}

export interface MainStemAPIControllersPurchasingDashboardFinanceSalesByLocationOptionsAPIResponse {
  options?: MainStemAPIControllersPurchasingDashboardFinanceSalesByLocationOptionsAPIResponseOptionDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardFinanceSalesByLocationOptionsAPIResponseOptionDetails {
  text?: string
  /** @format date-time */
  value?: string
}

export interface MainStemAPIControllersPurchasingDashboardFinanceTermsLedgerAPIResponse {
  ledger?: MainStemAPIControllersPurchasingDashboardFinanceTermsLedgerAPIResponseTermLedger[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardFinanceTermsLedgerAPIResponseTermLedger {
  /** @format double */
  amount?: number
  /** @format date-time */
  date?: string
  description?: string
  visible?: boolean
  /** @format int32 */
  orderID?: number
  orderUUID?: string
  /** @format double */
  total?: number
}

export interface MainStemAPIControllersPurchasingDashboardFinanceTotalSalesDataAPIRequest {
  /** @format date-time */
  date?: string
}

export interface MainStemAPIControllersPurchasingDashboardFinanceTotalSalesDataAPIResponse {
  data?: MainStemAPIControllersPurchasingDashboardFinanceTotalSalesDataAPIResponseDataDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardFinanceTotalSalesDataAPIResponseDataDetails {
  /** @format double */
  amount?: number
  /** @format date-time */
  date?: string
}

export interface MainStemAPIControllersPurchasingDashboardFinanceTotalSalesOptionsAPIResponse {
  options?: MainStemAPIControllersPurchasingDashboardFinanceTotalSalesOptionsAPIResponseOptionDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardFinanceTotalSalesOptionsAPIResponseOptionDetails {
  text?: string
  /** @format date-time */
  value?: string
}

export interface MainStemAPIControllersPurchasingDashboardFinanceUsersAPIResponse {
  users?: MainStemAPIControllersPurchasingDashboardFinanceUsersAPIResponseUserDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardFinanceUsersAPIResponseUserDetails {
  /** @format date-time */
  dateCreated?: string
  email?: string
  friendlyName?: string
  role?: string
  username?: string
  uuid?: string
  permissionGroup?: string
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingGetMapDetailDeprecateAPIRequest {
  /** @format int32 */
  locationId?: number
  /** @format date-time */
  endDate?: string
  /** @format date-time */
  startDate?: string
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingGetMapDetailDeprecateAPIResponse {
  address?: MainStemAPIControllersPurchasingDashboardPurchasingGetMapDetailDeprecateAPIResponseAddressDetails
  errorMessage?: string
  /** @format int32 */
  locationId?: number
  name?: string
  ordersAwaitingApproval?: MainStemAPIControllersPurchasingDashboardPurchasingGetMapDetailDeprecateOrdersAwaitingApproval[]
  ordersInTransit?: MainStemAPIControllersPurchasingDashboardPurchasingGetMapDetailDeprecateOrdersInTransit[]
  /** @format double */
  totalMonthlySpend?: number
  users?: MainStemAPIControllersPurchasingDashboardPurchasingGetMapDetailDeprecateUsersAtLocation[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingGetMapDetailDeprecateAPIResponseAddressDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingGetMapDetailDeprecateOrdersAwaitingApproval {
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  orderID?: number
  vendors?: MainStemAPIControllersPurchasingDashboardPurchasingGetMapDetailDeprecateLocation[]
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingGetMapDetailDeprecateOrdersInTransit {
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateShipped?: string
  /** @format int32 */
  fulfillmentID?: number
  /** @format int32 */
  orderID?: number
  vendor?: MainStemAPIControllersPurchasingDashboardPurchasingGetMapDetailDeprecateLocation
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingGetMapDetailDeprecateUsersAtLocation {
  email?: string
  friendlyName?: string
  isPrimaryUser?: boolean
  userID?: string
  userName?: string
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingGetMapDetailDeprecateLocation {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  locationID?: number
  locationName?: string
  state?: string
  zip?: string
}

export type MainStemAPIControllersPurchasingDashboardInventoryAPIRequest = object

export interface MainStemAPIControllersPurchasingDashboardInventoryAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsDetailsInvoicesAwaitingShipmentAPIRequest {
  /** @format int32 */
  locationId?: number
}

export interface MainStemAPIControllersPurchasingLocationsDetailsInvoicesAwaitingShipmentAPIResponse {
  invoicesAwaitingShipment?: MainStemAPIControllersPurchasingLocationsDetailsInvoicesAwaitingShipmentInvoiceDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsDetailsInvoicesAwaitingShipmentInvoiceDetail {
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateShipped?: string
  /** @format int32 */
  fulfillmentID?: number
  location?: MainStemAPIControllersPurchasingLocationsDetailsInvoicesAwaitingShipmentLocation
  /** @format int32 */
  orderID?: number
  /** @format double */
  total?: number
  vendor?: MainStemAPIControllersPurchasingLocationsDetailsInvoicesAwaitingShipmentLocation
}

export interface MainStemAPIControllersPurchasingLocationsDetailsInvoicesAwaitingShipmentLocation {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  locationID?: number
  locationName?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingLocationsDetailsOrderApprovalsAPIRequest {
  /** @format int32 */
  locationId?: number
}

export interface MainStemAPIControllersPurchasingLocationsDetailsOrderApprovalsAPIResponse {
  ordersForApproval?: MainStemAPIControllersPurchasingLocationsDetailsOrderApprovalsOrderForApproval[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsDetailsOrderApprovalsOrderForApproval {
  actionRequiredCommunication?: MainStemSharpDataModelsOrderCommunication[]
  approvalStatus?: 'SavedCart' | 'AwaitingApproval' | 'Approval' | 'ApprovalWithEdits' | 'Rejected'
  /** @format date-time */
  approvalStatusAsOfDate?: string
  /** @format date-time */
  dateCreated?: string
  location?: MainStemAPIControllersPurchasingLocationsDetailsOrderApprovalsLocation
  /** @format int32 */
  orderID?: number
  /** @format int32 */
  totalNumberOfProducts?: number
  /** @format int32 */
  totalNumberOfVendors?: number
  /** @format double */
  totalOrderLineItemCost?: number
  userCreatedBy?: string
  userRelated?: boolean
  uuid?: string
}

export interface MainStemSharpDataModelsOrderCommunication {
  actionResolved?: boolean
  actionResolvedBy?: string
  businessPartner?: MainStemSharpDataModelsBusinessPartner
  /** @format int32 */
  businessPartnerID?: number
  comment?: string
  createdByUser?: MainStemSharpDataModelsApplicationUser
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateModified?: string
  /** @format date-time */
  dateResolved?: string
  /** @format int32 */
  fulfillmentID?: number
  fulfillmentMethod?: MainStemSharpDataModelsFulfillmentMethod
  /** @format int32 */
  id?: number
  isDeleted?: boolean
  order?: MainStemSharpDataModelsOrder
  /** @format int32 */
  orderID?: number
  requiresAction?: boolean
  resolvedByUser?: MainStemSharpDataModelsApplicationUser
  userCreatedBy?: string
}

export interface MainStemAPIControllersPurchasingLocationsDetailsOrderApprovalsLocation {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export type MainStemAPIControllersPurchasingDashboardPurchasingPurchaseOrdersAwaitingAcceptanceAPIRequest = object

export interface MainStemAPIControllersPurchasingDashboardPurchasingPurchaseOrdersAwaitingAcceptanceAPIResponse {
  errorMessage?: string
  purchaseOrders?: MainStemAPIControllersPurchasingDashboardPurchasingPurchaseOrdersAwaitingAcceptancePurchaseOrderForApproval[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingPurchaseOrdersAwaitingAcceptancePurchaseOrderForApproval {
  acceptanceStatus?: 'Cancelled' | 'Pending' | 'Accepted' | 'Rejected'
  acknowledgedBy?: string
  acknowledgedByUserId?: string
  acknowledgementType?: 'MainStemPixel' | 'SupplierCoreOrderDetails' | 'Integration'
  approvalStatus?: 'SavedCart' | 'AwaitingApproval' | 'Approval' | 'ApprovalWithEdits' | 'Rejected'
  /** @format date-time */
  approvalStatusAsOfDate?: string
  /** @format date-time */
  dateAccepted?: string
  /** @format date-time */
  dateAcknowledged?: string
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  fulfillmentID?: number
  location?: MainStemAPIControllersPurchasingDashboardPurchasingPurchaseOrdersAwaitingAcceptanceLocation
  /** @format int32 */
  orderID?: number
  /** @format int32 */
  supplierID?: number
  supplierLogoURL?: string
  supplierName?: string
  /** @format double */
  totalCost?: number
  /** @format int32 */
  totalNumberOfProducts?: number
  userCreatedBy?: string
  userRelated?: boolean
  uuid?: string
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingPurchaseOrdersAwaitingAcceptanceLocation {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export type MainStemAPIControllersPurchasingDashboardPurchasingPurchaseOrdersAwaitingAcknowledgementAPIRequest = object

export interface MainStemAPIControllersPurchasingDashboardPurchasingPurchaseOrdersAwaitingAcknowledgementAPIResponse {
  errorMessage?: string
  purchaseOrders?: MainStemAPIControllersPurchasingDashboardPurchasingPurchaseOrdersAwaitingAcknowledgementPurchaseOrderForApproval[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingPurchaseOrdersAwaitingAcknowledgementPurchaseOrderForApproval {
  acceptanceStatus?: 'Cancelled' | 'Pending' | 'Accepted' | 'Rejected'
  acknowledgedBy?: string
  acknowledgedByUserId?: string
  acknowledgementType?: 'MainStemPixel' | 'SupplierCoreOrderDetails' | 'Integration'
  approvalStatus?: 'SavedCart' | 'AwaitingApproval' | 'Approval' | 'ApprovalWithEdits' | 'Rejected'
  /** @format date-time */
  approvalStatusAsOfDate?: string
  /** @format date-time */
  dateAccepted?: string
  /** @format date-time */
  dateAcknowledged?: string
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  fulfillmentID?: number
  location?: MainStemAPIControllersPurchasingDashboardPurchasingPurchaseOrdersAwaitingAcknowledgementLocation
  /** @format int32 */
  orderID?: number
  /** @format int32 */
  supplierID?: number
  supplierLogoURL?: string
  supplierName?: string
  /** @format double */
  totalCost?: number
  /** @format int32 */
  totalNumberOfProducts?: number
  userCreatedBy?: string
  userRelated?: boolean
  uuid?: string
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingPurchaseOrdersAwaitingAcknowledgementLocation {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingOrderApprovalsAPIResponse {
  approvals?: MainStemAPIControllersPurchasingDashboardPurchasingOrderApprovalsApprovalDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingOrderApprovalsApprovalDetails {
  approvalLevel?: MainStemAPIControllersPurchasingDashboardPurchasingOrderApprovalsApprovalWorkflowDetails
  approvalStatus?: 'Pending' | 'Approved' | 'Rejected'
  location?: MainStemAPIControllersPurchasingDashboardPurchasingOrderApprovalsLocation
  /** @format int32 */
  locationID?: number
  /** @format int32 */
  orderID?: number
  userRelated?: boolean
  uuid?: string
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingOrderApprovalsApprovalWorkflowDetails {
  /** @format int32 */
  id?: number
  levelRange?: string
  /** @format double */
  maxAmount?: number
  /** @format double */
  minAmount?: number
  users?: MainStemAPIControllersPurchasingDashboardPurchasingOrderApprovalsUserDetails[]
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingOrderApprovalsLocation {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingOrderApprovalsUserDetails {
  approvalType?: 'Pending' | 'Approved' | 'Rejected'
  /** @format date-time */
  dateNotified?: string
  /** @format date-time */
  dateOfDecision?: string
  friendlyName?: string
  id?: string
  userName?: string
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingGetOrganizationSpendDeprecateAPIRequest {
  /** @format date-time */
  endDate?: string
  /** @format date-time */
  startDate?: string
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingGetOrganizationSpendDeprecateAPIResponse {
  errorMessage?: string
  spendingByEntityLocations?: MainStemAPIControllersPurchasingDashboardPurchasingGetOrganizationSpendDeprecateTotalSalesByBusinessPartner[]
  spendingByUsers?: MainStemAPIControllersPurchasingDashboardPurchasingGetOrganizationSpendDeprecateDistinctOrdersByUsers[]
  spendingPerVendors?: MainStemAPIControllersPurchasingDashboardPurchasingGetOrganizationSpendDeprecatePurchasesByVendor[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingGetOrganizationSpendDeprecateTotalSalesByBusinessPartner {
  distinctOrdersByUsers?: MainStemAPIControllersPurchasingDashboardPurchasingGetOrganizationSpendDeprecateDistinctOrdersByUsers[]
  /** @format int32 */
  ordersMade?: number
  /** @format double */
  totalOrderCost?: number
  /** @format double */
  totalProductCost?: number
  /** @format double */
  totalShippingCost?: number
  /** @format double */
  totalTaxCost?: number
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  locationID?: number
  locationName?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingGetOrganizationSpendDeprecateDistinctOrdersByUsers {
  invoices?: MainStemSharpModelsInvoicesInvoiceBalanceDetails[]
  /** @format int32 */
  ordersMade?: number
  /** @format double */
  totalOrderCost?: number
  /** @format double */
  totalProductCost?: number
  /** @format double */
  totalShippingCost?: number
  /** @format double */
  totalTaxCost?: number
  userID?: string
  userName?: string
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  locationID?: number
  locationName?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingGetOrganizationSpendDeprecatePurchasesByVendor {
  orderShippingCosts?: MainStemAPIControllersPurchasingDashboardPurchasingGetOrganizationSpendDeprecateOrderShippingCost[]
  orderTaxCosts?: MainStemAPIControllersPurchasingDashboardPurchasingGetOrganizationSpendDeprecateOrderTaxCost[]
  /** @format int32 */
  productCount?: number
  purchaseProducts?: MainStemAPIControllersPurchasingDashboardPurchasingGetOrganizationSpendDeprecatePurchaseProductDetail[]
  /** @format double */
  totalOrderCost?: number
  /** @format double */
  totalProductCost?: number
  /** @format double */
  totalShippingCost?: number
  /** @format double */
  totalTaxCost?: number
  /** @format int32 */
  vendorID?: number
  vendorName?: string
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  locationID?: number
  locationName?: string
  state?: string
  zip?: string
}

export interface MainStemSharpModelsInvoicesInvoiceBalanceDetails {
  /** @format double */
  allFulfillmentTotals?: number
  /** @format int32 */
  supplierId?: number
  supplierName?: string
  /** @format date-time */
  dateShippedOn?: string
  /** @format double */
  discount?: number
  /** @format int32 */
  invoiceId?: number
  id?: string
  /** @format double */
  invoicePaymentsMade?: number
  /** @format double */
  subTotal?: number
  /** @format int32 */
  orderId?: number
  /** @format double */
  orderPaymentsMade?: number
  /** @format double */
  salesTax?: number
  /** @format double */
  shippingCost?: number
  termsApplied?: boolean
  /** @format double */
  total?: number
  userId?: string
  userName?: string
  isPrivate?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingGetOrganizationSpendDeprecateOrderShippingCost {
  /** @format int32 */
  fulfillmentMethodID?: number
  /** @format int32 */
  orderID?: number
  /** @format double */
  shippingCost?: number
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingGetOrganizationSpendDeprecateOrderTaxCost {
  /** @format int32 */
  fulfillmentMethodID?: number
  /** @format int32 */
  orderID?: number
  /** @format double */
  taxCost?: number
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingGetOrganizationSpendDeprecatePurchaseProductDetail {
  /** @format int32 */
  productID?: number
  /** @format int32 */
  quantity?: number
  /** @format double */
  totalCost?: number
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingMapAPIResponse {
  errorMessage?: string
  locations?: MainStemAPIControllersPurchasingDashboardPurchasingMapLocation[]
  markers?: MainStemAPIControllersPurchasingDashboardPurchasingMapMarkerLocation[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingMapLocation {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  /** @format double */
  latitude?: number
  /** @format double */
  longitude?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingMapMarkerLocation {
  address?: MainStemAPIControllersPurchasingDashboardPurchasingMapAddressInfo
  /** @format int32 */
  id?: number
  /** @format double */
  lat?: number
  /** @format double */
  lon?: number
  name?: string
  popup?: object
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingMapAddressInfo {
  address1?: string
  address2?: string
  city?: string
  country?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingMapDetailAPIRequest {
  /** @format int32 */
  locationId?: number
  /** @format date-time */
  endDate?: string
  /** @format date-time */
  startDate?: string
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingMapDetailAPIResponse {
  address?: MainStemAPIControllersPurchasingDashboardPurchasingMapDetailAPIResponseAddressDetails
  errorMessage?: string
  invoicesAwaitingShipment?: MainStemAPIControllersPurchasingDashboardPurchasingMapDetailInvoiceDetail[]
  invoicesToBeReceived?: MainStemAPIControllersPurchasingDashboardPurchasingMapDetailInvoiceDetail[]
  /** @format int32 */
  locationId?: number
  name?: string
  ordersApproved?: MainStemAPIControllersPurchasingDashboardPurchasingMapDetailOrderDetails[]
  ordersAwaitingApproval?: MainStemAPIControllersPurchasingDashboardPurchasingMapDetailOrdersAwaitingApproval[]
  /** @format int32 */
  totalApprovedOrders?: number
  /** @format double */
  totalMonthlySpend?: number
  users?: MainStemAPIControllersPurchasingDashboardPurchasingMapDetailUserDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingMapDetailAPIResponseAddressDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingMapDetailInvoiceDetail {
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateShipped?: string
  /** @format int32 */
  fulfillmentID?: number
  location?: MainStemAPIControllersPurchasingDashboardPurchasingMapDetailLocation
  /** @format int32 */
  orderID?: number
  /** @format double */
  total?: number
  vendor?: MainStemAPIControllersPurchasingDashboardPurchasingMapDetailLocation
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingMapDetailOrderDetails {
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  status?: string
  userApprovedBy?: string
  userCreatedBy?: string
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  uuid?: string
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingMapDetailOrdersAwaitingApproval {
  actionRequiredCommunication?: MainStemSharpDataModelsOrderCommunication[]
  approvalStatus?: 'SavedCart' | 'AwaitingApproval' | 'Approval' | 'ApprovalWithEdits' | 'Rejected'
  /** @format date-time */
  approvalStatusAsOfDate?: string
  /** @format date-time */
  dateCreated?: string
  location?: MainStemAPIControllersPurchasingDashboardPurchasingMapDetailLocation
  /** @format int32 */
  orderID?: number
  /** @format int32 */
  totalNumberOfProducts?: number
  /** @format int32 */
  totalNumberOfVendors?: number
  /** @format double */
  totalOrderLineItemCost?: number
  userCreatedBy?: string
  userRelated?: boolean
  uuid?: string
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingMapDetailUserDetails {
  /** @format date-time */
  dateCreated?: string
  email?: string
  friendlyName?: string
  id?: string
  permissionGroup?: string
  role?: string
  username?: string
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingMapDetailLocation {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  locationID?: number
  locationName?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingOrganizationSpendAPIRequest {
  /** @format date-time */
  endDate?: string
  /** @format date-time */
  startDate?: string
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingOrganizationSpendAPIResponse {
  errorMessage?: string
  spendingByEntityLocations?: MainStemAPIControllersPurchasingDashboardPurchasingOrganizationSpendTotalSalesByBusinessPartner[]
  spendingByUsers?: MainStemAPIControllersPurchasingDashboardPurchasingOrganizationSpendDistinctOrdersByUsers[]
  spendingPerVendors?: MainStemAPIControllersPurchasingDashboardPurchasingOrganizationSpendPurchasesByVendor[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingOrganizationSpendTotalSalesByBusinessPartner {
  distinctOrdersByUsers?: MainStemAPIControllersPurchasingDashboardPurchasingOrganizationSpendDistinctOrdersByUsers[]
  /** @format int32 */
  ordersMade?: number
  /** @format double */
  totalOrderCost?: number
  /** @format double */
  totalProductCost?: number
  /** @format double */
  totalShippingCost?: number
  /** @format double */
  totalTaxCost?: number
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  locationID?: number
  locationName?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingOrganizationSpendDistinctOrdersByUsers {
  invoices?: MainStemSharpModelsInvoicesInvoiceBalanceDetails[]
  /** @format int32 */
  ordersMade?: number
  /** @format double */
  totalOrderCost?: number
  /** @format double */
  totalProductCost?: number
  /** @format double */
  totalShippingCost?: number
  /** @format double */
  totalTaxCost?: number
  userID?: string
  userName?: string
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  locationID?: number
  locationName?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingOrganizationSpendPurchasesByVendor {
  orderShippingCosts?: MainStemAPIControllersPurchasingDashboardPurchasingOrganizationSpendOrderShippingCost[]
  orderTaxCosts?: MainStemAPIControllersPurchasingDashboardPurchasingOrganizationSpendOrderTaxCost[]
  /** @format int32 */
  productCount?: number
  purchaseProducts?: MainStemAPIControllersPurchasingDashboardPurchasingOrganizationSpendPurchaseProductDetail[]
  /** @format double */
  totalOrderCost?: number
  /** @format double */
  totalProductCost?: number
  /** @format double */
  totalShippingCost?: number
  /** @format double */
  totalTaxCost?: number
  /** @format int32 */
  vendorID?: number
  vendorName?: string
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  locationID?: number
  locationName?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingOrganizationSpendOrderShippingCost {
  /** @format int32 */
  fulfillmentMethodID?: number
  /** @format int32 */
  orderID?: number
  /** @format double */
  shippingCost?: number
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingOrganizationSpendOrderTaxCost {
  /** @format int32 */
  fulfillmentMethodID?: number
  /** @format int32 */
  orderID?: number
  /** @format double */
  taxCost?: number
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingOrganizationSpendPurchaseProductDetail {
  /** @format int32 */
  productID?: number
  /** @format int32 */
  quantity?: number
  /** @format double */
  totalCost?: number
}

export type MainStemAPIControllersPurchasingDashboardPurchasingUsersAPIRequest = object

export interface MainStemAPIControllersPurchasingDashboardPurchasingUsersAPIResponse {
  errorMessage?: string
  ordersForApproval?: MainStemAPIControllersPurchasingDashboardPurchasingUsersOrderForApproval[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingUsersOrderForApproval {
  actionRequiredCommunication?: MainStemSharpDataModelsOrderCommunication[]
  approvalStatus?: 'SavedCart' | 'AwaitingApproval' | 'Approval' | 'ApprovalWithEdits' | 'Rejected'
  /** @format date-time */
  approvalStatusAsOfDate?: string
  /** @format date-time */
  dateCreated?: string
  location?: MainStemAPIControllersPurchasingDashboardPurchasingUsersLocation
  /** @format int32 */
  orderID?: number
  /** @format int32 */
  totalNumberOfProducts?: number
  /** @format int32 */
  totalNumberOfVendors?: number
  /** @format double */
  totalOrderLineItemCost?: number
  userCreatedBy?: string
}

export interface MainStemAPIControllersPurchasingDashboardPurchasingUsersLocation {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingDashboardSupplyAuditAPIResponse {
  savings?: MainStemAPIControllersPurchasingDashboardSupplyAuditAPIResponseSavingsDetails
  supplyAuditsAutomated?: MainStemAPIControllersPurchasingDashboardSupplyAuditAPIResponseSupplyAuditAutomatedDetails[]
  supplyAuditsManual?: MainStemAPIControllersPurchasingDashboardSupplyAuditAPIResponseSupplyAuditManualDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDashboardSupplyAuditAPIResponseSavingsDetails {
  /** @format int32 */
  numberOfProductsBaselined?: number
  /** @format double */
  toDate?: number
  /** @format double */
  toDateMonth?: number
  /** @format double */
  toDateYear?: number
}

export interface MainStemAPIControllersPurchasingDashboardSupplyAuditAPIResponseSupplyAuditAutomatedDetails {
  location?: MainStemAPIControllersPurchasingDashboardSupplyAuditAPIResponseSupplyAuditAutomatedDetailsLocationDetails
  month?: string
  year?: string
}

export interface MainStemAPIControllersPurchasingDashboardSupplyAuditAPIResponseSupplyAuditManualDetails {
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  fileCount?: number
  /** @format int32 */
  id?: number
  title?: string
}

export interface MainStemAPIControllersPurchasingDashboardSupplyAuditAPIResponseSupplyAuditAutomatedDetailsLocationDetails {
  /** @format int32 */
  id?: number
  name?: string
  uuid?: string
}

export interface MainStemAPIControllersPurchasingDashboardTableauAPIResponse {
  reports?: MainStemSharpModelsIntegrationsSettingsTableauEmbeddedReportDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemSharpModelsIntegrationsSettingsTableauEmbeddedReportDetails {
  description?: string
  name?: string
  url?: string
}

export interface MainStemAPIControllersPurchasingDepartmentCreateAPIRequest {
  /** @format int32 */
  locationId?: number
  name?: string
  financialDimensions?: MainStemAPIControllersPurchasingDepartmentCreateAPIRequestFinancialDimensionUpdate[]
}

export interface MainStemAPIControllersPurchasingDepartmentCreateAPIRequestFinancialDimensionUpdate {
  /** @format int32 */
  financialDimensionID?: number
  /** @format int32 */
  financialDimensionOptionID?: number
}

export interface MainStemAPIControllersPurchasingDepartmentCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDepartmentDeleteAPIRequest {
  /** @format int32 */
  departmentId?: number
}

export interface MainStemAPIControllersPurchasingDepartmentDeleteAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDepartmentDetailsAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingDepartmentDetailsAPIResponse {
  financialDimension?: MainStemAPIControllersPurchasingDepartmentDetailsAPIResponseFinancialDimensionDetail
  financialDimensions?: MainStemSharpHelpersFinancialDimensionsFactoryFinancialDimensionOptionSelectionDetail[]
  invoices?: MainStemAPIControllersPurchasingDepartmentDetailsAPIResponseInvoiceDetails[]
  location?: MainStemAPIControllersPurchasingDepartmentDetailsAPIResponseLocationDetails
  name?: string
  receives?: MainStemAPIControllersPurchasingDepartmentDetailsAPIResponseReceiveDetails[]
  users?: MainStemAPIControllersPurchasingDepartmentDetailsAPIResponseUserDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDepartmentDetailsAPIResponseFinancialDimensionDetail {
  /** @format int32 */
  id?: number
  label?: string
  typeLabel?: string
  value?: string
}

export interface MainStemSharpHelpersFinancialDimensionsFactoryFinancialDimensionOptionSelectionDetail {
  /** @format int32 */
  financialDimensionID?: number
  financialDimensionLabel?: string
  /** @format int32 */
  financialDimensionOptionID?: number
  financialDimensionOptionLabel?: string
  financialDimensionOptionValue?: string
  /** @format int32 */
  financialDimensionRankOrder?: number
  type?: 'Product' | 'Location' | 'Department' | 'InvoiceLineItemAllocation' | 'Invoice' | 'Supplier'
}

export interface MainStemAPIControllersPurchasingDepartmentDetailsAPIResponseInvoiceDetails {
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  departmentID?: number
  departmentName?: string
  /** @format int32 */
  id?: number
  order?: MainStemAPIControllersPurchasingDepartmentDetailsAPIResponseInvoiceDetailsOrderDetails
  /** @format int32 */
  productID?: number
  productName?: string
  /** @format int32 */
  quantity?: number
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  userCreatedBy?: MainStemAPIControllersPurchasingDepartmentDetailsAPIResponseUserDetails
}

export interface MainStemAPIControllersPurchasingDepartmentDetailsAPIResponseLocationDetails {
  /** @format int32 */
  id?: number
  image?: string
  name?: string
}

export interface MainStemAPIControllersPurchasingDepartmentDetailsAPIResponseReceiveDetails {
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  departmentID?: number
  departmentName?: string
  /** @format int32 */
  id?: number
  /** @format int32 */
  productID?: number
  productName?: string
  /** @format int32 */
  quantity?: number
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  userCreatedBy?: MainStemAPIControllersPurchasingDepartmentDetailsAPIResponseUserDetails
  uuid?: string
}

export interface MainStemAPIControllersPurchasingDepartmentDetailsAPIResponseUserDetails {
  id?: string
  username?: string
}

export interface MainStemAPIControllersPurchasingDepartmentDetailsAPIResponseInvoiceDetailsOrderDetails {
  /** @format int32 */
  id?: number
  uuid?: string
}

export interface MainStemAPIControllersPurchasingDepartmentDuplicateAPIRequest {
  copyUsers?: boolean
  /** @format int32 */
  departmentId?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingDepartmentDuplicateAPIResponse {
  /** @format int32 */
  departmentId?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDepartmentExportAPIResponse {
  departments?: MainStemAPIControllersPurchasingDepartmentExportAPIResponseDepartmentDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDepartmentExportAPIResponseDepartmentDetails {
  /** @format int32 */
  id?: number
  /** @format int32 */
  locationId?: number
  locationName?: string
  name?: string
}

export interface MainStemAPIControllersPurchasingDepartmentListAPIRequest {
  /** @format int32 */
  locationId?: number
}

export interface MainStemAPIControllersPurchasingDepartmentListAPIResponse {
  departments?: MainStemAPIControllersPurchasingDepartmentListAPIResponseDepartmentDetails[]
  locations?: MainStemAPIControllersPurchasingDepartmentListAPIResponseLocationNodeDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingDepartmentListAPIResponseDepartmentDetails {
  /** @format int32 */
  id?: number
  leafLogixRoomLinkID?: string
  location?: MainStemAPIControllersPurchasingDepartmentListAPIResponseDepartmentDetailsLocationDetails
  name?: string
}

export interface MainStemAPIControllersPurchasingDepartmentListAPIResponseLocationNodeDetails {
  departments?: MainStemAPIControllersPurchasingDepartmentListAPIResponseLocationNodeDetailsDepartmentDetails[]
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingDepartmentListAPIResponseDepartmentDetailsLocationDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingDepartmentListAPIResponseLocationNodeDetailsDepartmentDetails {
  /** @format int32 */
  id?: number
  name?: string
  users?: MainStemAPIControllersPurchasingDepartmentListAPIResponseLocationNodeDetailsDepartmentDetailsUserDetails[]
}

export interface MainStemAPIControllersPurchasingDepartmentListAPIResponseLocationNodeDetailsDepartmentDetailsUserDetails {
  id?: string
  name?: string
  username?: string
}

export interface MainStemAPIControllersPurchasingDepartmentUpdateAPIRequest {
  /** @format int32 */
  id?: number
  name?: string
  financialDimensions?: MainStemAPIControllersPurchasingDepartmentUpdateAPIRequestFinancialDimensionUpdate[]
}

export interface MainStemAPIControllersPurchasingDepartmentUpdateAPIRequestFinancialDimensionUpdate {
  /** @format int32 */
  financialDimensionID?: number
  /** @format int32 */
  financialDimensionOptionID?: number
}

export interface MainStemAPIControllersPurchasingDepartmentUsersAddAPIRequest {
  /** @format int32 */
  departmentId?: number
  userIds?: string[]
}

export interface MainStemAPIControllersPurchasingDepartmentUsersRemoveAPIRequest {
  /** @format int32 */
  departmentId?: number
  userId?: string
}

export type MainStemAPIControllersPurchasingErrAPIRequest = object

export interface MainStemAPIControllersPurchasingFeedCreateAPIRequest {
  description?: string
  isPinned?: boolean
  title?: string
  url?: string
  userAssignedToId?: string
}

export interface MainStemAPIControllersPurchasingFeedCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingFeedDeleteAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingFeedDeleteAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingFeedDetailsAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingFeedDetailsAPIResponse {
  feed?: MainStemAPIControllersPurchasingFeedDetailsAPIResponseFeedDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingFeedDetailsAPIResponseFeedDetails {
  /** @format date-time */
  dateCreated?: string
  description?: string
  /** @format int32 */
  id?: number
  imageURL?: string
  isDeleted?: boolean
  isPinned?: boolean
  title?: string
  url?: string
  userAssignedTo?: MainStemAPIControllersPurchasingFeedDetailsAPIResponseFeedDetailsFeedStreamUserDetails
  userCreatedBy?: MainStemAPIControllersPurchasingFeedDetailsAPIResponseFeedDetailsFeedStreamUserDetails
}

export interface MainStemAPIControllersPurchasingFeedDetailsAPIResponseFeedDetailsFeedStreamUserDetails {
  id?: string
  imageURL?: string
  name?: string
}

export interface MainStemAPIControllersPurchasingFeedListAPIResponse {
  feeds?: MainStemAPIControllersPurchasingFeedListAPIResponseFeedDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingFeedListAPIResponseFeedDetails {
  /** @format date-time */
  dateCreated?: string
  description?: string
  /** @format int32 */
  id?: number
  imageURL?: string
  isDeleted?: boolean
  isPinned?: boolean
  title?: string
  url?: string
  userAssignedTo?: MainStemAPIControllersPurchasingFeedListAPIResponseFeedDetailsFeedStreamUserDetails
  userCreatedBy?: MainStemAPIControllersPurchasingFeedListAPIResponseFeedDetailsFeedStreamUserDetails
}

export interface MainStemAPIControllersPurchasingFeedListAPIResponseFeedDetailsFeedStreamUserDetails {
  id?: string
  imageURL?: string
  name?: string
}

export interface MainStemAPIControllersPurchasingFeedStreamAPIResponse {
  feeds?: MainStemAPIControllersPurchasingFeedStreamAPIResponseFeedStreamDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingFeedStreamAPIResponseFeedStreamDetails {
  description?: string
  /** @format int32 */
  id?: number
  imageURL?: string
  isPinned?: boolean
  title?: string
  url?: string
  userCreatedBy?: MainStemAPIControllersPurchasingFeedStreamAPIResponseFeedStreamDetailsFeedStreamUserDetails
}

export interface MainStemAPIControllersPurchasingFeedStreamAPIResponseFeedStreamDetailsFeedStreamUserDetails {
  id?: string
  imageURL?: string
  name?: string
}

export interface MainStemAPIControllersPurchasingFeedUpdateAPIRequest {
  description?: string
  /** @format int32 */
  id?: number
  isDeleted?: boolean
  isPinned?: boolean
  title?: string
  url?: string
  userAssignedToId?: string
}

export interface MainStemAPIControllersPurchasingFeedUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionCreateAPIRequest {
  type?: 'Product' | 'Location' | 'Department' | 'InvoiceLineItemAllocation' | 'Invoice' | 'Supplier'
  label?: string
  /** @format int32 */
  rankOrder?: number
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionDepartmentCustomerDepartmentsAPIRequest {
  /** @format int32 */
  financialDimensionID?: number
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionDepartmentCustomerDepartmentsAPIResponse {
  departments?: MainStemAPIControllersPurchasingAccountFinancialDimensionDepartmentCustomerDepartmentsAPIResponseDepartmentDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionDepartmentCustomerDepartmentsAPIResponseDepartmentDetail {
  /** @format int32 */
  id?: number
  name?: string
  /** @format int32 */
  locationID?: number
  locationName?: string
  /** @format int32 */
  financialDimensionOptionID?: number
  financialDimensionOptionLabel?: string
  financialDimensionOptionValue?: string
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionInvoiceCustomerInvoicesAPIRequest {
  /** @format int32 */
  financialDimensionID?: number
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionInvoiceCustomerInvoicesAPIResponse {
  invoices?: MainStemAPIControllersPurchasingAccountFinancialDimensionInvoiceCustomerInvoicesAPIResponseInvoiceDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionInvoiceCustomerInvoicesAPIResponseInvoiceDetail {
  /** @format int32 */
  financialDimensionOptionID?: number
  financialDimensionOptionLabel?: string
  financialDimensionOptionValue?: string
  /** @format int32 */
  id?: number
  imageURL?: string
  name?: string
  sku?: string
  /** @format int32 */
  supplierID?: number
  supplierName?: string
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionLocationCustomerLocationsAPIRequest {
  /** @format int32 */
  financialDimensionID?: number
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionLocationCustomerLocationsAPIResponse {
  locations?: MainStemAPIControllersPurchasingAccountFinancialDimensionLocationCustomerLocationsAPIResponseLocationDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionLocationCustomerLocationsAPIResponseLocationDetail {
  /** @format int32 */
  financialDimensionOptionID?: number
  financialDimensionOptionLabel?: string
  financialDimensionOptionValue?: string
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionProductCustomerProductsAPIRequest {
  /** @format int32 */
  financialDimensionID?: number
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionProductCustomerProductsAPIResponse {
  products?: MainStemAPIControllersPurchasingAccountFinancialDimensionProductCustomerProductsAPIResponseProductDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionProductCustomerProductsAPIResponseProductDetail {
  /** @format int32 */
  id?: number
  name?: string
  sku?: string
  /** @format int32 */
  supplierID?: number
  imageURL?: string
  supplierName?: string
  /** @format int32 */
  financialDimensionOptionID?: number
  financialDimensionOptionLabel?: string
  financialDimensionOptionValue?: string
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionSupplierCustomerSuppliersAPIRequest {
  /** @format int32 */
  financialDimensionID?: number
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionSupplierCustomerSuppliersAPIResponse {
  suppliers?: MainStemAPIControllersPurchasingAccountFinancialDimensionSupplierCustomerSuppliersAPIResponseSupplierDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionSupplierCustomerSuppliersAPIResponseSupplierDetail {
  /** @format int32 */
  financialDimensionOptionID?: number
  financialDimensionOptionLabel?: string
  financialDimensionOptionValue?: string
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionDeleteAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionDeleteAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionGetByTypeAPIRequest {
  type?: 'Product' | 'Location' | 'Department' | 'InvoiceLineItemAllocation' | 'Invoice' | 'Supplier'
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionGetByTypeAPIResponse {
  financialDimensions?: MainStemAPIControllersPurchasingAccountFinancialDimensionGetByTypeAPIResponseFinancialDimensionDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionGetByTypeAPIResponseFinancialDimensionDetail {
  /** @format int32 */
  id?: number
  type?: 'Product' | 'Location' | 'Department' | 'InvoiceLineItemAllocation' | 'Invoice' | 'Supplier'
  label?: string
  isDeleted?: boolean
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionListByTypeAPIRequest {
  type?: 'Product' | 'Location' | 'Department' | 'InvoiceLineItemAllocation' | 'Invoice' | 'Supplier'
  types?: ('Product' | 'Location' | 'Department' | 'InvoiceLineItemAllocation' | 'Invoice' | 'Supplier')[]
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionListByTypeAPIResponse {
  financialDimensions?: MainStemAPIControllersPurchasingAccountFinancialDimensionListByTypeAPIResponseFinancialDimensionDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionListByTypeAPIResponseFinancialDimensionDetail {
  /** @format int32 */
  id?: number
  type?: 'Product' | 'Location' | 'Department' | 'InvoiceLineItemAllocation' | 'Invoice' | 'Supplier'
  label?: string
  /** @format int32 */
  rankOrder?: number
  options?: MainStemAPIControllersPurchasingAccountFinancialDimensionListByTypeAPIResponseFinancialDimensionOptionDetail[]
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionListByTypeAPIResponseFinancialDimensionOptionDetail {
  /** @format int32 */
  id?: number
  type?: 'Product' | 'Location' | 'Department' | 'InvoiceLineItemAllocation' | 'Invoice' | 'Supplier'
  value?: string
  label?: string
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionOptionCreateAPIRequest {
  value?: string
  label?: string
  /** @format int32 */
  financialDimensionID?: number
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionOptionCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionOptionDeleteAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionOptionDeleteAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionOptionGetByTypeAPIRequest {
  type?: 'Product' | 'Location' | 'Department' | 'InvoiceLineItemAllocation' | 'Invoice' | 'Supplier'
  /** @format int32 */
  recordID?: number
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionOptionGetByTypeAPIResponse {
  financialDimensions?: MainStemSharpHelpersFinancialDimensionsFactoryFinancialDimensionOptionSelectionDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionOptionListByIDAPIRequest {
  /** @format int32 */
  financialDimensionID?: number
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionOptionListByIDAPIResponse {
  financialDimension?: MainStemAPIControllersPurchasingAccountFinancialDimensionOptionListByIDAPIResponseFinancialDimensionDetail
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionOptionListByIDAPIResponseFinancialDimensionDetail {
  /** @format int32 */
  id?: number
  label?: string
  options?: MainStemAPIControllersPurchasingAccountFinancialDimensionOptionListByIDAPIResponseFinancialDimensionOptionDetail[]
  /** @format int32 */
  rankOrder?: number
  type?: 'Product' | 'Location' | 'Department' | 'InvoiceLineItemAllocation' | 'Invoice' | 'Supplier'
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionOptionListByIDAPIResponseFinancialDimensionOptionDetail {
  /** @format int32 */
  id?: number
  label?: string
  type?: 'Product' | 'Location' | 'Department' | 'InvoiceLineItemAllocation' | 'Invoice' | 'Supplier'
  value?: string
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionOptionSetByTypeAPIRequest {
  type?: 'Product' | 'Location' | 'Department' | 'InvoiceLineItemAllocation' | 'Invoice' | 'Supplier'
  /** @format int32 */
  recordID?: number
  /** @format int32 */
  financialDimensionOptionID?: number
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionOptionSetByTypeAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionOptionUpdateAPIRequest {
  /** @format int32 */
  id?: number
  /** @format int32 */
  financialDimensionID?: number
  value?: string
  label?: string
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionOptionUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionUpdateAPIRequest {
  /** @format int32 */
  id?: number
  type?: 'Product' | 'Location' | 'Department' | 'InvoiceLineItemAllocation' | 'Invoice' | 'Supplier'
  label?: string
  /** @format int32 */
  rankOrder?: number
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionDepartmentUpdateDepartmentsAPIRequest {
  /** @format int32 */
  financialDimensionID?: number
  updateRecords?: MainStemSharpHelpersFinancialDimensionsFactoryFinancialDimensionLinkUpdate[]
}

export interface MainStemSharpHelpersFinancialDimensionsFactoryFinancialDimensionLinkUpdate {
  /** @format int32 */
  financialDimensionOptionID?: number
  /** @format int32 */
  recordID?: number
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionDepartmentUpdateDepartmentsAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionInvoiceUpdateInvoicesAPIRequest {
  updateRecords?: MainStemSharpHelpersFinancialDimensionsFactoryFinancialDimensionLinkUpdate[]
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionInvoiceUpdateInvoicesAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionLocationUpdateLocationsAPIRequest {
  /** @format int32 */
  financialDimensionID?: number
  updateRecords?: MainStemSharpHelpersFinancialDimensionsFactoryFinancialDimensionLinkUpdate[]
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionLocationUpdateLocationsAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionProductUpdateProductsAPIRequest {
  updateRecords?: MainStemSharpHelpersFinancialDimensionsFactoryFinancialDimensionLinkUpdate[]
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionProductUpdateProductsAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionSupplierUpdateSuppliersAPIRequest {
  /** @format int32 */
  financialDimensionID?: number
  updateRecords?: MainStemSharpHelpersFinancialDimensionsFactoryFinancialDimensionLinkUpdate[]
}

export interface MainStemAPIControllersPurchasingAccountFinancialDimensionSupplierUpdateSuppliersAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationAmazonOrderLineItemCreateAPIRequest {
  amazonProduct?: MainStemSharpIntegrationsAmazonModelsAmazonSearchProduct
  /** @format int32 */
  orderId?: number
  /** @format int32 */
  quantity?: number
  searchDetails?: MainStemAPIControllersPurchasingIntegrationAmazonOrderLineItemCreateAPIRequestSearchDetails
}

export interface MainStemSharpIntegrationsAmazonModelsAmazonSearchProduct {
  asin?: string
  includedDataTypes?: MainStemSharpIntegrationsAmazonModelsAmazonSearchProductIncludedDataTypesDetails
  productDescription?: string
  title?: string
}

export interface MainStemAPIControllersPurchasingIntegrationAmazonOrderLineItemCreateAPIRequestSearchDetails {
  partitionKey?: string
  rowKey?: string
}

export interface MainStemSharpIntegrationsAmazonModelsAmazonSearchProductIncludedDataTypesDetails {
  images?: MainStemSharpIntegrationsAmazonModelsAmazonSearchProductIncludedDataTypesDetailsImageDetails[]
  offers?: MainStemSharpIntegrationsAmazonModelsAmazonSearchProductIncludedDataTypesDetailsOfferDetails[]
}

export interface MainStemSharpIntegrationsAmazonModelsAmazonSearchProductIncludedDataTypesDetailsImageDetails {
  large?: MainStemSharpIntegrationsAmazonModelsAmazonSearchProductIncludedDataTypesDetailsImageDetailsImageSizeDetails
  medium?: MainStemSharpIntegrationsAmazonModelsAmazonSearchProductIncludedDataTypesDetailsImageDetailsImageSizeDetails
  small?: MainStemSharpIntegrationsAmazonModelsAmazonSearchProductIncludedDataTypesDetailsImageDetailsImageSizeDetails
  thumbnail?: MainStemSharpIntegrationsAmazonModelsAmazonSearchProductIncludedDataTypesDetailsImageDetailsImageSizeDetails
}

export interface MainStemSharpIntegrationsAmazonModelsAmazonSearchProductIncludedDataTypesDetailsOfferDetails {
  availability?: string
  price?: MainStemSharpIntegrationsAmazonModelsAmazonSearchProductIncludedDataTypesDetailsOfferDetailsPriceDetails
}

export interface MainStemSharpIntegrationsAmazonModelsAmazonSearchProductIncludedDataTypesDetailsImageDetailsImageSizeDetails {
  url?: string
}

export interface MainStemSharpIntegrationsAmazonModelsAmazonSearchProductIncludedDataTypesDetailsOfferDetailsPriceDetails {
  value?: MainStemSharpIntegrationsAmazonModelsAmazonSearchProductIncludedDataTypesDetailsOfferDetailsPriceDetailsPriceValueDetails
}

export interface MainStemSharpIntegrationsAmazonModelsAmazonSearchProductIncludedDataTypesDetailsOfferDetailsPriceDetailsPriceValueDetails {
  /** @format double */
  amount?: number
}

export interface MainStemAPIControllersPurchasingIntegrationAmazonOrderLineItemCreateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationD6DetailsAPIResponse {
  /** @format int32 */
  id?: number
  settings?: MainStemSharpModelsIntegrationsD6Settings
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemSharpModelsIntegrationsD6Settings {
  enabled?: boolean
  /** @format int32 */
  financialDimensionIDCostCenter?: number
  /** @format int32 */
  financialDimensionIDDepartment?: number
  /** @format int32 */
  financialDimensionIDItemGroupCodeGP?: number
  /** @format int32 */
  financialDimensionIDItemGroupCodeRetail?: number
  /** @format int32 */
  financialDimensionIDLocation?: number
}

export interface MainStemAPIControllersPurchasingIntegrationD6EnableAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationExcelAPIRequest {
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  uuid?: string
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixCheckStatusAPIRequest {
  /** @format int32 */
  customerIntegrationSettingID?: number
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixCheckStatusAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixGetByTypeAPIRequest {
  type?: 'Products' | 'Rooms' | 'Vendors'
  /** @format int32 */
  recordID?: number
  /** @format int32 */
  customerIntegrationSettingID?: number
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixGetByTypeAPIResponse {
  selectionResult?: MainStemAPIControllersPurchasingIntegrationLeafLogixGetByTypeAPIResponseLeafLogixSelection
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixGetByTypeAPIResponseLeafLogixSelection {
  /** @format int32 */
  recordID?: number
  leafLogixLinkID?: string
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixLogsListAPIRequest {
  /** @format int32 */
  customerIntegrationSettingID?: number
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixLogsListAPIResponse {
  logs?: MainStemSharpHelpersLoggingLeafLogixEntityActionLog[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemSharpHelpersLoggingLeafLogixEntityActionLog {
  status?: 'None' | 'Fail' | 'Partial' | 'Success'
  action?: 'AuthHeader' | 'ListProducts' | 'ListVendors' | 'ListRooms' | 'ReceiveInventory' | 'Inventory'
  reason?: string
  note?: string
  error?: string
  request?: object
  response?: object
  partitionKey?: string
  /** @format int32 */
  invoiceID?: number
  /** @format int32 */
  invoiceReceivedID?: number
  /** @format int32 */
  departmentID?: number
  groupID?: string
  /** @format int32 */
  customerIntegrationID?: number
  /** @format int32 */
  supplierID?: number
  data?: string
  rowKey?: string
  /** @format date-time */
  timestamp?: string
  eTag?: AzureETag
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixInventoryListAPIRequest {
  /** @format int32 */
  recordID?: number
  type?: 'Products' | 'Rooms' | 'Vendors'
  customerIntegrationSettingIDs?: number[]
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixInventoryListAPIResponse {
  customerIntegrationInventory?: MainStemAPIControllersPurchasingIntegrationLeafLogixInventoryListLeafLogixCustomerIntegrationInventoryDetail[]
  aggregate?: MainStemAPIControllersPurchasingIntegrationLeafLogixInventoryListInventoryAggregate
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixInventoryListLeafLogixCustomerIntegrationInventoryDetail {
  /** @format int32 */
  customerIntegrationID?: number
  /** @format double */
  totalQuantity?: number
  aggregateByType?: MainStemAPIControllersPurchasingIntegrationLeafLogixInventoryListInventoryAggregate[]
  message?: string
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixInventoryListInventoryAggregate {
  /** @format int32 */
  recordID?: number
  recordName?: string
  type?: 'Products' | 'Rooms' | 'Vendors'
  /** @format int32 */
  customerIntegrationID?: number
  customerIntegrationIDs?: number[]
  inventory?: MainStemAPIControllersPurchasingIntegrationLeafLogixInventoryListProductInventoryDetail[]
  /** @format double */
  totalQuantity?: number
  /** @format int32 */
  distinctVendors?: number
  /** @format int32 */
  distinctRooms?: number
  /** @format int32 */
  distinctProducts?: number
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixInventoryListProductInventoryDetail {
  /** @format int32 */
  customerIntegrationID?: number
  leafLogixProductID?: string
  leafLogixProductName?: string
  leafLogixRoomID?: string
  leafLogixRoomName?: string
  leafLogixVendorID?: string
  leafLogixVendorName?: string
  /** @format double */
  quantity?: number
  /** @format int32 */
  productID?: number
  productName?: string
  /** @format int32 */
  departmentID?: number
  departmentName?: string
  /** @format int32 */
  supplierID?: number
  supplierName?: string
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixProductsListAPIRequest {
  /** @format int32 */
  customerIntegrationSettingID?: number
}

export interface MainStemSharpProvidersLeafLogixControllersProductListProductDetail {
  /** @format int32 */
  productId?: number
  sku?: string
  productName?: string
  internalName?: string
  description?: string
  masterCategory?: string
  /** @format int32 */
  categoryId?: number
  category?: string
  imageUrl?: string
  imageUrls?: string[]
  /** @format int32 */
  strainId?: number
  strain?: string
  strainType?: string
  size?: string
  /** @format double */
  netWeight?: number
  /** @format int32 */
  brandId?: number
  brandName?: string
  /** @format int32 */
  vendorId?: number
  vendorName?: string
  isCannabis?: string
  isActive?: string
  isCoupon?: string
  /** @format double */
  thcContent?: number
  thcContentUnit?: string
  /** @format double */
  cbdContent?: number
  cbdContentUnit?: string
  /** @format double */
  productGrams?: number
  /** @format double */
  flowerEquivalent?: number
  /** @format double */
  recFlowerEquivalent?: number
  /** @format double */
  price?: number
  /** @format double */
  medPrice?: number
  /** @format double */
  recPrice?: number
  /** @format double */
  unitCost?: number
  onlineTitle?: string
  onlineDescription?: string
  onlineProduct?: boolean
  posProducts?: boolean
  /** @format int32 */
  pricingTier?: number
  onlineAvailable?: string
  /** @format double */
  lowInventoryThreshold?: number
  pricingTierName?: string
  pricingTierDescription?: string
  pricingTierData?: MainStemSharpProvidersLeafLogixControllersProductListPricingTierDetail[]
  flavor?: string
  alternateName?: string
  lineageName?: string
  distillationName?: string
  /** @format double */
  maxPurchaseablePerTransaction?: number
  tags?: MainStemSharpProvidersLeafLogixControllersProductListTagDetail[]
  dosage?: string
  instructions?: string
  allergens?: string
  defaultUnit?: string
  /** @format int32 */
  producerId?: number
  producerName?: string
  /** @format date-time */
  createdDate?: string
  isMedicalOnly?: boolean
  /** @format date-time */
  lastModifiedDateUTC?: string
  /** @format double */
  grossWeight?: number
  isTaxable?: boolean
  taxCategories?: string[]
  upc?: string
  regulatoryCategory?: string
  ndc?: string
  daysSupply?: string
}

export interface MainStemSharpProvidersLeafLogixControllersProductListPricingTierDetail {
  /** @format double */
  startWeight?: number
  /** @format double */
  endWeight?: number
  /** @format double */
  price?: number
  /** @format double */
  medicalPrice?: number
}

export interface MainStemSharpProvidersLeafLogixControllersProductListTagDetail {
  /** @format int32 */
  tagID?: number
  tagName?: string
  /** @format int32 */
  productID?: number
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixProductsUpdateLinksAPIRequest {
  /** @format int32 */
  customerIntegrationSettingID?: number
  linkUpdates?: MainStemAPIControllersPurchasingIntegrationLeafLogixProductsUpdateLinksUpdateLinkDetail[]
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixProductsUpdateLinksUpdateLinkDetail {
  /** @format int32 */
  productID?: number
  linkID?: string
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixProductsUpdateLinksAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixReceivingReceiveInventoryAPIRequest {
  /** @format int32 */
  customerIntegrationSettingID?: number
  /** @format int32 */
  inventoryReceivedID?: number
  /** @format int32 */
  invoiceReceivedID?: number
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixReceivingReceiveInventoryAPIResponse {
  /** @format int32 */
  receivedInventoryHistoryId?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixReceivingListAPIRequest {
  /** @format int32 */
  customerIntegrationSettingID?: number
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixReceivingListAPIResponse {
  receivedInventoriesTransactions?: MainStemAPIControllersPurchasingIntegrationLeafLogixReceivingListReceivedInventory[]
  leafLogixIntegration?: MainStemAPIControllersPurchasingIntegrationLeafLogixReceivingListLeafLogixIntegrationDetail
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixReceivingListReceivedInventory {
  /** @format int32 */
  id?: number
  uuid?: string
  userCreatedBy?: string
  /** @format date-time */
  receivedPhysicalDate?: string
  /** @format date-time */
  receivedSystemDate?: string
  leafLogixReceivedInventoryID?: string
  lineItems?: MainStemAPIControllersPurchasingIntegrationLeafLogixReceivingListReceivedInventoryItemLine[]
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  /** @format int32 */
  totalQuantity?: number
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixReceivingListLeafLogixIntegrationDetail {
  /** @format int32 */
  customerIntegrationID?: number
  fullyReceived?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixReceivingListReceivedInventoryItemLine {
  /** @format int32 */
  id?: number
  /** @format int32 */
  quantity?: number
  /** @format int32 */
  invoiceLineItemID?: number
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  /** @format int32 */
  productID?: number
  productName?: string
  /** @format int32 */
  departmentID?: number
  departmentName?: string
  leafLogixProductID?: string
  leafLogixVendorID?: string
  leafLogixRoomID?: string
  productSku?: string
  /** @format int32 */
  invoicedQuantity?: number
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixRoomsListAPIRequest {
  /** @format int32 */
  customerIntegrationSettingID?: number
  message?: string
}

export interface MainStemSharpProvidersLeafLogixControllersRoomListRoomDetail {
  /** @format int32 */
  roomId?: number
  roomName?: string
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixRoomsUpdateLinksAPIRequest {
  /** @format int32 */
  customerIntegrationSettingID?: number
  linkUpdates?: MainStemAPIControllersPurchasingIntegrationLeafLogixRoomsUpdateLinksUpdateLinkDetail[]
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixRoomsUpdateLinksUpdateLinkDetail {
  /** @format int32 */
  departmentID?: number
  linkID?: string
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixRoomsUpdateLinksAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixSetByTypeAPIRequest {
  type?: 'Products' | 'Rooms' | 'Vendors'
  /** @format int32 */
  recordID?: number
  updates?: MainStemAPIControllersPurchasingIntegrationLeafLogixSetByTypeLeafLogixRecordUpdate[]
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixSetByTypeLeafLogixRecordUpdate {
  leafLogixID?: string
  /** @format int32 */
  customerIntegrationSettingID?: number
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixSetByTypeAPIResponse {
  selectionResult?: MainStemAPIControllersPurchasingIntegrationLeafLogixSetByTypeAPIResponseLeafLogixSelection
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixSetByTypeAPIResponseLeafLogixSelection {
  /** @format int32 */
  recordID?: number
  leafLogixLinkID?: string
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixDetailsAPIRequest {
  /** @format int32 */
  customerIntegrationSettingID?: number
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixDetailsAPIResponse {
  description?: string
  locations?: MainStemAPIControllersPurchasingIntegrationLeafLogixDetailsAPIResponseIntegrationSettingLocation[]
  name?: string
  settings?: MainStemSharpModelsIntegrationsLeafLogixSettings
  enabled?: boolean
  /** @format date-time */
  lastUpdatedAt?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixDetailsAPIResponseIntegrationSettingLocation {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemSharpModelsIntegrationsLeafLogixSettings {
  apiKey?: string
  receiveInToInventory?: boolean
  receiveInventoryQueue?: MainStemSharpModelsIntegrationsLeafLogixSettingsReceiveInventoryQueued[]
}

export interface MainStemSharpModelsIntegrationsLeafLogixSettingsReceiveInventoryQueued {
  /** @format int32 */
  invoiceReceivedID?: number
  /** @format date-time */
  queuedAt?: string
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixVendorsListAPIRequest {
  /** @format int32 */
  customerIntegrationSettingID?: number
  message?: string
}

export interface MainStemSharpProvidersLeafLogixControllersVendorListVendorDetail {
  vendorId?: string
  vendorName?: string
  address?: string
  city?: string
  state?: string
  postalCode?: string
  licenseNumber?: string
  contactName?: string
  contactEmail?: string
  contactPhone?: string
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixVendorsUpdateLinksAPIRequest {
  /** @format int32 */
  customerIntegrationSettingID?: number
  linkUpdates?: MainStemAPIControllersPurchasingIntegrationLeafLogixVendorsUpdateLinksUpdateLinkDetail[]
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixVendorsUpdateLinksUpdateLinkDetail {
  /** @format int32 */
  supplierID?: number
  linkID?: string
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixVendorsUpdateLinksAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationParagonGetTokenAPIRequest {
  /** @format int32 */
  integrationId?: number
}

export interface MainStemAPIControllersPurchasingIntegrationParagonGetTokenAPIResponse {
  token?: string
  enabled?: boolean
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationPowerBIDetailsAPIResponse {
  settings?: MainStemAPIControllersPurchasingIntegrationPowerBIDetailsAPIResponseIntegrationSettings
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationPowerBIDetailsAPIResponseIntegrationSettings {
  fileURL?: string
}

export interface MainStemAPIControllersPurchasingIntegrationPowerBIRequestAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafAllItemsAPIRequest {
  /** @format int32 */
  integrationSettingsID?: number
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafAllItemsAPIResponse {
  items?: MainStemAPIControllersPurchasingIntegrationQuantumLeafAllItemsVendorItemDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafAllItemsVendorItemDetail {
  itemType?: 'Stock' | 'NonStock'
  label?: string
  value?: string
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafIntegrationLogsAPIRequest {
  /** @format int32 */
  integrationSettingsID?: number
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafIntegrationLogsAPIResponse {
  logs?: MainStemSharpModelsIntegrationsQuantumLeafQuantumLeafLogEntry[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemSharpModelsIntegrationsQuantumLeafQuantumLeafLogEntry {
  /** @format int32 */
  customerID?: number
  /** @format int32 */
  locationID?: number
  /** @format int32 */
  invoiceID?: number
  /** @format int32 */
  productID?: number
  productType?: string
  action?:
    | 'Running'
    | 'Failure'
    | 'Successful'
    | 'CreateOrder'
    | 'UpdateOrder'
    | 'DeleteOrder'
    | 'CreateInvoice'
    | 'UpdateInvoice'
    | 'DeleteInvoice'
    | 'CreateProduct'
    | 'UpdateProduct'
    | 'DeleteProduct'
    | 'CreateShipment'
    | 'UpdateShipment'
    | 'DeleteShipment'
    | 'QueueOrder'
  productIDsAsString?: string
  productIDs?: number[]
  /** @format int32 */
  customerIntegrationSettingsID?: number
  request?: object
  response?: object
  partitionKey?: string
  rowKey?: string
  sessionId?: string
  message?: string
  /** @format date-time */
  timestamp?: string
  environment?: 'None' | 'Local' | 'Dev' | 'Demo' | 'Prod'
  userId?: string
  breadcrumb?: string
  level?: 'Debug' | 'Error' | 'Fatal' | 'Info' | 'Verbose' | 'Warning'
  data?: object
  stackTrace?: string
  note?: string
  error?: string
  status?: 'None' | 'Fail' | 'Partial' | 'Success'
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafLinkLocationProductAPIRequest {
  /** @format int32 */
  integrationSettingsID?: number
  itemID?: string
  itemType?: 'Stock' | 'NonStock'
  /** @format int32 */
  locationID?: number
  /** @format int32 */
  productID?: number
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafLinkProductAPIRequest {
  /** @format int32 */
  integrationSettingsID?: number
  itemID?: string
  itemType?: 'Stock' | 'NonStock'
  /** @format int32 */
  productID?: number
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafLinkSupplierAPIRequest {
  /** @format int32 */
  integrationSettingsID?: number
  /** @format int32 */
  supplierID?: number
  linkID?: string
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafNonStockItemsAPIRequest {
  /** @format int32 */
  integrationSettingsID?: number
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafNonStockItemsAPIResponse {
  items?: MainStemAPIControllersPurchasingIntegrationQuantumLeafNonStockItemsVendorItemDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafNonStockItemsVendorItemDetail {
  label?: string
  value?: string
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafOrdersAPIRequest {
  /** @format int32 */
  integrationSettingsID?: number
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafOrdersAPIResponse {
  orders?: MainStemAPIControllersPurchasingIntegrationQuantumLeafOrdersAPIResponseOrderDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafOrdersAPIResponseOrderDetails {
  id?: string
  /** @format int32 */
  orderID?: number
  /** @format int32 */
  invoiceID?: number
  externalID?: string
  externalURL?: string
  orderUUID?: string
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  userCreatedBy?: string
  userApprovedBy?: string
  location?: MainStemAPIControllersPurchasingIntegrationQuantumLeafOrdersAPIResponseOrderDetailsLocationDetails
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafOrdersAPIResponseOrderDetailsLocationDetails {
  /** @format int32 */
  id?: number
  name?: string
  address1?: string
  address2?: string
  city?: string
  state?: string
  zip?: string
  country?: string
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafProductsAPIRequest {
  /** @format int32 */
  integrationSettingsID?: number
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafProductsAPIResponse {
  products?: MainStemAPIControllersPurchasingIntegrationQuantumLeafProductsProductDetail[]
  linkedProducts?: MainStemAPIControllersPurchasingIntegrationQuantumLeafProductsLinkedProductDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafProductsProductDetail {
  /** @format int32 */
  id?: number
  name?: string
  skuInternal?: string
  itemID?: string
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  isPrivate?: boolean
  image?: string
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafProductsLinkedProductDetail {
  /** @format int32 */
  productID?: number
  itemID?: string
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafVendorProductsAPIRequest {
  /** @format int32 */
  integrationSettingsID?: number
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafVendorProductsAPIResponse {
  products?: MainStemAPIControllersPurchasingIntegrationQuantumLeafVendorProductsVendorProductDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafVendorProductsVendorProductDetail {
  label?: string
  value?: string
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafVendorsAPIRequest {
  /** @format int32 */
  integrationSettingsID?: number
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafVendorsAPIResponse {
  vendors?: MainStemAPIControllersPurchasingIntegrationQuantumLeafVendorsVendorDetails[]
  linkedSuppliers?: MainStemAPIControllersPurchasingIntegrationQuantumLeafVendorsLinkedSupplierDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafVendorsVendorDetails {
  /** @format int32 */
  id?: number
  name?: string
  isPrivateSupplier?: boolean
  image?: string
  linkID?: string
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafVendorsLinkedSupplierDetail {
  /** @format int32 */
  supplierID?: number
  linkID?: string
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafVendorSuppliersAPIRequest {
  /** @format int32 */
  integrationSettingsID?: number
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafVendorSuppliersAPIResponse {
  suppliers?: MainStemAPIControllersPurchasingIntegrationQuantumLeafVendorSuppliersVendorSupplierDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafVendorSuppliersVendorSupplierDetail {
  label?: string
  value?: string
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafWarehousesAPIRequest {
  /** @format int32 */
  integrationSettingsID?: number
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafWarehousesAPIResponse {
  warehouses?: MainStemAPIControllersPurchasingIntegrationQuantumLeafWarehousesWarehouseDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationQuantumLeafWarehousesWarehouseDetail {
  label?: string
  value?: string
}

export interface MainStemAPIControllersPurchasingIntegrationQuickBooksLoggerIntegrationLogsAPIRequest {
  /** @format int32 */
  integrationSettingsID?: number
}

export interface MainStemAPIControllersPurchasingIntegrationQuickBooksLoggerIntegrationLogsAPIResponse {
  logs?: MainStemSharpModelsLoggingQuickBooksQuickBooksLogEntry[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemSharpModelsLoggingQuickBooksQuickBooksLogEntry {
  /** @format int32 */
  customerID?: number
  /** @format int32 */
  locationID?: number
  /** @format int32 */
  orderID?: number
  /** @format int32 */
  productID?: number
  productType?: string
  action?:
    | 'QueuedItemCreateEvent'
    | 'QueuedBillCreateEvent'
    | 'QueuedBillPaymentCreateEvent'
    | 'SyncInitiated'
    | 'ItemCreateEvent'
    | 'CreateBill'
    | 'CreateBillPayment'
    | 'SyncCompleted'
  productIDsAsString?: string
  productIDs?: number[]
  /** @format int32 */
  paymentTransactionID?: number
  /** @format int32 */
  invoicePaymentLinkID?: number
  /** @format int32 */
  customerIntegrationSettingsID?: number
  request?: object
  response?: object
  partitionKey?: string
  rowKey?: string
  sessionId?: string
  message?: string
  /** @format date-time */
  timestamp?: string
  environment?: 'None' | 'Local' | 'Dev' | 'Demo' | 'Prod'
  userId?: string
  breadcrumb?: string
  level?: 'Debug' | 'Error' | 'Fatal' | 'Info' | 'Verbose' | 'Warning'
  data?: object
  stackTrace?: string
  note?: string
  error?: string
  status?: 'None' | 'Fail' | 'Partial' | 'Success'
}

export interface MainStemAPIControllersPurchasingIntegrationQuickBooksLogsAPIRequest {
  /** @format int32 */
  integrationSettingId?: number
}

export interface MainStemAPIControllersPurchasingIntegrationQuickBooksLogsAPIResponse {
  logs?: MainStemAPIControllersPurchasingIntegrationQuickBooksLogsAPIResponseLogDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationQuickBooksLogsAPIResponseLogDetails {
  wasSuccessful?: boolean
  apiCallName?: string
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  request?: string
  response?: string
}

export interface MainStemAPIControllersPurchasingIntegrationQuickBooksLoggerOrderLogsAPIRequest {
  /** @format int32 */
  orderID?: number
}

export interface MainStemAPIControllersPurchasingIntegrationQuickBooksLoggerOrderLogsAPIResponse {
  logs?: MainStemSharpModelsLoggingQuickBooksQuickBooksLogEntry[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationQuickBooksOrdersAPIRequest {
  /** @format int32 */
  integrationSettingId?: number
}

export interface MainStemAPIControllersPurchasingIntegrationQuickBooksOrdersAPIResponse {
  orders?: MainStemAPIControllersPurchasingIntegrationQuickBooksOrdersAPIResponseOrderDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationQuickBooksOrdersAPIResponseOrderDetails {
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  location?: MainStemAPIControllersPurchasingIntegrationQuickBooksOrdersAPIResponseOrderDetailsLocationDetails
  userApprovedBy?: string
  userCreatedBy?: string
  uuid?: string
}

export interface MainStemAPIControllersPurchasingIntegrationQuickBooksOrdersAPIResponseOrderDetailsLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingIntegrationQuickBooksProductTypesAPIRequest {
  /** @format int32 */
  integrationSettingId?: number
}

export interface MainStemAPIControllersPurchasingIntegrationQuickBooksProductTypesAPIResponse {
  productTypes?: MainStemAPIControllersPurchasingIntegrationQuickBooksProductTypesAPIResponseProductTypeDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationQuickBooksProductTypesAPIResponseProductTypeDetails {
  /** @format int32 */
  quickbooksAccountId?: number
  type?: string
}

export interface MainStemAPIControllersPurchasingIntegrationSettingsCreateAPIRequest {
  description?: string
  name?: string
  type?: string
  locationIds?: number[]
}

export interface MainStemAPIControllersPurchasingIntegrationSettingsCreateAPIResponse {
  error?: string
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationSettingsDetailsAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingIntegrationSettingsDetailsAPIResponse {
  description?: string
  enabled?: boolean
  /** @format date-time */
  lastUpdatedAt?: string
  locations?: MainStemAPIControllersPurchasingIntegrationSettingsDetailsAPIResponseIntegrationSettingLocation[]
  name?: string
  paymentMethods?: MainStemAPIControllersPurchasingIntegrationSettingsDetailsAPIResponseIntegrationSettingPaymentMethod[]
  settings?: object
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationSettingsDetailsAPIResponseIntegrationSettingLocation {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingIntegrationSettingsDetailsAPIResponseIntegrationSettingPaymentMethod {
  /** @format int32 */
  id?: number
  last4OfAccount?: string
  location?: MainStemAPIControllersPurchasingIntegrationSettingsDetailsAPIResponseIntegrationSettingPaymentMethodLocationDetails
  type?: string
}

export interface MainStemAPIControllersPurchasingIntegrationSettingsDetailsAPIResponseIntegrationSettingPaymentMethodLocationDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingIntegrationSettingsListAPIRequest {
  type?: string
}

export interface MainStemAPIControllersPurchasingIntegrationSettingsListAPIResponse {
  settings?: MainStemAPIControllersPurchasingIntegrationSettingsListAPIResponseIntegrationSetting[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationSettingsListAPIResponseIntegrationSetting {
  /** @format int32 */
  id?: number
  description?: string
  enabled?: boolean
  /** @format date-time */
  lastUpdatedAt?: string
  locations?: MainStemAPIControllersPurchasingIntegrationSettingsListAPIResponseIntegrationSettingIntegrationSettingLocation[]
  name?: string
}

export interface MainStemAPIControllersPurchasingIntegrationSettingsListAPIResponseIntegrationSettingIntegrationSettingLocation {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingIntegrationSettingsLocationsAddAPIRequest {
  /** @format int32 */
  integrationId?: number
  /** @format int32 */
  locationId?: number
}

export interface MainStemAPIControllersPurchasingIntegrationSettingsLocationsAddAPIResponse {
  error?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationSettingsLocationsRemoveAPIRequest {
  /** @format int32 */
  integrationId?: number
  /** @format int32 */
  locationId?: number
}

export interface MainStemAPIControllersPurchasingIntegrationSettingsToggleAPIRequest {
  /** @format int32 */
  id?: number
  enabled?: boolean
  type?: string
}

export interface MainStemAPIControllersPurchasingIntegrationSettingsUpdateAPIRequest {
  /** @format int32 */
  id?: number
  description?: string
  type?: string
  name?: string
  settings?: object
  enabled?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationLogAPIRequest {
  type?: string
  /** @format int32 */
  customerSessionID?: number
}

export interface MainStemAPIControllersPurchasingIntegrationLogAPIResponse {
  logs?: MainStemAPIControllersPurchasingIntegrationLogAPIResponseLogDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationLogAPIResponseLogDetails {
  wasSuccessful?: boolean
  apiCallName?: string
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  request?: string
  response?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuantumLeafQueueOrderAPIRequest {
  orderUUID?: string
  invoiceUUID?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksCreateBillAPIRequest {
  orderUUID?: string
  invoiceUUID?: string
  /** @format int32 */
  paymentID?: number
}

export interface MainStemSharpIntegrationsExternalQuickbooksCreateBillCreateQbBillResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksCreateBillPaymentAPIRequest {
  orderUUID?: string
  invoiceUUID?: string
  /** @format int32 */
  paymentID?: number
}

export interface MainStemSharpIntegrationsExternalQuickbooksCreateBillPaymentCreateQbBillPaymentResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksCreateBillPaymentsFromPaymentAPIRequest {
  orderUUID?: string
  /** @format int32 */
  paymentID?: number
  baseRequest?: object
  /** @format int32 */
  customerID?: number
  affiliateProgramUUID?: string
  existingAffiliateProgram?: MainStemSharpDataModelsAffiliateProgram
  /** @format int32 */
  businessPartnerId?: number
  businessPartners?: MainStemSharpDataModelsBusinessPartner[]
  /** @format int32 */
  locationID?: number
  isAdmin?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksCreateBillPaymentsFromPaymentAPIResponse {
  error?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationStaplesOrderLineItemCreateAPIRequest {
  /** @format int32 */
  orderId?: number
  /** @format int32 */
  quantity?: number
  searchDetails?: MainStemAPIControllersPurchasingIntegrationStaplesOrderLineItemCreateAPIRequestSearchDetails
  staplesProduct?: MainStemSharpIntegrationsStaplesModelsStaplesProduct
}

export interface MainStemAPIControllersPurchasingIntegrationStaplesOrderLineItemCreateAPIRequestSearchDetails {
  partitionKey?: string
  rowKey?: string
}

export interface MainStemSharpIntegrationsStaplesModelsStaplesProduct {
  /** @format int32 */
  availableQuantity?: number
  description?: MainStemSharpIntegrationsStaplesModelsStaplesProductDescriptionDetails
  image?: string
  isDiscontinued?: boolean
  isOutOfStock?: boolean
  itemAvailability?: MainStemSharpIntegrationsStaplesModelsStaplesProductItemAvailabilityDetails
  itemId?: string
  manufacturerId?: string
  /** @format double */
  priceValue?: number
  title?: string
}

export interface MainStemSharpIntegrationsStaplesModelsStaplesProductDescriptionDetails {
  bullets?: string[]
  headliner?: string[]
  paragraph?: string[]
  specification?: MainStemSharpIntegrationsStaplesModelsStaplesProductDescriptionDetailsSpecificationDetials[]
}

export interface MainStemSharpIntegrationsStaplesModelsStaplesProductItemAvailabilityDetails {
  /** @format int32 */
  maxLeadTime?: number
}

export interface MainStemSharpIntegrationsStaplesModelsStaplesProductDescriptionDetailsSpecificationDetials {
  name?: string
  value?: string
}

export interface MainStemAPIControllersPurchasingIntegrationStaplesOrderLineItemCreateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingRFQInvitableSuppliersAPIResponse {
  relevantSuppliers?: MainStemAPIControllersPurchasingRFQInvitableSuppliersAPIResponseSupplierDetail[]
  suppliers?: MainStemAPIControllersPurchasingRFQInvitableSuppliersAPIResponseSupplierDetail[]
  suppliersByCategory?: MainStemAPIControllersPurchasingRFQInvitableSuppliersAPIResponseSuppliersByCategory[]
  categories?: string[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingRFQInvitableSuppliersAPIResponseSupplierDetail {
  /** @format int32 */
  id?: number
  name?: string
  isPrivateSupplier?: boolean
  categories?: string[]
}

export interface MainStemAPIControllersPurchasingRFQInvitableSuppliersAPIResponseSuppliersByCategory {
  categoryName?: string
  suppliers?: MainStemAPIControllersPurchasingRFQInvitableSuppliersAPIResponseSupplierDetail[]
}

export interface MainStemAPIControllersPurchasingInvoicesAllocationItemCreateAPIRequest {
  /** @format int32 */
  departmentId?: number
  /** @format int32 */
  invoiceLineItemId?: number
  /** @format int32 */
  quantity?: number
  financialDimensions?: MainStemAPIControllersPurchasingInvoicesAllocationItemCreateAPIRequestFinancialDimensionUpdate[]
}

export interface MainStemAPIControllersPurchasingInvoicesAllocationItemCreateAPIRequestFinancialDimensionUpdate {
  /** @format int32 */
  financialDimensionID?: number
  /** @format int32 */
  financialDimensionOptionID?: number
}

export interface MainStemAPIControllersPurchasingInvoicesAllocationItemDeleteAPIRequest {
  /** @format int32 */
  invoiceAllocationLineItemId?: number
}

export interface MainStemAPIControllersPurchasingInvoicesAllocationItemGetAPIRequest {
  /** @format int32 */
  invoiceLineItemId?: number
}

export interface MainStemAPIControllersPurchasingInvoicesAllocationItemGetAPIResponse {
  /** @format int32 */
  quantityUsed?: number
  /** @format int32 */
  quantityAvailable?: number
  /** @format int32 */
  currentQuantity?: number
  location?: MainStemAPIControllersPurchasingInvoicesAllocationItemGetAPIResponseLocationDetails
  allocation?: MainStemAPIControllersPurchasingInvoicesAllocationItemGetAPIResponseAllocationDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingInvoicesAllocationItemGetAPIResponseLocationDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingInvoicesAllocationItemGetAPIResponseAllocationDetails {
  /** @format int32 */
  id?: number
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  invoiceLineItemId?: number
  isDeleted?: boolean
  /** @format int32 */
  quantity?: number
  userCreatedByID?: string
  /** @format int32 */
  departmentID?: number
  financialDimensions?: MainStemSharpHelpersFinancialDimensionsFactoryFinancialDimensionOptionSelectionDetail[]
}

export interface MainStemAPIControllersPurchasingInvoicesAllocationItemListAPIRequest {
  /** @format int32 */
  invoiceLineItemId?: number
}

export interface MainStemAPIControllersPurchasingInvoicesAllocationItemListAPIResponse {
  /** @format int32 */
  quantityUsed?: number
  /** @format int32 */
  quantityAvailable?: number
  allocations?: MainStemAPIControllersPurchasingInvoicesAllocationItemListAPIResponseAllocationDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingInvoicesAllocationItemListAPIResponseAllocationDetails {
  /** @format int32 */
  id?: number
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  invoiceLineItemId?: number
  isDeleted?: boolean
  /** @format int32 */
  quantity?: number
  userCreatedByID?: string
  /** @format int32 */
  departmentID?: number
}

export interface MainStemAPIControllersPurchasingInvoicesAllocationItemUpdateAPIRequest {
  /** @format int32 */
  departmentId?: number
  /** @format int32 */
  invoiceLineItemId?: number
  /** @format int32 */
  quantity?: number
  financialDimensions?: MainStemAPIControllersPurchasingInvoicesAllocationItemUpdateAPIRequestFinancialDimensionUpdate[]
}

export interface MainStemAPIControllersPurchasingInvoicesAllocationItemUpdateAPIRequestFinancialDimensionUpdate {
  /** @format int32 */
  financialDimensionID?: number
  /** @format int32 */
  financialDimensionOptionID?: number
}

export interface MainStemAPIControllersPurchasingInvoiceProblemsCreateAPIRequest {
  content?: string
  subject?: string
  /** @format int32 */
  invoiceId?: number
  type?: string
}

export interface MainStemAPIControllersGlobalMessageCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingInvoiceProblemsDetailsAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingInvoiceProblemsDetailsAPIResponse {
  problem?: MainStemAPIControllersPurchasingInvoiceProblemsDetailsAPIResponseInvoiceProblemDetail
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingInvoiceProblemsDetailsAPIResponseInvoiceProblemDetail {
  message?: string
  /** @format int32 */
  id?: number
  /** @format date-time */
  dateCreated?: string
  createdBy?: string
  createdByID?: string
  status?: string
  /** @format int32 */
  messageCount?: number
  /** @format int32 */
  unreadCount?: number
}

export interface MainStemAPIControllersPurchasingInvoiceProblemsListAPIRequest {
  invoiceIds?: number[]
}

export interface MainStemAPIControllersPurchasingInvoiceProblemsListAPIResponse {
  problems?: MainStemAPIControllersPurchasingInvoiceProblemsListAPIResponseInvoiceProblemDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingInvoiceProblemsListAPIResponseInvoiceProblemDetail {
  message?: string
  /** @format int32 */
  messageCount?: number
  /** @format int32 */
  unreadCount?: number
  /** @format int32 */
  id?: number
  /** @format int32 */
  invoiceID?: number
  /** @format date-time */
  dateCreated?: string
  createdBy?: string
  createdByID?: string
  status?: string
  subject?: string
}

export interface MainStemAPIControllersPurchasingInvoiceProblemsListUnresolvedAPIRequest {
  invoiceIds?: number[]
}

export interface MainStemAPIControllersPurchasingInvoiceProblemsListUnresolvedAPIResponse {
  problems?: MainStemAPIControllersPurchasingInvoiceProblemsListUnresolvedAPIResponseInvoiceProblemDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingInvoiceProblemsListUnresolvedAPIResponseInvoiceProblemDetail {
  message?: string
  createdBy?: string
  createdByID?: string
  /** @format int32 */
  createdByLocationID?: number
  createdByLocationName?: string
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  /** @format int32 */
  invoiceID?: number
  /** @format int32 */
  locationID?: number
  locationName?: string
  /** @format int32 */
  messageCount?: number
  /** @format int32 */
  orderID?: number
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  orderUUID?: string
  status?: 'Open' | 'InProgress' | 'Resolved'
  type?: string
  /** @format int32 */
  unreadCount?: number
  userRelated?: boolean
}

export interface MainStemAPIControllersPurchasingInvoiceProblemsUpdateStatusAPIRequest {
  /** @format int32 */
  id?: number
  status?: string
}

export interface MainStemAPIControllersPurchasingInvoiceProblemsUpdateStatusAPIResponse {
  problem?: MainStemAPIControllersPurchasingInvoiceProblemsUpdateStatusAPIResponseInvoiceProblemDetail
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingInvoiceProblemsUpdateStatusAPIResponseInvoiceProblemDetail {
  message?: string
  /** @format int32 */
  id?: number
  /** @format date-time */
  dateCreated?: string
  createdBy?: string
  createdByID?: string
  status?: string
  /** @format int32 */
  messageCount?: number
  /** @format int32 */
  unreadCount?: number
}

export interface MainStemAPIControllersPurchasingReceivingUpdateAPIRequest {
  invoiceId?: string
  /** @format date-time */
  datePhysical?: string
  /** @format date-time */
  dateSystem?: string
}

export interface MainStemAPIControllersPurchasingReceivingDetailsAPIRequest {
  invoiceUUID?: string
}

export interface MainStemAPIControllersPurchasingReceivingDetailsAPIResponse {
  invoice?: MainStemAPIControllersPurchasingReceivingDetailsInvoiceDetail
  leafLogixIntegration?: MainStemAPIControllersPurchasingReceivingDetailsLeafLogixIntegrationDetail
  receiveDetails?: MainStemAPIControllersPurchasingReceivingDetailsAPIResponseInvoiceDetails
  receivedInventoriesTransactions?: MainStemAPIControllersPurchasingReceivingDetailsReceivedInventory[]
  receiveItems?: MainStemAPIControllersPurchasingReceivingDetailsAPIResponseReceiveItems[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingReceivingDetailsInvoiceDetail {
  /** @format int32 */
  fulfillmentID?: number
  invoiceID?: string
  /** @format int32 */
  locationID?: number
  locationName?: string
  /** @format int32 */
  orderID?: number
  orderUUID?: string
  /** @format int32 */
  supplierID?: number
  supplierName?: string
}

export interface MainStemAPIControllersPurchasingReceivingDetailsLeafLogixIntegrationDetail {
  /** @format int32 */
  customerIntegrationID?: number
  fullyReceived?: boolean
}

export interface MainStemAPIControllersPurchasingReceivingDetailsAPIResponseInvoiceDetails {
  addressShipTo?: MainStemAPIControllersPurchasingReceivingDetailsAPIResponseShipToDetails
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateReceivedPhysical?: string
  /** @format date-time */
  dateReceivedSystem?: string
  /** @format int32 */
  id?: number
  /** @format int32 */
  invoiceId?: number
  /** @format int32 */
  orderId?: number
  userCreatedBy?: MainStemAPIControllersPurchasingReceivingDetailsAPIResponseUserDetails
  uuid?: string
}

export interface MainStemAPIControllersPurchasingReceivingDetailsReceivedInventory {
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  leafLogixReceivedInventoryID?: string
  lineItems?: MainStemAPIControllersPurchasingReceivingDetailsReceivedInventoryItemLine[]
  /** @format date-time */
  receivedPhysicalDate?: string
  /** @format date-time */
  receivedSystemDate?: string
  userCreatedBy?: string
}

export interface MainStemAPIControllersPurchasingReceivingDetailsAPIResponseReceiveItems {
  department?: MainStemAPIControllersPurchasingReceivingDetailsAPIResponseDepartmentDetails
  /** @format int32 */
  departmentID?: number
  departmentName?: string
  /** @format int32 */
  invoicedQuantity?: number
  leafLogixProductLinkID?: string
  leafLogixRoomLinkID?: string
  leafLogixVendorLinkID?: string
  product?: MainStemAPIControllersPurchasingReceivingDetailsAPIResponseProductDetails
  /** @format int32 */
  productID?: number
  productName?: string
  productSku?: string
  /** @format int32 */
  quantity?: number
  /** @format int32 */
  quantityAllocated?: number
  supplerName?: string
  /** @format int32 */
  supplierID?: number
}

export interface MainStemAPIControllersPurchasingReceivingDetailsAPIResponseShipToDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingReceivingDetailsAPIResponseUserDetails {
  id?: string
  username?: string
}

export interface MainStemAPIControllersPurchasingReceivingDetailsReceivedInventoryItemLine {
  /** @format int32 */
  departmentID?: number
  departmentName?: string
  /** @format int32 */
  id?: number
  ignoreLeafLogix?: boolean
  /** @format int32 */
  invoicedQuantity?: number
  /** @format int32 */
  invoiceLineItemID?: number
  leafLogixProductID?: string
  leafLogixRoomID?: string
  leafLogixVendorID?: string
  /** @format int32 */
  productID?: number
  productName?: string
  productSku?: string
  /** @format int32 */
  quantity?: number
  /** @format int32 */
  supplierID?: number
  supplierName?: string
}

export interface MainStemAPIControllersPurchasingReceivingDetailsAPIResponseDepartmentDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingReceivingDetailsAPIResponseProductDetails {
  /** @format int32 */
  id?: number
  name?: string
  sku?: string
}

export interface MainStemAPIControllersPurchasingReceivingInvoiceListAPIRequest {
  /** @format int32 */
  invoiceId?: number
}

export interface MainStemAPIControllersPurchasingReceivingInvoiceListAPIResponse {
  itemsToReceive?: MainStemAPIControllersPurchasingReceivingInvoiceListAPIResponseItemToReceive[]
  allocatedLineItems?: MainStemAPIControllersPurchasingReceivingInvoiceListAPIResponseAllocationItem[]
  receivedLineItems?: MainStemAPIControllersPurchasingReceivingInvoiceListAPIResponseReceivedItem[]
  invoice?: MainStemAPIControllersPurchasingReceivingInvoiceListInvoiceDetail
  leafLogixIntegration?: MainStemAPIControllersPurchasingReceivingInvoiceListLeafLogixIntegrationDetail
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingReceivingInvoiceListAPIResponseItemToReceive {
  /** @format int32 */
  index?: number
  /** @format int32 */
  invoiceLineItemID?: number
  product?: MainStemAPIControllersPurchasingReceivingInvoiceListAPIResponseProductDetails
  location?: MainStemAPIControllersPurchasingReceivingInvoiceListAPIResponseLocationDetails
  /** @format int32 */
  quantityOrdered?: number
  /** @format int32 */
  quantityAllocated?: number
  /** @format int32 */
  quantityReceived?: number
  /** @format int32 */
  quantityAlreadyReceived?: number
  /** @format int32 */
  quantityAvailable?: number
  /** @format int32 */
  departmentID?: number
  leafLogixProductLinkID?: string
  leafLogixVendorLinkID?: string
  /** @format int32 */
  supplierID?: number
  supplierName?: string
}

export interface MainStemAPIControllersPurchasingReceivingInvoiceListAPIResponseAllocationItem {
  /** @format int32 */
  departmentID?: number
  /** @format int32 */
  invoiceLineItemID?: number
  /** @format int32 */
  quantityOrdered?: number
  /** @format int32 */
  quantityAllocated?: number
  /** @format int32 */
  quantityReceived?: number
  product?: MainStemAPIControllersPurchasingReceivingInvoiceListAPIResponseProductDetails
  location?: MainStemAPIControllersPurchasingReceivingInvoiceListAPIResponseLocationDetails
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  /** @format int32 */
  totalAllocated?: number
}

export interface MainStemAPIControllersPurchasingReceivingInvoiceListAPIResponseReceivedItem {
  /** @format int32 */
  invoiceLineItemID?: number
  product?: MainStemAPIControllersPurchasingReceivingInvoiceListAPIResponseProductDetails
  location?: MainStemAPIControllersPurchasingReceivingInvoiceListAPIResponseLocationDetails
  /** @format int32 */
  quantityOrdered?: number
  /** @format int32 */
  quantityReceived?: number
  /** @format int32 */
  departmentID?: number
  /** @format int32 */
  supplierID?: number
  supplierName?: string
}

export interface MainStemAPIControllersPurchasingReceivingInvoiceListInvoiceDetail {
  /** @format int32 */
  orderID?: number
  /** @format int32 */
  fulfillmentID?: number
  invoiceID?: string
  /** @format int32 */
  locationID?: number
  locationName?: string
  supplierName?: string
  /** @format int32 */
  supplierID?: number
  orderUUID?: string
}

export interface MainStemAPIControllersPurchasingReceivingInvoiceListLeafLogixIntegrationDetail {
  /** @format int32 */
  customerIntegrationID?: number
}

export interface MainStemAPIControllersPurchasingReceivingInvoiceListAPIResponseProductDetails {
  /** @format int32 */
  id?: number
  name?: string
  imageURL?: string
}

export interface MainStemAPIControllersPurchasingReceivingInvoiceListAPIResponseLocationDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingReceivingInvoiceUpdateAPIRequest {
  /** @format int32 */
  invoiceId?: number
  /** @format int32 */
  leafLogixIntegrationID?: number
  receiveItems?: MainStemAPIControllersPurchasingReceivingInvoiceUpdateAPIRequestReceiveItems[]
}

export interface MainStemAPIControllersPurchasingReceivingInvoiceUpdateAPIRequestReceiveItems {
  /** @format int32 */
  departmentID?: number
  ignoreLeafLogix?: boolean
  /** @format int32 */
  index?: number
  /** @format int32 */
  invoiceLineItemID?: number
  leafLogixProductLinkID?: string
  leafLogixRoomLinkID?: string
  leafLogixVendorLinkID?: string
  location?: MainStemAPIControllersPurchasingReceivingInvoiceUpdateAPIRequestLocationDetails
  product?: MainStemAPIControllersPurchasingReceivingInvoiceUpdateAPIRequestProductDetails
  /** @format int32 */
  quantityAllocated?: number
  /** @format int32 */
  quantityAvailable?: number
  /** @format int32 */
  quantityOrdered?: number
  /** @format int32 */
  quantityReceived?: number
  /** @format int32 */
  supplierID?: number
}

export interface MainStemAPIControllersPurchasingReceivingInvoiceUpdateAPIRequestLocationDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingReceivingInvoiceUpdateAPIRequestProductDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingReceivingItemListAPIRequest {
  /** @format int32 */
  invoiceLineItemId?: number
}

export interface MainStemAPIControllersPurchasingReceivingItemUpdateAPIRequest {
  /** @format date-time */
  dateReceivedActual?: string
  /** @format int32 */
  invoiceLineItemId?: number
  /** @format int32 */
  leafLogixIntegrationID?: number
  receiveItems?: MainStemAPIControllersPurchasingReceivingItemUpdateAPIRequestReceiveItems[]
}

export interface MainStemAPIControllersPurchasingReceivingItemUpdateAPIRequestReceiveItems {
  /** @format int32 */
  departmentID?: number
  ignoreLeafLogix?: boolean
  /** @format int32 */
  index?: number
  /** @format int32 */
  invoiceLineItemID?: number
  leafLogixProductLinkID?: string
  leafLogixRoomLinkID?: string
  leafLogixVendorLinkID?: string
  location?: MainStemAPIControllersPurchasingReceivingItemUpdateAPIRequestLocationDetails
  product?: MainStemAPIControllersPurchasingReceivingItemUpdateAPIRequestProductDetails
  /** @format int32 */
  quantityAllocated?: number
  /** @format int32 */
  quantityAvailable?: number
  /** @format int32 */
  quantityOrdered?: number
  /** @format int32 */
  quantityReceived?: number
  /** @format int32 */
  supplierID?: number
}

export interface MainStemAPIControllersPurchasingReceivingItemUpdateAPIRequestLocationDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingReceivingItemUpdateAPIRequestProductDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingInvoicesAcceptAPIRequest {
  /** @format int32 */
  invoiceId?: number
}

export interface MainStemAPIControllersPurchasingInvoicesAcceptAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingInvoicesCancelAPIRequest {
  /** @format int32 */
  invoiceId?: number
}

export interface MainStemAPIControllersPurchasingInvoicesCancelAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingInvoicesCompleteAPIRequest {
  /** @format int32 */
  invoiceId?: number
}

export interface MainStemAPIControllersPurchasingInvoicesCompleteAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingInvoicesDeclineAPIRequest {
  /** @format int32 */
  invoiceId?: number
}

export interface MainStemAPIControllersPurchasingInvoicesDeclineAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingInvoicesFinancialDimensionsUpdateAPIRequest {
  financialDimensions?: MainStemAPIControllersPurchasingInvoicesFinancialDimensionsUpdateAPIRequestFinancialDimensionUpdate[]
  /** @format int32 */
  invoiceId?: number
}

export interface MainStemAPIControllersPurchasingInvoicesFinancialDimensionsUpdateAPIRequestFinancialDimensionUpdate {
  /** @format int32 */
  financialDimensionID?: number
  /** @format int32 */
  financialDimensionOptionID?: number
}

export interface MainStemAPIControllersPurchasingInvoicesLineItemsUpdateAPIRequest {
  /** @format int32 */
  id?: number
  /** @format double */
  price?: number
  /** @format int32 */
  quantity?: number
}

export interface MainStemAPIControllersPurchasingInvoicesLineItemsUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingInvoicesListAPIResponse {
  invoices?: MainStemAPIControllersPurchasingInvoicesListAPIResponseInvoiceDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingInvoicesListAPIResponseInvoiceDetails {
  /** @format date-time */
  dateShipped?: string
  /** @format int32 */
  id?: number
  location?: MainStemAPIControllersPurchasingInvoicesListAPIResponseInvoiceDetailsLocationDetails
  order?: MainStemAPIControllersPurchasingInvoicesListAPIResponseInvoiceDetailsOrderDetails
  quantumLeafID?: string
  quantumLeafUrl?: string
  quickBooksID?: string
  quickBooksUrl?: string
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  /** @format double */
  subTotal?: number
  supplier?: MainStemAPIControllersPurchasingInvoicesListAPIResponseInvoiceDetailsSupplierDetails
  /** @format double */
  total?: number
  uuid?: string
}

export interface MainStemAPIControllersPurchasingInvoicesListAPIResponseInvoiceDetailsLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingInvoicesListAPIResponseInvoiceDetailsOrderDetails {
  /** @format int32 */
  id?: number
  paymentStatus?: string
  uuid?: string
}

export interface MainStemAPIControllersPurchasingInvoicesListAPIResponseInvoiceDetailsSupplierDetails {
  /** @format int32 */
  id?: number
  isPrivate?: boolean
  name?: string
  usesDirectPay?: boolean
  usesPreferredShipping?: boolean
}

export interface MainStemAPIControllersPurchasingInvoicesNotifyAPIRequest {
  /** @format int32 */
  invoiceId?: number
}

export interface MainStemAPIControllersPurchasingInvoicesNotifyAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingInvoicesShipmentsCreateAPIRequest {
  carrier?: string
  /** @format int32 */
  invoiceId?: number
  method?: string
  trackingNumber?: string
}

export interface MainStemAPIControllersPurchasingInvoicesShipmentsCreateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingInvoicesShipmentsListAPIRequest {
  /** @format int32 */
  invoiceId?: number
}

export interface MainStemAPIControllersPurchasingInvoicesShipmentsListAPIResponse {
  shipments?: MainStemAPIControllersPurchasingInvoicesShipmentsListAPIResponseShipmentDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingInvoicesShipmentsListAPIResponseShipmentDetails {
  carrier?: string
  /** @format int32 */
  id?: number
  method?: string
  trackingNumber?: string
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  uuid?: string
}

export interface MainStemAPIControllersPurchasingInvoicesUpdateAPIRequest {
  /** @format date-time */
  dateShipped?: string
  /** @format int32 */
  invoiceId?: number
}

export interface MainStemAPIControllersPurchasingInvoicesUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixRoomsActiveDepartmentsAPIRequest {
  /** @format int32 */
  customerIntegrationSettingID?: number
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixRoomsActiveDepartmentsAPIResponse {
  departments?: MainStemAPIControllersPurchasingIntegrationLeafLogixRoomsActiveDepartmentsAPIResponseDepartmentDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixRoomsActiveDepartmentsAPIResponseDepartmentDetail {
  /** @format int32 */
  departmentID?: number
  departmentName?: string
  linkID?: string
  /** @format int32 */
  locationID?: number
  locationName?: string
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixActiveProductsAPIRequest {
  /** @format int32 */
  customerIntegrationSettingID?: number
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixActiveProductsAPIResponse {
  products?: MainStemAPIControllersPurchasingIntegrationLeafLogixActiveProductsAPIResponseProductDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixActiveProductsAPIResponseProductDetail {
  /** @format int32 */
  productID?: number
  productName?: string
  productSKU?: string
  linkID?: string
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  isPrivate?: boolean
  imageURL?: string
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixVendorsActiveSuppliersAPIRequest {
  /** @format int32 */
  customerIntegrationSettingID?: number
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixVendorsActiveSuppliersAPIResponse {
  suppliers?: MainStemAPIControllersPurchasingIntegrationLeafLogixVendorsActiveSuppliersAPIResponseSupplierDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationLeafLogixVendorsActiveSuppliersAPIResponseSupplierDetail {
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  linkID?: string
  isPrivate?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsApprovalWorkflowSuperApproverAddAPIRequest {
  /** @format int32 */
  locationId?: number
  userId?: string
}

export interface MainStemAPIControllersPurchasingLocationsApprovalWorkflowSuperApproverAddAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsApprovalWorkflowSuperApproverRemoveAPIRequest {
  /** @format int32 */
  locationId?: number
  userId?: string
}

export interface MainStemAPIControllersPurchasingLocationsApprovalWorkflowSuperApproverRemoveAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationBudgetCreateAPIRequest {
  /** @format double */
  budget?: number
  /** @format int32 */
  locationId?: number
  /** @format int32 */
  month?: number
  /** @format int32 */
  year?: number
}

export interface MainStemAPIControllersPurchasingLocationBudgetCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationBudgetListAPIRequest {
  /** @format int32 */
  locationId?: number
}

export interface MainStemAPIControllersPurchasingLocationBudgetListAPIResponse {
  locationBudgets?: MainStemAPIControllersPurchasingLocationBudgetListAPIResponseLocationBudgetDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationBudgetListAPIResponseLocationBudgetDetails {
  /** @format double */
  budget?: number
  /** @format int32 */
  id?: number
  /** @format int32 */
  month?: number
  /** @format double */
  toDateMonth?: number
  /** @format double */
  toDateYear?: number
  /** @format int32 */
  year?: number
}

export interface MainStemAPIControllersPurchasingLocationBudgetUpdateAPIRequest {
  /** @format double */
  budget?: number
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingLocationBudgetUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsDepartmentsListAPIRequest {
  /** @format int32 */
  locationId?: number
}

export interface MainStemAPIControllersPurchasingLocationsDepartmentsListAPIResponse {
  departments?: MainStemAPIControllersPurchasingLocationsDepartmentsListAPIResponseDepartmentDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsDepartmentsListAPIResponseDepartmentDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingLocationsDetailsActivityLogAPIRequest {
  /** @format int32 */
  locationId?: number
}

export interface MainStemAPIControllersPurchasingLocationsDetailsActivityLogAPIResponse {
  activities?: MainStemAPIControllersPurchasingLocationsDetailsActivityLogAPIResponseActivityLogDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsDetailsActivityLogAPIResponseActivityLogDetails {
  content?: string
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  read?: boolean
  type?: string
  typeID?: string
}

export interface MainStemAPIControllersPurchasingLocationsDetailsOrdersAPIRequest {
  /** @format int32 */
  locationId?: number
}

export interface MainStemAPIControllersPurchasingLocationsDetailsOrdersAPIResponse {
  orders?: MainStemAPIControllersPurchasingLocationsDetailsOrdersAPIResponseOrderDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsDetailsOrdersAPIResponseOrderDetails {
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  status?: 'New' | 'PendingApproval' | 'Returned' | 'Approved' | 'Rejected' | 'Cancelled'
  userApprovedBy?: string
  userCreatedBy?: string
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  uuid?: string
}

export interface MainStemAPIControllersPurchasingLocationsDetailsProductFinancialDimensionCreateAPIRequest {
  /** @format int32 */
  financialDimensionId?: number
  /** @format int32 */
  locationId?: number
  showOnInvoicePDF?: boolean
  showOnOrderPDF?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsDetailsProductFinancialDimensionCreateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsDetailsProductFinancialDimensionDeleteAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingLocationsDetailsProductFinancialDimensionDeleteAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsDetailsProductFinancialDimensionUpdateAPIRequest {
  /** @format int32 */
  id?: number
  showOnInvoicePDF?: boolean
  showOnOrderPDF?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsDetailsProductFinancialDimensionUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsDetailsShipmentsAPIRequest {
  /** @format int32 */
  locationId?: number
}

export interface MainStemAPIControllersPurchasingLocationsDetailsShipmentsAPIResponse {
  shipments?: MainStemAPIControllersPurchasingLocationsDetailsShipmentsAPIResponseShipmentDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsDetailsShipmentsAPIResponseShipmentDetails {
  carrier?: string
  carrierType?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateReceived?: string
  /** @format date-time */
  dateShippedOn?: string
  /** @format date-time */
  estimatedDeliveryDate?: string
  /** @format int32 */
  orderID?: number
  orderUUID?: string
  trackingNumber?: string
  uuid?: string
}

export interface MainStemAPIControllersPurchasingLocationsDetailsSupplierFinancialDimensionCreateAPIRequest {
  /** @format int32 */
  financialDimensionId?: number
  /** @format int32 */
  locationId?: number
  showOnInvoicePDF?: boolean
  showOnOrderPDF?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsDetailsSupplierFinancialDimensionCreateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsDetailsSupplierFinancialDimensionDeleteAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingLocationsDetailsSupplierFinancialDimensionDeleteAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsDetailsSupplierFinancialDimensionUpdateAPIRequest {
  /** @format int32 */
  id?: number
  showOnInvoicePDF?: boolean
  showOnOrderPDF?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsDetailsSupplierFinancialDimensionUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsDetailsSupplyAuditReportsAPIRequest {
  /** @format int32 */
  locationId?: number
}

export interface MainStemAPIControllersPurchasingLocationsDetailsSupplyAuditReportsAPIResponse {
  supplyAuditReports?: MainStemAPIControllersPurchasingLocationsDetailsSupplyAuditReportsAPIResponseSupplyAuditReportDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsDetailsSupplyAuditReportsAPIResponseSupplyAuditReportDetails {
  /** @format date-time */
  date?: string
  /** @format int32 */
  month?: number
  /** @format int32 */
  year?: number
}

export interface MainStemAPIControllersPurchasingLocationsDetailsUsersAPIRequest {
  /** @format int32 */
  locationId?: number
}

export interface MainStemAPIControllersPurchasingLocationsDetailsUsersAPIResponse {
  users?: MainStemAPIControllersPurchasingLocationsDetailsUsersAPIResponseUserDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsDetailsUsersAPIResponseUserDetails {
  /** @format date-time */
  dateCreated?: string
  email?: string
  friendlyName?: string
  id?: string
  role?: string
  username?: string
  permissionGroup?: string
}

export interface MainStemAPIControllersPurchasingLocationsCreateAPIRequest {
  address1?: string
  address2?: string
  city?: string
  country?: string
  email?: string
  name?: string
  phone?: string
  state?: string
  tag?: string
  zip?: string
  financialDimensions?: MainStemAPIControllersPurchasingLocationsCreateAPIRequestFinancialDimensionUpdate[]
}

export interface MainStemAPIControllersPurchasingLocationsCreateAPIRequestFinancialDimensionUpdate {
  /** @format int32 */
  financialDimensionID?: number
  /** @format int32 */
  financialDimensionOptionID?: number
}

export interface MainStemAPIControllersPurchasingLocationsCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsDeleteAPIRequest {
  /** @format int32 */
  locationId?: number
}

export interface MainStemAPIControllersPurchasingLocationsDeleteAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsDetailsAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingLocationsDetailsAPIResponse {
  address1?: string
  address2?: string
  addressBillTo?: MainStemAPIControllersPurchasingLocationsDetailsAPIResponseAddressDetails
  addressPhysical?: MainStemAPIControllersPurchasingLocationsDetailsAPIResponseAddressDetails
  addressShipTo?: MainStemAPIControllersPurchasingLocationsDetailsAPIResponseAddressDetails
  city?: string
  country?: string
  email?: string
  enableSendInvoiceEmails?: boolean
  federalBusinessNumber?: string
  financialDimensions?: MainStemSharpHelpersFinancialDimensionsFactoryFinancialDimensionOptionSelectionDetail[]
  freightContactEmail?: string
  freightContactFirstName?: string
  freightContactLastName?: string
  freightContactPhone?: string
  freightLiftGateRequired?: boolean
  freightNotes?: string
  glCode?: string
  headerURL?: string
  /** @format int32 */
  id?: number
  integrations?: MainStemAPIControllersPurchasingLocationsDetailsAPIResponseIntegrationDetails
  localBusinessNumber?: string
  logoURL?: string
  name?: string
  phone?: string
  productFinancialDimensions?: MainStemAPIControllersPurchasingLocationsDetailsAPIResponseProductFinancialDimensionDetails[]
  sageLocationId?: string
  sageTransactionType?: string
  sendInvoiceEmailsAddresses?: string
  shippingAppointmentRequired?: boolean
  shippingDriverAssistanceRequired?: boolean
  shippingEquipmentLength?: string
  shippingEquipmentType?: string
  shippingForkliftOnSite?: boolean
  shippingGatedFacility?: boolean
  shippingInsideDelivery?: boolean
  state?: string
  supplierFinancialDimensions?: MainStemAPIControllersPurchasingLocationsDetailsAPIResponseSupplierFinancialDimensionDetails[]
  tags?: string[]
  tech?: MainStemAPIControllersPurchasingLocationsDetailsAPIResponseTechDetails[]
  uuid?: string
  zip?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsDetailsAPIResponseAddressDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingLocationsDetailsAPIResponseIntegrationDetails {
  quantumLeaf?: MainStemAPIControllersPurchasingLocationsDetailsAPIResponseIntegrationDetailsQuantumLeafDetails
}

export interface MainStemAPIControllersPurchasingLocationsDetailsAPIResponseProductFinancialDimensionDetails {
  financialDimension?: MainStemAPIControllersPurchasingLocationsDetailsAPIResponseProductFinancialDimensionDetailsFinancialDimensionDetails
  /** @format int32 */
  id?: number
  showOnInvoicePDF?: boolean
  showOnOrderPDF?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsDetailsAPIResponseSupplierFinancialDimensionDetails {
  financialDimension?: MainStemAPIControllersPurchasingLocationsDetailsAPIResponseSupplierFinancialDimensionDetailsFinancialDimensionDetails
  /** @format int32 */
  id?: number
  showOnInvoicePDF?: boolean
  showOnOrderPDF?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsDetailsAPIResponseTechDetails {
  /** @format int32 */
  id?: number
  logo?: string
  name?: string
  type?: string
}

export interface MainStemAPIControllersPurchasingLocationsDetailsAPIResponseIntegrationDetailsQuantumLeafDetails {
  /** @format int32 */
  defaultLocationSupplierFinancialDimensionId?: number
}

export interface MainStemAPIControllersPurchasingLocationsDetailsAPIResponseProductFinancialDimensionDetailsFinancialDimensionDetails {
  /** @format int32 */
  id?: number
  label?: string
}

export interface MainStemAPIControllersPurchasingLocationsDetailsAPIResponseSupplierFinancialDimensionDetailsFinancialDimensionDetails {
  /** @format int32 */
  id?: number
  label?: string
}

export interface MainStemAPIControllersPurchasingLocationsDetailsProductListsAPIRequest {
  /** @format int32 */
  locationId?: number
}

export interface MainStemAPIControllersPurchasingLocationsDetailsProductListsAPIResponse {
  productLists?: MainStemAPIControllersPurchasingLocationsDetailsProductListsAPIResponseProductListDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsDetailsProductListsAPIResponseProductListDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingLocationsDuplicateAPIRequest {
  copyUsers?: boolean
  /** @format int32 */
  locationId?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingLocationsDuplicateAPIResponse {
  /** @format int32 */
  locationId?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsIntegrationsQuantumLeafUpdateAPIRequest {
  /** @format int32 */
  locationID?: number
  /** @format int32 */
  locationSupplierFinancialDimensionID?: number
}

export interface MainStemAPIControllersPurchasingLocationsIntegrationsQuantumLeafUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsListAPIResponse {
  locations?: MainStemAPIControllersPurchasingLocationsListAPIResponseLocationDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationsListAPIResponseLocationDetails {
  freightContact?: MainStemAPIControllersPurchasingLocationsListAPIResponseFreightContactDetails
  address1?: string
  address2?: string
  city?: string
  country?: string
  headerURL?: string
  /** @format int32 */
  id?: number
  logoURL?: string
  name?: string
  state?: string
  tags?: string[]
  userRelated?: boolean
  uuid?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingLocationsListAPIResponseFreightContactDetails {
  email?: string
  firstName?: string
  lastName?: string
  phone?: string
}

export interface MainStemAPIControllersPurchasingLocationsUpdateAPIRequest {
  address1?: string
  address2?: string
  city?: string
  country?: string
  email?: string
  enableSendInvoiceEmails?: boolean
  federalBusinessNumber?: string
  financialDimensions?: MainStemAPIControllersPurchasingLocationsUpdateAPIRequestFinancialDimensionUpdate[]
  freightContactEmail?: string
  freightContactFirstName?: string
  freightContactLastName?: string
  freightContactPhone?: string
  freightLiftGateRequired?: boolean
  freightNotes?: string
  glCode?: string
  /** @format int32 */
  id?: number
  localBusinessNumber?: string
  name?: string
  phone?: string
  sendInvoiceEmailsAddresses?: string
  shippingAppointmentRequired?: boolean
  shippingDriverAssistanceRequired?: boolean
  shippingEquipmentLength?: string
  shippingEquipmentType?: string
  shippingForkliftOnSite?: boolean
  shippingGatedFacility?: boolean
  shippingInsideDelivery?: boolean
  state?: string
  tag?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingLocationsUpdateAPIRequestFinancialDimensionUpdate {
  /** @format int32 */
  financialDimensionID?: number
  /** @format int32 */
  financialDimensionOptionID?: number
}

export interface MainStemAPIControllersPurchasingLocationsUpdateAddressAPIRequest {
  addressType?: 'Physical' | 'Billing' | 'Shipping'
  /** @format int32 */
  locationId?: number
  /** @format int32 */
  addressId?: number
}

export interface MainStemAPIControllersPurchasingLocationSupplierFinancialDimensionListAPIResponse {
  locationSupplierFinancialDimensions?: MainStemAPIControllersPurchasingLocationSupplierFinancialDimensionListAPIResponseLocationSupplierFinancialDimensionDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingLocationSupplierFinancialDimensionListAPIResponseLocationSupplierFinancialDimensionDetails {
  financialDimension?: MainStemAPIControllersPurchasingLocationSupplierFinancialDimensionListAPIResponseLocationSupplierFinancialDimensionDetailsFinancialDimensionDetails
  /** @format int32 */
  id?: number
  location?: MainStemAPIControllersPurchasingLocationSupplierFinancialDimensionListAPIResponseLocationSupplierFinancialDimensionDetailsLocationDetails
}

export interface MainStemAPIControllersPurchasingLocationSupplierFinancialDimensionListAPIResponseLocationSupplierFinancialDimensionDetailsFinancialDimensionDetails {
  /** @format int32 */
  id?: number
  label?: string
}

export interface MainStemAPIControllersPurchasingLocationSupplierFinancialDimensionListAPIResponseLocationSupplierFinancialDimensionDetailsLocationDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingMakePaymentsInvoiceAPIRequest {
  /** @format int32 */
  paymentMethodId?: number
  payments?: MainStemAPIControllersPurchasingMakePaymentsInvoiceAPIRequestPaymentDetails[]
}

export interface MainStemAPIControllersPurchasingMakePaymentsInvoiceAPIRequestPaymentDetails {
  accountNumber?: string
  /** @format double */
  amount?: number
  bankName?: string
  customerTaxId?: string
  /** @format int32 */
  fulfillmentId?: number
  invoiceId?: string
  isACH?: boolean
  /** @format int32 */
  paymentMethodId?: number
  routingNumber?: string
  type?: string
}

export interface MainStemAPIControllersPurchasingMakePaymentsInvoiceAPIResponse {
  invoices?: MainStemAPIControllersPurchasingMakePaymentsInvoiceAPIResponseInvoicePaymentDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingMakePaymentsInvoiceAPIResponseInvoicePaymentDetail {
  wasSuccessful?: boolean
  /** @format double */
  amount?: number
  error?: string
  /** @format int32 */
  fulfillmentId?: number
  invoiceId?: string
  /** @format int32 */
  orderId?: number
  uuid?: string
  orderUUID?: string
  /** @format int32 */
  paymentTransactionID?: number
}

export interface MainStemAPIControllersPurchasingMakePaymentsOrderAPIRequest {
  /** @format int32 */
  paymentMethodId?: number
  payments?: MainStemAPIControllersPurchasingMakePaymentsOrderAPIRequestPaymentDetails[]
}

export interface MainStemAPIControllersPurchasingMakePaymentsOrderAPIRequestPaymentDetails {
  accountNumber?: string
  /** @format double */
  amount?: number
  bankName?: string
  customerTaxId?: string
  isACH?: boolean
  /** @format int32 */
  orderId?: number
  /** @format int32 */
  paymentMethodId?: number
  routingNumber?: string
  type?: string
}

export interface MainStemAPIControllersPurchasingMakePaymentsOrderAPIResponse {
  invoices?: MainStemAPIControllersPurchasingMakePaymentsOrderAPIResponseOrderPaymentDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingMakePaymentsOrderAPIResponseOrderPaymentDetail {
  wasSuccessful?: boolean
  /** @format double */
  amount?: number
  error?: string
  uuid?: string
  /** @format int32 */
  orderId?: number
  /** @format int32 */
  paymentTransactionID?: number
}

export interface MainStemAPIControllersPurchasingMenuItemsListAPIRequest {
  /** @format int32 */
  grandParentId?: number
  /** @format int32 */
  parentId?: number
}

export interface MainStemAPIControllersPurchasingMenuItemsListAPIResponse {
  menuItems?: MainStemAPIControllersPurchasingMenuItemsListAPIResponseMenuItemDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingMenuItemsListAPIResponseMenuItemDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingOrdersRFQApproveAPIRequest {
  orderUUID?: string
}

export interface MainStemAPIControllersPurchasingOrdersRFQApproveAPIResponse {
  paymentSuccessful?: boolean
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersCommunicationPurchasingOrderCommunicationActionResolvedControllerAPIRequest {
  comment?: string
  createResolutionCommunication?: boolean
  /** @format int32 */
  id?: number
  requiresAction?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersCommunicationPurchasingOrderCommunicationActionResolvedControllerAPIResponse {
  error?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersCommunicationPurchasingOrderCommunicationCreateControllerAPIRequest {
  /** @format int32 */
  businessPartnerId?: number
  comment?: string
  /** @format int32 */
  fulfillmentMethodId?: number
  /** @format int32 */
  orderId?: number
  requiresAction?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersCommunicationPurchasingOrderCommunicationCreateControllerAPIResponse {
  error?: string
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersCommunicationPurchasingOrderCommunicationForFulfillmentControllerAPIResponse {
  communications?: MainStemAPIControllersPurchasingOrdersCommunicationPurchasingOrderCommunicationForFulfillmentControllerOrderCommunicationData[]
  error?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersCommunicationPurchasingOrderCommunicationForFulfillmentControllerOrderCommunicationData {
  actionResolved?: boolean
  actionResolvedByEmail?: string
  actionResolvedByFriendlyName?: string
  actionResolvedByID?: string
  /** @format int32 */
  businessPartnerID?: number
  businessPartnerName?: string
  comment?: string
  createdByUserEmail?: string
  createdByUserFriendlyName?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateModified?: string
  /** @format date-time */
  dateResolved?: string
  /** @format int32 */
  fulfillmentMethodID?: number
  fulfillmentMethodUUID?: string
  /** @format int32 */
  id?: number
  isDeleted?: boolean
  /** @format int32 */
  orderID?: number
  requiresAction?: boolean
  userCreatedByID?: string
}

export interface MainStemAPIControllersPurchasingOrdersCommunicationPurchasingOrderCommunicationForOrderControllerAPIResponse {
  communications?: MainStemAPIControllersPurchasingOrdersCommunicationPurchasingOrderCommunicationForOrderControllerOrderCommunicationTableData[]
  error?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersCommunicationPurchasingOrderCommunicationForOrderControllerOrderCommunicationTableData {
  actionResolved?: boolean
  actionResolvedByEmail?: string
  actionResolvedByFriendlyName?: string
  actionResolvedByID?: string
  /** @format int32 */
  businessPartnerID?: number
  businessPartnerName?: string
  comment?: string
  createdByUserEmail?: string
  createdByUserFriendlyName?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateModified?: string
  /** @format date-time */
  dateResolved?: string
  /** @format int32 */
  fulfillmentMethodID?: number
  fulfillmentMethodUUID?: string
  /** @format int32 */
  id?: number
  isDeleted?: boolean
  /** @format int32 */
  orderID?: number
  requiresAction?: boolean
  userCreatedByID?: string
}

export interface MainStemAPIControllersPurchasingOrdersCommunicationPurchasingOrderCommunicationRemoveControllerAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingOrdersCommunicationPurchasingOrderCommunicationRemoveControllerAPIResponse {
  error?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersCommunicationPurchasingOrderCommunicationUpdateControllerAPIRequest {
  comment?: string
  /** @format int32 */
  id?: number
  requiresAction?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersCommunicationPurchasingOrderCommunicationUpdateControllerAPIResponse {
  error?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersRFQCreateFromBidsAPIRequest {
  rfqBidIds?: number[]
  /** @format int32 */
  locationId?: number
}

export interface MainStemAPIControllersPurchasingOrdersRFQCreateFromBidsAPIResponse {
  /** @format int32 */
  orderId?: number
  orderUUID?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersDuplicateAPIRequest {
  orderUUID?: string
  baseRequest?: object
  /** @format int32 */
  customerID?: number
  affiliateProgramUUID?: string
  existingAffiliateProgram?: MainStemSharpDataModelsAffiliateProgram
  /** @format int32 */
  businessPartnerId?: number
  businessPartners?: MainStemSharpDataModelsBusinessPartner[]
  /** @format int32 */
  locationID?: number
  isAdmin?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersDuplicateAPIResponse {
  orderUUID?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersSubmitIntegrationAuthorityApprovalAPIRequest {
  orderUUID?: string
  integrationAuthority?: 'Sage' | 'Quickbooks'
  paymentMethods?: MainStemAPIHelpersPaymentsFactoryPaymentRequestPaymentDetails[]
  privateSuppliers?: MainStemAPIControllersPurchasingOrdersSubmitIntegrationAuthorityApprovalAPIRequestPrivateSupplierFulfillments[]
  shippingOptions?: MainStemAPIControllersPurchasingOrdersSubmitIntegrationAuthorityApprovalAPIRequestShippingOptionsInfo[]
  baseRequest?: object
  /** @format int32 */
  customerID?: number
  affiliateProgramUUID?: string
  existingAffiliateProgram?: MainStemSharpDataModelsAffiliateProgram
  /** @format int32 */
  businessPartnerId?: number
  businessPartners?: MainStemSharpDataModelsBusinessPartner[]
  /** @format int32 */
  locationID?: number
  isAdmin?: boolean
}

export interface MainStemAPIHelpersPaymentsFactoryPaymentRequestPaymentDetails {
  accountNumber?: string
  /** @format double */
  amount?: number
  applyPaymentImmediately?: boolean
  bankName?: string
  customerTaxId?: string
  isACH?: boolean
  /** @format int32 */
  paymentMethodId?: number
  routingNumber?: string
  type?: string
}

export interface MainStemAPIControllersPurchasingOrdersSubmitIntegrationAuthorityApprovalAPIRequestPrivateSupplierFulfillments {
  lineItems?: MainStemAPIControllersPurchasingOrdersSubmitIntegrationAuthorityApprovalAPIRequestPrivateSupplierFulfillmentsLineItemDetails[]
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  /** @format int32 */
  supplierId?: number
}

export interface MainStemAPIControllersPurchasingOrdersSubmitIntegrationAuthorityApprovalAPIRequestShippingOptionsInfo {
  /** @format int32 */
  optionId?: number
  /** @format double */
  price?: number
  /** @format double */
  salesTax?: number
  /** @format int32 */
  supplierId?: number
}

export interface MainStemAPIControllersPurchasingOrdersSubmitIntegrationAuthorityApprovalAPIRequestPrivateSupplierFulfillmentsLineItemDetails {
  /** @format int32 */
  orderLineItemId?: number
  /** @format double */
  price?: number
  /** @format int32 */
  quantity?: number
}

export interface MainStemAPIControllersPurchasingOrdersSubmitIntegrationAuthorityApprovalAPIResponse {
  paymentSuccessful?: boolean
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrderLineItemCreateAPIRequest {
  /** @format int32 */
  orderId?: number
  /** @format int32 */
  productId?: number
  /** @format int32 */
  quantity?: number
}

export interface MainStemAPIControllersPurchasingOrderLineItemCreateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrderLineItemDeleteAPIRequest {
  /** @format int32 */
  orderLineItemId?: number
}

export interface MainStemAPIControllersPurchasingOrderLineItemUpdateAPIRequest {
  /** @format int32 */
  orderLineItemId?: number
  /** @format double */
  price?: number
  /** @format int32 */
  quantity?: number
}

export interface MainStemAPIControllersPurchasingOrdersApprovalApproveAPIRequest {
  orderUUID?: string
  /** @format int32 */
  workflowStepID?: number
}

export interface MainStemAPIControllersPurchasingOrdersApprovalApproveAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersApprovalRejectAPIRequest {
  note?: string
  orderUUID?: string
  /** @format int32 */
  workflowStepID?: number
}

export interface MainStemAPIControllersPurchasingOrdersApprovalRejectAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersApprovalRevertAPIRequest {
  orderUUID?: string
}

export interface MainStemAPIControllersPurchasingOrdersApprovalRevertAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersApprovalSubmitForApprovalAPIRequest {
  orderUUID?: string
}

export interface MainStemAPIControllersPurchasingOrdersApprovalSubmitForApprovalAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersCancelAPIRequest {
  orderUUID?: string
}

export interface MainStemAPIControllersPurchasingOrdersCreateAPIRequest {
  affiliateProgramUUID?: string
  /** @format int32 */
  billingAddressId?: number
  /** @format int32 */
  customerSessionID?: number
  /** @format int32 */
  locationId?: number
  /** @format double */
  salesTax?: number
  /** @format int32 */
  shippingAddressId?: number
  /** @format double */
  shippingCost?: number
}

export interface MainStemAPIControllersPurchasingOrdersCreateAPIResponse {
  /** @format int32 */
  orderId?: number
  orderUUID?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIRequest {
  uuid?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponse {
  sessionDetails?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseSessionDetails
  acumaticaSettings?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseAcumaticaIntegrationSettings
  alerts?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseAlertDetails[]
  allocations?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseAllocationsDetails
  allowOrderRecommendations?: boolean
  approvals?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseApprovalDetails
  approvalStatus?: 'SavedCart' | 'AwaitingApproval' | 'Approval' | 'ApprovalWithEdits' | 'Rejected'
  billTo?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseBillToDetails
  budget?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseBudgetDetails
  customerGLCode?: string
  customerPONumber?: string
  /** @format int32 */
  customerSessionID?: number
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateSubmittedForApproval?: string
  /** @format double */
  discount?: number
  email?: string
  events?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseEventDetails[]
  fulfillmentMethods?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseFulfillmentVendorDetails[]
  /** @format int32 */
  id?: number
  isPrivateOrder?: boolean
  lineItems?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseOrderLineItemDetails[]
  /** @format int32 */
  linkedRFQID?: number
  linkedRFQTitle?: string
  location?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseLocationDetails
  locationProductFinancialDimensions?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseLocationProductFinancialDimension[]
  locationSupplierFinancialDimensions?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseLocationSupplierFinancialDimension[]
  /** @format int32 */
  numberOfPossibleReplacements?: number
  /** @format double */
  orderTotal?: number
  paymentStatus?: string
  phone?: string
  purchaseOrders?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetails[]
  quantumLeafSettings?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseQuantumLeafIntegrationSettings
  quickBooksSettings?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseQuickbooksIntegrationSettings
  receiving?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseReceivingDetails
  requisitionStatus?: 'New' | 'PendingApproval' | 'Returned' | 'Approved' | 'Rejected' | 'Cancelled'
  rfqs?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseRFqBidOwner[]
  sageOrderId?: string
  sageRequisitionId?: string
  sageSyncSettings?: MainStemAPIControllersPurchasingOrdersDetailsSageSettings
  sageWorkflowAuthoritySetting?: string
  /** @format double */
  salesTax?: number
  shipments?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseShipmentDetails
  /** @format double */
  shipping?: number
  shipTo?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseShipToDetails
  status?: string
  /** @format double */
  subTotal?: number
  supplierFinancialDimensions?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseSupplierFinancialDimension[]
  suppliers?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseOrderSupplierDetails[]
  termsOrderDetail?: MainStemAPIControllersDEPRECATEAppTermsTermsOrderDetailsControllerAPIResponse
  userApprovedBy?: string
  userCreatedBy?: string
  userSubmittedBy?: string
  uuid?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseSessionDetails {
  allowcXMlOrderProcessing?: boolean
  description?: string
  fileUrl?: string
  name?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseAcumaticaIntegrationSettings {
  /** @format int32 */
  customerIntegrationID?: number
  enabled?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseAlertDetails {
  action?: string
  content?: string
  type?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseAllocationsDetails {
  completed?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseFulfillmentVendorDetailsLineItemDetailsAllocationDetails[]
  needed?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseAllocationNeededDetails[]
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseApprovalDetails {
  /** @format int32 */
  activeStepID?: number
  canApproveReject?: boolean
  isFutureSubmitter?: boolean
  isLastApprover?: boolean
  isSuperApprover?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseBillToDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseBudgetDetails {
  /** @format double */
  currentMTD?: number
  /** @format double */
  currentYTD?: number
  dateBudget?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseBudgetDetailsDateDetails
  dateFirstOfMonth?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseBudgetDetailsDateDetails
  dateFirstOfYear?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseBudgetDetailsDateDetails
  dateLastOfMonth?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseBudgetDetailsDateDetails
  dateLastOfYear?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseBudgetDetailsDateDetails
  /** @format double */
  totalMTD?: number
  /** @format double */
  totalYTD?: number
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseEventDetails {
  /** @format date-time */
  date?: string
  title?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseFulfillmentVendorDetails {
  acceptanceRequiredEnabled?: boolean
  acceptanceStatus?: 'Cancelled' | 'Pending' | 'Accepted' | 'Rejected'
  acknowledgedBy?: string
  acknowledgedByUserId?: string
  acknowledgementType?: 'MainStemPixel' | 'SupplierCoreOrderDetails' | 'Integration'
  acumaticaIntegration?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseAcumaticaIntegration
  avalaraTaxBreakDown?: AvalaraAvaTaxRestClientTransactionSummary[]
  /** @format date-time */
  dateAccepted?: string
  /** @format date-time */
  dateAcknowledged?: string
  /** @format date-time */
  dateNotified?: string
  /** @format date-time */
  datePickedOn?: string
  /** @format date-time */
  datePromisedBy?: string
  /** @format date-time */
  dateReceived?: string
  /** @format date-time */
  dateRejected?: string
  /** @format date-time */
  dateShipped?: string
  /** @format date-time */
  dateShippedOn?: string
  /** @format double */
  discount?: number
  /** @format double */
  fees?: number
  /** @format int32 */
  id?: number
  invoiceProblems?: MainStemAPIControllersPurchasingInvoiceProblemsListAPIResponseInvoiceProblemDetail[]
  invoiceStatusDetail?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseFulfillmentVendorDetailsPrivateSupplierInvoiceStatusDetail
  isPrivateSupplier?: boolean
  lineItems?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseFulfillmentVendorDetailsLineItemDetails[]
  location?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseLocationDetails
  logoURL?: string
  name?: string
  orderCommunications?: MainStemAPIControllersPurchasingOrdersCommunicationPurchasingOrderCommunicationForFulfillmentControllerOrderCommunicationData[]
  quantumLeafIntegration?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseQuantumLeafIntegration
  quickBooksIntegration?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseQuickbooksIntegration
  sageInvoiceId?: string
  /** @format double */
  salesTax?: number
  shipments?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseFulfillmentVendorDetailsShipmentDetails[]
  shipmentType?: string
  /** @format double */
  shipping?: number
  /** @format double */
  subTotal?: number
  supplier?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseFulfillmentVendorDetailsSupplierDetails
  taxBreakdownJSON?: string
  type?: string
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  uuid?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseOrderLineItemDetails {
  /** @format int32 */
  id?: number
  /** @format double */
  price?: number
  product?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseOrderLineItemDetailsProductDetails
  /** @format int32 */
  quantity?: number
  /** @format int32 */
  rfqBidID?: number
  /** @format int32 */
  rfqBidLineItemID?: number
  /** @format int32 */
  rfqID?: number
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseLocationDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseLocationProductFinancialDimension {
  financialDimensions?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseLocationProductFinancialDimensionFinancialDimensionDetails[]
  product?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseLocationProductFinancialDimensionProductDetails
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseLocationSupplierFinancialDimension {
  financialDimensions?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseLocationSupplierFinancialDimensionFinancialDimensionDetails[]
  supplier?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseLocationSupplierFinancialDimensionSupplierDetails
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetails {
  alerts?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsPurchaseOrderAlertDetails[]
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateSalesTaxCalculated?: string
  /** @format date-time */
  dateShippingCalculated?: string
  discounts?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsDiscountDetails[]
  fees?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsFeeDetails[]
  freightCollect?: boolean
  /** @format int32 */
  id?: number
  isPunchOutGenerated?: boolean
  lineItems?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsLineItemDetails[]
  requisition?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsRequisitionDetails
  salesTax?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsSalesTaxDescriptionDetails[]
  shipments?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsPurchaseOrderShipmentDetails[]
  shippingDetails?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsShippingDetails
  supplier?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsSupplierDetails
  totals?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsPurchaseOrderTotalsDetails
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseQuantumLeafIntegrationSettings {
  /** @format int32 */
  customerIntegrationID?: number
  enabled?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseQuickbooksIntegrationSettings {
  accountType?: string
  /** @format int32 */
  customerIntegrationID?: number
  enabled?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseReceivingDetails {
  completed?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseFulfillmentVendorDetailsLineItemDetailsReceivingDetails[]
  needed?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseReceivingNeededDetails[]
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseRFqBidOwner {
  /** @format int32 */
  id?: number
  /** @format int32 */
  rfqBidID?: number
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  title?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsSageSettings {
  canSyncInvoicesAtLocation?: boolean
  canSyncPurchaseOrdersAtLocation?: boolean
  canSyncPurchaseRequistionsAtLocation?: boolean
  enabled?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseShipmentDetails {
  needed?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseShipmentNeededDetails[]
  shipped?: MainStemAPIControllersPurchasingShipmentsListAPIResponseShipmentDetails[]
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseShipToDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseSupplierFinancialDimension {
  financialDimensions?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseSupplierFinancialDimensionFinancialDimensionDetails[]
  supplier?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseSupplierFinancialDimensionSupplierDetails
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseOrderSupplierDetails {
  /** @format int32 */
  id?: number
  supplier?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseOrderSupplierDetailsSupplierDetails
}

export interface MainStemAPIControllersDEPRECATEAppTermsTermsOrderDetailsControllerAPIResponse {
  invoiceCollectables?: MainStemAPIControllersDEPRECATEAppTermsTermsOrderDetailsControllerInvoiceCollectable[]
  additionalModifications?: MainStemAPIControllersDEPRECATEAppTermsTermsOrderDetailsControllerTermDetails[]
  allTermLedgers?: MainStemAPIControllersDEPRECATEAppTermsTermsOrderDetailsControllerTermDetails[]
  /** @format date-time */
  basedOnShippedDate?: string
  error?: string
  orderBalanceTerm?: MainStemAPIControllersDEPRECATEAppTermsTermsOrderDetailsControllerTermDetails
  organizationHasTerms?: boolean
  /** @format int32 */
  organizationID?: number
  /** @format date-time */
  paymentDateDue?: string
  /** @format double */
  paymentDue?: number
  /** @format double */
  paymentDueSoon?: number
  /** @format double */
  paymentsTotal?: number
  termPayments?: MainStemAPIControllersDEPRECATEAppTermsTermsOrderDetailsControllerTermDetails[]
  /** @format int32 */
  termsDays?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseFulfillmentVendorDetailsLineItemDetailsAllocationDetails {
  /** @format date-time */
  dateCreated?: string
  department?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseFulfillmentVendorDetailsLineItemDetailsAllocationDetailsDepartmentDetails
  /** @format int32 */
  id?: number
  /** @format int32 */
  quantity?: number
  userCreatedBy?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseFulfillmentVendorDetailsLineItemDetailsAllocationDetailsUserDetails
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseAllocationNeededDetails {
  allocationsLeft?: boolean
  /** @format int32 */
  id?: number
  /** @format double */
  price?: number
  product?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseFulfillmentVendorDetailsLineItemDetailsProductDetails
  /** @format int32 */
  quantity?: number
  /** @format int32 */
  quantityAllocated?: number
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseBudgetDetailsDateDetails {
  /** @format int32 */
  date?: number
  /** @format int32 */
  month?: number
  /** @format int32 */
  year?: number
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseAcumaticaIntegration {
  purchaseOrderID?: string
  purchaseOrderUrl?: string
  /** @format date-time */
  queuedAt?: string
  syncAllowable?: boolean
  /** @format int32 */
  customerIntegrationID?: number
  enabled?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseFulfillmentVendorDetailsPrivateSupplierInvoiceStatusDetail {
  allowResendOfEmail?: boolean
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateViewed?: string
  emailNotificationFileCopies?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseFulfillmentVendorDetailsPrivateSupplierNotificationFile[]
  /** @format int32 */
  id?: number
  status?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseFulfillmentVendorDetailsLineItemDetails {
  allocations?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseFulfillmentVendorDetailsLineItemDetailsAllocationDetails[]
  allocationsLeft?: boolean
  /** @format int32 */
  id?: number
  /** @format double */
  price?: number
  product?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseFulfillmentVendorDetailsLineItemDetailsProductDetails
  /** @format int32 */
  quantity?: number
  /** @format int32 */
  quantityAllocated?: number
  /** @format int32 */
  rfqBidID?: number
  /** @format int32 */
  rfqBidLIneItemID?: number
  /** @format int32 */
  rfqID?: number
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseQuantumLeafIntegration {
  purchaseOrderID?: string
  purchaseOrderUrl?: string
  /** @format date-time */
  queuedAt?: string
  syncAllowable?: boolean
  /** @format int32 */
  customerIntegrationID?: number
  enabled?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseQuickbooksIntegration {
  billID?: string
  /** @format date-time */
  queuedAt?: string
  syncAllowable?: boolean
  accountType?: string
  /** @format int32 */
  customerIntegrationID?: number
  enabled?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseFulfillmentVendorDetailsShipmentDetails {
  carrier?: string
  carrierType?: string
  /** @format date-time */
  dateReceived?: string
  /** @format date-time */
  dateShippedOn?: string
  /** @format date-time */
  estimatedDeliveryDate?: string
  trackingNumber?: string
  uuid?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseFulfillmentVendorDetailsSupplierDetails {
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  logoURL?: string
  name?: string
  state?: string
  usesDirectPay?: boolean
  usesPreferredShipping?: boolean
  zip?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseOrderLineItemDetailsProductDetails {
  hasVolumePricing?: boolean
  /** @format int32 */
  id?: number
  imageURL?: string
  isPrivate?: boolean
  /** @format int32 */
  maximumOrderAmount?: number
  /** @format int32 */
  minimumOrderAmount?: number
  name?: string
  /** @format int32 */
  soldInQuantitiesOf?: number
  type?: string
  volumePricing?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseOrderLineItemDetailsProductDetailsVolumePricingDetails[]
  volumePricingPriceAgreement?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseOrderLineItemDetailsProductDetailsVolumePricingAgreementDetails[]
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseLocationProductFinancialDimensionFinancialDimensionDetails {
  financialDimensionOption?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseLocationProductFinancialDimensionFinancialDimensionDetailsFinancialDimensionOptionDetails
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseLocationProductFinancialDimensionProductDetails {
  /** @format int32 */
  id?: number
  image?: string
  name?: string
  type?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseLocationSupplierFinancialDimensionFinancialDimensionDetails {
  financialDimensionOption?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseLocationSupplierFinancialDimensionFinancialDimensionDetailsFinancialDimensionOptionDetails
  /** @format int32 */
  id?: number
  /** @format int32 */
  locationSupplierFinancialDimensionID?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseLocationSupplierFinancialDimensionSupplierDetails {
  /** @format int32 */
  id?: number
  image?: string
  name?: string
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsPurchaseOrderAlertDetails {
  content?: string
  title?: string
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsDiscountDetails {
  /** @format double */
  amount?: number
  /** @format date-time */
  dateCreated?: string
  notes?: string
  reason?: string
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsFeeDetails {
  /** @format double */
  amount?: number
  /** @format date-time */
  dateCreated?: string
  notes?: string
  reason?: string
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsLineItemDetails {
  /** @format double */
  discountsPerItem?: number
  /** @format double */
  feesPerItem?: number
  /** @format int32 */
  id?: number
  /** @format double */
  pricePerItem?: number
  product?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsLineItemDetailsProductDetails
  /** @format int32 */
  quantity?: number
  /** @format double */
  salesTaxPerItem?: number
  /** @format double */
  shippingPerItem?: number
  /** @format double */
  total?: number
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsRequisitionDetails {
  /** @format date-time */
  dateApproved?: string
  /** @format int32 */
  id?: number
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  uuid?: string
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsSalesTaxDescriptionDetails {
  /** @format double */
  amount?: number
  jurisdiction?: string
  name?: string
  /** @format double */
  rate?: number
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsPurchaseOrderShipmentDetails {
  carrier?: string
  carrierType?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateShippedOn?: string
  location?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsPurchaseOrderShipmentDetailsLocationDetails
  status?: string
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  uuid?: string
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsShippingDetails {
  carrier?: string
  frieghtCollect?: boolean
  log?: string[]
  method?: string
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsSupplierDetails {
  bandedShippingRates?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsSupplierDetailsBandedShippingRateDetails[]
  city?: string
  country?: string
  freightShippingRequired?: boolean
  /** @format int32 */
  freightWeightLimit?: number
  hasBandedShipping?: boolean
  hasDirectPay?: boolean
  hasPreferredShipping?: boolean
  /** @format int32 */
  id?: number
  isVerified?: boolean
  logo?: string
  name?: string
  state?: string
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsPurchaseOrderTotalsDetails {
  /** @format double */
  discounts?: number
  /** @format double */
  fees?: number
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  /** @format double */
  subtotal?: number
  /** @format double */
  total?: number
  /** @format double */
  weight?: number
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseFulfillmentVendorDetailsLineItemDetailsReceivingDetails {
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  datePromisedBy?: string
  /** @format date-time */
  dateShipped?: string
  /** @format int32 */
  fulfillmentID?: number
  invoiceID?: string
  /** @format int32 */
  invoiceLineItemID?: number
  /** @format int32 */
  locationID?: number
  locationName?: string
  /** @format int32 */
  orderID?: number
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  orderUUID?: string
  /** @format int32 */
  productID?: number
  productName?: string
  /** @format int32 */
  quantity?: number
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  /** @format int32 */
  totalReceived?: number
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  uuid?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseReceivingNeededDetails {
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  datePromisedBy?: string
  /** @format date-time */
  dateShipped?: string
  /** @format int32 */
  fulfillmentID?: number
  invoiceID?: string
  /** @format int32 */
  invoiceLineItemID?: number
  /** @format int32 */
  locationID?: number
  locationName?: string
  /** @format int32 */
  orderID?: number
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  orderUUID?: string
  /** @format int32 */
  productID?: number
  productName?: string
  /** @format int32 */
  quantity?: number
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  /** @format int32 */
  totalReceived?: number
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  uuid?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseShipmentNeededDetails {
  /** @format int32 */
  id?: number
  /** @format double */
  price?: number
  product?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseFulfillmentVendorDetailsLineItemDetailsProductDetails
  /** @format int32 */
  quantity?: number
}

export interface MainStemAPIControllersPurchasingShipmentsListAPIResponseShipmentDetails {
  carrier?: string
  carrierType?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateReceived?: string
  /** @format date-time */
  dateShippedOn?: string
  /** @format date-time */
  estimatedDeliveryDate?: string
  /** @format int32 */
  id?: number
  location?: MainStemAPIControllersPurchasingShipmentsListAPIResponseShipmentDetailsLocationDetails
  /** @format int32 */
  orderID?: number
  orderUUID?: string
  status?: string
  trackingNumber?: string
  userRelated?: boolean
  uuid?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseSupplierFinancialDimensionFinancialDimensionDetails {
  financialDimensionOption?: MainStemAPIControllersPurchasingOrdersDetailsAPIResponseSupplierFinancialDimensionFinancialDimensionDetailsFinancialDimensionOptionDetails
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseSupplierFinancialDimensionSupplierDetails {
  /** @format int32 */
  id?: number
  image?: string
  name?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseOrderSupplierDetailsSupplierDetails {
  /** @format int32 */
  id?: number
  image?: string
  name?: string
}

export interface MainStemAPIControllersDEPRECATEAppTermsTermsOrderDetailsControllerInvoiceCollectable {
  /** @format double */
  amount?: number
  /** @format date-time */
  dateCollectable?: string
  /** @format date-time */
  earliestCollectable?: string
  isDue?: boolean
  /** @format date-time */
  shippedOn?: string
}

export interface MainStemAPIControllersDEPRECATEAppTermsTermsOrderDetailsControllerTermDetails {
  /** @format double */
  amount?: number
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  note?: string
  /** @format int32 */
  orderID?: number
  reason?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseFulfillmentVendorDetailsLineItemDetailsAllocationDetailsDepartmentDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseFulfillmentVendorDetailsLineItemDetailsAllocationDetailsUserDetails {
  id?: string
  username?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseFulfillmentVendorDetailsLineItemDetailsProductDetails {
  /** @format int32 */
  id?: number
  imageURL?: string
  name?: string
  type?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseFulfillmentVendorDetailsPrivateSupplierNotificationFile {
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  fileID?: number
  fileLocation?: string
  fileType?: string
  fileUUID?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseOrderLineItemDetailsProductDetailsVolumePricingDetails {
  /** @format double */
  price?: number
  /** @format int32 */
  quantityHigh?: number
  /** @format int32 */
  quantityLow?: number
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseOrderLineItemDetailsProductDetailsVolumePricingAgreementDetails {
  /** @format double */
  price?: number
  /** @format int32 */
  quantityHigh?: number
  /** @format int32 */
  quantityLow?: number
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseLocationProductFinancialDimensionFinancialDimensionDetailsFinancialDimensionOptionDetails {
  /** @format int32 */
  id?: number
  label?: string
  value?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseLocationSupplierFinancialDimensionFinancialDimensionDetailsFinancialDimensionOptionDetails {
  /** @format int32 */
  id?: number
  label?: string
  value?: string
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsLineItemDetailsProductDetails {
  /** @format int32 */
  id?: number
  image?: string
  name?: string
  type?: string
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsPurchaseOrderShipmentDetailsLocationDetails {
  address?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsPurchaseOrderShipmentDetailsLocationDetailsAddressDetails
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsSupplierDetailsBandedShippingRateDetails {
  /** @format double */
  amount?: number
  /** @format double */
  orderTotalLowerLimit?: number
  /** @format double */
  orderTotalUpperLimit?: number
}

export interface MainStemAPIControllersPurchasingShipmentsListAPIResponseShipmentDetailsLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsAPIResponseSupplierFinancialDimensionFinancialDimensionDetailsFinancialDimensionOptionDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetailsPurchaseOrderShipmentDetailsLocationDetailsAddressDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsFrictionFreeAPIRequest {
  /** @format int32 */
  orderId?: number
}

export interface MainStemAPIControllersPurchasingOrdersDetailsFrictionFreeAPIResponse {
  results?: MainStemAPIControllersPurchasingOrdersDetailsFrictionFreeAPIResponseFrictionFreeResults[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersDetailsFrictionFreeAPIResponseFrictionFreeResults {
  matches?: MainStemAPIControllersGlobalShopGetProductsAPIResponseProductDetails[]
  /** @format int32 */
  orderLineItemID?: number
  product?: MainStemAPIControllersPurchasingOrdersDetailsFrictionFreeAPIResponseFrictionFreeResultsProductDetails
}

export interface MainStemAPIControllersGlobalShopGetProductsAPIResponseProductDetails {
  /** @format int32 */
  daysToFulfill?: number
  hasVolumePricing?: boolean
  /** @format double */
  highestPrice?: number
  /** @format int32 */
  id?: number
  image?: string
  isOutOfStock?: boolean
  isPrivate?: boolean
  /** @format double */
  lowestPrice?: number
  name?: string
  /** @format int32 */
  numberOfSuppliers?: number
  /** @format double */
  priceSellEnterprise?: number
  type?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsFrictionFreeAPIResponseFrictionFreeResultsProductDetails {
  name?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIRequest {
  /** @format int32 */
  orderId?: number
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponse {
  location?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponseLocationDetails
  orders?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponseOrderDetails[]
  products?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponseProductDetials[]
  d6?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponseD6Details
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponseLocationDetails {
  hasLocationDimension?: boolean
  type?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponseOrderDetails {
  /** @format int32 */
  id?: number
  quickBooksId?: string
  quickBooksUrl?: string
  supplier?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponseOrderDetailsSupplierDetails
  uuid?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponseProductDetials {
  /** @format int32 */
  id?: number
  image?: string
  isPrivate?: boolean
  itemGroupCodeGP?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponseProductDetialsFinancialDimensionOption
  itemGroupCodeRetail?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponseProductDetialsFinancialDimensionOption
  name?: string
  skuInternal?: string
  /** @format int32 */
  supplierId?: number
  supplierName?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponseD6Details {
  financialDimensions?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponseD6DetailsD6FinancialDimensionDetails
  /** @format int32 */
  integrationSettingsId?: number
  items?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponseD6DetailsD6Item[]
  vendors?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponseD6DetailsD6Vendor[]
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponseOrderDetailsSupplierDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponseProductDetialsFinancialDimensionOption {
  /** @format int32 */
  id?: number
  label?: string
  value?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponseD6DetailsD6FinancialDimensionDetails {
  costCenter?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponseD6DetailsD6FinancialDimensionDetailsFinancialDimensionDetails
  department?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponseD6DetailsD6FinancialDimensionDetailsFinancialDimensionDetails
  itemGroupCodeGP?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponseD6DetailsD6FinancialDimensionDetailsFinancialDimensionDetails
  itemGroupCodeRetail?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponseD6DetailsD6FinancialDimensionDetailsFinancialDimensionDetails
  location?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponseD6DetailsD6FinancialDimensionDetailsFinancialDimensionDetails
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponseD6DetailsD6Item {
  label?: string
  value?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponseD6DetailsD6Vendor {
  label?: string
  value?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponseD6DetailsD6FinancialDimensionDetailsFinancialDimensionDetails {
  /** @format int32 */
  id?: number
  value?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuantumLeafDetailsAPIRequest {
  /** @format int32 */
  orderId?: number
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuantumLeafDetailsAPIResponse {
  locationProducts?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuantumLeafDetailsAPIResponseProductDetials[]
  logs?: MainStemSharpModelsIntegrationsQuantumLeafQuantumLeafLogEntry[]
  orders?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuantumLeafDetailsAPIResponseOrderDetails[]
  products?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuantumLeafDetailsAPIResponseProductDetials[]
  suppliers?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuantumLeafDetailsAPIResponseSupplierDetails[]
  /** @format int32 */
  defaultLocationSupplierFinancialDimensionID?: number
  quantumLeaf?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuantumLeafDetailsAPIResponseQuantumLeafDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuantumLeafDetailsAPIResponseProductDetials {
  /** @format int32 */
  id?: number
  image?: string
  isPrivate?: boolean
  itemId?: string
  name?: string
  skuInternal?: string
  /** @format int32 */
  supplierId?: number
  supplierName?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuantumLeafDetailsAPIResponseOrderDetails {
  /** @format int32 */
  id?: number
  quantumLeafId?: string
  quantumLeafUrl?: string
  supplier?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuantumLeafDetailsAPIResponseOrderDetailsSupplierDetails
  uuid?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuantumLeafDetailsAPIResponseSupplierDetails {
  /** @format int32 */
  id?: number
  image?: string
  isPrivateSupplier?: boolean
  linkId?: string
  name?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuantumLeafDetailsAPIResponseQuantumLeafDetails {
  /** @format int32 */
  integrationSettingsId?: number
  items?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuantumLeafDetailsAPIResponseQuantumLeafDetailsQuantumLeafItem[]
  vendors?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuantumLeafDetailsAPIResponseQuantumLeafDetailsQuantumLeafVendor[]
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuantumLeafDetailsAPIResponseOrderDetailsSupplierDetails {
  /** @format int32 */
  id?: number
  inNetwork?: boolean
  name?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuantumLeafDetailsAPIResponseQuantumLeafDetailsQuantumLeafItem {
  itemType?: 'Stock' | 'NonStock'
  label?: string
  value?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuantumLeafDetailsAPIResponseQuantumLeafDetailsQuantumLeafVendor {
  label?: string
  value?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksDetailsAPIRequest {
  /** @format int32 */
  orderId?: number
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksDetailsAPIResponse {
  invoices?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksDetailsAPIResponseInvoiceDetails[]
  logs?: MainStemSharpModelsLoggingQuickBooksQuickBooksLogEntry[]
  paymentMethods?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksDetailsAPIResponsePaymentMethodDetails[]
  payments?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksDetailsAPIResponsePaymentDetails[]
  productCategories?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksDetailsAPIResponseProductCategoryDetails[]
  products?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksDetailsAPIResponseProductDetials[]
  quickBooks?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksDetailsAPIResponseQuickBooksDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksDetailsAPIResponseInvoiceDetails {
  /** @format int32 */
  id?: number
  order?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksDetailsAPIResponseInvoiceDetailsOrderDetails
  quickBooksId?: string
  quickBooksUrl?: string
  supplier?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksDetailsAPIResponseInvoiceDetailsSupplierDetails
  uuid?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksDetailsAPIResponsePaymentMethodDetails {
  /** @format int32 */
  id?: number
  last4OfAccount?: string
  quickBooksId?: string
  type?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksDetailsAPIResponsePaymentDetails {
  /** @format int32 */
  id?: number
  quickBooksId?: string
  quickBooksUrl?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksDetailsAPIResponseProductCategoryDetails {
  category?: string
  /** @format int32 */
  quickbooksAccountId?: number
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksDetailsAPIResponseProductDetials {
  /** @format int32 */
  financialDimensionOptionId?: number
  /** @format int32 */
  id?: number
  image?: string
  isPrivate?: boolean
  name?: string
  quickBooksId?: string
  skuInternal?: string
  /** @format int32 */
  supplierId?: number
  supplierImage?: string
  supplierName?: string
  type?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksDetailsAPIResponseQuickBooksDetails {
  billPaySyncEnabled?: boolean
  integrationCategoryDetails?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksDetailsAPIResponseQuickBooksDetailsCategoryIntegrationDetails
  integrationProductDetails?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksDetailsAPIResponseQuickBooksDetailsProductIntegrationDetails
  /** @format int32 */
  integrationSettingsId?: number
  productIntegrationLevel?: 'Product' | 'Category'
  salesTaxQuickbooksAccountId?: string
  salesTaxQuickbooksClassId?: string
  shippingQuickbooksAccountId?: string
  shippingQuickbooksClassId?: string
  trackClassesEnabled?: boolean
  trackLocationsEnabled?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksDetailsAPIResponseInvoiceDetailsOrderDetails {
  /** @format int32 */
  id?: number
  uuid?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksDetailsAPIResponseInvoiceDetailsSupplierDetails {
  /** @format int32 */
  id?: number
  inNetwork?: boolean
  name?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksDetailsAPIResponseQuickBooksDetailsCategoryIntegrationDetails {
  financialDimension?: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksDetailsAPIResponseQuickBooksDetailsCategoryIntegrationDetailsFinancialDimensionDetails
  type?: 'Aggregate' | 'LineItem'
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksDetailsAPIResponseQuickBooksDetailsProductIntegrationDetails {
  defaultExpenseAccount?: string
  defaultItemType?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksDetailsAPIResponseQuickBooksDetailsCategoryIntegrationDetailsFinancialDimensionDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsPaymentsAPIRequest {
  uuid?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsPaymentsAPIResponse {
  payments?: MainStemAPIControllersPurchasingOrdersDetailsPaymentsAPIResponsePaymentDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersDetailsPaymentsAPIResponsePaymentDetails {
  paymentType?: string
  /** @format double */
  amount?: number
  createdBy?: string
  /** @format date-time */
  dateCreated?: string
  last4?: string
  note?: string
  status?: string
  type?: string
  quickBooksBillID?: string
  quickBooksBillPaymentID?: string
  hasQuickBooksIntegration?: boolean
  /** @format int32 */
  id?: number
  paymentLinks?: MainStemAPIControllersPurchasingOrdersDetailsPaymentsAPIResponsePaymentLinkDetail[]
  quickBooksIntegrationEnabled?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersDetailsPaymentsAPIResponsePaymentLinkDetail {
  /** @format int32 */
  fulfillmentMethodID?: number
  /** @format double */
  amount?: number
  quickBooksBillPaymentID?: string
  /** @format int32 */
  orderID?: number
  quickBooksBillID?: string
  syncAllowable?: boolean
  /** @format date-time */
  queuedAt?: string
  /** @format int32 */
  id?: number
  fulfillmentUUID?: string
  quickBooksAccountType?: string
  linkedInvoiceIsPrivate?: boolean
  quickBooksEnabled?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersEmailLogsAPIRequest {
  orderUUID?: string
}

export interface MainStemAPIControllersPurchasingOrdersEmailLogsAPIResponse {
  logs?: MainStemSharpHelpersLoggingOrderEmailedVerificationEntry[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemSharpHelpersLoggingOrderEmailedVerificationEntry {
  partitionKey?: string
  allEmailStatusesAsString?: string
  data?: string
  /** @format int32 */
  orderID?: number
  /** @format int32 */
  fulfillmentID?: number
  /** @format date-time */
  sentToMiddleWareAt?: string
  /** @format date-time */
  sentToQueueAt?: string
  queueMessageID?: string
  /** @format date-time */
  enteredQueueAt?: string
  subject?: string
  sentTo?: string
  /** @format date-time */
  emailProviderEventAt?: string
  emailProviderWasSuccessful?: boolean
  emailProviderMessage?: string
  emailProviderID?: string
  /** @format date-time */
  emailProviderDeliveredAt?: string
  sentMultipleEmails?: boolean
  groupID?: string
  rowKey?: string
  /** @format date-time */
  timestamp?: string
  eTag?: AzureETag
}

export interface MainStemAPIControllersPurchasingOrdersListAPIResponse {
  orders?: MainStemAPIControllersPurchasingOrdersListAPIResponseOrderDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersListAPIResponseOrderDetails {
  approvalStatus?: 'SavedCart' | 'AwaitingApproval' | 'Approval' | 'ApprovalWithEdits' | 'Rejected'
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format double */
  discount?: number
  /** @format int32 */
  id?: number
  isPrivateOrder?: boolean
  location?: MainStemAPIControllersPurchasingOrdersListAPIResponseOrderDetailsLocationDetails
  make?: string
  /** @format double */
  orderTotal?: number
  paymentStatus?: string
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  status?: string
  /** @format double */
  subTotal?: number
  suppliers?: MainStemAPIControllersPurchasingOrdersListAPIResponseOrderDetailsSupplierDetails[]
  userApprovedBy?: string
  userCreatedBy?: string
  userRelated?: boolean
  uuid?: string
}

export interface MainStemAPIControllersPurchasingOrdersListAPIResponseOrderDetailsLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingOrdersListAPIResponseOrderDetailsSupplierDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingOrdersPayAPIRequest {
  orderUUID?: string
  paymentMethods?: MainStemAPIHelpersPaymentsFactoryPaymentRequestPaymentDetails[]
}

export interface MainStemAPIControllersPurchasingOrdersResendPrivateSupplierEmailAPIRequest {
  invoiceUUID?: string
  orderUUID?: string
}

export interface MainStemAPIControllersPurchasingOrdersResendPrivateSupplierEmailAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersRevertAPIRequest {
  orderUUID?: string
}

export interface MainStemAPIControllersPurchasingOrdersSubmitPaymentMethodsListAPIRequest {
  orderUUID?: string
}

export interface MainStemAPIControllersPurchasingOrdersSubmitPaymentMethodsListAPIResponse {
  accounts?: MainStemAPIControllersPurchasingOrdersSubmitPaymentMethodsListAPIResponseAccountDetails[]
  creditCards?: MainStemAPIControllersPurchasingOrdersSubmitPaymentMethodsListAPIResponseCreditCardDetails[]
  terms?: MainStemAPIControllersDEPRECATEAppOrganizationsGetTermsTotalAPIResponse
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersSubmitPaymentMethodsListAPIResponseAccountDetails {
  /** @format int32 */
  id?: number
  last4OfACH?: string
  nickname?: string
}

export interface MainStemAPIControllersPurchasingOrdersSubmitPaymentMethodsListAPIResponseCreditCardDetails {
  /** @format date-time */
  expirationDate?: string
  /** @format int32 */
  id?: number
  isDefault?: boolean
  issuer?: string
  last4OfCard?: string
}

export interface MainStemAPIControllersDEPRECATEAppOrganizationsGetTermsTotalAPIResponse {
  /** @format double */
  available?: number
  /** @format double */
  balance?: number
  /** @format double */
  creditLimit?: number
  /** @format int32 */
  days?: number
  /** @format double */
  due?: number
  error?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersSubmitApproveAPIRequest {
  orderUUID?: string
  paymentMethods?: MainStemAPIHelpersPaymentsFactoryPaymentRequestPaymentDetails[]
  privateSuppliers?: MainStemAPIControllersPurchasingOrdersSubmitApproveAPIRequestPrivateSupplierFulfillments[]
  shippingOptions?: MainStemAPIControllersPurchasingOrdersSubmitApproveAPIRequestShippingOptionsInfo[]
  shipTo?: MainStemAPIControllersPurchasingOrdersSubmitApproveAPIRequestAddressDetails
  billTo?: MainStemAPIControllersPurchasingOrdersSubmitApproveAPIRequestAddressDetails
  baseRequest?: object
  /** @format int32 */
  customerID?: number
  affiliateProgramUUID?: string
  existingAffiliateProgram?: MainStemSharpDataModelsAffiliateProgram
  /** @format int32 */
  businessPartnerId?: number
  businessPartners?: MainStemSharpDataModelsBusinessPartner[]
  /** @format int32 */
  locationID?: number
  isAdmin?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersSubmitApproveAPIRequestPrivateSupplierFulfillments {
  lineItems?: MainStemAPIControllersPurchasingOrdersSubmitApproveAPIRequestPrivateSupplierFulfillmentsLineItemDetails[]
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  /** @format int32 */
  supplierId?: number
}

export interface MainStemAPIControllersPurchasingOrdersSubmitApproveAPIRequestShippingOptionsInfo {
  /** @format int32 */
  optionId?: number
  /** @format double */
  price?: number
  /** @format double */
  salesTax?: number
  /** @format int32 */
  supplierId?: number
}

export interface MainStemAPIControllersPurchasingOrdersSubmitApproveAPIRequestAddressDetails {
  /** @format int32 */
  id?: number
  name?: string
  address1?: string
  address2?: string
  city?: string
  country?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingOrdersSubmitApproveAPIRequestPrivateSupplierFulfillmentsLineItemDetails {
  /** @format int32 */
  orderLineItemId?: number
  /** @format double */
  price?: number
  /** @format int32 */
  quantity?: number
}

export interface MainStemAPIControllersPurchasingOrdersSubmitApproveAPIResponse {
  paymentSuccessful?: boolean
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersRFQSubmitForApprovalAPIRequest {
  orderUUID?: string
  shippingOptions?: MainStemAPIControllersPurchasingOrdersRFQSubmitForApprovalAPIRequestShippingOptionsInfo[]
}

export interface MainStemAPIControllersPurchasingOrdersRFQSubmitForApprovalAPIRequestShippingOptionsInfo {
  /** @format int32 */
  optionId?: number
  /** @format double */
  price?: number
  /** @format double */
  salesTax?: number
  /** @format int32 */
  vendorId?: number
}

export interface MainStemAPIControllersPurchasingOrdersRFQSubmitForApprovalAPIResponse {
  error?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersDetailsOrderTotalsOverviewAPIRequest {
  uuid?: string
}

export interface MainStemAPIControllersPurchasingOrdersDetailsOrderTotalsOverviewAPIResponse {
  combinedTotals?: MainStemAPIControllersPurchasingOrdersDetailsOrderTotalsOverviewTotalDetails
  invoicedLineItemsDetails?: MainStemAPIControllersPurchasingOrdersDetailsOrderTotalsOverviewLineItemTotals
  orderLineItemsDetails?: MainStemAPIControllersPurchasingOrdersDetailsOrderTotalsOverviewLineItemTotals
  privateTotals?: MainStemAPIControllersPurchasingOrdersDetailsOrderTotalsOverviewTotalDetails
  purchaseOrderTotals?: MainStemAPIControllersPurchasingOrdersDetailsOrderTotalsOverviewTotalDetails
  verifiedTotals?: MainStemAPIControllersPurchasingOrdersDetailsOrderTotalsOverviewTotalDetails
  /** @format double */
  allPaymentsMade?: number
  /** @format double */
  balance?: number
  error?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingOrdersDetailsOrderTotalsOverviewTotalDetails {
  /** @format double */
  discounts?: number
  /** @format double */
  fees?: number
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  /** @format double */
  subTotal?: number
  /** @format double */
  total?: number
}

export interface MainStemAPIControllersPurchasingOrdersDetailsOrderTotalsOverviewLineItemTotals {
  /** @format double */
  privateSubTotal?: number
  /** @format double */
  subTotal?: number
  /** @format int32 */
  totalQuantity?: number
  /** @format int32 */
  uniqueLineItems?: number
  /** @format double */
  verifiedSubTotal?: number
}

export interface MainStemAPIControllersPurchasingOrdersUpdateAPIRequest {
  customerGLCode?: string
  customerPONumber?: string
  email?: string
  orderUUID?: string
  phone?: string
}

export interface MainStemAPIControllersPurchasingOrdersUpdateAddressAPIRequest {
  orderUUID?: string
  /** @format int32 */
  id?: number
  type?: 'ShipTo' | 'BillTo'
  address1?: string
  address2?: string
  city?: string
  name?: string
  state?: string
  country?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingPaymentMethodsCreateACHAPIRequest {
  accountNumber?: string
  accountType?: string
  bankName?: string
  /** @format int32 */
  locationId?: number
  nameOnAccount?: string
  nickname?: string
  routingNumber?: string
}

export interface MainStemAPIControllersPurchasingPaymentMethodsCreateACHAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPaymentMethodsCreateCreditCardAPIRequest {
  cvc?: string
  /** @format int32 */
  expirationMonth?: number
  /** @format int32 */
  expirationYear?: number
  /** @format int32 */
  locationId?: number
  name?: string
  number?: string
}

export interface MainStemAPIControllersPurchasingPaymentMethodsCreateCreditCardAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPaymentMethodsListAPIResponse {
  achs?: MainStemAPIControllersPurchasingPaymentMethodsListAPIResponseAccountDetails[]
  allowedOptions?: MainStemAPIControllersGlobalPaymentMethodsListAllowablePaymentOptions
  creditCards?: MainStemAPIControllersPurchasingPaymentMethodsListAPIResponseCreditCardDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPaymentMethodsListAPIResponseAccountDetails {
  /** @format int32 */
  id?: number
  last4OfACH?: string
  location?: MainStemAPIControllersPurchasingPaymentMethodsListAPIResponseLocationDetails
  nickname?: string
}

export interface MainStemAPIControllersGlobalPaymentMethodsListAllowablePaymentOptions {
  ach?: boolean
  creditCard?: boolean
}

export interface MainStemAPIControllersPurchasingPaymentMethodsListAPIResponseCreditCardDetails {
  /** @format date-time */
  expirationDate?: string
  /** @format int32 */
  id?: number
  issuer?: string
  last4OfCard?: string
  location?: MainStemAPIControllersPurchasingPaymentMethodsListAPIResponseLocationDetails
}

export interface MainStemAPIControllersPurchasingPaymentMethodsListAPIResponseLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingPermissionGroupCreateAPIRequest {
  name?: string
}

export interface MainStemAPIControllersPurchasingPermissionGroupCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPermissionGroupDeleteAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingPermissionGroupDeleteAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPermissionGroupListAPIResponse {
  permissionGroups?: MainStemAPIControllersPurchasingPermissionGroupListAPIResponsePermissionGroupDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPermissionGroupListAPIResponsePermissionGroupDetail {
  /** @format int32 */
  id?: number
  name?: string
  permissionGroupValues?: MainStemAPIControllersPurchasingPermissionGroupListAPIResponsePermissionGroupValueDetail[]
  /** @format int32 */
  permissionsAssigned?: number
  /** @format int32 */
  totalPermissionsCount?: number
  /** @format int32 */
  usersAssigned?: number
  usersAssignedToGroup?: MainStemAPIControllersPurchasingPermissionGroupListAPIResponseAssignedUserDetail[]
}

export interface MainStemAPIControllersPurchasingPermissionGroupListAPIResponsePermissionGroupValueDetail {
  displayName?: string
  group?: string
  /** @format int32 */
  id?: number
  /** @format int32 */
  permissionId?: number
  shortCode?: string
  value?: string
}

export interface MainStemAPIControllersPurchasingPermissionGroupListAPIResponseAssignedUserDetail {
  /** @format date-time */
  dateCreated?: string
  email?: string
  friendlyName?: string
  role?: string
  title?: string
  username?: string
  userRelated?: boolean
  uuid?: string
}

export interface MainStemAPIControllersPurchasingPermissionGroupUpdateAPIRequest {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingPermissionGroupUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPermissionGroupUsersAddAPIRequest {
  /** @format int32 */
  permissionGroupId?: number
  userId?: string
}

export interface MainStemAPIControllersPurchasingPermissionGroupUsersAddAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPermissionGroupUsersNotInGroupAPIRequest {
  /** @format int32 */
  permissionGroupId?: number
}

export interface MainStemAPIControllersPurchasingPermissionGroupUsersNotInGroupAPIResponse {
  usersNotInGroup?: MainStemAPIControllersPurchasingPermissionGroupUsersNotInGroupAvailableForAssignmentUserDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPermissionGroupUsersNotInGroupAvailableForAssignmentUserDetail {
  /** @format date-time */
  dateCreated?: string
  email?: string
  friendlyName?: string
  permissionGroup?: string
  role?: string
  title?: string
  username?: string
  userRelated?: boolean
  uuid?: string
}

export interface MainStemAPIControllersPurchasingPermissionGroupUsersRemoveAPIRequest {
  /** @format int32 */
  permissionGroupId?: number
  userId?: string
}

export interface MainStemAPIControllersPurchasingPermissionGroupUsersRemoveAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPermissionGroupValueCreateAPIRequest {
  /** @format int32 */
  permissionGroupId?: number
  /** @format int32 */
  permissionId?: number
  value?: string
}

export interface MainStemAPIControllersPurchasingPermissionGroupValueCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPermissionGroupValueDeleteAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingPermissionGroupValueDeleteAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPermissionGroupValueListAPIRequest {
  /** @format int32 */
  permissionGroupId?: number
}

export interface MainStemAPIControllersPurchasingPermissionGroupValueListAPIResponse {
  permissionGroupDetail?: MainStemAPIControllersPurchasingPermissionGroupValueListPermissionGroupDetail
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPermissionGroupValueListPermissionGroupDetail {
  /** @format int32 */
  id?: number
  name?: string
  permissionGroupValues?: MainStemAPIControllersPurchasingPermissionGroupValueListPermissionGroupValueDetail[]
  /** @format int32 */
  totalPermissionsCount?: number
  usersAssigned?: MainStemAPIControllersPurchasingPermissionGroupValueListAssignedUserDetail[]
}

export interface MainStemAPIControllersPurchasingPermissionGroupValueListPermissionGroupValueDetail {
  displayName?: string
  group?: string
  /** @format int32 */
  id?: number
  /** @format int32 */
  permissionId?: number
  shortCode?: string
  value?: string
}

export interface MainStemAPIControllersPurchasingPermissionGroupValueListAssignedUserDetail {
  /** @format date-time */
  dateCreated?: string
  email?: string
  friendlyName?: string
  permissionGroup?: string
  role?: string
  title?: string
  username?: string
  userRelated?: boolean
  uuid?: string
}

export interface MainStemAPIControllersPurchasingPermissionGroupValueUpdateAPIRequest {
  /** @format int32 */
  id?: number
  /** @format int32 */
  permissionGroupId?: number
  /** @format int32 */
  permissionId?: number
  value?: string
}

export interface MainStemAPIControllersPurchasingPermissionGroupValueUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPermissionListAPIResponse {
  error?: string
  permissions?: MainStemAPIControllersPurchasingPermissionListPermissionDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPermissionListPermissionDetail {
  displayName?: string
  group?: string
  /** @format int32 */
  id?: number
  /** @format int32 */
  rank?: number
  shortCode?: string
}

export interface MainStemAPIControllersPurchasingPrivateProductAttributeValuesCreateAPIRequest {
  /** @format int32 */
  attributeValueId?: number
  /** @format int32 */
  productId?: number
}

export interface MainStemAPIControllersPurchasingPrivateProductMenuItemsCreateAPIRequest {
  /** @format int32 */
  menuItemId?: number
  /** @format int32 */
  productId?: number
  baseRequest?: object
  /** @format int32 */
  customerID?: number
  affiliateProgramUUID?: string
  existingAffiliateProgram?: MainStemSharpDataModelsAffiliateProgram
  /** @format int32 */
  businessPartnerId?: number
  businessPartners?: MainStemSharpDataModelsBusinessPartner[]
  /** @format int32 */
  locationID?: number
  isAdmin?: boolean
}

export interface MainStemAPIControllersPurchasingPrivateProductAttributeValuesDeleteAPIRequest {
  /** @format int32 */
  attributeValueId?: number
  /** @format int32 */
  productId?: number
}

export interface MainStemAPIControllersPurchasingPrivateProductMenuItemsDeleteAPIRequest {
  /** @format int32 */
  menuItemId?: number
  /** @format int32 */
  productId?: number
}

export interface MainStemAPIControllersPurchasingPrivateProductVolumePricingCreateAPIRequest {
  /** @format double */
  price?: number
  /** @format int32 */
  productId?: number
  /** @format int32 */
  quantityHigh?: number
  /** @format int32 */
  quantityLow?: number
}

export interface MainStemAPIControllersPurchasingPrivateProductVolumePricingDeleteAPIRequest {
  /** @format int32 */
  productVolumePricingId?: number
}

export interface MainStemAPIControllersPurchasingPrivateSuppliersArchiveAPIRequest {
  /** @format int32 */
  supplierId?: number
}

export interface MainStemAPIControllersPurchasingPrivateSuppliersCreateAPIRequest {
  name?: string
  email?: string
  phone?: string
  address1?: string
  address2?: string
  city?: string
  state?: string
  zip?: string
  country?: string
  /** @format double */
  minimumOrderAmount?: number
}

export interface MainStemAPIControllersPurchasingPrivateSuppliersCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPrivateSuppliersDeleteAPIRequest {
  /** @format int32 */
  supplierId?: number
}

export interface MainStemAPIControllersPurchasingPrivateSuppliersImpersonationAPIRequest {
  /** @format int32 */
  defaultAffiliateProgramID?: number
  /** @format int32 */
  supplierID?: number
}

export interface MainStemAPIControllersPurchasingPrivateSuppliersImpersonationAPIResponse {
  ecomBaseUrl?: string
  impersonationUrl?: string
  impersonationUrls?: MainStemAPIControllersPurchasingPrivateSuppliersImpersonationImpersonationUrl[]
  purchasingBaseUrl?: string
  supplierBaseUrl?: string
  token?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPrivateSuppliersImpersonationImpersonationUrl {
  name?: string
  url?: string
}

export interface MainStemAPIControllersPurchasingPrivateSuppliersDuplicateAPIRequest {
  name?: string
  /** @format int32 */
  supplierId?: number
}

export interface MainStemAPIControllersPurchasingPrivateSuppliersDuplicateAPIResponse {
  /** @format int32 */
  supplierId?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPrivateSuppliersUpdateAPIRequest {
  /** @format int32 */
  id?: number
  name?: string
  email?: string
  phone?: string
  address1?: string
  address2?: string
  city?: string
  state?: string
  zip?: string
  country?: string
  /** @format double */
  minimumOrderAmount?: number
}

export interface MainStemAPIControllersPurchasingProductsAllocationsListAPIRequest {
  /** @format int32 */
  productId?: number
}

export interface MainStemAPIControllersPurchasingProductsAllocationsListAPIResponse {
  allocations?: MainStemAPIControllersPurchasingProductsAllocationsListAPIResponseAllocationDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingProductsAllocationsListAPIResponseAllocationDetails {
  /** @format date-time */
  dateCreated?: string
  department?: MainStemAPIControllersPurchasingProductsAllocationsListAPIResponseAllocationDetailsDepartmentDetails
  /** @format int32 */
  id?: number
  order?: MainStemAPIControllersPurchasingProductsAllocationsListAPIResponseAllocationDetailsOrderDetails
  /** @format int32 */
  quantity?: number
  user?: MainStemAPIControllersPurchasingProductsAllocationsListAPIResponseAllocationDetailsUserDetails
}

export interface MainStemAPIControllersPurchasingProductsAllocationsListAPIResponseAllocationDetailsDepartmentDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingProductsAllocationsListAPIResponseAllocationDetailsOrderDetails {
  /** @format int32 */
  id?: number
  uuid?: string
}

export interface MainStemAPIControllersPurchasingProductsAllocationsListAPIResponseAllocationDetailsUserDetails {
  id?: string
  username?: string
}

export interface MainStemAPIControllersPurchasingProductsArchiveAPIRequest {
  /** @format int32 */
  productId?: number
}

export interface MainStemAPIControllersPurchasingProductsArchiveAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingProductsAttributesListAPIRequest {
  /** @format int32 */
  productId?: number
}

export interface MainStemAPIControllersPurchasingProductsAttributesListAPIResponse {
  attributes?: MainStemAPIControllersPurchasingProductsAttributesListAPIResponseAttributeDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingProductsAttributesListAPIResponseAttributeDetails {
  name?: string
  value?: string
}

export interface MainStemAPIControllersPurchasingProductsCatalogsListAPIRequest {
  /** @format int32 */
  productId?: number
}

export interface MainStemAPIControllersPurchasingProductsCatalogsListAPIResponse {
  catalogs?: MainStemAPIControllersPurchasingProductsCatalogsListAPIResponseCatalogDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingProductsCatalogsListAPIResponseCatalogDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingProductsCreateAPIRequest {
  /** @format int32 */
  customerSessionID?: number
  description?: string
  /** @format int32 */
  financialDimensionOptionID?: number
  financialDimensions?: MainStemAPIControllersPurchasingProductsCreateAPIRequestFinancialDimensionUpdate[]
  /** @format double */
  height?: number
  /** @format int32 */
  imgFileID?: number
  /** @format double */
  length?: number
  /** @format int32 */
  maximumPurchasableQuantity?: number
  menuItems?: MainStemAPIControllersPurchasingProductsCreateFlatMenuItems[]
  /** @format int32 */
  minimumPurchasableQuantity?: number
  name?: string
  /** @format int32 */
  parentProductId?: number
  /** @format double */
  priceEnterprise?: number
  /** @format double */
  priceRetail?: number
  /** @format double */
  priceWholesale?: number
  /** @format int32 */
  rfqBidLineItemID?: number
  sku?: string
  /** @format int32 */
  supplierId?: number
  type?: string
  unitOfMeasureLabel?: string
  unitOfMeasureValue?: string
  /** @format double */
  weight?: number
  /** @format double */
  width?: number
}

export interface MainStemAPIControllersPurchasingProductsCreateAPIRequestFinancialDimensionUpdate {
  /** @format int32 */
  financialDimensionID?: number
  /** @format int32 */
  financialDimensionOptionID?: number
}

export interface MainStemAPIControllersPurchasingProductsCreateFlatMenuItems {
  /** @format int32 */
  childMenuId?: number
  childMenuName?: string
  /** @format int32 */
  grandParentId?: number
  grandParentMenuName?: string
  /** @format int32 */
  parentMenuId?: number
  parentMenuName?: string
}

export interface MainStemAPIControllersPurchasingProductsCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingProductsDetailsAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingProductsDetailsAPIResponse {
  childProducts?: MainStemAPIControllersPurchasingProductsDetailsAPIResponseProductDetail[]
  alternativeOptions?: MainStemAPIControllersPurchasingProductsDetailsAPIResponseAlternativeOptionDetails
  /** @format int32 */
  daysToFulfill?: number
  description?: string
  financialDimensions?: MainStemSharpHelpersFinancialDimensionsFactoryFinancialDimensionOptionSelectionDetail[]
  hasVolumePricing?: boolean
  /** @format double */
  height?: number
  /** @format int32 */
  id?: number
  images?: string[]
  integrations?: MainStemAPIControllersPurchasingProductsDetailsAPIResponseIntegrationDetails
  isOutOfStock?: boolean
  isPrivate?: boolean
  itemMaster?: boolean
  /** @format double */
  length?: number
  mainImage?: string
  /** @format int32 */
  maximumOrderAmount?: number
  menuItems?: MainStemSharpHelpersFlatMenuItem[]
  /** @format int32 */
  minimumOrderAmount?: number
  /** @format double */
  msrp?: number
  name?: string
  /** @format int32 */
  parentProductId?: number
  /** @format double */
  priceAgreement?: number
  /** @format double */
  priceCustomerRetail?: number
  /** @format double */
  priceEnterprise?: number
  /** @format double */
  priceRetail?: number
  /** @format double */
  priceToUse?: number
  /** @format double */
  priceWholesale?: number
  skuInternal?: string
  /** @format int32 */
  soldInQuantitiesOf?: number
  supplier?: MainStemAPIControllersPurchasingProductsDetailsAPIResponseSupplierDetails
  tags?: string[]
  /** @format double */
  totalSpend?: number
  type?: string
  unitOfMeasureLabel?: string
  unitOfMeasureValue?: string
  volumePricing?: MainStemAPIControllersPurchasingProductsDetailsAPIResponseVolumePricingDetails[]
  volumePricingPriceAgreement?: MainStemAPIControllersPurchasingProductsDetailsAPIResponseVolumePricingDetails[]
  /** @format double */
  weight?: number
  /** @format double */
  width?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingProductsDetailsAPIResponseProductDetail {
  description?: string
  /** @format int32 */
  id?: number
  images?: string[]
  isOutOfStock?: boolean
  isPrivate?: boolean
  /** @format int32 */
  maximumOrderAmount?: number
  /** @format int32 */
  minimumOrderAmount?: number
  /** @format double */
  msrp?: number
  name?: string
  /** @format double */
  priceCustomerRetail?: number
  /** @format double */
  priceEnterprise?: number
  /** @format double */
  priceRetail?: number
  /** @format double */
  priceWholesale?: number
  skuInternal?: string
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  tags?: string[]
  type?: string
}

export interface MainStemAPIControllersPurchasingProductsDetailsAPIResponseAlternativeOptionDetails {
  /** @format double */
  priceHighest?: number
  /** @format double */
  priceLowest?: number
  /** @format int32 */
  totalOptions?: number
}

export interface MainStemAPIControllersPurchasingProductsDetailsAPIResponseIntegrationDetails {
  quantumLeaf?: MainStemAPIControllersPurchasingProductsDetailsAPIResponseIntegrationDetailsQuantumLeafDetails
}

export interface MainStemSharpHelpersFlatMenuItem {
  /** @format int32 */
  affiliatesMenuId?: number
  /** @format int32 */
  grandParentMenuId?: number
  grandParentMenuName?: string
  grandParentMenuSEOSlug?: string
  /** @format int32 */
  menuId?: number
  menuImage?: string
  menuIndexName?: string
  menuName?: string
  menuSEOSlug?: string
  /** @format int32 */
  parentMenuId?: number
  parentMenuName?: string
  parentMenuSEOSlug?: string
  /** @format int32 */
  productId?: number
}

export interface MainStemAPIControllersPurchasingProductsDetailsAPIResponseSupplierDetails {
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  isPrivate?: boolean
  logo?: string
  name?: string
  state?: string
  usesDirectPay?: boolean
  usesPreferredShipping?: boolean
}

export interface MainStemAPIControllersPurchasingProductsDetailsAPIResponseVolumePricingDetails {
  /** @format int32 */
  id?: number
  /** @format double */
  price?: number
  /** @format int32 */
  quantityHigh?: number
  /** @format int32 */
  quantityLow?: number
}

export interface MainStemAPIControllersPurchasingProductsDetailsAPIResponseIntegrationDetailsQuantumLeafDetails {
  locationProductItemMaps?: MainStemAPIControllersPurchasingProductsDetailsAPIResponseIntegrationDetailsQuantumLeafDetailsLocationProductItemMap[]
}

export interface MainStemAPIControllersPurchasingProductsDetailsAPIResponseIntegrationDetailsQuantumLeafDetailsLocationProductItemMap {
  itemId?: string
  location?: MainStemAPIControllersPurchasingProductsDetailsAPIResponseIntegrationDetailsQuantumLeafDetailsLocationProductItemMapLocationDetails
  /** @format int32 */
  settingsId?: number
}

export interface MainStemAPIControllersPurchasingProductsDetailsAPIResponseIntegrationDetailsQuantumLeafDetailsLocationProductItemMapLocationDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingProductsDuplicateAPIRequest {
  name?: string
  /** @format int32 */
  productId?: number
}

export interface MainStemAPIControllersPurchasingProductsDuplicateAPIResponse {
  /** @format int32 */
  productId?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingProductsListAPIResponse {
  products?: MainStemAPIControllersPurchasingProductsListAPIResponseProductDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingProductsListAPIResponseProductDetails {
  /** @format date-time */
  firstOrderedDate?: string
  /** @format int32 */
  id?: number
  imgURL?: string
  isDeleted?: boolean
  isPrivate?: boolean
  /** @format date-time */
  lastOrderedDate?: string
  name?: string
  skuInternal?: string
  skuManufacturer?: string
  /** @format int32 */
  totalOrders?: number
  /** @format int32 */
  totalQuantityOrdered?: number
  /** @format int32 */
  vendorID?: number
  vendorName?: string
}

export interface MainStemAPIControllersPurchasingProductsOrdersListAPIRequest {
  /** @format int32 */
  productId?: number
}

export interface MainStemAPIControllersPurchasingProductsOrdersListAPIResponse {
  orders?: MainStemAPIControllersPurchasingProductsOrdersListAPIResponseOrderDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingProductsOrdersListAPIResponseOrderDetails {
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format double */
  discount?: number
  /** @format int32 */
  id?: number
  location?: MainStemAPIControllersPurchasingProductsOrdersListAPIResponseOrderDetailsLocationDetails
  /** @format double */
  orderTotal?: number
  paymentStatus?: string
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  status?: 'New' | 'PendingApproval' | 'Returned' | 'Approved' | 'Rejected' | 'Cancelled'
  /** @format double */
  subTotal?: number
  userApprovedBy?: string
  userCreatedBy?: string
  uuid?: string
}

export interface MainStemAPIControllersPurchasingProductsOrdersListAPIResponseOrderDetailsLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingProductsQAListAPIRequest {
  /** @format int32 */
  productId?: number
}

export interface MainStemAPIControllersPurchasingProductsQAListAPIResponse {
  qa?: MainStemAPIControllersPurchasingProductsQAListAPIResponseQADetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingProductsQAListAPIResponseQADetails {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingProductsReceivingListAPIRequest {
  /** @format int32 */
  productId?: number
}

export interface MainStemAPIControllersPurchasingProductsReceivingListAPIResponse {
  receiving?: MainStemAPIControllersPurchasingProductsReceivingListAPIResponseReceivingDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingProductsReceivingListAPIResponseReceivingDetails {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingProductsShipmentsListAPIRequest {
  /** @format int32 */
  productId?: number
}

export interface MainStemAPIControllersPurchasingProductsShipmentsListAPIResponse {
  shipments?: MainStemAPIControllersPurchasingProductsShipmentsListAPIResponseShipmentDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingProductsShipmentsListAPIResponseShipmentDetails {
  carrier?: string
  carrierType?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateReceived?: string
  /** @format date-time */
  dateShippedOn?: string
  /** @format date-time */
  estimatedDeliveryDate?: string
  /** @format int32 */
  orderID?: number
  orderUUID?: string
  trackingNumber?: string
  uuid?: string
}

export interface MainStemAPIControllersPurchasingProductsUpdateAPIRequest {
  description?: string
  financialDimensions?: MainStemAPIControllersPurchasingProductsUpdateAPIRequestFinancialDimensionUpdate[]
  /** @format double */
  height?: number
  /** @format int32 */
  id?: number
  /** @format double */
  length?: number
  /** @format int32 */
  maximumPurchasableQuantity?: number
  menuItems?: MainStemAPIControllersPurchasingProductsUpdateFlatMenuItems[]
  /** @format int32 */
  minimumPurchasableQuantity?: number
  /** @format double */
  msrp?: number
  name?: string
  /** @format int32 */
  parentProductId?: number
  /** @format double */
  priceCustomerRetail?: number
  /** @format double */
  priceEnterprise?: number
  /** @format double */
  priceRetail?: number
  /** @format double */
  priceWholesale?: number
  sku?: string
  /** @format int32 */
  supplierId?: number
  type?: string
  unitOfMeasureLabel?: string
  unitOfMeasureValue?: string
  /** @format double */
  weight?: number
  /** @format double */
  width?: number
}

export interface MainStemAPIControllersPurchasingProductsUpdateAPIRequestFinancialDimensionUpdate {
  /** @format int32 */
  financialDimensionID?: number
  /** @format int32 */
  financialDimensionOptionID?: number
}

export interface MainStemAPIControllersPurchasingProductsUpdateFlatMenuItems {
  /** @format int32 */
  childMenuId?: number
  childMenuName?: string
  /** @format int32 */
  grandParentId?: number
  grandParentMenuName?: string
  /** @format int32 */
  parentMenuId?: number
  parentMenuName?: string
}

export interface MainStemAPIControllersPurchasingProductsUpdateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPunchoutsOrderGenerateAPIRequest {
  buyersCookie?: string
}

export interface MainStemAPIControllersPurchasingPunchoutsOrderGenerateAPIResponse {
  orderUUID?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPunchoutsRequestSessionAPIRequest {
  /** @format int32 */
  integrationSettingID?: number
  /** @format int32 */
  locationID?: number
}

export interface MainStemAPIControllersPurchasingPunchoutsRequestSessionAPIResponse {
  url?: string
  cXmlDocument?: string
  redirectUrl?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPunchoutsSettingsAddLocationsAPIRequest {
  locationIds?: number[]
  /** @format int32 */
  customerSettingsID?: number
}

export interface MainStemAPIControllersPurchasingPunchoutsSettingsCreatePunchoutAPIRequest {
  punchoutSupplierName?: string
  punchoutSupplierEmail?: string
  /** @format int32 */
  privateSupplierID?: number
  description?: string
  locationIds?: number[]
  senderIdentity?: string
  senderIdentityType?: string
  supplierIdentity?: string
  supplierIdentityType?: string
  sharedSecret?: string
  punchoutUrl?: string
  requiresNewWindow?: boolean
  cXmlOrderProcessingAllowed?: boolean
  cXmlUseSeparateDeliveryUrl?: boolean
  cXmlSeparateDeliveryUrl?: string
  defaultOrderProcessingEmail?: string
  orderEmailNote?: string
}

export interface MainStemAPIControllersPurchasingPunchoutsSettingsCreatePunchoutAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPunchoutsSettingsDetailsAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingPunchoutsSettingsDetailsAPIResponse {
  assignedLocations?: MainStemAPIControllersPurchasingPunchoutsSettingsDetailsLocationDetails[]
  cXmlOrderProcessingAllowed?: boolean
  cXmlSeparateDeliveryUrl?: string
  cXmlUseSeparateDeliveryUrl?: boolean
  defaultOrderProcessingEmail?: string
  description?: string
  fileUrl?: string
  /** @format int32 */
  id?: number
  isActive?: boolean
  orderEmailNote?: string
  orders?: MainStemAPIControllersPurchasingPunchoutsSettingsDetailsAPIResponseOrderDetails[]
  /** @format int32 */
  privateSupplierID?: number
  punchoutSupplierName?: string
  punchoutUrl?: string
  requiresNewWindow?: boolean
  senderIdentity?: string
  senderIdentityType?: string
  sharedSecret?: string
  supplierIdentity?: string
  supplierIdentityType?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPunchoutsSettingsDetailsLocationDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingPunchoutsSettingsDetailsAPIResponseOrderDetails {
  approvalStatus?: 'SavedCart' | 'AwaitingApproval' | 'Approval' | 'ApprovalWithEdits' | 'Rejected'
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format double */
  discount?: number
  /** @format int32 */
  id?: number
  location?: MainStemAPIControllersPurchasingPunchoutsSettingsDetailsAPIResponseOrderDetailsLocationDetails
  /** @format double */
  orderTotal?: number
  paymentStatus?: string
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  status?: string
  /** @format double */
  subTotal?: number
  userApprovedBy?: string
  userCreatedBy?: string
  userRelated?: boolean
  uuid?: string
}

export interface MainStemAPIControllersPurchasingPunchoutsSettingsDetailsAPIResponseOrderDetailsLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingPunchoutsSettingsListAPIResponse {
  list?: MainStemAPIControllersPurchasingPunchoutsSettingsListAPIResponsePunchoutDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPunchoutsSettingsListAPIResponsePunchoutDetail {
  description?: string
  /** @format int32 */
  id?: number
  isActive?: boolean
  punchoutSupplierName?: string
  /** @format int32 */
  privateSupplierID?: number
  fileUrl?: string
  senderIdentity?: string
  senderIdentityType?: string
  supplierIdentity?: string
  supplierIdentityType?: string
  sharedSecret?: string
  punchoutUrl?: string
  cXmlOrderProcessingAllowed?: boolean
  cXmlUseSeparateDeliveryUrl?: boolean
  cXmlSeparateDeliveryUrl?: string
  defaultOrderProcessingEmail?: string
  assignedLocations?: MainStemAPIControllersPurchasingPunchoutsSettingsListLocationDetails[]
  requiresNewWindow?: boolean
}

export interface MainStemAPIControllersPurchasingPunchoutsSettingsListLocationDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingPunchoutsSettingsRemoveLocationsAPIRequest {
  locationIds?: number[]
  /** @format int32 */
  customerSettingsID?: number
}

export interface MainStemAPIControllersPurchasingPunchoutsSettingsUpdateAPIRequest {
  description?: string
  /** @format int32 */
  id?: number
  locationIds?: number[]
  punchoutSupplierName?: string
  /** @format int32 */
  privateSupplierID?: number
  senderIdentity?: string
  senderIdentityType?: string
  supplierIdentity?: string
  supplierIdentityType?: string
  sharedSecret?: string
  punchoutUrl?: string
  cXmlOrderProcessingAllowed?: boolean
  cXmlUseSeparateDeliveryUrl?: boolean
  cXmlSeparateDeliveryUrl?: string
  defaultOrderProcessingEmail?: string
  orderEmailNote?: string
  requiresNewWindow?: boolean
}

export interface MainStemAPIControllersPurchasingPurchaseOrdersDiscountsCreateAPIRequest {
  /** @format double */
  amount?: number
  notes?: string
  /** @format int32 */
  purchaseOrderId?: number
  reason?: string
}

export interface MainStemAPIControllersPurchasingPurchaseOrdersDiscountsCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPurchaseOrdersFeesCreateAPIRequest {
  /** @format double */
  amount?: number
  notes?: string
  /** @format int32 */
  purchaseOrderId?: number
  reason?: string
}

export interface MainStemAPIControllersPurchasingPurchaseOrdersFeesCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPurchaseOrdersAllocationsDeleteAPIRequest {
  allocations?: MainStemAPIControllersPurchasingPurchaseOrdersAllocationsDeleteAPIRequestAllocationDetails[]
}

export interface MainStemAPIControllersPurchasingPurchaseOrdersAllocationsDeleteAPIRequestAllocationDetails {
  /** @format int32 */
  lineItemId?: number
  type?: 'Shipping' | 'Fees' | 'Discounts' | 'SalesTax'
}

export interface MainStemAPIControllersPurchasingPurchaseOrdersAllocationsDeleteAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPurchaseOrdersAllocationsEnableAPIRequest {
  allocations?: MainStemAPIControllersPurchasingPurchaseOrdersAllocationsEnableAPIRequestAllocationDetails[]
}

export interface MainStemAPIControllersPurchasingPurchaseOrdersAllocationsEnableAPIRequestAllocationDetails {
  /** @format int32 */
  lineItemId?: number
  type?: 'Shipping' | 'Fees' | 'Discounts' | 'SalesTax'
}

export interface MainStemAPIControllersPurchasingPurchaseOrdersAllocationsEnableAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPurchaseOrdersAllocationsUpdateAPIRequest {
  /** @format double */
  amount?: number
  /** @format int32 */
  lineItemId?: number
  type?: 'Shipping' | 'Fees' | 'Discounts' | 'SalesTax'
}

export interface MainStemAPIControllersPurchasingPurchaseOrdersAllocationsUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPurchaseOrdersListAPIResponse {
  purchaseOrders?: MainStemAPIControllersPurchasingPurchaseOrdersListAPIResponsePurchaseOrderDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPurchaseOrdersListAPIResponsePurchaseOrderDetails {
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  location?: MainStemAPIControllersPurchasingPurchaseOrdersListAPIResponsePurchaseOrderDetailsLocationDetails
  order?: MainStemAPIControllersPurchasingPurchaseOrdersListAPIResponsePurchaseOrderDetailsOrderDetails
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  supplier?: MainStemAPIControllersPurchasingPurchaseOrdersListAPIResponsePurchaseOrderDetailsSupplierDetails
}

export interface MainStemAPIControllersPurchasingPurchaseOrdersListAPIResponsePurchaseOrderDetailsLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingPurchaseOrdersListAPIResponsePurchaseOrderDetailsOrderDetails {
  /** @format int32 */
  id?: number
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  uuid?: string
}

export interface MainStemAPIControllersPurchasingPurchaseOrdersListAPIResponsePurchaseOrderDetailsSupplierDetails {
  /** @format int32 */
  id?: number
  inNetwork?: boolean
  name?: string
}

export interface MainStemAPIControllersPurchasingPurchaseOrdersSalesTaxCalculateAPIRequest {
  /** @format int32 */
  purchaseOrderId?: number
}

export interface MainStemAPIControllersPurchasingPurchaseOrdersSalesTaxCalculateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPurchaseOrdersSalesTaxUpdateAPIRequest {
  /** @format double */
  amount?: number
  /** @format int32 */
  purchaseOrderId?: number
}

export interface MainStemAPIControllersPurchasingPurchaseOrdersSalesTaxUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPurchaseOrdersShippingCalculateAPIRequest {
  /** @format int32 */
  purchaseOrderId?: number
}

export interface MainStemAPIControllersPurchasingPurchaseOrdersShippingCalculateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingPurchaseOrdersShippingUpdateAPIRequest {
  /** @format double */
  amount?: number
  carrier?: string
  method?: string
  /** @format int32 */
  purchaseOrderId?: number
}

export interface MainStemAPIControllersPurchasingPurchaseOrdersShippingUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationQuickBooksItemsCreateManyAPIRequest {
  /** @format int32 */
  integrationSettingID?: number
  productIDs?: number[]
}

export interface MainStemAPIControllersPurchasingIntegrationQuickBooksItemsCreateManyAPIResponse {
  errorResponses?: string[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationQuickBooksCustomerProductsAPIRequest {
  /** @format int32 */
  integrationSettingID?: number
  baseRequest?: object
  /** @format int32 */
  customerID?: number
  affiliateProgramUUID?: string
  existingAffiliateProgram?: MainStemSharpDataModelsAffiliateProgram
  /** @format int32 */
  businessPartnerId?: number
  businessPartners?: MainStemSharpDataModelsBusinessPartner[]
  /** @format int32 */
  locationID?: number
  isAdmin?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationQuickBooksCustomerProductsAPIResponse {
  products?: MainStemAPIControllersPurchasingIntegrationQuickBooksCustomerProductsAPIResponseProductDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingIntegrationQuickBooksCustomerProductsAPIResponseProductDetail {
  /** @format int32 */
  id?: number
  name?: string
  skuInternal?: string
  itemID?: string
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  isPrivate?: boolean
  image?: string
  inCreateQueue?: boolean
  /** @format date-time */
  queuedAt?: string
}

export interface MainStemAPIControllersPurchasingIntegrationQuickBooksItemsUpdateItemLinksAPIRequest {
  /** @format int32 */
  integrationSettingID?: number
  linkUpdates?: MainStemAPIControllersPurchasingIntegrationQuickBooksItemsUpdateItemLinksUpdateLinkDetail[]
}

export interface MainStemAPIControllersPurchasingIntegrationQuickBooksItemsUpdateItemLinksUpdateLinkDetail {
  /** @format int32 */
  productID?: number
  itemID?: string
}

export interface MainStemAPIControllersPurchasingIntegrationQuickBooksItemsUpdateItemLinksAPIResponse {
  itemId?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingReceivingDashboardAPIResponse {
  needToReceiveInvoices?: MainStemAPIControllersPurchasingReceivingDashboardAPIResponseNeedToReceiveInvoiceDetails[]
  needToReceiveItems?: MainStemAPIControllersPurchasingReceivingDashboardAPIResponseNeedToReceiveItemDetails[]
  alreadyReceivedInvoices?: MainStemAPIControllersPurchasingReceivingDashboardAPIResponseAlreadyReceivedInvoiceDetails[]
  alreadyReceivedItems?: MainStemAPIControllersPurchasingReceivingDashboardAPIResponseAlreadyReceivedItemDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingReceivingDashboardAPIResponseNeedToReceiveInvoiceDetails {
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  datePromisedBy?: string
  /** @format date-time */
  dateShipped?: string
  /** @format int32 */
  fulfillmentID?: number
  invoiceID?: string
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  uuid?: string
  /** @format int32 */
  locationID?: number
  locationName?: string
  /** @format int32 */
  orderID?: number
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  orderUUID?: string
  /** @format int32 */
  quantity?: number
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  /** @format int32 */
  totalReceived?: number
}

export interface MainStemAPIControllersPurchasingReceivingDashboardAPIResponseNeedToReceiveItemDetails {
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  datePromisedBy?: string
  /** @format date-time */
  dateShipped?: string
  /** @format int32 */
  fulfillmentID?: number
  invoiceID?: string
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  uuid?: string
  /** @format int32 */
  invoiceLineItemID?: number
  /** @format int32 */
  locationID?: number
  locationName?: string
  /** @format int32 */
  orderID?: number
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  orderUUID?: string
  /** @format int32 */
  productID?: number
  productName?: string
  /** @format int32 */
  quantity?: number
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  /** @format int32 */
  totalReceived?: number
}

export interface MainStemAPIControllersPurchasingReceivingDashboardAPIResponseAlreadyReceivedInvoiceDetails {
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  datePromisedBy?: string
  /** @format date-time */
  dateShipped?: string
  /** @format int32 */
  fulfillmentID?: number
  invoiceID?: string
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  uuid?: string
  /** @format int32 */
  locationID?: number
  locationName?: string
  /** @format int32 */
  orderID?: number
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  orderUUID?: string
  /** @format int32 */
  quantity?: number
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  /** @format int32 */
  totalReceived?: number
}

export interface MainStemAPIControllersPurchasingReceivingDashboardAPIResponseAlreadyReceivedItemDetails {
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  datePromisedBy?: string
  /** @format date-time */
  dateShipped?: string
  /** @format int32 */
  fulfillmentID?: number
  invoiceID?: string
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  uuid?: string
  /** @format int32 */
  invoiceLineItemID?: number
  /** @format int32 */
  locationID?: number
  locationName?: string
  /** @format int32 */
  orderID?: number
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  orderUUID?: string
  /** @format int32 */
  productID?: number
  productName?: string
  /** @format int32 */
  quantity?: number
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  /** @format int32 */
  totalReceived?: number
}

export interface MainStemAPIControllersPurchasingReportsLineItemAPIRequest {
  /** @format date-time */
  dateEnd?: string
  /** @format date-time */
  dateStart?: string
}

export interface MainStemAPIControllersPurchasingReportsLineItemAPIResponse {
  customFields?: MainStemAPIControllersPurchasingReportsLineItemAPIResponseCustomFieldDetails[]
  lineItems?: MainStemAPIControllersPurchasingReportsLineItemAPIResponseLineItemDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingReportsLineItemAPIResponseCustomFieldDetails {
  /** @format int32 */
  id?: number
  key?: string
  type?: string
  value?: string
}

export interface MainStemAPIControllersPurchasingReportsLineItemAPIResponseLineItemDetails {
  customFields?: MainStemAPIControllersPurchasingReportsLineItemAPIResponseCustomFieldDetails[]
  /** @format date-time */
  dateInvoiceShipped?: string
  /** @format date-time */
  dateOrderApproved?: string
  /** @format date-time */
  dateOrderCreated?: string
  /** @format date-time */
  dateOrderSubmitted?: string
  /** @format int32 */
  invoiceId?: number
  location?: MainStemAPIControllersPurchasingReportsLineItemAPIResponseLineItemDetailsLocationDetails
  /** @format int32 */
  orderId?: number
  orderInvoiceId?: string
  orderType?: 'InNetwork' | 'Private' | 'PunchOut'
  /** @format double */
  perItemCost?: number
  product?: MainStemAPIControllersPurchasingReportsLineItemAPIResponseLineItemDetailsProductDetails
  /** @format int32 */
  quantity?: number
  status?: 'New' | 'PendingApproval' | 'Returned' | 'Approved' | 'Rejected' | 'Cancelled'
  /** @format double */
  subTotal?: number
  supplier?: MainStemAPIControllersPurchasingReportsLineItemAPIResponseLineItemDetailsSupplierDetails
  userApprovedBy?: string
  userCreatedBy?: string
  userSubmittedBy?: string
}

export interface MainStemAPIControllersPurchasingReportsLineItemAPIResponseLineItemDetailsLocationDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingReportsLineItemAPIResponseLineItemDetailsProductDetails {
  /** @format int32 */
  id?: number
  name?: string
  type?: string
}

export interface MainStemAPIControllersPurchasingReportsLineItemAPIResponseLineItemDetailsSupplierDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingReportsOpenOrdersAPIResponse {
  orders?: MainStemAPIControllersPurchasingReportsOpenOrdersAPIResponseOrderDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingReportsOpenOrdersAPIResponseOrderDetails {
  approvalStatus?: 'SavedCart' | 'AwaitingApproval' | 'Approval' | 'ApprovalWithEdits' | 'Rejected'
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format double */
  discount?: number
  /** @format int32 */
  id?: number
  isPrivateOrder?: boolean
  location?: MainStemAPIControllersPurchasingReportsOpenOrdersAPIResponseOrderDetailsLocationDetails
  make?: string
  /** @format double */
  orderTotal?: number
  paymentStatus?: string
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  status?: 'New' | 'PendingApproval' | 'Returned' | 'Approved' | 'Rejected' | 'Cancelled'
  /** @format double */
  subTotal?: number
  userApprovedBy?: string
  userCreatedBy?: string
  userRelated?: boolean
  uuid?: string
}

export interface MainStemAPIControllersPurchasingReportsOpenOrdersAPIResponseOrderDetailsLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingReportsOrdersAPIRequest {
  /** @format date-time */
  dateEnd?: string
  /** @format date-time */
  dateStart?: string
}

export interface MainStemAPIControllersPurchasingReportsOrdersAPIResponse {
  orders?: MainStemAPIControllersPurchasingReportsOrdersAPIResponseOrderDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingReportsOrdersAPIResponseOrderDetails {
  approvalStatus?: 'SavedCart' | 'AwaitingApproval' | 'Approval' | 'ApprovalWithEdits' | 'Rejected'
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format double */
  discount?: number
  /** @format int32 */
  id?: number
  isPrivateOrder?: boolean
  location?: MainStemAPIControllersPurchasingReportsOrdersAPIResponseOrderDetailsLocationDetails
  make?: string
  /** @format double */
  orderTotal?: number
  paymentStatus?: string
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  status?: 'New' | 'PendingApproval' | 'Returned' | 'Approved' | 'Rejected' | 'Cancelled'
  /** @format double */
  subTotal?: number
  userApprovedBy?: string
  userCreatedBy?: string
  userRelated?: boolean
  uuid?: string
}

export interface MainStemAPIControllersPurchasingReportsOrdersAPIResponseOrderDetailsLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingReportsPunchOutsAPIResponse {
  orders?: MainStemAPIControllersPurchasingReportsPunchOutsAPIResponseOrderDetails[]
}

export interface MainStemAPIControllersPurchasingReportsPunchOutsAPIResponseOrderDetails {
  approvalStatus?: 'SavedCart' | 'AwaitingApproval' | 'Approval' | 'ApprovalWithEdits' | 'Rejected'
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format double */
  discount?: number
  /** @format int32 */
  id?: number
  isPrivateOrder?: boolean
  location?: MainStemAPIControllersPurchasingReportsPunchOutsAPIResponseOrderDetailsLocationDetails
  make?: string
  /** @format double */
  orderTotal?: number
  paymentStatus?: string
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  status?: 'New' | 'PendingApproval' | 'Returned' | 'Approved' | 'Rejected' | 'Cancelled'
  /** @format double */
  subTotal?: number
  userApprovedBy?: string
  userCreatedBy?: string
  userRelated?: boolean
  uuid?: string
}

export interface MainStemAPIControllersPurchasingReportsPunchOutsAPIResponseOrderDetailsLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingRequisitionApproveDetailsAPIRequest {
  orderUUID?: string
}

export interface MainStemAPIControllersPurchasingRequisitionApproveDetailsAPIResponse {
  requisition?: MainStemAPIControllersPurchasingRequisitionApproveDetailsAPIResponseRequisitionDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingRequisitionApproveDetailsAPIResponseRequisitionDetails {
  billTo?: MainStemAPIControllersPurchasingRequisitionApproveDetailsAPIResponseRequisitionDetailsAddressDetails
  email?: string
  /** @format int32 */
  id?: number
  location?: MainStemAPIControllersPurchasingRequisitionApproveDetailsAPIResponseRequisitionDetailsLocationDetails
  phone?: string
  purchaseOrders?: MainStemAPIHelpersPurchaseOrdersResponsePurchaseOrderDetails[]
  shipTo?: MainStemAPIControllersPurchasingRequisitionApproveDetailsAPIResponseRequisitionDetailsAddressDetails
  totals?: MainStemAPIControllersPurchasingRequisitionApproveDetailsAPIResponseRequisitionDetailsTotalDetails
}

export interface MainStemAPIControllersPurchasingRequisitionApproveDetailsAPIResponseRequisitionDetailsAddressDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingRequisitionApproveDetailsAPIResponseRequisitionDetailsLocationDetails {
  address?: MainStemAPIControllersPurchasingRequisitionApproveDetailsAPIResponseRequisitionDetailsLocationDetailsAddressDetails
  header?: string
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingRequisitionApproveDetailsAPIResponseRequisitionDetailsTotalDetails {
  /** @format double */
  discounts?: number
  /** @format double */
  fees?: number
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  /** @format double */
  subtotal?: number
  /** @format double */
  total?: number
}

export interface MainStemAPIControllersPurchasingRequisitionApproveDetailsAPIResponseRequisitionDetailsLocationDetailsAddressDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingRequisitionApproveSubmitAPIRequest {
  /** @format double */
  expectedTotal?: number
  notes?: string
  paymentMethod?: MainStemAPIControllersPurchasingRequisitionApproveSubmitAPIRequestPaymentMethodDetails
  requisition?: MainStemAPIControllersPurchasingRequisitionApproveSubmitAPIRequestRequisitionDetails
}

export interface MainStemAPIControllersPurchasingRequisitionApproveSubmitAPIRequestPaymentMethodDetails {
  /** @format int32 */
  paymentMethodId?: number
  type?: 'CreditCard' | 'ACH' | 'Terms'
}

export interface MainStemAPIControllersPurchasingRequisitionApproveSubmitAPIRequestRequisitionDetails {
  uuid?: string
}

export interface MainStemAPIControllersPurchasingRequisitionApproveSubmitAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingRequisitionListAPIRequest {
  filter?: MainStemAPIControllersPurchasingRequisitionListAPIRequestFilterDetail
  /** @format int32 */
  page?: number
  /** @format int32 */
  pageSize?: number
  sort?: MainStemAPIControllersPurchasingRequisitionListAPIRequestSortDetails
}

export interface MainStemAPIControllersPurchasingRequisitionListAPIRequestFilterDetail {
  id?: string
  location?: string
  onlyShowMyLocations?: boolean
  onlyShowNonCancelled?: boolean
  status?: 'New' | 'PendingApproval' | 'Returned' | 'Approved' | 'Rejected' | 'Cancelled'
  userCreatedBy?: string
}

export interface MainStemAPIControllersPurchasingRequisitionListAPIRequestSortDetails {
  by?: 'CreatedBy' | 'ID' | 'Location' | 'Status'
  order?: 'Ascending' | 'Descending'
}

export interface MainStemAPIControllersPurchasingRequisitionListAPIResponse {
  requisitions?: MainStemAPIControllersPurchasingRequisitionListAPIResponseRequisitionDetails[]
  totals?: MainStemAPIControllersPurchasingRequisitionListAPIResponseTotalDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingRequisitionListAPIResponseRequisitionDetails {
  approvalStatus?: 'SavedCart' | 'AwaitingApproval' | 'Approval' | 'ApprovalWithEdits' | 'Rejected'
  approvalUsers?: MainStemAPIControllersPurchasingRequisitionListAPIResponseRequisitionDetailsApprovalUserDetails[]
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateSubmittedForApproval?: string
  /** @format int32 */
  id?: number
  location?: MainStemAPIControllersPurchasingRequisitionListAPIResponseRequisitionDetailsLocationDetails
  paymentStatus?: string
  status?: 'New' | 'PendingApproval' | 'Returned' | 'Approved' | 'Rejected' | 'Cancelled'
  suppliers?: MainStemAPIControllersPurchasingRequisitionListAPIResponseRequisitionDetailsSupplierDetails[]
  /** @format double */
  total?: number
  userApprovedBy?: MainStemAPIControllersPurchasingRequisitionListAPIResponseRequisitionDetailsUserDetails
  userCreatedBy?: MainStemAPIControllersPurchasingRequisitionListAPIResponseRequisitionDetailsUserDetails
  uuid?: string
}

export interface MainStemAPIControllersPurchasingRequisitionListAPIResponseTotalDetails {
  /** @format int32 */
  new?: number
  /** @format int32 */
  approved?: number
  /** @format int32 */
  cancelled?: number
  /** @format int32 */
  pendingApproval?: number
  /** @format int32 */
  rejected?: number
  /** @format int32 */
  returned?: number
  /** @format int32 */
  total?: number
  /** @format int32 */
  totalFiltered?: number
}

export interface MainStemAPIControllersPurchasingRequisitionListAPIResponseRequisitionDetailsApprovalUserDetails {
  hasApproved?: boolean
  username?: string
}

export interface MainStemAPIControllersPurchasingRequisitionListAPIResponseRequisitionDetailsLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingRequisitionListAPIResponseRequisitionDetailsSupplierDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingRequisitionListAPIResponseRequisitionDetailsUserDetails {
  id?: string
  name?: string
}

export interface MainStemAPIControllersPurchasingRFQAddCategoryAPIRequest {
  /** @format int32 */
  rfqID?: number
  /** @format int32 */
  rfqCategoryID?: number
}

export interface MainStemAPIControllersPurchasingRFQAddFileAttachmentAPIResponse {
  /** @format int32 */
  id?: number
  fileDetail?: MainStemAPIControllersPurchasingRFQDetailsAPIResponseRFQFileDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingRFQDetailsAPIResponseRFQFileDetails {
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  name?: string
  url?: string
}

export interface MainStemAPIControllersPurchasingRFQAddLineItemAPIRequest {
  /** @format int32 */
  rfqID?: number
  name?: string
  /** @format int32 */
  quantity?: number
  /** @format double */
  pricePerItem?: number
  note?: string
  sku?: string
}

export interface MainStemAPIControllersPurchasingRFQAddLineItemAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingRFQAddPreferredPaymentAPIRequest {
  /** @format int32 */
  rfqID?: number
  preferredPayment?: string
}

export interface MainStemAPIControllersPurchasingRFQAddRFQSupplierAPIRequest {
  /** @format int32 */
  rfqID?: number
  supplierIDs?: number[]
}

export interface MainStemAPIControllersPurchasingRFQBidsBidDecisionUpdateAPIRequest {
  /** @format int32 */
  rfqID?: number
  bidLineItemIDs?: number[]
  awardStatus?: string
}

export interface MainStemAPIControllersPurchasingRFQBidsListAPIResponse {
  bids?: MainStemAPIControllersPurchasingRFQBidsListAPIResponseRFQBidDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingRFQBidsListAPIResponseRFQBidDetails {
  /** @format int32 */
  id?: number
  agreedToAllDates?: boolean
  agreedToAllPreferredPayments?: boolean
  /** @format date-time */
  dateEstimatedDelivery?: string
  preferredPayments?: string[]
  /** @format int32 */
  rfqID?: number
  rfqTitle?: string
  /** @format int32 */
  rfqSupplierID?: number
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  /** @format date-time */
  dateCreated?: string
  status?: string
  /** @format double */
  total?: number
  /** @format double */
  lineTotals?: number
  /** @format double */
  salesTax?: number
  /** @format double */
  shippingCost?: number
  allLineItems?: MainStemAPIControllersPurchasingRFQBidsListAPIResponseRFQBidLineItemDetail[]
  lineItems?: MainStemAPIControllersPurchasingRFQBidsListAPIResponseRFQLineItemDetail[]
}

export interface MainStemAPIControllersPurchasingRFQBidsListAPIResponseRFQBidLineItemDetail {
  /** @format int32 */
  id?: number
  awardStatus?: string
  /** @format int32 */
  rfqBidID?: number
  /** @format int32 */
  rfqLineItemID?: number
  name?: string
  type?: string
  /** @format double */
  pricePerItem?: number
  /** @format int32 */
  quantity?: number
  /** @format double */
  salesTax?: number
  /** @format double */
  shippingCost?: number
  sku?: string
  note?: string
  priceOption?: string
  /** @format int32 */
  leadTimeInDays?: number
  isFreight?: boolean
}

export interface MainStemAPIControllersPurchasingRFQBidsListAPIResponseRFQLineItemDetail {
  /** @format int32 */
  id?: number
  /** @format int32 */
  rfqID?: number
  name?: string
  sku?: string
  note?: string
  bidLineItems?: MainStemAPIControllersPurchasingRFQBidsListAPIResponseRFQBidLineItemDetail[]
}

export interface MainStemAPIControllersPurchasingRFQBidsRFQBidsAPIResponse {
  bids?: MainStemAPIControllersPurchasingRFQBidsRFQBidsAPIResponseRFQBidDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingRFQBidsRFQBidsAPIResponseRFQBidDetails {
  agreedToAllDates?: boolean
  agreedToAllPreferredPayments?: boolean
  allLineItems?: MainStemAPIControllersPurchasingRFQBidsRFQBidsAPIResponseRFQBidLineItemDetail[]
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateEstimatedDelivery?: string
  /** @format int32 */
  id?: number
  lineItems?: MainStemAPIControllersPurchasingRFQBidsRFQBidsAPIResponseRFQLineItemDetail[]
  /** @format double */
  lineTotals?: number
  linkedOrderIDs?: number[]
  preferredPayments?: string[]
  /** @format int32 */
  rfqID?: number
  /** @format int32 */
  rfqSupplierID?: number
  rfqTitle?: string
  /** @format double */
  salesTax?: number
  /** @format double */
  shippingCost?: number
  status?: string
  statusAsEnum?: 'Lost' | 'Undecided' | 'Won' | 'Withdrawn' | 'PartialWin' | 'PartialLoss' | 'PartialDecisionsMade'
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  /** @format double */
  total?: number
}

export interface MainStemAPIControllersPurchasingRFQBidsRFQBidsAPIResponseRFQBidLineItemDetail {
  isFreight?: boolean
  awardStatus?: string
  /** @format int32 */
  generatedProductID?: number
  /** @format int32 */
  id?: number
  /** @format int32 */
  leadTimeInDays?: number
  linkedOrderIDs?: number[]
  linkedOrderLineItemIDs?: number[]
  name?: string
  note?: string
  priceOption?: string
  /** @format double */
  pricePerItem?: number
  /** @format int32 */
  quantity?: number
  /** @format int32 */
  rfqBidID?: number
  /** @format int32 */
  rfqLineItemID?: number
  /** @format double */
  salesTax?: number
  /** @format double */
  shippingCost?: number
  sku?: string
  type?: string
}

export interface MainStemAPIControllersPurchasingRFQBidsRFQBidsAPIResponseRFQLineItemDetail {
  bidLineItems?: MainStemAPIControllersPurchasingRFQBidsRFQBidsAPIResponseRFQBidLineItemDetail[]
  hasBeenAwarded?: boolean
  /** @format int32 */
  id?: number
  name?: string
  note?: string
  /** @format double */
  pricePerItem?: number
  /** @format int32 */
  quantity?: number
  /** @format int32 */
  rfqID?: number
  sku?: string
  status?: string
}

export interface MainStemAPIControllersPurchasingRFQCancelAPIRequest {
  /** @format int32 */
  rfqID?: number
}

export interface MainStemAPIControllersPurchasingRFQCreateAPIRequest {
  /** @format date-time */
  dateRFQAwardExpected?: string
  description?: string
  /** @format date-time */
  dueDate?: string
  /** @format date-time */
  dueDeliveryDate?: string
  /** @format int32 */
  locationID?: number
  title?: string
}

export interface MainStemAPIControllersPurchasingRFQCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingRFQCreateAndInviteSupplierAPIRequest {
  /** @format int32 */
  rfqID?: number
  locationName?: string
  email?: string
  phone?: string
  address1?: string
  address2?: string
  city?: string
  state?: string
  zip?: string
  country?: string
}

export interface MainStemAPIControllersPurchasingRFQCreateAndInviteSupplierAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingRFQDetailsAPIResponse {
  details?: MainStemAPIControllersPurchasingRFQDetailsAPIResponseRFQDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingRFQDetailsAPIResponseRFQDetails {
  categories?: MainStemAPIControllersPurchasingRFQDetailsAPIResponseRFQCategoryDetail[]
  suggestedSuppliers?: MainStemAPIControllersPurchasingRFQDetailsAPIResponseSupplierDetail[]
  suppliers?: MainStemAPIControllersPurchasingRFQDetailsAPIResponseRFQSupplierDetail[]
  urls?: MainStemAPIControllersPurchasingRFQDetailsAPIResponseRFQUrlDetails[]
  billToAddress?: MainStemAPIControllersPurchasingRFQDetailsAPIResponseAddressDetails
  /** @format int32 */
  billToAddressID?: number
  createdByUser?: MainStemAPIControllersPurchasingRFQDetailsAPIResponseUserDetails
  /** @format int32 */
  customerID?: number
  customerName?: string
  /** @format date-time */
  dateAwardExpected?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateDeliveryDue?: string
  /** @format date-time */
  dateDue?: string
  /** @format date-time */
  dateInitiatedOn?: string
  description?: string
  eligibleToAwardRFQ?: boolean
  files?: MainStemAPIControllersPurchasingRFQDetailsAPIResponseRFQFileDetails[]
  /** @format int32 */
  id?: number
  initiatedByUser?: MainStemAPIControllersPurchasingRFQDetailsAPIResponseUserDetails
  isPublic?: boolean
  lineItems?: MainStemAPIControllersPurchasingRFQDetailsAPIResponseRFQLineItemDetail[]
  /** @format int32 */
  locationID?: number
  locationName?: string
  preferredPayments?: string[]
  shipToAddress?: MainStemAPIControllersPurchasingRFQDetailsAPIResponseAddressDetails
  /** @format int32 */
  shipToAddressID?: number
  status?: string
  title?: string
  wantsHelp?: boolean
}

export interface MainStemAPIControllersPurchasingRFQDetailsAPIResponseRFQCategoryDetail {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingRFQDetailsAPIResponseSupplierDetail {
  categories?: string[]
  /** @format int32 */
  id?: number
  invited?: boolean
  name?: string
}

export interface MainStemAPIControllersPurchasingRFQDetailsAPIResponseRFQSupplierDetail {
  acceptedInvite?: boolean
  categories?: string[]
  /** @format date-time */
  dateAccepted?: string
  /** @format date-time */
  dateInvited?: string
  /** @format int32 */
  id?: number
  logoUrl?: string
  name?: string
  /** @format int32 */
  supplierID?: number
  emailEventSent?: boolean
}

export interface MainStemAPIControllersPurchasingRFQDetailsAPIResponseRFQUrlDetails {
  /** @format int32 */
  id?: number
  url?: string
}

export interface MainStemAPIControllersPurchasingRFQDetailsAPIResponseAddressDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingRFQDetailsAPIResponseUserDetails {
  email?: string
  friendlyName?: string
  id?: string
  username?: string
}

export interface MainStemAPIControllersPurchasingRFQDetailsAPIResponseRFQLineItemDetail {
  hasBeenAwarded?: boolean
  bidLineItems?: MainStemAPIControllersPurchasingRFQDetailsAPIResponseRFQBidLineItemDetail[]
  bids?: MainStemAPIControllersPurchasingRFQDetailsAPIResponseRFQBidDetails[]
  /** @format int32 */
  id?: number
  name?: string
  note?: string
  /** @format double */
  pricePerItem?: number
  /** @format int32 */
  quantity?: number
  /** @format int32 */
  rfqID?: number
  sku?: string
  status?: string
}

export interface MainStemAPIControllersPurchasingRFQDetailsAPIResponseRFQBidLineItemDetail {
  awardStatus?: string
  /** @format int32 */
  id?: number
  /** @format int32 */
  leadTimeInDays?: number
  isFreight?: boolean
  name?: string
  note?: string
  priceOption?: string
  /** @format double */
  pricePerItem?: number
  /** @format int32 */
  quantity?: number
  /** @format int32 */
  rfqBidID?: number
  /** @format int32 */
  rfqLineItemID?: number
  /** @format double */
  salesTax?: number
  /** @format double */
  shippingCost?: number
  sku?: string
  type?: string
}

export interface MainStemAPIControllersPurchasingRFQDetailsAPIResponseRFQBidDetails {
  agreedToAllDates?: boolean
  agreedToAllPreferredPayments?: boolean
  allLineItems?: MainStemAPIControllersPurchasingRFQDetailsAPIResponseRFQBidLineItemDetail[]
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateEstimatedDelivery?: string
  /** @format int32 */
  id?: number
  lineItems?: MainStemAPIControllersPurchasingRFQDetailsAPIResponseRFQLineItemDetail[]
  /** @format double */
  lineTotals?: number
  preferredPayments?: string[]
  /** @format int32 */
  rfqID?: number
  /** @format int32 */
  rfqSupplierID?: number
  rfqTitle?: string
  /** @format double */
  salesTax?: number
  /** @format double */
  shippingCost?: number
  status?: string
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  /** @format double */
  total?: number
}

export interface MainStemAPIControllersPurchasingRFQDuplicateAPIRequest {
  /** @format int32 */
  rfqID?: number
}

export interface MainStemAPIControllersPurchasingRFQDuplicateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingRFQFinalizeAPIRequest {
  /** @format int32 */
  rfqID?: number
}

export interface MainStemAPIControllersPurchasingRFQGeneratedOrdersListAPIRequest {
  /** @format int32 */
  rfqID?: number
}

export interface MainStemAPIControllersPurchasingRFQGeneratedOrdersListAPIResponse {
  generatedOrders?: MainStemAPIControllersPurchasingRFQGeneratedOrdersListAPIResponseGeneratedOrder[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingRFQGeneratedOrdersListAPIResponseGeneratedOrder {
  /** @format int32 */
  orderID?: number
  orderUUID?: string
  createdBy?: string
  createdByUUID?: string
  /** @format date-time */
  dateCreated?: string
  bidOwners?: MainStemAPIControllersPurchasingRFQGeneratedOrdersListAPIResponseRFQBidOwner[]
}

export interface MainStemAPIControllersPurchasingRFQGeneratedOrdersListAPIResponseRFQBidOwner {
  /** @format int32 */
  rfqBidID?: number
  /** @format int32 */
  supplierID?: number
  supplierName?: string
}

export interface MainStemAPIControllersPurchasingRFQInitiateAPIRequest {
  /** @format int32 */
  rfqID?: number
}

export interface MainStemAPIControllersPurchasingRFQListAPIResponse {
  rfqs?: MainStemAPIControllersPurchasingRFQListAPIResponseRFQDetails[]
  /** @format int32 */
  drafts?: number
  /** @format int32 */
  inProgress?: number
  /** @format int32 */
  awarded?: number
  /** @format int32 */
  cancelled?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingRFQListAPIResponseRFQDetails {
  /** @format int32 */
  id?: number
  title?: string
  status?: string
  /** @format int32 */
  invitedSuppliers?: number
  /** @format int32 */
  confirmedSuppliers?: number
  /** @format int32 */
  numberOfBids?: number
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateDue?: string
  /** @format date-time */
  dateDeliveryDue?: string
}

export interface MainStemAPIControllersPurchasingRFQReloadLineItemsAPIRequest {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingRFQReloadLineItemsAPIResponse {
  lineItems?: MainStemAPIControllersPurchasingRFQReloadLineItemsAPIResponseRFQLineItemDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingRFQReloadLineItemsAPIResponseRFQLineItemDetail {
  /** @format int32 */
  id?: number
  status?: string
  name?: string
  /** @format int32 */
  quantity?: number
  /** @format double */
  pricePerItem?: number
  note?: string
  sku?: string
  /** @format int32 */
  rfqID?: number
  bids?: MainStemAPIControllersPurchasingRFQReloadLineItemsAPIResponseRFQBidDetails[]
  bidLineItems?: MainStemAPIControllersPurchasingRFQReloadLineItemsAPIResponseRFQBidLineItemDetail[]
  hasBeenAwarded?: boolean
}

export interface MainStemAPIControllersPurchasingRFQReloadLineItemsAPIResponseRFQBidDetails {
  /** @format int32 */
  id?: number
  agreedToAllDates?: boolean
  agreedToAllPreferredPayments?: boolean
  /** @format date-time */
  dateEstimatedDelivery?: string
  preferredPayments?: string[]
  /** @format int32 */
  rfqID?: number
  rfqTitle?: string
  /** @format int32 */
  rfqSupplierID?: number
  /** @format int32 */
  supplierID?: number
  supplierName?: string
  /** @format date-time */
  dateCreated?: string
  status?: string
  /** @format double */
  total?: number
  /** @format double */
  lineTotals?: number
  /** @format double */
  salesTax?: number
  /** @format double */
  shippingCost?: number
  allLineItems?: MainStemAPIControllersPurchasingRFQReloadLineItemsAPIResponseRFQBidLineItemDetail[]
  lineItems?: MainStemAPIControllersPurchasingRFQReloadLineItemsAPIResponseRFQLineItemDetail[]
}

export interface MainStemAPIControllersPurchasingRFQReloadLineItemsAPIResponseRFQBidLineItemDetail {
  /** @format int32 */
  id?: number
  awardStatus?: string
  /** @format int32 */
  rfqBidID?: number
  /** @format int32 */
  rfqLineItemID?: number
  name?: string
  type?: string
  /** @format double */
  pricePerItem?: number
  /** @format int32 */
  quantity?: number
  /** @format double */
  salesTax?: number
  /** @format double */
  shippingCost?: number
  sku?: string
  note?: string
  priceOption?: string
  /** @format int32 */
  leadTimeInDays?: number
  isFreight?: boolean
}

export interface MainStemAPIControllersPurchasingRFQRemoveCategoriesAPIRequest {
  /** @format int32 */
  rfqID?: number
  rfqCategoryIDs?: number[]
}

export interface MainStemAPIControllersPurchasingRFQRemoveFileAttachmentAPIRequest {
  /** @format int32 */
  rfqID?: number
  /** @format int32 */
  fileID?: number
}

export interface MainStemAPIControllersPurchasingRFQRemoveLineItemAPIRequest {
  /** @format int32 */
  rfqID?: number
  /** @format int32 */
  lineItemID?: number
}

export interface MainStemAPIControllersPurchasingRFQRemovePreferredPaymentAPIRequest {
  /** @format int32 */
  rfqID?: number
  preferredPayments?: string[]
}

export interface MainStemAPIControllersPurchasingRFQRemoveSuppliersAPIRequest {
  /** @format int32 */
  rfqID?: number
  rfqSupplierIDs?: number[]
  supplierIds?: number[]
}

export interface MainStemAPIControllersPurchasingRFQSuppliersListAPIResponse {
  suggestedSuppliers?: MainStemAPIControllersPurchasingRFQSuppliersListAPIResponseSupplierDetail[]
  suppliers?: MainStemAPIControllersPurchasingRFQSuppliersListAPIResponseRFQSupplierDetail[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingRFQSuppliersListAPIResponseSupplierDetail {
  categories?: string[]
  /** @format int32 */
  id?: number
  invited?: boolean
  name?: string
}

export interface MainStemAPIControllersPurchasingRFQSuppliersListAPIResponseRFQSupplierDetail {
  acceptedInvite?: boolean
  categories?: string[]
  /** @format date-time */
  dateAccepted?: string
  /** @format date-time */
  dateInvited?: string
  /** @format int32 */
  id?: number
  name?: string
  /** @format int32 */
  supplierID?: number
}

export interface MainStemAPIControllersPurchasingRFQSupplierMessagesCreateAPIRequest {
  message?: string
  /** @format int32 */
  rfqID?: number
  supplierIDs?: number[]
}

export interface MainStemAPIControllersPurchasingRFQSupplierMessagesCreateAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingRFQSupplierMessagesListAPIResponse {
  supplierMessages?: MainStemAPIControllersPurchasingRFQSupplierMessagesListAPIResponseSupplierMessagesBySupplier[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingRFQSupplierMessagesListAPIResponseSupplierMessagesBySupplier {
  /** @format int32 */
  id?: number
  name?: string
  logoUrl?: string
  /** @format int32 */
  messageCount?: number
  /** @format int32 */
  unreadCount?: number
  supplier?: MainStemAPIControllersPurchasingRFQSupplierMessagesListAPIResponseRFQSupplierDetail
  messages?: MainStemAPIHelpersFactoriesMessagesMessageDetail[]
}

export interface MainStemAPIControllersPurchasingRFQSupplierMessagesListAPIResponseRFQSupplierDetail {
  categories?: string[]
  /** @format int32 */
  supplierID?: number
  acceptedInvite?: boolean
  /** @format date-time */
  dateInvited?: string
  /** @format date-time */
  dateAccepted?: string
  logoUrl?: string
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIHelpersFactoriesMessagesMessageDetail {
  message?: string
  /** @format int32 */
  id?: number
  /** @format date-time */
  dateSent?: string
  direction?: string
  sentBy?: MainStemAPIHelpersFactoriesMessagesUserDetails
  hasRead?: boolean
}

export interface MainStemAPIHelpersFactoriesMessagesUserDetails {
  id?: string
  username?: string
  email?: string
  friendlyName?: string
  logoUrl?: string
  customerLogoUrl?: string
  /** @format int32 */
  customerID?: number
  /** @format int32 */
  locationID?: number
  /** @format int32 */
  supplierID?: number
  isMainStem?: boolean
}

export interface MainStemAPIControllersPurchasingRFQUpdateAPIRequest {
  urls?: MainStemAPIControllersPurchasingRFQUpdateRFQUrlDetails[]
  /** @format int32 */
  id?: number
  isPublic?: boolean
  wantsHelp?: boolean
  title?: string
  description?: string
  /** @format int32 */
  shipToAddressID?: number
  /** @format int32 */
  billToAddressID?: number
  /** @format date-time */
  dateDue?: string
  /** @format date-time */
  dateDeliveryDue?: string
  /** @format date-time */
  dateInitiatedOn?: string
  /** @format date-time */
  dateAwardExpected?: string
  preferredPayments?: string[]
}

export interface MainStemAPIControllersPurchasingRFQUpdateRFQUrlDetails {
  /** @format int32 */
  id?: number
  url?: string
}

export interface MainStemAPIControllersPurchasingRFQUpdateLineItemAPIRequest {
  /** @format int32 */
  id?: number
  name?: string
  /** @format int32 */
  quantity?: number
  /** @format double */
  pricePerItem?: number
  note?: string
  sku?: string
}

export interface MainStemAPIControllersPurchasingRFQUpdateLineItemAPIResponse {
  /** @format int32 */
  id?: number
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingSearchAPIRequest {
  query?: string
}

export interface MainStemAPIControllersPurchasingSearchAPIResponse {
  locations?: MainStemAPIControllersPurchasingSearchAPIResponseLocationDetails[]
  orders?: MainStemAPIControllersPurchasingSearchAPIResponseOrderDetails[]
  productLists?: MainStemAPIControllersPurchasingSearchAPIResponseProductListDetails[]
  products?: MainStemAPIControllersPurchasingSearchAPIResponseProductDetails[]
  shipments?: MainStemAPIControllersPurchasingSearchAPIResponseShipmentDetails[]
  users?: MainStemAPIControllersPurchasingSearchAPIResponseUserDetails[]
  vendors?: MainStemAPIControllersPurchasingSearchAPIResponseVendorDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingSearchAPIResponseLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  headerURL?: string
  /** @format int32 */
  id?: number
  logoURL?: string
  name?: string
  state?: string
  uuid?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingSearchAPIResponseOrderDetails {
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format double */
  discount?: number
  /** @format int32 */
  id?: number
  location?: MainStemAPIControllersPurchasingSearchAPIResponseOrderDetailsLocationDetails
  /** @format double */
  orderTotal?: number
  paymentStatus?: string
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  status?: string
  /** @format double */
  subTotal?: number
  userApprovedBy?: string
  userCreatedBy?: string
  uuid?: string
}

export interface MainStemAPIControllersPurchasingSearchAPIResponseProductListDetails {
  /** @format int32 */
  id?: number
  isPrivate?: boolean
  isShared?: boolean
  name?: string
  /** @format int32 */
  productCount?: number
  /** @format int32 */
  userCount?: number
}

export interface MainStemAPIControllersPurchasingSearchAPIResponseProductDetails {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingSearchAPIResponseShipmentDetails {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingSearchAPIResponseUserDetails {
  /** @format date-time */
  dateCreated?: string
  email?: string
  friendlyName?: string
  role?: string
  username?: string
  uuid?: string
}

export interface MainStemAPIControllersPurchasingSearchAPIResponseVendorDetails {
  /** @format date-time */
  firstOrderedDate?: string
  /** @format int32 */
  id?: number
  /** @format date-time */
  lastOrderedDate?: string
  name?: string
  /** @format int32 */
  totalOrders?: number
  /** @format int32 */
  totalQuantityOrdered?: number
}

export interface MainStemAPIControllersPurchasingSearchAPIResponseOrderDetailsLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingRFQSendPrivateSupplierInviteEmailAPIRequest {
  /** @format int32 */
  rfqID?: number
  /** @format int32 */
  supplierID?: number
}

export interface MainStemAPIControllersPurchasingShipmentsDetailsAPIRequest {
  shipmentId?: string
}

export interface MainStemAPIControllersPurchasingShipmentsDetailsAPIResponse {
  /** @format date-time */
  datePacked?: string
  /** @format date-time */
  dateShipped?: string
  lineItems?: MainStemAPIControllersPurchasingShipmentsDetailsLineItemDetails[]
  order?: MainStemAPIControllersPurchasingShipmentsDetailsOrderDetails
  shipment?: MainStemAPIControllersPurchasingShipmentsDetailsShipmentDetails
  trackingInformation?: ShipEngineSDKTrackUsingCarrierCodeAndTrackingNumberResult
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingShipmentsDetailsLineItemDetails {
  product?: MainStemAPIControllersPurchasingShipmentsDetailsProductDetails
  /** @format int32 */
  quantity?: number
}

export interface MainStemAPIControllersPurchasingShipmentsDetailsOrderDetails {
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  id?: number
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  uuid?: string
}

export interface MainStemAPIControllersPurchasingShipmentsDetailsShipmentDetails {
  carrier?: string
  carrierLink?: string
  carrierType?: string
  destination?: MainStemAPIControllersPurchasingShipmentsDetailsAddressDetails
  /** @format double */
  height?: number
  /** @format double */
  length?: number
  origin?: MainStemAPIControllersPurchasingShipmentsDetailsAddressDetails
  trackingNumber?: string
  /** @format double */
  weight?: number
  /** @format double */
  width?: number
}

export interface ShipEngineSDKTrackUsingCarrierCodeAndTrackingNumberResult {
  trackingNumber?: string
  statusCode?: 'AC' | 'IT' | 'DE' | 'EX' | 'AT' | 'UN' | 'NY'
  statusDescription?:
    | 'Accepted'
    | 'Attempted Delivery'
    | 'Delivered'
    | 'Exception'
    | 'In Transit'
    | 'Unknown'
    | 'Not Yet In System'
  carrierDetailCode?: string
  carrierStatusCode?: string
  carrierStatusDescription?: string
  shipDate?: string
  estimatedDeliveryDate?: string
  actualDeliveryDate?: string
  exceptionDescription?: string
  events?: ShipEngineSDKTrackUsingCarrierCodeAndTrackingNumberTrackingEvent[]
}

export interface MainStemAPIControllersPurchasingShipmentsDetailsProductDetails {
  /** @format int32 */
  id?: number
  imageURL?: string
  name?: string
}

export interface MainStemAPIControllersPurchasingShipmentsDetailsAddressDetails {
  address1?: string
  address2?: string
  city?: string
  state?: string
  zip?: string
}

export interface ShipEngineSDKTrackUsingCarrierCodeAndTrackingNumberTrackingEvent {
  occurredAt?: string
  carrierOccurredAt?: string
  description?: string
  cityLocality?: string
  stateProvince?: string
  postalCode?: string
  countryCode?: string
  companyName?: string
  signer?: string
  eventCode?: string
  statusCode?: string
  carrierStatusCode?: string
  carrierDetailCode?: string
  /** @format double */
  latitude?: number
  /** @format double */
  longitude?: number
}

export interface MainStemAPIControllersPurchasingShipmentsListAPIResponse {
  shipments?: MainStemAPIControllersPurchasingShipmentsListAPIResponseShipmentDetails[]
  shipmentTotals?: MainStemAPIControllersPurchasingShipmentsListAPIResponseShipmentTotals[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingShipmentsListAPIResponseShipmentTotals {
  status?: string
  /** @format int32 */
  total?: number
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsAPIRequest {
  /** @format int32 */
  vendorId?: number
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsAPIResponse {
  address1?: string
  address2?: string
  addresses?: MainStemAPIControllersPurchasingSuppliersDetailsAPIResponseAddressCollections
  chartDetails?: MainStemAPIControllersPurchasingSuppliersDetailsAPIResponseChartDataDetails
  city?: string
  contacts?: MainStemAPIControllersPurchasingSuppliersDetailsAPIResponseContactDetails[]
  country?: string
  /** @format date-time */
  dateFirstOrder?: string
  /** @format date-time */
  dateLastOrder?: string
  description?: string
  email?: string
  frieghtSettings?: MainStemAPIControllersPurchasingSuppliersDetailsAPIResponseFreightSettingsDetails
  headerURL?: string
  /** @format int32 */
  id?: number
  isPrivateSupplier?: boolean
  logoURL?: string
  name?: string
  news?: MainStemAPIControllersPurchasingSuppliersDetailsAPIResponseNewsDetails[]
  phone?: string
  refundPolicy?: string
  rfqCategories?: MainStemAPIControllersPurchasingSuppliersDetailsAPIResponseRFQCategoryDetails[]
  rfqs?: MainStemAPIControllersPurchasingSuppliersDetailsAPIResponseRFQDetails[]
  shipmentSettings?: MainStemAPIControllersPurchasingSuppliersDetailsAPIResponseShipmentSettingsDetails
  socialMedia?: MainStemAPIControllersPurchasingSuppliersDetailsAPIResponseSocialMediaLinks
  state?: string
  /** @format double */
  totalSpend?: number
  usesDirectPay?: boolean
  usesPreferredShipping?: boolean
  website?: string
  zip?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsAPIResponseAddressCollections {
  billing?: MainStemAPIControllersPurchasingSuppliersDetailsAPIResponseAddressDetails
  physical?: MainStemAPIControllersPurchasingSuppliersDetailsAPIResponseAddressDetails
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsAPIResponseChartDataDetails {
  lastMonthSales?: MainStemAPIControllersPurchasingSuppliersDetailsAPIResponseChartDataDetailsChartDetails
  thisMonthSales?: MainStemAPIControllersPurchasingSuppliersDetailsAPIResponseChartDataDetailsChartDetails
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsAPIResponseContactDetails {
  email?: string
  firstName?: string
  isPrimary?: boolean
  lastName?: string
  phone?: string
  phoneExtension?: string
  position?: string
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsAPIResponseFreightSettingsDetails {
  carrier?: string
  /** @format double */
  freeShippingMinimum?: number
  required?: boolean
  /** @format int32 */
  trigger?: number
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsAPIResponseNewsDetails {
  content?: string
  /** @format date-time */
  dateCreated?: string
  title?: string
  url?: string
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsAPIResponseRFQCategoryDetails {
  name?: string
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsAPIResponseRFQDetails {
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateDeliveryDue?: string
  /** @format date-time */
  dateDue?: string
  /** @format int32 */
  id?: number
  status?: string
  title?: string
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsAPIResponseShipmentSettingsDetails {
  /** @format int32 */
  bundle?: number
  carrier?: string
  /** @format double */
  freeShippingMinimum?: number
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsAPIResponseSocialMediaLinks {
  facebook?: string
  instagram?: string
  linkedIn?: string
  twitter?: string
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsAPIResponseAddressDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsAPIResponseChartDataDetailsChartDetails {
  label?: string
  /** @format double */
  value?: number
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsOrdersAPIRequest {
  /** @format int32 */
  vendorId?: number
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsOrdersAPIResponse {
  orders?: MainStemAPIControllersPurchasingSuppliersDetailsOrdersAPIResponseOrderDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsOrdersAPIResponseOrderDetails {
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format double */
  discount?: number
  /** @format int32 */
  id?: number
  location?: MainStemAPIControllersPurchasingSuppliersDetailsOrdersAPIResponseOrderDetailsLocationDetails
  /** @format double */
  orderTotal?: number
  paymentStatus?: string
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  status?: 'New' | 'PendingApproval' | 'Returned' | 'Approved' | 'Rejected' | 'Cancelled'
  /** @format double */
  subTotal?: number
  userApprovedBy?: string
  userCreatedBy?: string
  uuid?: string
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsOrdersAPIResponseOrderDetailsLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsProductListsAPIRequest {
  /** @format int32 */
  vendorId?: number
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsProductListsAPIResponse {
  productLists?: MainStemAPIControllersPurchasingSuppliersDetailsProductListsAPIResponseProductListDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsProductListsAPIResponseProductListDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsProductsAPIRequest {
  /** @format int32 */
  vendorId?: number
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsProductsAPIResponse {
  products?: MainStemAPIControllersPurchasingSuppliersDetailsProductsAPIResponseProductDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsProductsAPIResponseProductDetails {
  /** @format date-time */
  firstOrderedDate?: string
  /** @format int32 */
  id?: number
  /** @format date-time */
  lastOrderedDate?: string
  name?: string
  /** @format int32 */
  totalOrders?: number
  /** @format int32 */
  totalQuantityOrdered?: number
  type?: string
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsProductsYouMayLikeAPIRequest {
  /** @format int32 */
  vendorId?: number
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsProductsYouMayLikeAPIResponse {
  products?: MainStemAPIControllersPurchasingSuppliersDetailsProductsYouMayLikeAPIResponseProductDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsProductsYouMayLikeAPIResponseProductDetails {
  /** @format int32 */
  id?: number
  name?: string
  productImageURL?: string
  type?: string
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsShipmentsAPIRequest {
  /** @format int32 */
  vendorId?: number
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsShipmentsAPIResponse {
  shipments?: MainStemAPIControllersPurchasingSuppliersDetailsShipmentsAPIResponseShipmentDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingSuppliersDetailsShipmentsAPIResponseShipmentDetails {
  carrier?: string
  carrierType?: string
  /** @format date-time */
  dateCreated?: string
  /** @format date-time */
  dateReceived?: string
  /** @format date-time */
  dateShippedOn?: string
  /** @format date-time */
  estimatedDeliveryDate?: string
  /** @format int32 */
  orderID?: number
  orderUUID?: string
  trackingNumber?: string
  uuid?: string
}

export interface MainStemAPIControllersPurchasingSuppliersListAPIRequest {
  isPrivate?: boolean
}

export interface MainStemAPIControllersPurchasingSuppliersListAPIResponse {
  suppliers?: MainStemAPIControllersPurchasingSuppliersListAPIResponseSupplierDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingSuppliersListAPIResponseSupplierDetails {
  /** @format date-time */
  firstOrderedDate?: string
  /** @format int32 */
  id?: number
  imgURL?: string
  isPrivateSupplier?: boolean
  /** @format date-time */
  lastOrderedDate?: string
  name?: string
  /** @format int32 */
  totalOrders?: number
  /** @format int32 */
  totalProducts?: number
  /** @format int32 */
  totalQuantityOrdered?: number
  userRelated?: boolean
  usesDirectPay?: boolean
  usesPreferredShipping?: boolean
}

export interface MainStemAPIControllersPurchasingSupplyAuditGenerateAPIRequest {
  /**
   * @format uuid
   * @example 00000000-0000-0000-0000-000000000000
   */
  orderUUID?: string
}

export interface MainStemAPIControllersPurchasingSupplyAuditGenerateAPIResponse {
  lineItems?: MainStemAPIControllersPurchasingSupplyAuditGenerateAPIResponseLineItemDetails[]
  order?: MainStemAPIControllersPurchasingSupplyAuditGenerateAPIResponseOrderDetails
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingSupplyAuditGenerateAPIResponseLineItemDetails {
  /** @format double */
  estimatedShipping?: number
  /** @format int32 */
  id?: number
  options?: MainStemAPIControllersPurchasingSupplyAuditGenerateAPIResponseLineItemDetailsOptionDetails[]
  product?: MainStemAPIControllersPurchasingSupplyAuditGenerateAPIResponseProductDetails
  /** @format int32 */
  quantity?: number
  supplier?: MainStemAPIControllersPurchasingSupplyAuditGenerateAPIResponseSupplierDetails
}

export interface MainStemAPIControllersPurchasingSupplyAuditGenerateAPIResponseOrderDetails {
  /** @format int32 */
  id?: number
}

export interface MainStemAPIControllersPurchasingSupplyAuditGenerateAPIResponseLineItemDetailsOptionDetails {
  /** @format double */
  estimatedShipping?: number
  product?: MainStemAPIControllersPurchasingSupplyAuditGenerateAPIResponseProductDetails
  /** @format int32 */
  recommendationLineItemOptionId?: number
  supplier?: MainStemAPIControllersPurchasingSupplyAuditGenerateAPIResponseSupplierDetails
}

export interface MainStemAPIControllersPurchasingSupplyAuditGenerateAPIResponseProductDetails {
  /** @format double */
  flatShipping?: number
  hasVolumePricing?: boolean
  /** @format int32 */
  id?: number
  image?: string
  isPrivate?: boolean
  name?: string
  /** @format int32 */
  parentProductId?: number
  /** @format double */
  price?: number
  /** @format double */
  priceEnterprise?: number
  /** @format double */
  pricePerUnit?: number
  /** @format double */
  priceRetail?: number
  /** @format double */
  priceWholesale?: number
  type?: string
  unitOfMeasureValue?: string
  /** @format double */
  weight?: number
}

export interface MainStemAPIControllersPurchasingSupplyAuditGenerateAPIResponseSupplierDetails {
  /** @format int32 */
  id?: number
  image?: string
  location?: MainStemAPIControllersPurchasingSupplyAuditGenerateAPIResponseSupplierDetailsAddressDetails
  name?: string
}

export interface MainStemAPIControllersPurchasingSupplyAuditGenerateAPIResponseSupplierDetailsAddressDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingSupplyAuditSwapAPIRequest {
  /** @format int32 */
  orderId?: number
  recommendationLineItemOptionIds?: number[]
}

export interface MainStemAPIControllersPurchasingSupplyAuditSwapAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingTechListAPIResponse {
  tech?: MainStemAPIControllersPurchasingTechListAPIResponseTechDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingTechListAPIResponseTechDetails {
  /** @format int32 */
  id?: number
  logo?: string
  name?: string
  type?: string
}

export interface MainStemAPIControllersPurchasingTechLocationAddAPIRequest {
  /** @format int32 */
  locationId?: number
  /** @format int32 */
  techId?: number
}

export interface MainStemAPIControllersPurchasingTechLocationAddAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingTechLocationRemoveAPIRequest {
  /** @format int32 */
  locationId?: number
  /** @format int32 */
  techId?: number
}

export interface MainStemAPIControllersPurchasingTechLocationRemoveAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingUserPermissionsCreateAPIRequest {
  /** @format int32 */
  permissionId?: number
  userId?: string
  value?: string
}

export interface MainStemAPIControllersPurchasingUserPermissionsCreateAPIResponse {
  displayName?: string
  group?: string
  /** @format int32 */
  id?: number
  shortCode?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingUserPermissionsDeleteAPIRequest {
  /** @format int32 */
  id?: number
  userId?: string
}

export interface MainStemAPIControllersPurchasingUserPermissionsUpdateAPIRequest {
  /** @format int32 */
  id?: number
  value?: string
}

export interface MainStemAPIControllersPurchasingUserPermissionsUpdateAPIResponse {
  displayName?: string
  group?: string
  /** @format int32 */
  id?: number
  shortCode?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingUsersCreateAPIRequest {
  email?: string
  friendlyName?: string
  /** @format int32 */
  locationId?: number
  password?: string
  /** @format int32 */
  permissionGroupId?: number
  title?: string
  type?: string
}

export interface MainStemAPIControllersPurchasingUsersCreateAPIResponse {
  error?: string
  userId?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingUsersDeleteAPIRequest {
  userId?: string
}

export interface MainStemAPIControllersPurchasingUsersDeleteAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingUsersDetailsAPIRequest {
  uuid?: string
}

export interface MainStemAPIControllersPurchasingUsersDetailsAPIResponse {
  activityLogs?: MainStemAPIControllersPurchasingUsersDetailsAPIResponseActivityLog[]
  copyUserOnApprovedOrders?: boolean
  copyUserOnCreatedOrders?: boolean
  /** @format date-time */
  dateCreated?: string
  defaultLocation?: MainStemAPIControllersPurchasingUsersDetailsAPIResponseLocationDetails
  departments?: MainStemAPIControllersPurchasingUsersDetailsAPIResponseDepartmentDetails[]
  email?: string
  friendlyName?: string
  groupPermissions?: MainStemAPIControllersPurchasingUsersDetailsAPIResponsePermissionDetails[]
  permissionGroup?: MainStemAPIControllersPurchasingUsersDetailsAPIResponsePermissionGroupDetail
  permissions?: MainStemAPIControllersPurchasingUsersDetailsAPIResponsePermissionDetails[]
  role?: string
  secondaryLocations?: MainStemAPIControllersPurchasingUsersDetailsAPIResponseLocationDetails[]
  title?: string
  type?: string
  username?: string
  userPermissions?: MainStemAPIControllersPurchasingUsersDetailsAPIResponsePermissionDetails[]
  uuid?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingUsersDetailsAPIResponseActivityLog {
  content?: string
  /** @format date-time */
  dateCreated?: string
  /** @format int32 */
  documentID?: number
  documentType?: string
  /** @format int32 */
  id?: number
  reason?: string
}

export interface MainStemAPIControllersPurchasingUsersDetailsAPIResponseLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  headerURL?: string
  /** @format int32 */
  id?: number
  logoURL?: string
  name?: string
  state?: string
  uuid?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingUsersDetailsAPIResponseDepartmentDetails {
  /** @format int32 */
  id?: number
  location?: MainStemAPIControllersPurchasingUsersDetailsAPIResponseDepartmentDetailsLocationDetails
  name?: string
}

export interface MainStemAPIControllersPurchasingUsersDetailsAPIResponsePermissionDetails {
  group?: string
  shortCode?: string
  value?: string
}

export interface MainStemAPIControllersPurchasingUsersDetailsAPIResponsePermissionGroupDetail {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingUsersDetailsAPIResponseDepartmentDetailsLocationDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingUsersDetailsOrdersAPIRequest {
  uuid?: string
}

export interface MainStemAPIControllersPurchasingUsersDetailsOrdersAPIResponse {
  orders?: MainStemAPIControllersPurchasingUsersDetailsOrdersAPIResponseOrderDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingUsersDetailsOrdersAPIResponseOrderDetails {
  /** @format date-time */
  dateApproved?: string
  /** @format date-time */
  dateCreated?: string
  /** @format double */
  discount?: number
  /** @format int32 */
  id?: number
  location?: MainStemAPIControllersPurchasingUsersDetailsOrdersAPIResponseOrderDetailsLocationDetails
  /** @format double */
  orderTotal?: number
  paymentStatus?: string
  /** @format double */
  salesTax?: number
  /** @format double */
  shipping?: number
  status?: 'New' | 'PendingApproval' | 'Returned' | 'Approved' | 'Rejected' | 'Cancelled'
  /** @format double */
  subTotal?: number
  userApprovedBy?: string
  userCreatedBy?: string
  uuid?: string
}

export interface MainStemAPIControllersPurchasingUsersDetailsOrdersAPIResponseOrderDetailsLocationDetails {
  address1?: string
  address2?: string
  city?: string
  country?: string
  /** @format int32 */
  id?: number
  name?: string
  state?: string
  zip?: string
}

export interface MainStemAPIControllersPurchasingUsersDetailsProductsAPIRequest {
  uuid?: string
}

export interface MainStemAPIControllersPurchasingUsersDetailsProductsAPIResponse {
  products?: MainStemAPIControllersPurchasingUsersDetailsProductsAPIResponseProductDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingUsersDetailsProductsAPIResponseProductDetails {
  /** @format int32 */
  id?: number
  name?: string
  type?: string
  vendor?: MainStemAPIControllersPurchasingUsersDetailsProductsAPIResponseProductDetailsVendorDetails
}

export interface MainStemAPIControllersPurchasingUsersDetailsProductsAPIResponseProductDetailsVendorDetails {
  /** @format int32 */
  id?: number
  name?: string
}

export interface MainStemAPIControllersPurchasingUsersDetailsResetPasswordAPIRequest {
  password?: string
  userId?: string
}

export interface MainStemAPIControllersPurchasingUsersDetailsResetPasswordAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingUsersDetailsVendorsAPIRequest {
  uuid?: string
}

export interface MainStemAPIControllersPurchasingUsersDetailsVendorsAPIResponse {
  vendors?: MainStemAPIControllersPurchasingUsersDetailsVendorsAPIResponseVendorDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingUsersDetailsVendorsAPIResponseVendorDetails {
  /** @format date-time */
  firstOrderedDate?: string
  /** @format int32 */
  id?: number
  /** @format date-time */
  lastOrderedDate?: string
  name?: string
  /** @format int32 */
  totalOrders?: number
  /** @format int32 */
  totalQuantityOrdered?: number
}

export interface MainStemAPIControllersPurchasingUsersDuplicateAPIRequest {
  copyLocations?: boolean
  password?: string
  userId?: string
  username?: string
}

export interface MainStemAPIControllersPurchasingUsersDuplicateAPIResponse {
  userId?: string
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingUsersListAPIResponse {
  users?: MainStemAPIControllersPurchasingUsersListAPIResponseUserDetails[]
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingUsersListAPIResponseUserDetails {
  /** @format date-time */
  dateCreated?: string
  email?: string
  friendlyName?: string
  permissionGroup?: string
  title?: string
  username?: string
  userRelated?: boolean
  uuid?: string
}

export interface MainStemAPIControllersPurchasingUsersLocationsAddAPIRequest {
  locationIds?: number[]
  userId?: string
}

export interface MainStemAPIControllersPurchasingUsersLocationsAddAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

export interface MainStemAPIControllersPurchasingUsersLocationsRemoveAPIRequest {
  /** @format int32 */
  locationId?: number
  userId?: string
}

export interface MainStemAPIControllersPurchasingUsersLocationsSetDefaultAPIRequest {
  /** @format int32 */
  locationId?: number
  userId?: string
}

export interface MainStemAPIControllersPurchasingUsersUpdateAPIRequest {
  copyUserOnApprovedOrders?: boolean
  copyUserOnCreatedOrders?: boolean
  email?: string
  friendlyName?: string
  /** @format int32 */
  permissionGroupId?: number
  title?: string
  type?: string
  userId?: string
  username?: string
}

export interface MainStemAPIControllersPurchasingUsersUpdateAPIResponse {
  message?: string
  wasSuccessful?: boolean
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded'
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    const enterpriseToken = window.localStorage.getItem('mainstem_user_token')
    const sessionURL = window.localStorage.getItem('logrocket_session_url')
    const affiliateProgramUUID = import.meta.env.VITE_REACT_APP_PURCHASE_PRO_AFFILIATE_ID
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'https://api.mainstem.io',
      headers: {
        Authorization: `Bearer ${enterpriseToken || ''}`,
        'X-AffiliateProgramUUID': affiliateProgramUUID,
        'X-SessionURL': sessionURL,
        'X-Platform': 'Purchase Pro'
      }
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  private mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    }
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === 'object' && property !== null
          ? JSON.stringify(property)
          : `${property}`
      )
      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = (format && this.format) || void 0

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      requestParams.headers.common = { Accept: '*/*' }
      requestParams.headers.post = {}
      requestParams.headers.put = {}

      body = this.createFormData(body as Record<string, unknown>)
    }

    const enterpriseToken = window.localStorage.getItem('mainstem_user_token')
    const sessionURL = window.localStorage.getItem('logrocket_session_url')
    const affiliateProgramUUID = import.meta.env.VITE_REACT_APP_PURCHASE_PRO_AFFILIATE_ID

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        ...(requestParams.headers || {}),
        Authorization: `Bearer ${enterpriseToken || ''}`,
        'X-AffiliateProgramUUID': affiliateProgramUUID,
        'X-SessionURL': sessionURL,
        'X-Platform': 'Purchase Pro'
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path
    })
  }
}

/**
 * @title MainStem Purchase Pro™ API V1
 * @version purchasing
 * @baseUrl https://api.mainstem.io
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags GlobalNotesCreate
     * @name OrderManagementOrderNotesCreate
     * @request POST:/api/purchasing/order-management/order-notes/create
     */
    orderManagementOrderNotesCreate: (
      apiRequest: MainStemAPIControllersGlobalNotesCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersGlobalNotesCreateAPIResponse, any>({
        path: `/api/purchasing/order-management/order-notes/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags GlobalNotesDelete
     * @name OrderManagementOrderNotesDelete
     * @request POST:/api/purchasing/order-management/order-notes/delete
     */
    orderManagementOrderNotesDelete: (
      apiRequest: MainStemAPIControllersGlobalNotesDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/order-management/order-notes/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags GlobalNotesList
     * @name OrderManagementOrderNotesList
     * @request POST:/api/purchasing/order-management/order-notes/list
     */
    orderManagementOrderNotesList: (
      apiRequest: MainStemAPIControllersGlobalNotesListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersGlobalNotesListAPIResponse, any>({
        path: `/api/purchasing/order-management/order-notes/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags GlobalNotesUpdate
     * @name OrderManagementOrderNotesUpdate
     * @request POST:/api/purchasing/order-management/order-notes/update
     */
    orderManagementOrderNotesUpdate: (
      request: MainStemAPIControllersGlobalNotesUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/order-management/order-notes/update`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingAccountDetails
     * @name AccountDetails
     * @request POST:/api/purchasing/account/details
     */
    accountDetails: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingAccountDetailsAPIResponse, any>({
        path: `/api/purchasing/account/details`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingAccountUpdate
     * @name AccountUpdate
     * @request POST:/api/purchasing/account/update
     */
    accountUpdate: (apiRequest: MainStemAPIControllersPurchasingAccountUpdateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingAccountUpdateAPIResponse, any>({
        path: `/api/purchasing/account/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingAccountUpgrade
     * @name AccountUpgrade
     * @request POST:/api/purchasing/account/upgrade
     */
    accountUpgrade: (
      apiRequest: MainStemAPIControllersPurchasingAccountUpgradeAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/account/upgrade`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingApprovalWorkflowAllowableUsers
     * @name LocationsApprovalWorkflowAllowableUsers
     * @request GET:/api/purchasing/locations/approval-workflow/allowable-users
     */
    locationsApprovalWorkflowAllowableUsers: (
      query?: {
        /** @format int32 */
        'apiRequest.id'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationsApprovalWorkflowAllowableUsersAPIResponse, any>({
        path: `/api/purchasing/locations/approval-workflow/allowable-users`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingApprovalWorkflowList
     * @name LocationsApprovalWorkflow
     * @request GET:/api/purchasing/locations/approval-workflow
     */
    locationsApprovalWorkflow: (
      query?: {
        /** @format int32 */
        'apiRequest.id'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationsApprovalWorkflowListAPIResponse, any>({
        path: `/api/purchasing/locations/approval-workflow`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingApprovalWorkflowSave
     * @name LocationsApprovalWorkflowSave
     * @request POST:/api/purchasing/locations/approval-workflow/save
     */
    locationsApprovalWorkflowSave: (
      apiRequest: MainStemAPIControllersPurchasingLocationsApprovalWorkflowSaveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationsApprovalWorkflowListAPIResponse, any>({
        path: `/api/purchasing/locations/approval-workflow/save`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingApprovalWorkflowValidate
     * @name LocationsApprovalWorkflowValidate
     * @request POST:/api/purchasing/locations/approval-workflow/validate
     */
    locationsApprovalWorkflowValidate: (
      apiRequest: MainStemAPIControllersPurchasingLocationsApprovalWorkflowValidateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationsApprovalWorkflowListAPIResponse, any>({
        path: `/api/purchasing/locations/approval-workflow/validate`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingAuthCurrentUser
     * @name AuthCurrentUser
     * @request GET:/api/purchasing/auth/current-user
     */
    authCurrentUser: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingAuthCurrentUserAPIResponse, any>({
        path: `/api/purchasing/auth/current-user`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingAuthLogin
     * @name AuthLogin
     * @request POST:/api/purchasing/auth/login
     */
    authLogin: (apiRequest: MainStemAPIHelpersAuthorizationRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIHelpersAuthorizationResponse, any>({
        path: `/api/purchasing/auth/login`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingAuthPasswordResetProcess
     * @name AuthPasswordResetProcess
     * @request POST:/api/purchasing/auth/password-reset/process
     */
    authPasswordResetProcess: (
      APIRequest: MainStemAPIControllersPurchasingAuthPassswordResetProcessAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingAuthPassswordResetProcessAPIResponse, any>({
        path: `/api/purchasing/auth/password-reset/process`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingAuthPasswordResetRequest
     * @name AuthPasswordResetRequest
     * @request POST:/api/purchasing/auth/password-reset/request
     */
    authPasswordResetRequest: (
      APIRequest: MainStemAPIControllersPurchasingAuthPassswordResetRequestAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/auth/password-reset/request`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingAuthPasswordResetValidate
     * @name AuthPasswordResetValidate
     * @request POST:/api/purchasing/auth/password-reset/validate
     */
    authPasswordResetValidate: (
      APIRequest: MainStemAPIControllersPurchasingAuthPassswordResetValidateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingAuthPassswordResetValidateAPIResponse, any>({
        path: `/api/purchasing/auth/password-reset/validate`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogCreate
     * @name ProductListsCreate
     * @request POST:/api/purchasing/productLists/create
     */
    productListsCreate: (
      apiRequest: MainStemAPIControllersPurchasingCatalogCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCatalogCreateAPIResponse, any>({
        path: `/api/purchasing/productLists/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogCreate
     * @name CatalogCreate
     * @request POST:/api/purchasing/catalog/create
     */
    catalogCreate: (apiRequest: MainStemAPIControllersPurchasingCatalogCreateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingCatalogCreateAPIResponse, any>({
        path: `/api/purchasing/catalog/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogDelete
     * @name CatalogDelete
     * @request POST:/api/purchasing/catalog/delete
     */
    catalogDelete: (apiRequest: MainStemAPIControllersPurchasingCatalogDeleteAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingCatalogDeleteAPIResponse, any>({
        path: `/api/purchasing/catalog/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogDetails
     * @name CatalogDetails
     * @request GET:/api/purchasing/catalog/details
     */
    catalogDetails: (
      query?: {
        /** @format int32 */
        'apiRequest.id'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCatalogDetailsAPIResponse, any>({
        path: `/api/purchasing/catalog/details`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogDetails
     * @name ProductListsDetails
     * @request GET:/api/purchasing/productLists/details
     */
    productListsDetails: (
      query?: {
        /** @format int32 */
        'apiRequest.id'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCatalogDetailsAPIResponse, any>({
        path: `/api/purchasing/productLists/details`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogDuplicate
     * @name CatalogDuplicate
     * @request POST:/api/purchasing/catalog/duplicate
     */
    catalogDuplicate: (
      apiRequest: MainStemAPIControllersPurchasingCatalogDuplicateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCatalogDuplicateAPIResponse, any>({
        path: `/api/purchasing/catalog/duplicate`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogList
     * @name CatalogList
     * @request GET:/api/purchasing/catalog/list
     */
    catalogList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingCatalogListAPIResponse, any>({
        path: `/api/purchasing/catalog/list`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogList
     * @name ProductListsList
     * @request GET:/api/purchasing/productLists/list
     */
    productListsList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingCatalogListAPIResponse, any>({
        path: `/api/purchasing/productLists/list`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogLocationsAdd
     * @name CatalogLocationsAdd
     * @request POST:/api/purchasing/catalog/locations/add
     */
    catalogLocationsAdd: (
      apiRequest: MainStemAPIControllersPurchasingCatalogLocationsAddAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCatalogLocationsAddAPIResponse, any>({
        path: `/api/purchasing/catalog/locations/add`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogLocationsAdd
     * @name ProductListsDetailsLocationsAdd
     * @request POST:/api/purchasing/productLists/details/locations/add
     */
    productListsDetailsLocationsAdd: (
      apiRequest: MainStemAPIControllersPurchasingCatalogLocationsAddAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCatalogLocationsAddAPIResponse, any>({
        path: `/api/purchasing/productLists/details/locations/add`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogLocationsList
     * @name CatalogLocationsList
     * @request GET:/api/purchasing/catalog/locations/list
     */
    catalogLocationsList: (
      query?: {
        /** @format int32 */
        'apiRequest.productListId'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCatalogLocationsListAPIResponse, any>({
        path: `/api/purchasing/catalog/locations/list`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogLocationsList
     * @name ProductListsDetailsLocationsList
     * @request GET:/api/purchasing/productLists/details/locations/list
     */
    productListsDetailsLocationsList: (
      query?: {
        /** @format int32 */
        'apiRequest.productListId'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCatalogLocationsListAPIResponse, any>({
        path: `/api/purchasing/productLists/details/locations/list`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogLocationsRemove
     * @name CatalogLocationsRemove
     * @request POST:/api/purchasing/catalog/locations/remove
     */
    catalogLocationsRemove: (
      apiRequest: MainStemAPIControllersPurchasingCatalogLocationsRemoveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCatalogLocationsRemoveAPIResponse, any>({
        path: `/api/purchasing/catalog/locations/remove`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogLocationsRemove
     * @name ProductListsDetailsLocationsRemove
     * @request POST:/api/purchasing/productLists/details/locations/remove
     */
    productListsDetailsLocationsRemove: (
      apiRequest: MainStemAPIControllersPurchasingCatalogLocationsRemoveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCatalogLocationsRemoveAPIResponse, any>({
        path: `/api/purchasing/productLists/details/locations/remove`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogProductsAdd
     * @name CatalogProductsAdd
     * @request POST:/api/purchasing/catalog/products/add
     */
    catalogProductsAdd: (
      apiRequest: MainStemAPIControllersPurchasingCatalogProductsAddAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCatalogProductsAddAPIResponse, any>({
        path: `/api/purchasing/catalog/products/add`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogProductsAdd
     * @name ProductListsDetailsProductsAdd
     * @request POST:/api/purchasing/productLists/details/products/add
     */
    productListsDetailsProductsAdd: (
      apiRequest: MainStemAPIControllersPurchasingCatalogProductsAddAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCatalogProductsAddAPIResponse, any>({
        path: `/api/purchasing/productLists/details/products/add`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogProductsList
     * @name CatalogProductsList
     * @request GET:/api/purchasing/catalog/products/list
     */
    catalogProductsList: (
      query?: {
        /** @format int32 */
        'apiRequest.productListId'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCatalogProductsListAPIResponse, any>({
        path: `/api/purchasing/catalog/products/list`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogProductsList
     * @name ProductListsDetailsProductsList
     * @request GET:/api/purchasing/productLists/details/products/list
     */
    productListsDetailsProductsList: (
      query?: {
        /** @format int32 */
        'apiRequest.productListId'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCatalogProductsListAPIResponse, any>({
        path: `/api/purchasing/productLists/details/products/list`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogProductsRemove
     * @name CatalogProductsRemove
     * @request POST:/api/purchasing/catalog/products/remove
     */
    catalogProductsRemove: (
      apiRequest: MainStemAPIControllersPurchasingCatalogProductsRemoveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCatalogProductsRemoveAPIResponse, any>({
        path: `/api/purchasing/catalog/products/remove`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogProductsRemove
     * @name ProductListsDetailsProductsRemove
     * @request POST:/api/purchasing/productLists/details/products/remove
     */
    productListsDetailsProductsRemove: (
      apiRequest: MainStemAPIControllersPurchasingCatalogProductsRemoveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCatalogProductsRemoveAPIResponse, any>({
        path: `/api/purchasing/productLists/details/products/remove`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogSuppliersList
     * @name CatalogSuppliersList
     * @request POST:/api/purchasing/catalog/suppliers/list
     */
    catalogSuppliersList: (
      apiRequest: MainStemAPIControllersPurchasingCatalogSuppliersListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCatalogSuppliersListAPIResponse, any>({
        path: `/api/purchasing/catalog/suppliers/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogUpdate
     * @name ProductListsUpdate
     * @request POST:/api/purchasing/productLists/update
     */
    productListsUpdate: (
      apiRequest: MainStemAPIControllersPurchasingCatalogUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/productLists/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogUpdate
     * @name CatalogUpdate
     * @request POST:/api/purchasing/catalog/update
     */
    catalogUpdate: (apiRequest: MainStemAPIControllersPurchasingCatalogUpdateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/catalog/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogUsersAdd
     * @name CatalogUsersAdd
     * @request POST:/api/purchasing/catalog/users/add
     */
    catalogUsersAdd: (
      apiRequest: MainStemAPIControllersPurchasingCatalogUsersAddAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCatalogUsersAddAPIResponse, any>({
        path: `/api/purchasing/catalog/users/add`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogUsersAdd
     * @name ProductListsDetailsUsersAdd
     * @request POST:/api/purchasing/productLists/details/users/add
     */
    productListsDetailsUsersAdd: (
      apiRequest: MainStemAPIControllersPurchasingCatalogUsersAddAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCatalogUsersAddAPIResponse, any>({
        path: `/api/purchasing/productLists/details/users/add`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogUsersList
     * @name CatalogUsersList
     * @request GET:/api/purchasing/catalog/users/list
     */
    catalogUsersList: (
      query?: {
        /** @format int32 */
        'apiRequest.productListId'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCatalogUsersListAPIResponse, any>({
        path: `/api/purchasing/catalog/users/list`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogUsersList
     * @name ProductListsDetailsUsersList
     * @request GET:/api/purchasing/productLists/details/users/list
     */
    productListsDetailsUsersList: (
      query?: {
        /** @format int32 */
        'apiRequest.productListId'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCatalogUsersListAPIResponse, any>({
        path: `/api/purchasing/productLists/details/users/list`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogUsersRemove
     * @name CatalogUsersRemove
     * @request POST:/api/purchasing/catalog/users/remove
     */
    catalogUsersRemove: (
      apiRequest: MainStemAPIControllersPurchasingCatalogUsersRemoveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCatalogUsersRemoveAPIResponse, any>({
        path: `/api/purchasing/catalog/users/remove`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCatalogUsersRemove
     * @name ProductListsDetailsUsersRemove
     * @request POST:/api/purchasing/productLists/details/users/remove
     */
    productListsDetailsUsersRemove: (
      apiRequest: MainStemAPIControllersPurchasingCatalogUsersRemoveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCatalogUsersRemoveAPIResponse, any>({
        path: `/api/purchasing/productLists/details/users/remove`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingChangeLogList
     * @name ChangeLogList
     * @request POST:/api/purchasing/change-log/list
     */
    changeLogList: (APIRequest: MainStemAPIControllersPurchasingChangeLogListAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingChangeLogListAPIResponse, any>({
        path: `/api/purchasing/change-log/list`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingContactSendMessage
     * @name ContactSendMessage
     * @request POST:/api/purchasing/contact/send-message
     */
    contactSendMessage: (
      apiRequest: MainStemAPIControllersPurchasingContactSendMessageAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/contact/send-message`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCreateMenuItemAutoComplete
     * @name PrivateMenuItemCreateAutoComplete
     * @request GET:/api/purchasing/privateMenuItem/create/auto-complete
     */
    privateMenuItemCreateAutoComplete: (
      query?: {
        'apiRequest.query'?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPrivateMenuItemCreateAutoCompleteAPIResponse, any>({
        path: `/api/purchasing/privateMenuItem/create/auto-complete`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCustomFieldCreate
     * @name CustomFieldCreate
     * @request POST:/api/purchasing/custom-field/create
     */
    customFieldCreate: (
      apiRequest: MainStemAPIControllersPurchasingCustomFieldCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCustomFieldCreateAPIResponse, any>({
        path: `/api/purchasing/custom-field/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCustomFieldDelete
     * @name CustomFieldDelete
     * @request POST:/api/purchasing/custom-field/delete
     */
    customFieldDelete: (
      apiRequest: MainStemAPIControllersPurchasingCustomFieldDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCustomFieldDeleteAPIResponse, any>({
        path: `/api/purchasing/custom-field/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCustomFieldList
     * @name CustomFieldList
     * @request POST:/api/purchasing/custom-field/list
     */
    customFieldList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingCustomFieldListAPIResponse, any>({
        path: `/api/purchasing/custom-field/list`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCustomFieldUpdate
     * @name CustomFieldUpdate
     * @request POST:/api/purchasing/custom-field/update
     */
    customFieldUpdate: (
      apiRequest: MainStemAPIControllersPurchasingCustomFieldUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCustomFieldUpdateAPIResponse, any>({
        path: `/api/purchasing/custom-field/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCustomFieldValueAdd
     * @name CustomFieldValueAdd
     * @request POST:/api/purchasing/custom-field/value/add
     */
    customFieldValueAdd: (
      apiRequest: MainStemAPIControllersPurchasingCustomFieldValueAddAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCustomFieldValueAddAPIResponse, any>({
        path: `/api/purchasing/custom-field/value/add`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCustomFieldValueList
     * @name CustomFieldValueList
     * @request POST:/api/purchasing/custom-field/value/list
     */
    customFieldValueList: (
      apiRequest: MainStemAPIControllersPurchasingCustomFieldValueListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCustomFieldValueListAPIResponse, any>({
        path: `/api/purchasing/custom-field/value/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingCustomFieldValueUpdate
     * @name CustomFieldValueUpdate
     * @request POST:/api/purchasing/custom-field/value/update
     */
    customFieldValueUpdate: (
      apiRequest: MainStemAPIControllersPurchasingCustomFieldValueUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingCustomFieldValueUpdateAPIResponse, any>({
        path: `/api/purchasing/custom-field/value/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardApprovalApprovalsByLocationData
     * @name DashboardApprovalApprovalsByLocationData
     * @request GET:/api/purchasing/dashboard/approval/approvalsByLocation/data
     */
    dashboardApprovalApprovalsByLocationData: (
      query?: {
        /** @format date-time */
        'apiRequest.date'?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingDashboardApprovalApprovalsByLocationDataAPIResponse, any>({
        path: `/api/purchasing/dashboard/approval/approvalsByLocation/data`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardApprovalApprovalsByLocationOptions
     * @name DashboardApprovalApprovalsByLocationOptions
     * @request GET:/api/purchasing/dashboard/approval/approvalsByLocation/options
     */
    dashboardApprovalApprovalsByLocationOptions: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingDashboardApprovalApprovalsByLocationOptionsAPIResponse, any>({
        path: `/api/purchasing/dashboard/approval/approvalsByLocation/options`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardApprovalOrdersMyApproval
     * @name DashboardApprovalOrdersNeedingMyApproval
     * @request GET:/api/purchasing/dashboard/approval/orders-needing-my-approval
     */
    dashboardApprovalOrdersNeedingMyApproval: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingDashboardApprovalOrdersMyApprovalAPIResponse, any>({
        path: `/api/purchasing/dashboard/approval/orders-needing-my-approval`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardApprovalOrdersOthersApproval
     * @name DashboardApprovalOrdersNeedingOthersApproval
     * @request GET:/api/purchasing/dashboard/approval/orders-needing-others-approval
     */
    dashboardApprovalOrdersNeedingOthersApproval: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingDashboardApprovalOrdersOthersApprovalAPIResponse, any>({
        path: `/api/purchasing/dashboard/approval/orders-needing-others-approval`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardApprovalTotalApprovalsData
     * @name DashboardApprovalTotalApprovalsData
     * @request GET:/api/purchasing/dashboard/approval/totalApprovals/data
     */
    dashboardApprovalTotalApprovalsData: (
      query?: {
        /** @format date-time */
        'apiRequest.date'?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingDashboardApprovalTotalApprovalsDataAPIResponse, any>({
        path: `/api/purchasing/dashboard/approval/totalApprovals/data`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardApprovalTotalApprovalsOptions
     * @name DashboardApprovalTotalApprovalsOptions
     * @request GET:/api/purchasing/dashboard/approval/totalApprovals/options
     */
    dashboardApprovalTotalApprovalsOptions: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingDashboardApprovalTotalApprovalsOptionsAPIResponse, any>({
        path: `/api/purchasing/dashboard/approval/totalApprovals/options`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardBudgets
     * @name DashboardBudgets
     * @request POST:/api/purchasing/dashboard/budgets
     */
    dashboardBudgets: (
      apiRequest: MainStemAPIControllersPurchasingDashboardBudgetsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingDashboardBudgetsAPIResponse, any>({
        path: `/api/purchasing/dashboard/budgets`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardCanold
     * @name DashboardCanold
     * @request POST:/api/purchasing/dashboard/canold
     */
    dashboardCanold: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingDashboardCanoldAPIResponse, any>({
        path: `/api/purchasing/dashboard/canold`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardCustomCreate
     * @name DashboardCustomCreate
     * @request POST:/api/purchasing/dashboard/custom/create
     */
    dashboardCustomCreate: (
      APIRequest: MainStemAPIControllersPurchasingDashboardCustomCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingDashboardCustomCreateAPIResponse, any>({
        path: `/api/purchasing/dashboard/custom/create`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardCustomDelete
     * @name DashboardCustomDelete
     * @request POST:/api/purchasing/dashboard/custom/delete
     */
    dashboardCustomDelete: (
      APIRequest: MainStemAPIControllersPurchasingDashboardCustomDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingDashboardCustomDeleteAPIResponse, any>({
        path: `/api/purchasing/dashboard/custom/delete`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardCustomList
     * @name DashboardCustomList
     * @request POST:/api/purchasing/dashboard/custom/list
     */
    dashboardCustomList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingDashboardCustomListAPIResponse, any>({
        path: `/api/purchasing/dashboard/custom/list`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardCustomRowCreate
     * @name DashboardCustomRowCreate
     * @request POST:/api/purchasing/dashboard/custom/row/create
     */
    dashboardCustomRowCreate: (
      APIRequest: MainStemAPIControllersPurchasingDashboardCustomRowCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingDashboardCustomRowCreateAPIResponse, any>({
        path: `/api/purchasing/dashboard/custom/row/create`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardCustomRowDelete
     * @name DashboardCustomRowDelete
     * @request POST:/api/purchasing/dashboard/custom/row/delete
     */
    dashboardCustomRowDelete: (
      APIRequest: MainStemAPIControllersPurchasingDashboardCustomRowDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingDashboardCustomRowDeleteAPIResponse, any>({
        path: `/api/purchasing/dashboard/custom/row/delete`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardCustomRowUpdate
     * @name DashboardCustomRowUpdate
     * @request POST:/api/purchasing/dashboard/custom/row/update
     */
    dashboardCustomRowUpdate: (
      APIRequest: MainStemAPIControllersPurchasingDashboardCustomRowUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingDashboardCustomRowUpdateAPIResponse, any>({
        path: `/api/purchasing/dashboard/custom/row/update`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardCustomUpdate
     * @name DashboardCustomUpdate
     * @request POST:/api/purchasing/dashboard/custom/update
     */
    dashboardCustomUpdate: (
      APIRequest: MainStemAPIControllersPurchasingDashboardCustomUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingDashboardCustomUpdateAPIResponse, any>({
        path: `/api/purchasing/dashboard/custom/update`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardCustomWidgetAdd
     * @name DashboardCustomWidgetAdd
     * @request POST:/api/purchasing/dashboard/custom/widget/add
     */
    dashboardCustomWidgetAdd: (
      APIRequest: MainStemAPIControllersPurchasingDashboardCustomWidgetAddAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingDashboardCustomWidgetAddAPIResponse, any>({
        path: `/api/purchasing/dashboard/custom/widget/add`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardCustomWidgetList
     * @name DashboardCustomWidgetList
     * @request POST:/api/purchasing/dashboard/custom/widget/list
     */
    dashboardCustomWidgetList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingDashboardCustomWidgetListAPIResponse, any>({
        path: `/api/purchasing/dashboard/custom/widget/list`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardCustomWidgetRelocate
     * @name DashboardCustomWidgetRelocate
     * @request POST:/api/purchasing/dashboard/custom/widget/relocate
     */
    dashboardCustomWidgetRelocate: (
      APIRequest: MainStemAPIControllersPurchasingDashboardCustomWidgetRelocateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingDashboardCustomWidgetRelocateAPIResponse, any>({
        path: `/api/purchasing/dashboard/custom/widget/relocate`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardCustomWidgetRemove
     * @name DashboardCustomWidgetRemove
     * @request POST:/api/purchasing/dashboard/custom/widget/remove
     */
    dashboardCustomWidgetRemove: (
      APIRequest: MainStemAPIControllersPurchasingDashboardCustomWidgetRemoveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingDashboardCustomWidgetRemoveAPIResponse, any>({
        path: `/api/purchasing/dashboard/custom/widget/remove`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardFinanceApprovals
     * @name DashboardFinanceOrderApproval
     * @request GET:/api/purchasing/dashboard/finance/orderApproval
     */
    dashboardFinanceOrderApproval: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingDashboardFinanceOrderApprovalsAPIResponse, any>({
        path: `/api/purchasing/dashboard/finance/orderApproval`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardFinanceOpenInvoices
     * @name DashboardFinanceOpenInvoices
     * @request GET:/api/purchasing/dashboard/finance/openInvoices
     */
    dashboardFinanceOpenInvoices: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingDashboardFinanceOpenInvoicesAPIResponse, any>({
        path: `/api/purchasing/dashboard/finance/openInvoices`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardFinanceOpenOrders
     * @name DashboardFinanceOpenOrders
     * @request GET:/api/purchasing/dashboard/finance/openOrders
     */
    dashboardFinanceOpenOrders: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingDashboardFinanceOpenOrdersAPIResponse, any>({
        path: `/api/purchasing/dashboard/finance/openOrders`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardFinancePayments
     * @name DashboardFinancePayments
     * @request GET:/api/purchasing/dashboard/finance/payments
     */
    dashboardFinancePayments: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingDashboardFinancePaymentsAPIResponse, any>({
        path: `/api/purchasing/dashboard/finance/payments`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardFinanceSalesByLocationData
     * @name DashboardFinanceSalesByLocationData
     * @request GET:/api/purchasing/dashboard/finance/salesByLocation/data
     */
    dashboardFinanceSalesByLocationData: (
      query?: {
        /** @format date-time */
        'apiRequest.date'?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingDashboardFinanceSalesByLocationDataAPIResponse, any>({
        path: `/api/purchasing/dashboard/finance/salesByLocation/data`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardFinanceSalesByLocationOptions
     * @name DashboardFinanceSalesByLocationOptions
     * @request GET:/api/purchasing/dashboard/finance/salesByLocation/options
     */
    dashboardFinanceSalesByLocationOptions: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingDashboardFinanceSalesByLocationOptionsAPIResponse, any>({
        path: `/api/purchasing/dashboard/finance/salesByLocation/options`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardFinanceTermsLedger
     * @name DashboardFinanceTermsLedger
     * @request GET:/api/purchasing/dashboard/finance/termsLedger
     */
    dashboardFinanceTermsLedger: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingDashboardFinanceTermsLedgerAPIResponse, any>({
        path: `/api/purchasing/dashboard/finance/termsLedger`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardFinanceTotalSalesData
     * @name DashboardFinanceTotalSalesData
     * @request GET:/api/purchasing/dashboard/finance/totalSales/data
     */
    dashboardFinanceTotalSalesData: (
      query?: {
        /** @format date-time */
        'apiRequest.date'?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingDashboardFinanceTotalSalesDataAPIResponse, any>({
        path: `/api/purchasing/dashboard/finance/totalSales/data`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardFinanceTotalSalesOptions
     * @name DashboardFinanceTotalSalesOptions
     * @request GET:/api/purchasing/dashboard/finance/totalSales/options
     */
    dashboardFinanceTotalSalesOptions: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingDashboardFinanceTotalSalesOptionsAPIResponse, any>({
        path: `/api/purchasing/dashboard/finance/totalSales/options`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardFinanceUsers
     * @name DashboardFinanceUsers
     * @request GET:/api/purchasing/dashboard/finance/users
     */
    dashboardFinanceUsers: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingDashboardFinanceUsersAPIResponse, any>({
        path: `/api/purchasing/dashboard/finance/users`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardGetPurchasingMapDetail
     * @name DashboardPurchasingMapDetail
     * @request GET:/api/purchasing/dashboard/purchasing/mapDetail
     */
    dashboardPurchasingMapDetail: (
      query?: {
        /** @format int32 */
        'apiRequest.locationId'?: number
        /** @format date-time */
        'apiRequest.endDate'?: string
        /** @format date-time */
        'apiRequest.startDate'?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingDashboardPurchasingGetMapDetailDeprecateAPIResponse, any>({
        path: `/api/purchasing/dashboard/purchasing/mapDetail`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardInventory
     * @name DashboardInventory
     * @request POST:/api/purchasing/dashboard/inventory
     */
    dashboardInventory: (
      apiRequest: MainStemAPIControllersPurchasingDashboardInventoryAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingDashboardInventoryAPIResponse, any>({
        path: `/api/purchasing/dashboard/inventory`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardLocationInvoicesAwaitingShipment
     * @name LocationsDetailsInvoicesAwaitingShipment
     * @request GET:/api/purchasing/locations/details/invoicesAwaitingShipment
     */
    locationsDetailsInvoicesAwaitingShipment: (
      query?: {
        /** @format int32 */
        'apiRequest.locationId'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationsDetailsInvoicesAwaitingShipmentAPIResponse, any>({
        path: `/api/purchasing/locations/details/invoicesAwaitingShipment`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardLocationOrderApproval
     * @name LocationsDetailsOrderApproval
     * @request GET:/api/purchasing/locations/details/orderApproval
     */
    locationsDetailsOrderApproval: (
      query?: {
        /** @format int32 */
        'apiRequest.locationId'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationsDetailsOrderApprovalsAPIResponse, any>({
        path: `/api/purchasing/locations/details/orderApproval`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardOrdersAwaitingAcceptance
     * @name DashboardPurchasingPurchaseOrdersAwaitingAcceptance
     * @request POST:/api/purchasing/dashboard/purchasing/purchaseOrdersAwaitingAcceptance
     */
    dashboardPurchasingPurchaseOrdersAwaitingAcceptance: (
      apiRequest: MainStemAPIControllersPurchasingDashboardPurchasingPurchaseOrdersAwaitingAcceptanceAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingDashboardPurchasingPurchaseOrdersAwaitingAcceptanceAPIResponse, any>(
        {
          path: `/api/purchasing/dashboard/purchasing/purchaseOrdersAwaitingAcceptance`,
          method: 'POST',
          body: apiRequest,
          type: ContentType.Json,
          format: 'json',
          ...params
        }
      ),

    /**
     * No description
     *
     * @tags PurchasingDashboardOrdersAwaitingAcknowledgement
     * @name DashboardPurchasingPurchaseOrdersAwaitingAcknowledgement
     * @request POST:/api/purchasing/dashboard/purchasing/purchaseOrdersAwaitingAcknowledgement
     */
    dashboardPurchasingPurchaseOrdersAwaitingAcknowledgement: (
      apiRequest: MainStemAPIControllersPurchasingDashboardPurchasingPurchaseOrdersAwaitingAcknowledgementAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        MainStemAPIControllersPurchasingDashboardPurchasingPurchaseOrdersAwaitingAcknowledgementAPIResponse,
        any
      >({
        path: `/api/purchasing/dashboard/purchasing/purchaseOrdersAwaitingAcknowledgement`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardPurchasingApprovals
     * @name DashboardPurchasingOrderApproval
     * @request GET:/api/purchasing/dashboard/purchasing/orderApproval
     */
    dashboardPurchasingOrderApproval: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingDashboardPurchasingOrderApprovalsAPIResponse, any>({
        path: `/api/purchasing/dashboard/purchasing/orderApproval`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardPurchasingGetOrganizationSpend
     * @name DashboardPurchasingOrganizationSpend
     * @request GET:/api/purchasing/dashboard/purchasing/organizationSpend
     */
    dashboardPurchasingOrganizationSpend: (
      query?: {
        /** @format date-time */
        'apiRequest.endDate'?: string
        /** @format date-time */
        'apiRequest.startDate'?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingDashboardPurchasingGetOrganizationSpendDeprecateAPIResponse, any>({
        path: `/api/purchasing/dashboard/purchasing/organizationSpend`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardPurchasingMap
     * @name DashboardPurchasingMapData
     * @request GET:/api/purchasing/dashboard/purchasing/mapData
     */
    dashboardPurchasingMapData: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingDashboardPurchasingMapAPIResponse, any>({
        path: `/api/purchasing/dashboard/purchasing/mapData`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardPurchasingMapDetail
     * @name DashboardPurchasingMapDetailTimeSlice
     * @request POST:/api/purchasing/dashboard/purchasing/mapDetailTimeSlice
     */
    dashboardPurchasingMapDetailTimeSlice: (
      apiRequest: MainStemAPIControllersPurchasingDashboardPurchasingMapDetailAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingDashboardPurchasingMapDetailAPIResponse, any>({
        path: `/api/purchasing/dashboard/purchasing/mapDetailTimeSlice`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardPurchasingOrganizationSpend
     * @name DashboardPurchasingSpendTimeSlice
     * @request POST:/api/purchasing/dashboard/purchasing/spendTimeSlice
     */
    dashboardPurchasingSpendTimeSlice: (
      apiRequest: MainStemAPIControllersPurchasingDashboardPurchasingOrganizationSpendAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingDashboardPurchasingOrganizationSpendAPIResponse, any>({
        path: `/api/purchasing/dashboard/purchasing/spendTimeSlice`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardPurchasingUsers
     * @name DashboardPurchasingUsers
     * @request GET:/api/purchasing/dashboard/purchasing/users
     */
    dashboardPurchasingUsers: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingDashboardPurchasingUsersAPIResponse, any>({
        path: `/api/purchasing/dashboard/purchasing/users`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardSupplyAudit
     * @name DashboardSupplyAudit
     * @request POST:/api/purchasing/dashboard/supply-audit
     */
    dashboardSupplyAudit: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingDashboardSupplyAuditAPIResponse, any>({
        path: `/api/purchasing/dashboard/supply-audit`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDashboardTableau
     * @name DashboardTableau
     * @request POST:/api/purchasing/dashboard/tableau
     */
    dashboardTableau: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingDashboardTableauAPIResponse, any>({
        path: `/api/purchasing/dashboard/tableau`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDepartmentCreate
     * @name DepartmentsCreate
     * @request POST:/api/purchasing/departments/create
     */
    departmentsCreate: (
      apiRequest: MainStemAPIControllersPurchasingDepartmentCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingDepartmentCreateAPIResponse, any>({
        path: `/api/purchasing/departments/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDepartmentDelete
     * @name DepartmentsDelete
     * @request POST:/api/purchasing/departments/delete
     */
    departmentsDelete: (
      apiRequest: MainStemAPIControllersPurchasingDepartmentDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingDepartmentDeleteAPIResponse, any>({
        path: `/api/purchasing/departments/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDepartmentDetails
     * @name DepartmentsDetails
     * @request POST:/api/purchasing/departments/details
     */
    departmentsDetails: (
      apiRequest: MainStemAPIControllersPurchasingDepartmentDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingDepartmentDetailsAPIResponse, any>({
        path: `/api/purchasing/departments/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDepartmentDuplicate
     * @name DepartmentsDuplicate
     * @request POST:/api/purchasing/departments/duplicate
     */
    departmentsDuplicate: (
      apiRequest: MainStemAPIControllersPurchasingDepartmentDuplicateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingDepartmentDuplicateAPIResponse, any>({
        path: `/api/purchasing/departments/duplicate`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDepartmentExport
     * @name DepartmentsExport
     * @request POST:/api/purchasing/departments/export
     */
    departmentsExport: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingDepartmentExportAPIResponse, any>({
        path: `/api/purchasing/departments/export`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDepartmentList
     * @name DepartmentsList
     * @request POST:/api/purchasing/departments/list
     */
    departmentsList: (
      apiRequest: MainStemAPIControllersPurchasingDepartmentListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingDepartmentListAPIResponse, any>({
        path: `/api/purchasing/departments/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDepartmentUpdate
     * @name DepartmentsUpdate
     * @request POST:/api/purchasing/departments/update
     */
    departmentsUpdate: (
      apiRequest: MainStemAPIControllersPurchasingDepartmentUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/departments/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDepartmentUserAdd
     * @name DepartmentsUserAdd
     * @request POST:/api/purchasing/departments/user/add
     */
    departmentsUserAdd: (
      apiRequest: MainStemAPIControllersPurchasingDepartmentUsersAddAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/departments/user/add`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingDepartmentUserRemove
     * @name DepartmentsUserRemove
     * @request POST:/api/purchasing/departments/user/remove
     */
    departmentsUserRemove: (
      apiRequest: MainStemAPIControllersPurchasingDepartmentUsersRemoveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/departments/user/remove`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingErr
     * @name Err
     * @request POST:/api/purchasing/err
     */
    err: (apiRequest: MainStemAPIControllersPurchasingErrAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/err`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFeedCreate
     * @name FeedCreate
     * @request POST:/api/purchasing/feed/create
     */
    feedCreate: (apiRequest: MainStemAPIControllersPurchasingFeedCreateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingFeedCreateAPIResponse, any>({
        path: `/api/purchasing/feed/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFeedDelete
     * @name FeedDelete
     * @request POST:/api/purchasing/feed/delete
     */
    feedDelete: (apiRequest: MainStemAPIControllersPurchasingFeedDeleteAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingFeedDeleteAPIResponse, any>({
        path: `/api/purchasing/feed/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFeedDetails
     * @name FeedDetails
     * @request POST:/api/purchasing/feed/details
     */
    feedDetails: (apiRequest: MainStemAPIControllersPurchasingFeedDetailsAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingFeedDetailsAPIResponse, any>({
        path: `/api/purchasing/feed/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFeedList
     * @name FeedList
     * @request POST:/api/purchasing/feed/list
     */
    feedList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingFeedListAPIResponse, any>({
        path: `/api/purchasing/feed/list`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFeedStream
     * @name FeedStream
     * @request POST:/api/purchasing/feed/stream
     */
    feedStream: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingFeedStreamAPIResponse, any>({
        path: `/api/purchasing/feed/stream`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFeedUpdate
     * @name FeedUpdate
     * @request POST:/api/purchasing/feed/update
     */
    feedUpdate: (apiRequest: MainStemAPIControllersPurchasingFeedUpdateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingFeedUpdateAPIResponse, any>({
        path: `/api/purchasing/feed/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFinancialDimensionCreate
     * @name AccountFinancialDimensionCreate
     * @request POST:/api/purchasing/account/financialDimension/create
     */
    accountFinancialDimensionCreate: (
      apiRequest: MainStemAPIControllersPurchasingAccountFinancialDimensionCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingAccountFinancialDimensionCreateAPIResponse, any>({
        path: `/api/purchasing/account/financialDimension/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFinancialDimensionCustomerDepartments
     * @name AccountFinancialDimensionDepartmentsCustomerDepartments
     * @request POST:/api/purchasing/account/financialDimensionDepartments/customerDepartments
     */
    accountFinancialDimensionDepartmentsCustomerDepartments: (
      apiRequest: MainStemAPIControllersPurchasingAccountFinancialDimensionDepartmentCustomerDepartmentsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        MainStemAPIControllersPurchasingAccountFinancialDimensionDepartmentCustomerDepartmentsAPIResponse,
        any
      >({
        path: `/api/purchasing/account/financialDimensionDepartments/customerDepartments`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFinancialDimensionCustomerInvoices
     * @name AccountFinancialDimensionInvoiceCustomerInvoices
     * @request POST:/api/purchasing/account/financialDimensionInvoice/customerInvoices
     */
    accountFinancialDimensionInvoiceCustomerInvoices: (
      apiRequest: MainStemAPIControllersPurchasingAccountFinancialDimensionInvoiceCustomerInvoicesAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingAccountFinancialDimensionInvoiceCustomerInvoicesAPIResponse, any>({
        path: `/api/purchasing/account/financialDimensionInvoice/customerInvoices`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFinancialDimensionCustomerLocations
     * @name AccountFinancialDimensionLocationCustomerLocations
     * @request POST:/api/purchasing/account/financialDimensionLocation/customerLocations
     */
    accountFinancialDimensionLocationCustomerLocations: (
      apiRequest: MainStemAPIControllersPurchasingAccountFinancialDimensionLocationCustomerLocationsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingAccountFinancialDimensionLocationCustomerLocationsAPIResponse, any>({
        path: `/api/purchasing/account/financialDimensionLocation/customerLocations`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFinancialDimensionCustomerProducts
     * @name AccountFinancialDimensionProductCustomerProducts
     * @request POST:/api/purchasing/account/financialDimensionProduct/customerProducts
     */
    accountFinancialDimensionProductCustomerProducts: (
      apiRequest: MainStemAPIControllersPurchasingAccountFinancialDimensionProductCustomerProductsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingAccountFinancialDimensionProductCustomerProductsAPIResponse, any>({
        path: `/api/purchasing/account/financialDimensionProduct/customerProducts`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFinancialDimensionCustomerSuppliers
     * @name AccountFinancialDimensionLocationCustomerSuppliers
     * @request POST:/api/purchasing/account/financialDimensionLocation/customerSuppliers
     */
    accountFinancialDimensionLocationCustomerSuppliers: (
      apiRequest: MainStemAPIControllersPurchasingAccountFinancialDimensionSupplierCustomerSuppliersAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingAccountFinancialDimensionSupplierCustomerSuppliersAPIResponse, any>({
        path: `/api/purchasing/account/financialDimensionLocation/customerSuppliers`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFinancialDimensionDelete
     * @name AccountFinancialDimensionDelete
     * @request POST:/api/purchasing/account/financialDimension/delete
     */
    accountFinancialDimensionDelete: (
      apiRequest: MainStemAPIControllersPurchasingAccountFinancialDimensionDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingAccountFinancialDimensionDeleteAPIResponse, any>({
        path: `/api/purchasing/account/financialDimension/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFinancialDimensionGetByType
     * @name AccountFinancialDimensionGetByType
     * @request POST:/api/purchasing/account/financialDimension/getByType
     */
    accountFinancialDimensionGetByType: (
      apiRequest: MainStemAPIControllersPurchasingAccountFinancialDimensionGetByTypeAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingAccountFinancialDimensionGetByTypeAPIResponse, any>({
        path: `/api/purchasing/account/financialDimension/getByType`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFinancialDimensionList
     * @name AccountFinancialDimensionListByType
     * @request POST:/api/purchasing/account/financialDimension/listByType
     */
    accountFinancialDimensionListByType: (
      apiRequest: MainStemAPIControllersPurchasingAccountFinancialDimensionListByTypeAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingAccountFinancialDimensionListByTypeAPIResponse, any>({
        path: `/api/purchasing/account/financialDimension/listByType`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFinancialDimensionOptionCreate
     * @name AccountFinancialDimensionOptionCreate
     * @request POST:/api/purchasing/account/financialDimensionOption/create
     */
    accountFinancialDimensionOptionCreate: (
      apiRequest: MainStemAPIControllersPurchasingAccountFinancialDimensionOptionCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingAccountFinancialDimensionOptionCreateAPIResponse, any>({
        path: `/api/purchasing/account/financialDimensionOption/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFinancialDimensionOptionDelete
     * @name AccountFinancialDimensionOptionDelete
     * @request POST:/api/purchasing/account/financialDimensionOption/delete
     */
    accountFinancialDimensionOptionDelete: (
      apiRequest: MainStemAPIControllersPurchasingAccountFinancialDimensionOptionDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingAccountFinancialDimensionOptionDeleteAPIResponse, any>({
        path: `/api/purchasing/account/financialDimensionOption/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFinancialDimensionOptionGetByType
     * @name AccountFinancialDimensionOptionGetByType
     * @request POST:/api/purchasing/account/FinancialDimensionOption/getByType
     */
    accountFinancialDimensionOptionGetByType: (
      apiRequest: MainStemAPIControllersPurchasingAccountFinancialDimensionOptionGetByTypeAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingAccountFinancialDimensionOptionGetByTypeAPIResponse, any>({
        path: `/api/purchasing/account/FinancialDimensionOption/getByType`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFinancialDimensionOptionList
     * @name AccountFinancialDimensionOptionListById
     * @request POST:/api/purchasing/account/FinancialDimensionOption/listByID
     */
    accountFinancialDimensionOptionListById: (
      apiRequest: MainStemAPIControllersPurchasingAccountFinancialDimensionOptionListByIDAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingAccountFinancialDimensionOptionListByIDAPIResponse, any>({
        path: `/api/purchasing/account/FinancialDimensionOption/listByID`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFinancialDimensionOptionSetByType
     * @name AccountFinancialDimensionOptionSetByType
     * @request POST:/api/purchasing/account/FinancialDimensionOption/setByType
     */
    accountFinancialDimensionOptionSetByType: (
      apiRequest: MainStemAPIControllersPurchasingAccountFinancialDimensionOptionSetByTypeAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingAccountFinancialDimensionOptionSetByTypeAPIResponse, any>({
        path: `/api/purchasing/account/FinancialDimensionOption/setByType`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFinancialDimensionOptionUpdate
     * @name AccountFinancialDimensionOptionUpdate
     * @request POST:/api/purchasing/account/financialDimensionOption/update
     */
    accountFinancialDimensionOptionUpdate: (
      apiRequest: MainStemAPIControllersPurchasingAccountFinancialDimensionOptionUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingAccountFinancialDimensionOptionUpdateAPIResponse, any>({
        path: `/api/purchasing/account/financialDimensionOption/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFinancialDimensionUpdate
     * @name AccountFinancialDimensionUpdate
     * @request POST:/api/purchasing/account/financialDimension/update
     */
    accountFinancialDimensionUpdate: (
      apiRequest: MainStemAPIControllersPurchasingAccountFinancialDimensionUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingAccountFinancialDimensionUpdateAPIResponse, any>({
        path: `/api/purchasing/account/financialDimension/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFinancialDimensionUpdateDepartments
     * @name AccountFinancialDimensionDepartmentsUpdateDepartments
     * @request POST:/api/purchasing/account/financialDimensionDepartments/updateDepartments
     */
    accountFinancialDimensionDepartmentsUpdateDepartments: (
      apiRequest: MainStemAPIControllersPurchasingAccountFinancialDimensionDepartmentUpdateDepartmentsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        MainStemAPIControllersPurchasingAccountFinancialDimensionDepartmentUpdateDepartmentsAPIResponse,
        any
      >({
        path: `/api/purchasing/account/financialDimensionDepartments/updateDepartments`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFinancialDimensionUpdateInvoices
     * @name AccountFinancialDimensionInvoiceUpdateInvoices
     * @request POST:/api/purchasing/account/financialDimensionInvoice/updateInvoices
     */
    accountFinancialDimensionInvoiceUpdateInvoices: (
      apiRequest: MainStemAPIControllersPurchasingAccountFinancialDimensionInvoiceUpdateInvoicesAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingAccountFinancialDimensionInvoiceUpdateInvoicesAPIResponse, any>({
        path: `/api/purchasing/account/financialDimensionInvoice/updateInvoices`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFinancialDimensionUpdateLocations
     * @name AccountFinancialDimensionLocationUpdateLocations
     * @request POST:/api/purchasing/account/financialDimensionLocation/updateLocations
     */
    accountFinancialDimensionLocationUpdateLocations: (
      apiRequest: MainStemAPIControllersPurchasingAccountFinancialDimensionLocationUpdateLocationsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingAccountFinancialDimensionLocationUpdateLocationsAPIResponse, any>({
        path: `/api/purchasing/account/financialDimensionLocation/updateLocations`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFinancialDimensionUpdateProducts
     * @name AccountFinancialDimensionProductUpdateProducts
     * @request POST:/api/purchasing/account/financialDimensionProduct/updateProducts
     */
    accountFinancialDimensionProductUpdateProducts: (
      apiRequest: MainStemAPIControllersPurchasingAccountFinancialDimensionProductUpdateProductsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingAccountFinancialDimensionProductUpdateProductsAPIResponse, any>({
        path: `/api/purchasing/account/financialDimensionProduct/updateProducts`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingFinancialDimensionUpdateSuppliers
     * @name AccountFinancialDimensionLocationUpdateSuppliers
     * @request POST:/api/purchasing/account/financialDimensionLocation/updateSuppliers
     */
    accountFinancialDimensionLocationUpdateSuppliers: (
      apiRequest: MainStemAPIControllersPurchasingAccountFinancialDimensionSupplierUpdateSuppliersAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingAccountFinancialDimensionSupplierUpdateSuppliersAPIResponse, any>({
        path: `/api/purchasing/account/financialDimensionLocation/updateSuppliers`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationAmazonOrderLineItemCreate
     * @name IntegrationsAmazonOrderLineItemCreate
     * @request POST:/api/purchasing/integrations/amazon/order-line-item/create
     */
    integrationsAmazonOrderLineItemCreate: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationAmazonOrderLineItemCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationAmazonOrderLineItemCreateAPIResponse, any>({
        path: `/api/purchasing/integrations/amazon/order-line-item/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationD6Details
     * @name IntegrationD6Details
     * @request POST:/api/purchasing/integration/d6/details
     */
    integrationD6Details: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingIntegrationD6DetailsAPIResponse, any>({
        path: `/api/purchasing/integration/d6/details`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationD6Enable
     * @name IntegrationD6Enable
     * @request POST:/api/purchasing/integration/d6/enable
     */
    integrationD6Enable: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingIntegrationD6EnableAPIResponse, any>({
        path: `/api/purchasing/integration/d6/enable`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationExcel
     * @name IntegrationExcelGetFile
     * @request GET:/api/purchasing/integration/excel/get-file
     */
    integrationExcelGetFile: (
      query?: {
        /** @format uuid */
        'apiRequest.uuid'?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<AzureETag, any>({
        path: `/api/purchasing/integration/excel/get-file`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationLeafLogixCheckStatus
     * @name IntegrationLeafLogixCheckStatus
     * @request POST:/api/purchasing/integration/leafLogix/checkStatus
     */
    integrationLeafLogixCheckStatus: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationLeafLogixCheckStatusAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationLeafLogixCheckStatusAPIResponse, any>({
        path: `/api/purchasing/integration/leafLogix/checkStatus`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationLeafLogixGetByType
     * @name IntegrationLeafLogixGetByType
     * @request POST:/api/purchasing/integration/leafLogix/getByType
     */
    integrationLeafLogixGetByType: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationLeafLogixGetByTypeAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationLeafLogixGetByTypeAPIResponse, any>({
        path: `/api/purchasing/integration/leafLogix/getByType`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationLeafLogixIntegrationLogList
     * @name IntegrationsLeafLogixLogsList
     * @request POST:/api/purchasing/integrations/leafLogix/logs/list
     */
    integrationsLeafLogixLogsList: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationLeafLogixLogsListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationLeafLogixLogsListAPIResponse, any>({
        path: `/api/purchasing/integrations/leafLogix/logs/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationLeafLogixInventoryList
     * @name IntegrationLeafLogixInventoryList
     * @request POST:/api/purchasing/integration/leafLogix/inventory/list
     */
    integrationLeafLogixInventoryList: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationLeafLogixInventoryListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationLeafLogixInventoryListAPIResponse, any>({
        path: `/api/purchasing/integration/leafLogix/inventory/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationLeafLogixProductsList
     * @name IntegrationsLeafLogixProductsList
     * @request POST:/api/purchasing/integrations/leafLogix/products/list
     */
    integrationsLeafLogixProductsList: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationLeafLogixProductsListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpProvidersLeafLogixControllersProductListProductDetail[], any>({
        path: `/api/purchasing/integrations/leafLogix/products/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationLeafLogixProductsUpdateLinks
     * @name IntegrationsLeafLogixProductsUpdateLinks
     * @request POST:/api/purchasing/integrations/leafLogix/products/updateLinks
     */
    integrationsLeafLogixProductsUpdateLinks: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationLeafLogixProductsUpdateLinksAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationLeafLogixProductsUpdateLinksAPIResponse, any>({
        path: `/api/purchasing/integrations/leafLogix/products/updateLinks`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationLeafLogixReceiveInventory
     * @name IntegrationsLeafLogixReceivingReceiveInventory
     * @request POST:/api/purchasing/integrations/leafLogix/receiving/receiveInventory
     */
    integrationsLeafLogixReceivingReceiveInventory: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationLeafLogixReceivingReceiveInventoryAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationLeafLogixReceivingReceiveInventoryAPIResponse, any>({
        path: `/api/purchasing/integrations/leafLogix/receiving/receiveInventory`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationLeafLogixReceivingList
     * @name IntegrationsLeafLogixReceivingList
     * @request POST:/api/purchasing/integrations/leafLogix/receiving/list
     */
    integrationsLeafLogixReceivingList: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationLeafLogixReceivingListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationLeafLogixReceivingListAPIResponse, any>({
        path: `/api/purchasing/integrations/leafLogix/receiving/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationLeafLogixRoomList
     * @name IntegrationsLeafLogixRoomsList
     * @request POST:/api/purchasing/integrations/leafLogix/rooms/list
     */
    integrationsLeafLogixRoomsList: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationLeafLogixRoomsListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpProvidersLeafLogixControllersRoomListRoomDetail[], any>({
        path: `/api/purchasing/integrations/leafLogix/rooms/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationLeafLogixRoomUpdateLinks
     * @name IntegrationsLeafLogixRoomsUpdateLinks
     * @request POST:/api/purchasing/integrations/leafLogix/rooms/updateLinks
     */
    integrationsLeafLogixRoomsUpdateLinks: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationLeafLogixRoomsUpdateLinksAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationLeafLogixRoomsUpdateLinksAPIResponse, any>({
        path: `/api/purchasing/integrations/leafLogix/rooms/updateLinks`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationLeafLogixSetByType
     * @name IntegrationLeafLogixSetByType
     * @request POST:/api/purchasing/integration/leafLogix/setByType
     */
    integrationLeafLogixSetByType: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationLeafLogixSetByTypeAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationLeafLogixSetByTypeAPIResponse, any>({
        path: `/api/purchasing/integration/leafLogix/setByType`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationLeafLogixSettingsDetails
     * @name IntegrationsLeafLogixDetails
     * @request POST:/api/purchasing/integrations/leafLogix/details
     */
    integrationsLeafLogixDetails: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationLeafLogixDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationLeafLogixDetailsAPIResponse, any>({
        path: `/api/purchasing/integrations/leafLogix/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationLeafLogixVendorList
     * @name IntegrationsLeafLogixVendorsList
     * @request POST:/api/purchasing/integrations/leafLogix/vendors/list
     */
    integrationsLeafLogixVendorsList: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationLeafLogixVendorsListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpProvidersLeafLogixControllersVendorListVendorDetail[], any>({
        path: `/api/purchasing/integrations/leafLogix/vendors/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationLeafLogixVendorUpdateLinks
     * @name IntegrationsLeafLogixVendorsUpdateLinks
     * @request POST:/api/purchasing/integrations/leafLogix/vendors/updateLinks
     */
    integrationsLeafLogixVendorsUpdateLinks: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationLeafLogixVendorsUpdateLinksAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationLeafLogixVendorsUpdateLinksAPIResponse, any>({
        path: `/api/purchasing/integrations/leafLogix/vendors/updateLinks`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationParagonGetToken
     * @name IntegrationsParagonGetToken
     * @request POST:/api/purchasing/integrations/paragon/get-token
     */
    integrationsParagonGetToken: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationParagonGetTokenAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationParagonGetTokenAPIResponse, any>({
        path: `/api/purchasing/integrations/paragon/get-token`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationPowerBIDetails
     * @name IntegrationPowerBiDetails
     * @request POST:/api/purchasing/integration/power-bi/details
     */
    integrationPowerBiDetails: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingIntegrationPowerBIDetailsAPIResponse, any>({
        path: `/api/purchasing/integration/power-bi/details`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationPowerBIRequest
     * @name IntegrationPowerBiRequest
     * @request POST:/api/purchasing/integration/power-bi/request
     */
    integrationPowerBiRequest: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingIntegrationPowerBIRequestAPIResponse, any>({
        path: `/api/purchasing/integration/power-bi/request`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationQuantumLeafAllItems
     * @name IntegrationsQuantumLeafAllItems
     * @request POST:/api/purchasing/integrations/quantum-leaf/all-items
     */
    integrationsQuantumLeafAllItems: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationQuantumLeafAllItemsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationQuantumLeafAllItemsAPIResponse, any>({
        path: `/api/purchasing/integrations/quantum-leaf/all-items`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationQuantumLeafIntegrationSettingsLogs
     * @name IntegrationsQuantumLeafLogs
     * @request POST:/api/purchasing/integrations/quantum-leaf/logs
     */
    integrationsQuantumLeafLogs: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationQuantumLeafIntegrationLogsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationQuantumLeafIntegrationLogsAPIResponse, any>({
        path: `/api/purchasing/integrations/quantum-leaf/logs`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationQuantumLeafLinkLocationProduct
     * @name IntegrationsQuantumLeafLinkLocationProduct
     * @request POST:/api/purchasing/integrations/quantum-leaf/link-location-product
     */
    integrationsQuantumLeafLinkLocationProduct: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationQuantumLeafLinkLocationProductAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/integrations/quantum-leaf/link-location-product`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationQuantumLeafLinkProduct
     * @name IntegrationsQuantumLeafLinkProduct
     * @request POST:/api/purchasing/integrations/quantum-leaf/link-product
     */
    integrationsQuantumLeafLinkProduct: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationQuantumLeafLinkProductAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/integrations/quantum-leaf/link-product`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationQuantumLeafLinkSupplier
     * @name IntegrationsQuantumLeafLinkSupplier
     * @request POST:/api/purchasing/integrations/quantum-leaf/link-supplier
     */
    integrationsQuantumLeafLinkSupplier: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationQuantumLeafLinkSupplierAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/integrations/quantum-leaf/link-supplier`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationQuantumLeafNonStockItems
     * @name IntegrationsQuantumLeafNonStockItems
     * @request POST:/api/purchasing/integrations/quantum-leaf/non-stock-items
     */
    integrationsQuantumLeafNonStockItems: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationQuantumLeafNonStockItemsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationQuantumLeafNonStockItemsAPIResponse, any>({
        path: `/api/purchasing/integrations/quantum-leaf/non-stock-items`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationQuantumLeafOrders
     * @name IntegrationsQuantumLeafOrders
     * @request POST:/api/purchasing/integrations/quantum-leaf/orders
     */
    integrationsQuantumLeafOrders: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationQuantumLeafOrdersAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationQuantumLeafOrdersAPIResponse, any>({
        path: `/api/purchasing/integrations/quantum-leaf/orders`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationQuantumLeafProducts
     * @name IntegrationsQuantumLeafProducts
     * @request POST:/api/purchasing/integrations/quantum-leaf/products
     */
    integrationsQuantumLeafProducts: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationQuantumLeafProductsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationQuantumLeafProductsAPIResponse, any>({
        path: `/api/purchasing/integrations/quantum-leaf/products`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationQuantumLeafVendorProducts
     * @name IntegrationsQuantumLeafVendorProducts
     * @request POST:/api/purchasing/integrations/quantum-leaf/vendor-products
     */
    integrationsQuantumLeafVendorProducts: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationQuantumLeafVendorProductsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationQuantumLeafVendorProductsAPIResponse, any>({
        path: `/api/purchasing/integrations/quantum-leaf/vendor-products`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationQuantumLeafVendors
     * @name IntegrationsQuantumLeafVendors
     * @request POST:/api/purchasing/integrations/quantum-leaf/vendors
     */
    integrationsQuantumLeafVendors: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationQuantumLeafVendorsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationQuantumLeafVendorsAPIResponse, any>({
        path: `/api/purchasing/integrations/quantum-leaf/vendors`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationQuantumLeafVendorSuppliers
     * @name IntegrationsQuantumLeafVendorSuppliers
     * @request POST:/api/purchasing/integrations/quantum-leaf/vendor-suppliers
     */
    integrationsQuantumLeafVendorSuppliers: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationQuantumLeafVendorSuppliersAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationQuantumLeafVendorSuppliersAPIResponse, any>({
        path: `/api/purchasing/integrations/quantum-leaf/vendor-suppliers`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationQuantumLeafWarehouses
     * @name IntegrationsQuantumLeafWarehouses
     * @request POST:/api/purchasing/integrations/quantum-leaf/warehouses
     */
    integrationsQuantumLeafWarehouses: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationQuantumLeafWarehousesAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationQuantumLeafWarehousesAPIResponse, any>({
        path: `/api/purchasing/integrations/quantum-leaf/warehouses`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationQuickBooksIntegrationSettingsLogs
     * @name IntegrationsQuickbooksLogsIntegrationSettings
     * @request POST:/api/purchasing/integrations/quickbooks/logs/integrationSettings
     */
    integrationsQuickbooksLogsIntegrationSettings: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationQuickBooksLoggerIntegrationLogsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationQuickBooksLoggerIntegrationLogsAPIResponse, any>({
        path: `/api/purchasing/integrations/quickbooks/logs/integrationSettings`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationQuickBooksLogs
     * @name IntegrationsQuickbooksLogs
     * @request POST:/api/purchasing/integrations/quickbooks/logs
     */
    integrationsQuickbooksLogs: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationQuickBooksLogsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationQuickBooksLogsAPIResponse, any>({
        path: `/api/purchasing/integrations/quickbooks/logs`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationQuickBooksOrderLogs
     * @name IntegrationsQuickbooksLogsOrder
     * @request POST:/api/purchasing/integrations/quickbooks/logs/order
     */
    integrationsQuickbooksLogsOrder: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationQuickBooksLoggerOrderLogsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationQuickBooksLoggerOrderLogsAPIResponse, any>({
        path: `/api/purchasing/integrations/quickbooks/logs/order`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationQuickBooksOrders
     * @name IntegrationsQuickbooksOrders
     * @request POST:/api/purchasing/integrations/quickbooks/orders
     */
    integrationsQuickbooksOrders: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationQuickBooksOrdersAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationQuickBooksOrdersAPIResponse, any>({
        path: `/api/purchasing/integrations/quickbooks/orders`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationQuickBooksProductTypes
     * @name IntegrationsQuickbooksProductTypes
     * @request POST:/api/purchasing/integrations/quickbooks/productTypes
     */
    integrationsQuickbooksProductTypes: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationQuickBooksProductTypesAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationQuickBooksProductTypesAPIResponse, any>({
        path: `/api/purchasing/integrations/quickbooks/productTypes`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationSettingsCreate
     * @name IntegrationsSettingsCreate
     * @request POST:/api/purchasing/integrations/settings/create
     */
    integrationsSettingsCreate: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationSettingsCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationSettingsCreateAPIResponse, any>({
        path: `/api/purchasing/integrations/settings/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationSettingsDetails
     * @name IntegrationsSettingsDetails
     * @request POST:/api/purchasing/integrations/settings/details
     */
    integrationsSettingsDetails: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationSettingsDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationSettingsDetailsAPIResponse, any>({
        path: `/api/purchasing/integrations/settings/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationSettingsList
     * @name IntegrationsSettingsList
     * @request POST:/api/purchasing/integrations/settings/list
     */
    integrationsSettingsList: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationSettingsListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationSettingsListAPIResponse, any>({
        path: `/api/purchasing/integrations/settings/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationSettingsLocationsAdd
     * @name IntegrationsSettingsLocationsAdd
     * @request POST:/api/purchasing/integrations/settings/locations/add
     */
    integrationsSettingsLocationsAdd: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationSettingsLocationsAddAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationSettingsLocationsAddAPIResponse, any>({
        path: `/api/purchasing/integrations/settings/locations/add`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationSettingsLocationsRemove
     * @name IntegrationsSettingsLocationsRemove
     * @request POST:/api/purchasing/integrations/settings/locations/remove
     */
    integrationsSettingsLocationsRemove: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationSettingsLocationsRemoveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/integrations/settings/locations/remove`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationSettingsToggle
     * @name IntegrationsSettingsToggle
     * @request POST:/api/purchasing/integrations/settings/toggle
     */
    integrationsSettingsToggle: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationSettingsToggleAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/integrations/settings/toggle`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationSettingsUpdate
     * @name IntegrationsSettingsUpdate
     * @request POST:/api/purchasing/integrations/settings/update
     */
    integrationsSettingsUpdate: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationSettingsUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/integrations/settings/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationsLog
     * @name IntegrationsLog
     * @request POST:/api/purchasing/integrations/log
     */
    integrationsLog: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationLogAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationLogAPIResponse, any>({
        path: `/api/purchasing/integrations/log`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationsQuantumLeafQueueOrder
     * @name OrdersDetailsIntegrationsQuantumLeafQueueOrder
     * @request POST:/api/purchasing/orders/details/integrations/quantum-leaf/queue-order
     */
    ordersDetailsIntegrationsQuantumLeafQueueOrder: (
      apiRequest: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuantumLeafQueueOrderAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/orders/details/integrations/quantum-leaf/queue-order`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationsQuickbooksCreateBill
     * @name OrdersDetailsIntegrationsQuickbooksCreateBill
     * @request POST:/api/purchasing/orders/details/integrations/quickbooks/createBill
     */
    ordersDetailsIntegrationsQuickbooksCreateBill: (
      apiRequest: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksCreateBillAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpIntegrationsExternalQuickbooksCreateBillCreateQbBillResponse, any>({
        path: `/api/purchasing/orders/details/integrations/quickbooks/createBill`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationsQuickbooksCreateBillPayment
     * @name OrdersDetailsIntegrationsQuickbooksCreateBillPayment
     * @request POST:/api/purchasing/orders/details/integrations/quickbooks/createBillPayment
     */
    ordersDetailsIntegrationsQuickbooksCreateBillPayment: (
      apiRequest: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksCreateBillPaymentAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpIntegrationsExternalQuickbooksCreateBillPaymentCreateQbBillPaymentResponse, any>({
        path: `/api/purchasing/orders/details/integrations/quickbooks/createBillPayment`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationsQuickbooksCreateBillPaymentsFromPayment
     * @name OrdersDetailsIntegrationsQuickbooksCreateBillPaymentFromPayment
     * @request POST:/api/purchasing/orders/details/integrations/quickbooks/createBillPaymentFromPayment
     */
    ordersDetailsIntegrationsQuickbooksCreateBillPaymentFromPayment: (
      apiRequest: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksCreateBillPaymentsFromPaymentAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksCreateBillPaymentsFromPaymentAPIResponse,
        any
      >({
        path: `/api/purchasing/orders/details/integrations/quickbooks/createBillPaymentFromPayment`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingIntegrationStaplesOrderLineItemCreate
     * @name IntegrationsStaplesOrderLineItemCreate
     * @request POST:/api/purchasing/integrations/staples/order-line-item/create
     */
    integrationsStaplesOrderLineItemCreate: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationStaplesOrderLineItemCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationStaplesOrderLineItemCreateAPIResponse, any>({
        path: `/api/purchasing/integrations/staples/order-line-item/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvitableSuppliers
     * @name RfqInvitableSuppliers
     * @request GET:/api/purchasing/rfq/invitableSuppliers
     */
    rfqInvitableSuppliers: (
      query: {
        /** @format int32 */
        id: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingRFQInvitableSuppliersAPIResponse, any>({
        path: `/api/purchasing/rfq/invitableSuppliers`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoiceAllocationCreate
     * @name InvoicesAllocationItemCreate
     * @request POST:/api/purchasing/invoices/allocation/item/create
     */
    invoicesAllocationItemCreate: (
      apiRequest: MainStemAPIControllersPurchasingInvoicesAllocationItemCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/invoices/allocation/item/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoiceAllocationDelete
     * @name InvoicesAllocationItemDelete
     * @request POST:/api/purchasing/invoices/allocation/item/delete
     */
    invoicesAllocationItemDelete: (
      apiRequest: MainStemAPIControllersPurchasingInvoicesAllocationItemDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/invoices/allocation/item/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoiceAllocationGet
     * @name InvoicesAllocationItemGet
     * @request POST:/api/purchasing/invoices/allocation/item/get
     */
    invoicesAllocationItemGet: (
      apiRequest: MainStemAPIControllersPurchasingInvoicesAllocationItemGetAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingInvoicesAllocationItemGetAPIResponse, any>({
        path: `/api/purchasing/invoices/allocation/item/get`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoiceAllocationList
     * @name InvoicesAllocationItemList
     * @request POST:/api/purchasing/invoices/allocation/item/list
     */
    invoicesAllocationItemList: (
      apiRequest: MainStemAPIControllersPurchasingInvoicesAllocationItemListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingInvoicesAllocationItemListAPIResponse, any>({
        path: `/api/purchasing/invoices/allocation/item/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoiceAllocationUpdate
     * @name InvoicesAllocationItemUpdate
     * @request POST:/api/purchasing/invoices/allocation/item/update
     */
    invoicesAllocationItemUpdate: (
      apiRequest: MainStemAPIControllersPurchasingInvoicesAllocationItemUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/invoices/allocation/item/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoiceProblemsCreate
     * @name InvoiceProblemCreate
     * @request POST:/api/purchasing/invoice-problem/create
     */
    invoiceProblemCreate: (
      apiRequest: MainStemAPIControllersPurchasingInvoiceProblemsCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersGlobalMessageCreateAPIResponse, any>({
        path: `/api/purchasing/invoice-problem/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoiceProblemsDetails
     * @name InvoiceProblemDetails
     * @request POST:/api/purchasing/invoice-problem/details
     */
    invoiceProblemDetails: (
      apiRequest: MainStemAPIControllersPurchasingInvoiceProblemsDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingInvoiceProblemsDetailsAPIResponse, any>({
        path: `/api/purchasing/invoice-problem/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoiceProblemsList
     * @name InvoiceProblemList
     * @request POST:/api/purchasing/invoice-problem/list
     */
    invoiceProblemList: (
      apiRequest: MainStemAPIControllersPurchasingInvoiceProblemsListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingInvoiceProblemsListAPIResponse, any>({
        path: `/api/purchasing/invoice-problem/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoiceProblemsListUnresolved
     * @name InvoiceProblemListUnresolved
     * @request POST:/api/purchasing/invoice-problem/listUnresolved
     */
    invoiceProblemListUnresolved: (
      apiRequest: MainStemAPIControllersPurchasingInvoiceProblemsListUnresolvedAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingInvoiceProblemsListUnresolvedAPIResponse, any>({
        path: `/api/purchasing/invoice-problem/listUnresolved`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoiceProblemsUpdateStatus
     * @name InvoiceProblemUpdateStatus
     * @request POST:/api/purchasing/invoice-problem/updateStatus
     */
    invoiceProblemUpdateStatus: (
      apiRequest: MainStemAPIControllersPurchasingInvoiceProblemsUpdateStatusAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingInvoiceProblemsUpdateStatusAPIResponse, any>({
        path: `/api/purchasing/invoice-problem/updateStatus`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoiceReceivingDetailsUpdate
     * @name ReceivingUpdate
     * @request POST:/api/purchasing/receiving/update
     */
    receivingUpdate: (
      apiRequest: MainStemAPIControllersPurchasingReceivingUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/receiving/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoiceReceivingInvoiceDetails
     * @name ReceivingDetails
     * @request POST:/api/purchasing/receiving/details
     */
    receivingDetails: (
      apiRequest: MainStemAPIControllersPurchasingReceivingDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingReceivingDetailsAPIResponse, any>({
        path: `/api/purchasing/receiving/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoiceReceivingInvoiceList
     * @name ReceivingInvoiceList
     * @request POST:/api/purchasing/receiving/invoice/list
     */
    receivingInvoiceList: (
      apiRequest: MainStemAPIControllersPurchasingReceivingInvoiceListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingReceivingInvoiceListAPIResponse, any>({
        path: `/api/purchasing/receiving/invoice/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoiceReceivingInvoiceUpdate
     * @name ReceivingInvoiceUpdate
     * @request POST:/api/purchasing/receiving/invoice/update
     */
    receivingInvoiceUpdate: (
      apiRequest: MainStemAPIControllersPurchasingReceivingInvoiceUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/receiving/invoice/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoiceReceivingItemList
     * @name ReceivingItemList
     * @request POST:/api/purchasing/receiving/item/list
     */
    receivingItemList: (
      apiRequest: MainStemAPIControllersPurchasingReceivingItemListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingReceivingInvoiceListAPIResponse, any>({
        path: `/api/purchasing/receiving/item/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoiceReceivingItemUpdate
     * @name ReceivingItemUpdate
     * @request POST:/api/purchasing/receiving/item/update
     */
    receivingItemUpdate: (
      apiRequest: MainStemAPIControllersPurchasingReceivingItemUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/receiving/item/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoicesAccept
     * @name InvoicesAccept
     * @request POST:/api/purchasing/invoices/accept
     */
    invoicesAccept: (
      APIRequest: MainStemAPIControllersPurchasingInvoicesAcceptAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingInvoicesAcceptAPIResponse, any>({
        path: `/api/purchasing/invoices/accept`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoicesCancel
     * @name InvoicesCancel
     * @request POST:/api/purchasing/invoices/cancel
     */
    invoicesCancel: (
      APIRequest: MainStemAPIControllersPurchasingInvoicesCancelAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingInvoicesCancelAPIResponse, any>({
        path: `/api/purchasing/invoices/cancel`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoicesComplete
     * @name InvoicesComplete
     * @request POST:/api/purchasing/invoices/complete
     */
    invoicesComplete: (
      APIRequest: MainStemAPIControllersPurchasingInvoicesCompleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingInvoicesCompleteAPIResponse, any>({
        path: `/api/purchasing/invoices/complete`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoicesDecline
     * @name InvoicesDecline
     * @request POST:/api/purchasing/invoices/decline
     */
    invoicesDecline: (
      APIRequest: MainStemAPIControllersPurchasingInvoicesDeclineAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingInvoicesDeclineAPIResponse, any>({
        path: `/api/purchasing/invoices/decline`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoicesFinancialDimensionsUpdate
     * @name InvoicesFinancialDimensionsUpdate
     * @request POST:/api/purchasing/invoices/financial-dimensions/update
     */
    invoicesFinancialDimensionsUpdate: (
      apiRequest: MainStemAPIControllersPurchasingInvoicesFinancialDimensionsUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/invoices/financial-dimensions/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoicesLineItemUpdate
     * @name InvoicesLineItemsUpdate
     * @request POST:/api/purchasing/invoices/line-items/update
     */
    invoicesLineItemsUpdate: (
      APIRequest: MainStemAPIControllersPurchasingInvoicesLineItemsUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingInvoicesLineItemsUpdateAPIResponse, any>({
        path: `/api/purchasing/invoices/line-items/update`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoicesList
     * @name InvoicesList
     * @request POST:/api/purchasing/invoices/list
     */
    invoicesList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingInvoicesListAPIResponse, any>({
        path: `/api/purchasing/invoices/list`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoicesNotify
     * @name InvoicesNotify
     * @request POST:/api/purchasing/invoices/notify
     */
    invoicesNotify: (
      APIRequest: MainStemAPIControllersPurchasingInvoicesNotifyAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingInvoicesNotifyAPIResponse, any>({
        path: `/api/purchasing/invoices/notify`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoicesShipmentsCreate
     * @name InvoicesShipmentsCreate
     * @request POST:/api/purchasing/invoices/shipments/create
     */
    invoicesShipmentsCreate: (
      APIRequest: MainStemAPIControllersPurchasingInvoicesShipmentsCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingInvoicesShipmentsCreateAPIResponse, any>({
        path: `/api/purchasing/invoices/shipments/create`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoicesShipmentsList
     * @name InvoicesShipmentsList
     * @request POST:/api/purchasing/invoices/shipments/list
     */
    invoicesShipmentsList: (
      APIRequest: MainStemAPIControllersPurchasingInvoicesShipmentsListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingInvoicesShipmentsListAPIResponse, any>({
        path: `/api/purchasing/invoices/shipments/list`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingInvoicesUpdate
     * @name InvoicesUpdate
     * @request POST:/api/purchasing/invoices/update
     */
    invoicesUpdate: (
      APIRequest: MainStemAPIControllersPurchasingInvoicesUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingInvoicesUpdateAPIResponse, any>({
        path: `/api/purchasing/invoices/update`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLeafLogixActiveDepartments
     * @name IntegrationsLeafLogixRoomsActiveDepartments
     * @request POST:/api/purchasing/integrations/leafLogix/rooms/activeDepartments
     */
    integrationsLeafLogixRoomsActiveDepartments: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationLeafLogixRoomsActiveDepartmentsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationLeafLogixRoomsActiveDepartmentsAPIResponse, any>({
        path: `/api/purchasing/integrations/leafLogix/rooms/activeDepartments`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLeafLogixActiveProducts
     * @name IntegrationsLeafLogixProductsActiveProducts
     * @request POST:/api/purchasing/integrations/leafLogix/products/activeProducts
     */
    integrationsLeafLogixProductsActiveProducts: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationLeafLogixActiveProductsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationLeafLogixActiveProductsAPIResponse, any>({
        path: `/api/purchasing/integrations/leafLogix/products/activeProducts`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLeafLogixActiveSuppliers
     * @name IntegrationsLeafLogixVendorsActiveSuppliers
     * @request POST:/api/purchasing/integrations/leafLogix/vendors/activeSuppliers
     */
    integrationsLeafLogixVendorsActiveSuppliers: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationLeafLogixVendorsActiveSuppliersAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationLeafLogixVendorsActiveSuppliersAPIResponse, any>({
        path: `/api/purchasing/integrations/leafLogix/vendors/activeSuppliers`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationApprovalWorkflowSuperApproverAdd
     * @name LocationsApprovalWorkflowSuperApproverAdd
     * @request POST:/api/purchasing/locations/approval-workflow/super-approver/add
     */
    locationsApprovalWorkflowSuperApproverAdd: (
      query?: {
        /** @format int32 */
        'apiRequest.locationId'?: number
        'apiRequest.userId'?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationsApprovalWorkflowSuperApproverAddAPIResponse, any>({
        path: `/api/purchasing/locations/approval-workflow/super-approver/add`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationApprovalWorkflowSuperApproverRemove
     * @name LocationsApprovalWorkflowSuperApproverRemove
     * @request POST:/api/purchasing/locations/approval-workflow/super-approver/remove
     */
    locationsApprovalWorkflowSuperApproverRemove: (
      query?: {
        /** @format int32 */
        'apiRequest.locationId'?: number
        'apiRequest.userId'?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationsApprovalWorkflowSuperApproverRemoveAPIResponse, any>({
        path: `/api/purchasing/locations/approval-workflow/super-approver/remove`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationBudgetCreate
     * @name LocationBudgetCreate
     * @request POST:/api/purchasing/location-budget/create
     */
    locationBudgetCreate: (
      apiRequest: MainStemAPIControllersPurchasingLocationBudgetCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationBudgetCreateAPIResponse, any>({
        path: `/api/purchasing/location-budget/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationBudgetList
     * @name LocationBudgetList
     * @request POST:/api/purchasing/location-budget/list
     */
    locationBudgetList: (
      apiRequest: MainStemAPIControllersPurchasingLocationBudgetListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationBudgetListAPIResponse, any>({
        path: `/api/purchasing/location-budget/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationBudgetUpdate
     * @name LocationBudgetUpdate
     * @request POST:/api/purchasing/location-budget/update
     */
    locationBudgetUpdate: (
      apiRequest: MainStemAPIControllersPurchasingLocationBudgetUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationBudgetUpdateAPIResponse, any>({
        path: `/api/purchasing/location-budget/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationDepartmentsList
     * @name LocationsDepartmentsList
     * @request POST:/api/purchasing/locations/departments/list
     */
    locationsDepartmentsList: (
      apiRequest: MainStemAPIControllersPurchasingLocationsDepartmentsListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationsDepartmentsListAPIResponse, any>({
        path: `/api/purchasing/locations/departments/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationDetailsActivityLog
     * @name LocationsDetailsActivityLog
     * @request GET:/api/purchasing/locations/details/activityLog
     */
    locationsDetailsActivityLog: (
      query?: {
        /** @format int32 */
        'apiRequest.locationId'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationsDetailsActivityLogAPIResponse, any>({
        path: `/api/purchasing/locations/details/activityLog`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationDetailsOrders
     * @name LocationsDetailsOrders
     * @request POST:/api/purchasing/locations/details/orders
     */
    locationsDetailsOrders: (
      query?: {
        /** @format int32 */
        'apiRequest.locationId'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationsDetailsOrdersAPIResponse, any>({
        path: `/api/purchasing/locations/details/orders`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationDetailsProductFinancialDimensionCreate
     * @name LocationDetailsProductFinancialDimensionCreate
     * @request POST:/api/purchasing/location/details/product-financial-dimension/create
     */
    locationDetailsProductFinancialDimensionCreate: (
      apiRequest: MainStemAPIControllersPurchasingLocationsDetailsProductFinancialDimensionCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationsDetailsProductFinancialDimensionCreateAPIResponse, any>({
        path: `/api/purchasing/location/details/product-financial-dimension/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationDetailsProductFinancialDimensionDelete
     * @name LocationDetailsProductFinancialDimensionDelete
     * @request POST:/api/purchasing/location/details/product-financial-dimension/delete
     */
    locationDetailsProductFinancialDimensionDelete: (
      apiRequest: MainStemAPIControllersPurchasingLocationsDetailsProductFinancialDimensionDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationsDetailsProductFinancialDimensionDeleteAPIResponse, any>({
        path: `/api/purchasing/location/details/product-financial-dimension/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationDetailsProductFinancialDimensionUpdate
     * @name LocationDetailsProductFinancialDimensionUpdate
     * @request POST:/api/purchasing/location/details/product-financial-dimension/update
     */
    locationDetailsProductFinancialDimensionUpdate: (
      apiRequest: MainStemAPIControllersPurchasingLocationsDetailsProductFinancialDimensionUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationsDetailsProductFinancialDimensionUpdateAPIResponse, any>({
        path: `/api/purchasing/location/details/product-financial-dimension/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationDetailsShipments
     * @name LocationsDetailsShipments
     * @request GET:/api/purchasing/locations/details/shipments
     */
    locationsDetailsShipments: (
      query?: {
        /** @format int32 */
        'apiRequest.locationId'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationsDetailsShipmentsAPIResponse, any>({
        path: `/api/purchasing/locations/details/shipments`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationDetailsSupplierFinancialDimensionCreate
     * @name LocationDetailsSupplierFinancialDimensionCreate
     * @request POST:/api/purchasing/location/details/supplier-financial-dimension/create
     */
    locationDetailsSupplierFinancialDimensionCreate: (
      apiRequest: MainStemAPIControllersPurchasingLocationsDetailsSupplierFinancialDimensionCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationsDetailsSupplierFinancialDimensionCreateAPIResponse, any>({
        path: `/api/purchasing/location/details/supplier-financial-dimension/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationDetailsSupplierFinancialDimensionDelete
     * @name LocationDetailsSupplierFinancialDimensionDelete
     * @request POST:/api/purchasing/location/details/supplier-financial-dimension/delete
     */
    locationDetailsSupplierFinancialDimensionDelete: (
      apiRequest: MainStemAPIControllersPurchasingLocationsDetailsSupplierFinancialDimensionDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationsDetailsSupplierFinancialDimensionDeleteAPIResponse, any>({
        path: `/api/purchasing/location/details/supplier-financial-dimension/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationDetailsSupplierFinancialDimensionUpdate
     * @name LocationDetailsSupplierFinancialDimensionUpdate
     * @request POST:/api/purchasing/location/details/supplier-financial-dimension/update
     */
    locationDetailsSupplierFinancialDimensionUpdate: (
      apiRequest: MainStemAPIControllersPurchasingLocationsDetailsSupplierFinancialDimensionUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationsDetailsSupplierFinancialDimensionUpdateAPIResponse, any>({
        path: `/api/purchasing/location/details/supplier-financial-dimension/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationDetailsSupplyAuditReports
     * @name LocationsDetailsSupplyAuditReports
     * @request GET:/api/purchasing/locations/details/supplyAuditReports
     */
    locationsDetailsSupplyAuditReports: (
      query?: {
        /** @format int32 */
        'apiRequest.locationId'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationsDetailsSupplyAuditReportsAPIResponse, any>({
        path: `/api/purchasing/locations/details/supplyAuditReports`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationDetailsUsers
     * @name LocationsDetailsUsers
     * @request GET:/api/purchasing/locations/details/users
     */
    locationsDetailsUsers: (
      query?: {
        /** @format int32 */
        'apiRequest.locationId'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationsDetailsUsersAPIResponse, any>({
        path: `/api/purchasing/locations/details/users`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationsCreate
     * @name LocationsCreate
     * @request POST:/api/purchasing/locations/create
     */
    locationsCreate: (
      apiRequest: MainStemAPIControllersPurchasingLocationsCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationsCreateAPIResponse, any>({
        path: `/api/purchasing/locations/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationsDelete
     * @name LocationsDelete
     * @request POST:/api/purchasing/locations/delete
     */
    locationsDelete: (
      apiRequest: MainStemAPIControllersPurchasingLocationsDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationsDeleteAPIResponse, any>({
        path: `/api/purchasing/locations/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationsDetails
     * @name LocationsDetails
     * @request GET:/api/purchasing/locations/details
     */
    locationsDetails: (
      query?: {
        /** @format int32 */
        'apiRequest.id'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationsDetailsAPIResponse, any>({
        path: `/api/purchasing/locations/details`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationsDetailsProductLists
     * @name LocationsDetailsProductLists
     * @request GET:/api/purchasing/locations/details/productLists
     */
    locationsDetailsProductLists: (
      query?: {
        /** @format int32 */
        'apiRequest.locationId'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationsDetailsProductListsAPIResponse, any>({
        path: `/api/purchasing/locations/details/productLists`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationsDuplicate
     * @name LocationsDuplicate
     * @request POST:/api/purchasing/locations/duplicate
     */
    locationsDuplicate: (
      apiRequest: MainStemAPIControllersPurchasingLocationsDuplicateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationsDuplicateAPIResponse, any>({
        path: `/api/purchasing/locations/duplicate`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationsIntegrationQuantumLeafUpdate
     * @name LocationsIntegrationsQuantumLeafUpdate
     * @request POST:/api/purchasing/locations/integrations/quantum-leaf/update
     */
    locationsIntegrationsQuantumLeafUpdate: (
      apiRequest: MainStemAPIControllersPurchasingLocationsIntegrationsQuantumLeafUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingLocationsIntegrationsQuantumLeafUpdateAPIResponse, any>({
        path: `/api/purchasing/locations/integrations/quantum-leaf/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationsList
     * @name LocationsList
     * @request GET:/api/purchasing/locations/list
     */
    locationsList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingLocationsListAPIResponse, any>({
        path: `/api/purchasing/locations/list`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationsUpdate
     * @name LocationsUpdate
     * @request POST:/api/purchasing/locations/update
     */
    locationsUpdate: (
      apiRequest: MainStemAPIControllersPurchasingLocationsUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/locations/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationsUpdateAddress
     * @name LocationsUpdateAddress
     * @request POST:/api/purchasing/locations/update/address
     */
    locationsUpdateAddress: (
      apiRequest: MainStemAPIControllersPurchasingLocationsUpdateAddressAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/locations/update/address`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationsUpdateHeader
     * @name LocationsUpdateHeader
     * @request POST:/api/purchasing/locations/updateHeader
     */
    locationsUpdateHeader: (
      query: {
        /** @format int32 */
        id: number
      },
      params: RequestParams = {}
    ) =>
      this.request<AzureETag, any>({
        path: `/api/purchasing/locations/updateHeader`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationsUpdateLogo
     * @name LocationsUpdateLogo
     * @request POST:/api/purchasing/locations/updateLogo
     */
    locationsUpdateLogo: (
      query: {
        /** @format int32 */
        id: number
      },
      params: RequestParams = {}
    ) =>
      this.request<AzureETag, any>({
        path: `/api/purchasing/locations/updateLogo`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingLocationSupplierFinancialDimensionList
     * @name LocationSupplierFinancialDimensionList
     * @request POST:/api/purchasing/location-supplier-financial-dimension/list
     */
    locationSupplierFinancialDimensionList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingLocationSupplierFinancialDimensionListAPIResponse, any>({
        path: `/api/purchasing/location-supplier-financial-dimension/list`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingMakeInvoicePayments
     * @name MakePaymentsInvoice
     * @request POST:/api/purchasing/makePayments/invoice
     */
    makePaymentsInvoice: (
      apiRequest: MainStemAPIControllersPurchasingMakePaymentsInvoiceAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingMakePaymentsInvoiceAPIResponse, any>({
        path: `/api/purchasing/makePayments/invoice`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingMakeOrderPayments
     * @name MakePaymentsOrder
     * @request POST:/api/purchasing/makePayments/order
     */
    makePaymentsOrder: (
      apiRequest: MainStemAPIControllersPurchasingMakePaymentsOrderAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingMakePaymentsOrderAPIResponse, any>({
        path: `/api/purchasing/makePayments/order`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingMenuItemsList
     * @name MenuItemsList
     * @request GET:/api/purchasing/menuItems/list
     */
    menuItemsList: (
      query?: {
        /** @format int32 */
        'apiRequest.grandParentId'?: number
        /** @format int32 */
        'apiRequest.parentId'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingMenuItemsListAPIResponse, any>({
        path: `/api/purchasing/menuItems/list`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrderApproveRFQ
     * @name OrderRfqApprove
     * @request POST:/api/purchasing/order/rfq/approve
     */
    orderRfqApprove: (
      apiRequest: MainStemAPIControllersPurchasingOrdersRFQApproveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingOrdersRFQApproveAPIResponse, any>({
        path: `/api/purchasing/order/rfq/approve`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrderCommunicationActionResolved
     * @name OrdersCommunicationsActionResolved
     * @request POST:/api/purchasing/orders/communications/actionResolved
     */
    ordersCommunicationsActionResolved: (
      apiRequest: MainStemAPIControllersPurchasingOrdersCommunicationPurchasingOrderCommunicationActionResolvedControllerAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        MainStemAPIControllersPurchasingOrdersCommunicationPurchasingOrderCommunicationActionResolvedControllerAPIResponse,
        any
      >({
        path: `/api/purchasing/orders/communications/actionResolved`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrderCommunicationCreate
     * @name OrdersCommunicationsCreate
     * @request POST:/api/purchasing/orders/communications/create
     */
    ordersCommunicationsCreate: (
      apiRequest: MainStemAPIControllersPurchasingOrdersCommunicationPurchasingOrderCommunicationCreateControllerAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        MainStemAPIControllersPurchasingOrdersCommunicationPurchasingOrderCommunicationCreateControllerAPIResponse,
        any
      >({
        path: `/api/purchasing/orders/communications/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrderCommunicationForFulfillment
     * @name OrdersCommunicationsFulfillment
     * @request GET:/api/purchasing/orders/communications/fulfillment
     */
    ordersCommunicationsFulfillment: (
      query: {
        fulfillmentMethodIds: number[]
      },
      params: RequestParams = {}
    ) =>
      this.request<
        MainStemAPIControllersPurchasingOrdersCommunicationPurchasingOrderCommunicationForFulfillmentControllerAPIResponse,
        any
      >({
        path: `/api/purchasing/orders/communications/fulfillment`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrderCommunicationForOrder
     * @name OrdersCommunicationsOrder
     * @request GET:/api/purchasing/orders/communications/order
     */
    ordersCommunicationsOrder: (
      query: {
        /** @format int32 */
        orderID: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        MainStemAPIControllersPurchasingOrdersCommunicationPurchasingOrderCommunicationForOrderControllerAPIResponse,
        any
      >({
        path: `/api/purchasing/orders/communications/order`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrderCommunicationRemove
     * @name OrdersCommunicationsRemove
     * @request POST:/api/purchasing/orders/communications/remove
     */
    ordersCommunicationsRemove: (
      apiRequest: MainStemAPIControllersPurchasingOrdersCommunicationPurchasingOrderCommunicationRemoveControllerAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        MainStemAPIControllersPurchasingOrdersCommunicationPurchasingOrderCommunicationRemoveControllerAPIResponse,
        any
      >({
        path: `/api/purchasing/orders/communications/remove`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrderCommunicationUpdate
     * @name OrdersCommunicationsUpdate
     * @request POST:/api/purchasing/orders/communications/update
     */
    ordersCommunicationsUpdate: (
      apiRequest: MainStemAPIControllersPurchasingOrdersCommunicationPurchasingOrderCommunicationUpdateControllerAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        MainStemAPIControllersPurchasingOrdersCommunicationPurchasingOrderCommunicationUpdateControllerAPIResponse,
        any
      >({
        path: `/api/purchasing/orders/communications/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrderCreateFromRFQBids
     * @name OrdersCreateFromRfqBids
     * @request POST:/api/purchasing/orders/createFromRFQBids
     */
    ordersCreateFromRfqBids: (
      apiRequest: MainStemAPIControllersPurchasingOrdersRFQCreateFromBidsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingOrdersRFQCreateFromBidsAPIResponse, any>({
        path: `/api/purchasing/orders/createFromRFQBids`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrderCreateFromRFQBids
     * @name OrderManagementPurchaseOrderDetailsOrderCreateFromRfq
     * @request POST:/api/purchasing/order-management/purchase-order-details/order/create-from-rfq
     */
    orderManagementPurchaseOrderDetailsOrderCreateFromRfq: (
      apiRequest: MainStemAPIControllersPurchasingOrdersRFQCreateFromBidsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingOrdersRFQCreateFromBidsAPIResponse, any>({
        path: `/api/purchasing/order-management/purchase-order-details/order/create-from-rfq`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrderDuplicate
     * @name OrdersDuplicate
     * @request POST:/api/purchasing/orders/duplicate
     */
    ordersDuplicate: (
      apiRequest: MainStemAPIControllersPurchasingOrdersDuplicateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingOrdersDuplicateAPIResponse, any>({
        path: `/api/purchasing/orders/duplicate`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrderIntegrationAuthorityApproval
     * @name OrderIntegrationAuthorityApprove
     * @request POST:/api/purchasing/order/integration-authority/approve
     */
    orderIntegrationAuthorityApprove: (
      apiRequest: MainStemAPIControllersPurchasingOrdersSubmitIntegrationAuthorityApprovalAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingOrdersSubmitIntegrationAuthorityApprovalAPIResponse, any>({
        path: `/api/purchasing/order/integration-authority/approve`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrderLineItemCreate
     * @name OrderLineItemCreate
     * @request POST:/api/purchasing/order-line-item/create
     */
    orderLineItemCreate: (
      apiRequest: MainStemAPIControllersPurchasingOrderLineItemCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingOrderLineItemCreateAPIResponse, any>({
        path: `/api/purchasing/order-line-item/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrderLineItemDelete
     * @name OrderLineItemDelete
     * @request POST:/api/purchasing/order-line-item/delete
     */
    orderLineItemDelete: (
      apiRequest: MainStemAPIControllersPurchasingOrderLineItemDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/order-line-item/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrderLineItemUpdate
     * @name OrderLineItemUpdate
     * @request POST:/api/purchasing/order-line-item/update
     */
    orderLineItemUpdate: (
      apiRequest: MainStemAPIControllersPurchasingOrderLineItemUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/order-line-item/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrdersApprovalApprove
     * @name OrdersApprovalApprove
     * @request POST:/api/purchasing/orders/approval/approve
     */
    ordersApprovalApprove: (
      apiRequest: MainStemAPIControllersPurchasingOrdersApprovalApproveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingOrdersApprovalApproveAPIResponse, any>({
        path: `/api/purchasing/orders/approval/approve`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrdersApprovalReject
     * @name OrdersApprovalReject
     * @request POST:/api/purchasing/orders/approval/reject
     */
    ordersApprovalReject: (
      apiRequest: MainStemAPIControllersPurchasingOrdersApprovalRejectAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingOrdersApprovalRejectAPIResponse, any>({
        path: `/api/purchasing/orders/approval/reject`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrdersApprovalRevert
     * @name OrdersApprovalRevert
     * @request POST:/api/purchasing/orders/approval/revert
     */
    ordersApprovalRevert: (
      apiRequest: MainStemAPIControllersPurchasingOrdersApprovalRevertAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingOrdersApprovalRevertAPIResponse, any>({
        path: `/api/purchasing/orders/approval/revert`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrdersApprovalSubmitForApproval
     * @name OrdersApprovalSubmitForApproval
     * @request POST:/api/purchasing/orders/approval/submit-for-approval
     */
    ordersApprovalSubmitForApproval: (
      apiRequest: MainStemAPIControllersPurchasingOrdersApprovalSubmitForApprovalAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingOrdersApprovalSubmitForApprovalAPIResponse, any>({
        path: `/api/purchasing/orders/approval/submit-for-approval`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrdersCancel
     * @name OrdersCancel
     * @request POST:/api/purchasing/orders/cancel
     */
    ordersCancel: (apiRequest: MainStemAPIControllersPurchasingOrdersCancelAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/orders/cancel`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrdersCreate
     * @name OrdersCreate
     * @request POST:/api/purchasing/orders/create
     */
    ordersCreate: (apiRequest: MainStemAPIControllersPurchasingOrdersCreateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingOrdersCreateAPIResponse, any>({
        path: `/api/purchasing/orders/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrdersDetails
     * @name OrdersDetails
     * @request POST:/api/purchasing/orders/details
     */
    ordersDetails: (apiRequest: MainStemAPIControllersPurchasingOrdersDetailsAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingOrdersDetailsAPIResponse, any>({
        path: `/api/purchasing/orders/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrdersDetailsFrictionFree
     * @name OrdersDetailsFrictionFree
     * @request POST:/api/purchasing/orders/details/friction-free
     */
    ordersDetailsFrictionFree: (
      apiRequest: MainStemAPIControllersPurchasingOrdersDetailsFrictionFreeAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingOrdersDetailsFrictionFreeAPIResponse, any>({
        path: `/api/purchasing/orders/details/friction-free`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrdersDetailsIntegrationsD6Details
     * @name OrdersDetailsIntegrationsD6Details
     * @request POST:/api/purchasing/orders/details/integrations/d6/details
     */
    ordersDetailsIntegrationsD6Details: (
      apiRequest: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingOrdersDetailsIntegrationsD6DetailsAPIResponse, any>({
        path: `/api/purchasing/orders/details/integrations/d6/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrdersDetailsIntegrationsQuantumLeafDetails
     * @name OrdersDetailsIntegrationsQuantumLeafDetails
     * @request POST:/api/purchasing/orders/details/integrations/quantum-leaf/details
     */
    ordersDetailsIntegrationsQuantumLeafDetails: (
      apiRequest: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuantumLeafDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuantumLeafDetailsAPIResponse, any>({
        path: `/api/purchasing/orders/details/integrations/quantum-leaf/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrdersDetailsIntegrationsQuickBooksDetails
     * @name OrdersDetailsIntegrationsQuickbooksDetails
     * @request POST:/api/purchasing/orders/details/integrations/quickbooks/details
     */
    ordersDetailsIntegrationsQuickbooksDetails: (
      apiRequest: MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingOrdersDetailsIntegrationsQuickbooksDetailsAPIResponse, any>({
        path: `/api/purchasing/orders/details/integrations/quickbooks/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrdersDetailsPayments
     * @name OrdersDetailsPayments
     * @request POST:/api/purchasing/orders/details/payments
     */
    ordersDetailsPayments: (
      apiRequest: MainStemAPIControllersPurchasingOrdersDetailsPaymentsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingOrdersDetailsPaymentsAPIResponse, any>({
        path: `/api/purchasing/orders/details/payments`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrdersEmailLog
     * @name OrdersEmailLogs
     * @request POST:/api/purchasing/orders/email/logs
     */
    ordersEmailLogs: (
      apiRequest: MainStemAPIControllersPurchasingOrdersEmailLogsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingOrdersEmailLogsAPIResponse, any>({
        path: `/api/purchasing/orders/email/logs`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrdersList
     * @name OrdersList
     * @request GET:/api/purchasing/orders/list
     */
    ordersList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingOrdersListAPIResponse, any>({
        path: `/api/purchasing/orders/list`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrdersPay
     * @name OrderPay
     * @request POST:/api/purchasing/order/pay
     */
    orderPay: (apiRequest: MainStemAPIControllersPurchasingOrdersPayAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/order/pay`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrdersResendPrivateSupplierEmail
     * @name OrderResendPrivateSupplierEmail
     * @request POST:/api/purchasing/order/resend-private-supplier-email
     */
    orderResendPrivateSupplierEmail: (
      apiRequest: MainStemAPIControllersPurchasingOrdersResendPrivateSupplierEmailAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingOrdersResendPrivateSupplierEmailAPIResponse, any>({
        path: `/api/purchasing/order/resend-private-supplier-email`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrdersRevert
     * @name OrdersRevert
     * @request POST:/api/purchasing/orders/revert
     */
    ordersRevert: (apiRequest: MainStemAPIControllersPurchasingOrdersRevertAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/orders/revert`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrdersSubmitPaymentMethodsList
     * @name OrdersSubmitPaymentMethodsList
     * @request GET:/api/purchasing/orders/submit/paymentMethods/list
     */
    ordersSubmitPaymentMethodsList: (
      query?: {
        'apiRequest.orderUUID'?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingOrdersSubmitPaymentMethodsListAPIResponse, any>({
        path: `/api/purchasing/orders/submit/paymentMethods/list`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrderSubmitApprove
     * @name OrderSubmitApprove
     * @request POST:/api/purchasing/order/submit/approve
     */
    orderSubmitApprove: (
      apiRequest: MainStemAPIControllersPurchasingOrdersSubmitApproveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingOrdersSubmitApproveAPIResponse, any>({
        path: `/api/purchasing/order/submit/approve`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrderSubmitRFQForApproval
     * @name OrderRfqSubmitForApproval
     * @request POST:/api/purchasing/order/rfq/submitForApproval
     */
    orderRfqSubmitForApproval: (
      apiRequest: MainStemAPIControllersPurchasingOrdersRFQSubmitForApprovalAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingOrdersRFQSubmitForApprovalAPIResponse, any>({
        path: `/api/purchasing/order/rfq/submitForApproval`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrderTotalsOverview
     * @name OrdersOrderTotalsOverview
     * @request POST:/api/purchasing/orders/orderTotalsOverview
     */
    ordersOrderTotalsOverview: (
      apiRequest: MainStemAPIControllersPurchasingOrdersDetailsOrderTotalsOverviewAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingOrdersDetailsOrderTotalsOverviewAPIResponse, any>({
        path: `/api/purchasing/orders/orderTotalsOverview`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrderUpdate
     * @name OrdersUpdate
     * @request POST:/api/purchasing/orders/update
     */
    ordersUpdate: (apiRequest: MainStemAPIControllersPurchasingOrdersUpdateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/orders/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingOrderUpdateAddress
     * @name OrdersUpdateAddress
     * @request POST:/api/purchasing/orders/updateAddress
     */
    ordersUpdateAddress: (
      apiRequest: MainStemAPIControllersPurchasingOrdersUpdateAddressAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/orders/updateAddress`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPaymentMethodsCreateACH
     * @name PaymentMethodsCreateAch
     * @request POST:/api/purchasing/paymentMethods/create/ach
     */
    paymentMethodsCreateAch: (
      apiRequest: MainStemAPIControllersPurchasingPaymentMethodsCreateACHAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPaymentMethodsCreateACHAPIResponse, any>({
        path: `/api/purchasing/paymentMethods/create/ach`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPaymentMethodsCreateCreditCard
     * @name PaymentMethodsCreateCreditCard
     * @request POST:/api/purchasing/paymentMethods/create/creditCard
     */
    paymentMethodsCreateCreditCard: (
      apiRequest: MainStemAPIControllersPurchasingPaymentMethodsCreateCreditCardAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPaymentMethodsCreateCreditCardAPIResponse, any>({
        path: `/api/purchasing/paymentMethods/create/creditCard`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPaymentMethodsList
     * @name PaymentMethodsList
     * @request GET:/api/purchasing/paymentMethods/list
     */
    paymentMethodsList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingPaymentMethodsListAPIResponse, any>({
        path: `/api/purchasing/paymentMethods/list`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPermissionGroupCreate
     * @name PermissionGroupCreate
     * @request POST:/api/purchasing/permission-group/create
     */
    permissionGroupCreate: (
      apiRequest: MainStemAPIControllersPurchasingPermissionGroupCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPermissionGroupCreateAPIResponse, any>({
        path: `/api/purchasing/permission-group/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPermissionGroupDelete
     * @name PermissionGroupDelete
     * @request POST:/api/purchasing/permission-group/delete
     */
    permissionGroupDelete: (
      apiRequest: MainStemAPIControllersPurchasingPermissionGroupDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPermissionGroupDeleteAPIResponse, any>({
        path: `/api/purchasing/permission-group/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPermissionGroupList
     * @name PermissionGroupList
     * @request GET:/api/purchasing/permission-group/list
     */
    permissionGroupList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingPermissionGroupListAPIResponse, any>({
        path: `/api/purchasing/permission-group/list`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPermissionGroupUpdate
     * @name PermissionGroupUpdate
     * @request POST:/api/purchasing/permission-group/update
     */
    permissionGroupUpdate: (
      apiRequest: MainStemAPIControllersPurchasingPermissionGroupUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPermissionGroupUpdateAPIResponse, any>({
        path: `/api/purchasing/permission-group/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPermissionGroupUsersAdd
     * @name PermissionGroupAddUserToGroup
     * @request POST:/api/purchasing/permissionGroup/addUserToGroup
     */
    permissionGroupAddUserToGroup: (
      apiRequest: MainStemAPIControllersPurchasingPermissionGroupUsersAddAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPermissionGroupUsersAddAPIResponse, any>({
        path: `/api/purchasing/permissionGroup/addUserToGroup`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPermissionGroupUsersAdd
     * @name PermissionGroupUsersAdd
     * @request POST:/api/purchasing/permission-group/users/add
     */
    permissionGroupUsersAdd: (
      apiRequest: MainStemAPIControllersPurchasingPermissionGroupUsersAddAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPermissionGroupUsersAddAPIResponse, any>({
        path: `/api/purchasing/permission-group/users/add`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPermissionGroupUsersNotInGroup
     * @name PermissionGroupUsersNotInGroup
     * @request POST:/api/purchasing/permission-group/users/not-in-group
     */
    permissionGroupUsersNotInGroup: (
      apiRequest: MainStemAPIControllersPurchasingPermissionGroupUsersNotInGroupAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPermissionGroupUsersNotInGroupAPIResponse, any>({
        path: `/api/purchasing/permission-group/users/not-in-group`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPermissionGroupUsersRemove
     * @name PermissionGroupRemoveUserFromGroup
     * @request POST:/api/purchasing/permissionGroup/removeUserFromGroup
     */
    permissionGroupRemoveUserFromGroup: (
      apiRequest: MainStemAPIControllersPurchasingPermissionGroupUsersRemoveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPermissionGroupUsersRemoveAPIResponse, any>({
        path: `/api/purchasing/permissionGroup/removeUserFromGroup`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPermissionGroupUsersRemove
     * @name PermissionGroupUsersRemove
     * @request POST:/api/purchasing/permission-group/users/remove
     */
    permissionGroupUsersRemove: (
      apiRequest: MainStemAPIControllersPurchasingPermissionGroupUsersRemoveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPermissionGroupUsersRemoveAPIResponse, any>({
        path: `/api/purchasing/permission-group/users/remove`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPermissionGroupValueCreate
     * @name PermissionGroupValueCreate
     * @request POST:/api/purchasing/permission-group-value/create
     */
    permissionGroupValueCreate: (
      apiRequest: MainStemAPIControllersPurchasingPermissionGroupValueCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPermissionGroupValueCreateAPIResponse, any>({
        path: `/api/purchasing/permission-group-value/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPermissionGroupValueDelete
     * @name PermissionGroupValueDelete
     * @request POST:/api/purchasing/permission-group-value/delete
     */
    permissionGroupValueDelete: (
      apiRequest: MainStemAPIControllersPurchasingPermissionGroupValueDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPermissionGroupValueDeleteAPIResponse, any>({
        path: `/api/purchasing/permission-group-value/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPermissionGroupValueList
     * @name PermissionGroupValueList
     * @request POST:/api/purchasing/permission-group-value/list
     */
    permissionGroupValueList: (
      apiRequest: MainStemAPIControllersPurchasingPermissionGroupValueListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPermissionGroupValueListAPIResponse, any>({
        path: `/api/purchasing/permission-group-value/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPermissionGroupValueUpdate
     * @name PermissionGroupValueUpdate
     * @request POST:/api/purchasing/permission-group-value/update
     */
    permissionGroupValueUpdate: (
      apiRequest: MainStemAPIControllersPurchasingPermissionGroupValueUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPermissionGroupValueUpdateAPIResponse, any>({
        path: `/api/purchasing/permission-group-value/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPermissionList
     * @name PermissionList
     * @request GET:/api/purchasing/permission/list
     */
    permissionList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingPermissionListAPIResponse, any>({
        path: `/api/purchasing/permission/list`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPrivateProductAttributeValuesCreate
     * @name PrivateProductsAttributeValuesCreate
     * @request POST:/api/purchasing/privateProducts/attribute-values/create
     */
    privateProductsAttributeValuesCreate: (
      apiRequest: MainStemAPIControllersPurchasingPrivateProductAttributeValuesCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/privateProducts/attribute-values/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPrivateProductMenuItemsCreate
     * @name PrivateProductsMenuItemsCreate
     * @request POST:/api/purchasing/privateProducts/menu-items/create
     */
    privateProductsMenuItemsCreate: (
      apiRequest: MainStemAPIControllersPurchasingPrivateProductMenuItemsCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/privateProducts/menu-items/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPrivateProductsAttributeValuesDelete
     * @name PrivateProductsAttributeValuesDelete
     * @request POST:/api/purchasing/privateProducts/attribute-values/delete
     */
    privateProductsAttributeValuesDelete: (
      apiRequest: MainStemAPIControllersPurchasingPrivateProductAttributeValuesDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/privateProducts/attribute-values/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPrivateProductsMenuItemsDelete
     * @name PrivateProductsMenuItemsDelete
     * @request POST:/api/purchasing/privateProducts/menu-items/delete
     */
    privateProductsMenuItemsDelete: (
      apiRequest: MainStemAPIControllersPurchasingPrivateProductMenuItemsDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/privateProducts/menu-items/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPrivateProductVolumePricingCreate
     * @name PrivateProductsVolumePricingCreate
     * @request POST:/api/purchasing/private-products/volume-pricing/create
     */
    privateProductsVolumePricingCreate: (
      apiRequest: MainStemAPIControllersPurchasingPrivateProductVolumePricingCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/private-products/volume-pricing/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPrivateProductVolumePricingDelete
     * @name PrivateProductsVolumePricingDelete
     * @request POST:/api/purchasing/private-products/volume-pricing/delete
     */
    privateProductsVolumePricingDelete: (
      apiRequest: MainStemAPIControllersPurchasingPrivateProductVolumePricingDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/private-products/volume-pricing/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPrivateSupplierArchive
     * @name PrivateSupplierArchive
     * @request POST:/api/purchasing/private-supplier/archive
     */
    privateSupplierArchive: (
      apiRequest: MainStemAPIControllersPurchasingPrivateSuppliersArchiveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/private-supplier/archive`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPrivateSupplierCreate
     * @name PrivateSupplierCreate
     * @request POST:/api/purchasing/privateSupplier/create
     */
    privateSupplierCreate: (
      apiRequest: MainStemAPIControllersPurchasingPrivateSuppliersCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPrivateSuppliersCreateAPIResponse, any>({
        path: `/api/purchasing/privateSupplier/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPrivateSupplierDelete
     * @name PrivateSupplierDelete
     * @request POST:/api/purchasing/privateSupplier/delete
     */
    privateSupplierDelete: (
      apiRequest: MainStemAPIControllersPurchasingPrivateSuppliersDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/privateSupplier/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPrivateSupplierImpersonation
     * @name PrivateSupplierImpersonate
     * @request POST:/api/purchasing/private-supplier/impersonate
     */
    privateSupplierImpersonate: (
      request: MainStemAPIControllersPurchasingPrivateSuppliersImpersonationAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPrivateSuppliersImpersonationAPIResponse, any>({
        path: `/api/purchasing/private-supplier/impersonate`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPrivateSuppliersDuplicate
     * @name PrivateSuppliersDuplicate
     * @request POST:/api/purchasing/private-suppliers/duplicate
     */
    privateSuppliersDuplicate: (
      apiRequest: MainStemAPIControllersPurchasingPrivateSuppliersDuplicateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPrivateSuppliersDuplicateAPIResponse, any>({
        path: `/api/purchasing/private-suppliers/duplicate`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPrivateSupplierUpdate
     * @name PrivateSupplierUpdate
     * @request POST:/api/purchasing/privateSupplier/update
     */
    privateSupplierUpdate: (
      apiRequest: MainStemAPIControllersPurchasingPrivateSuppliersUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/privateSupplier/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPrivateSupplierUpdateHeader
     * @name PrivateSupplierUpdateHeader
     * @request POST:/api/purchasing/private-supplier/update-header
     */
    privateSupplierUpdateHeader: (
      query: {
        /** @format int32 */
        id: number
      },
      params: RequestParams = {}
    ) =>
      this.request<AzureETag, any>({
        path: `/api/purchasing/private-supplier/update-header`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPrivateSupplierUpdateLogo
     * @name PrivateSupplierUpdateLogo
     * @request POST:/api/purchasing/privateSupplier/updateLogo
     */
    privateSupplierUpdateLogo: (
      query: {
        /** @format int32 */
        id: number
      },
      params: RequestParams = {}
    ) =>
      this.request<AzureETag, any>({
        path: `/api/purchasing/privateSupplier/updateLogo`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingProductsAllocationsList
     * @name ProductsAllocationsList
     * @request POST:/api/purchasing/products/allocations/list
     */
    productsAllocationsList: (
      apiRequest: MainStemAPIControllersPurchasingProductsAllocationsListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingProductsAllocationsListAPIResponse, any>({
        path: `/api/purchasing/products/allocations/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingProductsArchive
     * @name ProductsArchive
     * @request POST:/api/purchasing/products/archive
     */
    productsArchive: (
      apiRequest: MainStemAPIControllersPurchasingProductsArchiveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingProductsArchiveAPIResponse, any>({
        path: `/api/purchasing/products/archive`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingProductsAttributesList
     * @name ProductsAttributesList
     * @request POST:/api/purchasing/products/attributes/list
     */
    productsAttributesList: (
      apiRequest: MainStemAPIControllersPurchasingProductsAttributesListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingProductsAttributesListAPIResponse, any>({
        path: `/api/purchasing/products/attributes/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingProductsCatalogsList
     * @name ProductsCatalogsList
     * @request POST:/api/purchasing/products/catalogs/list
     */
    productsCatalogsList: (
      apiRequest: MainStemAPIControllersPurchasingProductsCatalogsListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingProductsCatalogsListAPIResponse, any>({
        path: `/api/purchasing/products/catalogs/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingProductsCreate
     * @name ProductsCreate
     * @request POST:/api/purchasing/products/create
     */
    productsCreate: (
      apiRequest: MainStemAPIControllersPurchasingProductsCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingProductsCreateAPIResponse, any>({
        path: `/api/purchasing/products/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingProductsDetails
     * @name ProductsDetails
     * @request POST:/api/purchasing/products/details
     */
    productsDetails: (
      apiRequest: MainStemAPIControllersPurchasingProductsDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingProductsDetailsAPIResponse, any>({
        path: `/api/purchasing/products/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingProductsDuplicate
     * @name ProductsDuplicate
     * @request POST:/api/purchasing/products/duplicate
     */
    productsDuplicate: (
      apiRequest: MainStemAPIControllersPurchasingProductsDuplicateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingProductsDuplicateAPIResponse, any>({
        path: `/api/purchasing/products/duplicate`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingProductsList
     * @name ProductsList
     * @request GET:/api/purchasing/products/list
     */
    productsList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingProductsListAPIResponse, any>({
        path: `/api/purchasing/products/list`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingProductsOrdersList
     * @name ProductsOrdersList
     * @request POST:/api/purchasing/products/orders/list
     */
    productsOrdersList: (
      apiRequest: MainStemAPIControllersPurchasingProductsOrdersListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingProductsOrdersListAPIResponse, any>({
        path: `/api/purchasing/products/orders/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingProductsQAList
     * @name ProductsQaList
     * @request POST:/api/purchasing/products/qa/list
     */
    productsQaList: (
      apiRequest: MainStemAPIControllersPurchasingProductsQAListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingProductsQAListAPIResponse, any>({
        path: `/api/purchasing/products/qa/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingProductsReceivingList
     * @name ProductsReceivingList
     * @request POST:/api/purchasing/products/receiving/list
     */
    productsReceivingList: (
      apiRequest: MainStemAPIControllersPurchasingProductsReceivingListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingProductsReceivingListAPIResponse, any>({
        path: `/api/purchasing/products/receiving/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingProductsShipmentsList
     * @name ProductsShipmentsList
     * @request POST:/api/purchasing/products/shipments/list
     */
    productsShipmentsList: (
      apiRequest: MainStemAPIControllersPurchasingProductsShipmentsListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingProductsShipmentsListAPIResponse, any>({
        path: `/api/purchasing/products/shipments/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingProductsUpdate
     * @name ProductsUpdate
     * @request POST:/api/purchasing/products/update
     */
    productsUpdate: (
      apiRequest: MainStemAPIControllersPurchasingProductsUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingProductsUpdateAPIResponse, any>({
        path: `/api/purchasing/products/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingProductsUpdateImage
     * @name ProductsUpdateImage
     * @request POST:/api/purchasing/products/update/image
     */
    productsUpdateImage: (
      query: {
        /** @format int32 */
        id: number
      },
      params: RequestParams = {}
    ) =>
      this.request<AzureETag, any>({
        path: `/api/purchasing/products/update/image`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPunchoutOrderGenerate
     * @name PunchoutsOrderComplete
     * @request POST:/api/purchasing/punchouts/order-complete
     */
    punchoutsOrderComplete: (
      apiRequest: MainStemAPIControllersPurchasingPunchoutsOrderGenerateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPunchoutsOrderGenerateAPIResponse, any>({
        path: `/api/purchasing/punchouts/order-complete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPunchoutRequestSession
     * @name PunchoutsSetup
     * @request POST:/api/purchasing/punchouts/setup
     */
    punchoutsSetup: (
      apiRequest: MainStemAPIControllersPurchasingPunchoutsRequestSessionAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPunchoutsRequestSessionAPIResponse, any>({
        path: `/api/purchasing/punchouts/setup`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPunchoutSettingsAddLocations
     * @name PunchoutsAddLocations
     * @request POST:/api/purchasing/punchouts/add-locations
     */
    punchoutsAddLocations: (
      apiRequest: MainStemAPIControllersPurchasingPunchoutsSettingsAddLocationsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/punchouts/add-locations`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPunchoutSettingsCreate
     * @name PunchoutsCreate
     * @request POST:/api/purchasing/punchouts/create
     */
    punchoutsCreate: (
      apiRequest: MainStemAPIControllersPurchasingPunchoutsSettingsCreatePunchoutAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPunchoutsSettingsCreatePunchoutAPIResponse, any>({
        path: `/api/purchasing/punchouts/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPunchoutSettingsDetails
     * @name PunchoutsDetails
     * @request POST:/api/purchasing/punchouts/details
     */
    punchoutsDetails: (
      apiRequest: MainStemAPIControllersPurchasingPunchoutsSettingsDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPunchoutsSettingsDetailsAPIResponse, any>({
        path: `/api/purchasing/punchouts/details`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPunchoutSettingsList
     * @name PunchoutsList
     * @request POST:/api/purchasing/punchouts/list
     */
    punchoutsList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingPunchoutsSettingsListAPIResponse, any>({
        path: `/api/purchasing/punchouts/list`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPunchoutSettingsRemoveLocations
     * @name PunchoutsRemoveLocations
     * @request POST:/api/purchasing/punchouts/remove-locations
     */
    punchoutsRemoveLocations: (
      apiRequest: MainStemAPIControllersPurchasingPunchoutsSettingsRemoveLocationsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/punchouts/remove-locations`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPunchoutSettingsUpdate
     * @name PunchoutsUpdate
     * @request POST:/api/purchasing/punchouts/update
     */
    punchoutsUpdate: (
      apiRequest: MainStemAPIControllersPurchasingPunchoutsSettingsUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/punchouts/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPurchaseOrderDiscountsCreate
     * @name PurchaseOrdersDiscountsCreate
     * @request POST:/api/purchasing/purchase-orders/discounts/create
     */
    purchaseOrdersDiscountsCreate: (
      apiRequest: MainStemAPIControllersPurchasingPurchaseOrdersDiscountsCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPurchaseOrdersDiscountsCreateAPIResponse, any>({
        path: `/api/purchasing/purchase-orders/discounts/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPurchaseOrderFeesCreate
     * @name PurchaseOrdersFeesCreate
     * @request POST:/api/purchasing/purchase-orders/fees/create
     */
    purchaseOrdersFeesCreate: (
      apiRequest: MainStemAPIControllersPurchasingPurchaseOrdersFeesCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPurchaseOrdersFeesCreateAPIResponse, any>({
        path: `/api/purchasing/purchase-orders/fees/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPurchaseOrdersAllocationsDelete
     * @name PurchaseOrdersAllocationsDelete
     * @request POST:/api/purchasing/purchase-orders/allocations/delete
     */
    purchaseOrdersAllocationsDelete: (
      apiRequest: MainStemAPIControllersPurchasingPurchaseOrdersAllocationsDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPurchaseOrdersAllocationsDeleteAPIResponse, any>({
        path: `/api/purchasing/purchase-orders/allocations/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPurchaseOrdersAllocationsEnable
     * @name PurchaseOrdersAllocationsEnable
     * @request POST:/api/purchasing/purchase-orders/allocations/enable
     */
    purchaseOrdersAllocationsEnable: (
      apiRequest: MainStemAPIControllersPurchasingPurchaseOrdersAllocationsEnableAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPurchaseOrdersAllocationsEnableAPIResponse, any>({
        path: `/api/purchasing/purchase-orders/allocations/enable`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPurchaseOrdersAllocationsUpdate
     * @name PurchaseOrdersAllocationsUpdate
     * @request POST:/api/purchasing/purchase-orders/allocations/update
     */
    purchaseOrdersAllocationsUpdate: (
      apiRequest: MainStemAPIControllersPurchasingPurchaseOrdersAllocationsUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPurchaseOrdersAllocationsUpdateAPIResponse, any>({
        path: `/api/purchasing/purchase-orders/allocations/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPurchaseOrdersList
     * @name PurchaseOrdersList
     * @request POST:/api/purchasing/purchase-orders/list
     */
    purchaseOrdersList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingPurchaseOrdersListAPIResponse, any>({
        path: `/api/purchasing/purchase-orders/list`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPurchaseOrdersSalesTaxCalculate
     * @name PurchaseOrdersSalesTaxCalculate
     * @request POST:/api/purchasing/purchase-orders/sales-tax/calculate
     */
    purchaseOrdersSalesTaxCalculate: (
      apiRequest: MainStemAPIControllersPurchasingPurchaseOrdersSalesTaxCalculateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPurchaseOrdersSalesTaxCalculateAPIResponse, any>({
        path: `/api/purchasing/purchase-orders/sales-tax/calculate`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPurchaseOrdersSalesTaxUpdate
     * @name PurchaseOrdersSalesTaxUpdate
     * @request POST:/api/purchasing/purchase-orders/sales-tax/update
     */
    purchaseOrdersSalesTaxUpdate: (
      apiRequest: MainStemAPIControllersPurchasingPurchaseOrdersSalesTaxUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPurchaseOrdersSalesTaxUpdateAPIResponse, any>({
        path: `/api/purchasing/purchase-orders/sales-tax/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPurchaseOrdersShippingCalculate
     * @name PurchaseOrdersShippingCalculate
     * @request POST:/api/purchasing/purchase-orders/shipping/calculate
     */
    purchaseOrdersShippingCalculate: (
      apiRequest: MainStemAPIControllersPurchasingPurchaseOrdersShippingCalculateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPurchaseOrdersShippingCalculateAPIResponse, any>({
        path: `/api/purchasing/purchase-orders/shipping/calculate`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingPurchaseOrdersShippingUpdate
     * @name PurchaseOrdersShippingUpdate
     * @request POST:/api/purchasing/purchase-orders/shipping/update
     */
    purchaseOrdersShippingUpdate: (
      apiRequest: MainStemAPIControllersPurchasingPurchaseOrdersShippingUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingPurchaseOrdersShippingUpdateAPIResponse, any>({
        path: `/api/purchasing/purchase-orders/shipping/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingQuickBooksCreateMany
     * @name IntegrationsQuickbooksItemsCreateMany
     * @request POST:/api/purchasing/integrations/quickbooks/items/create-many
     */
    integrationsQuickbooksItemsCreateMany: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationQuickBooksItemsCreateManyAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationQuickBooksItemsCreateManyAPIResponse, any>({
        path: `/api/purchasing/integrations/quickbooks/items/create-many`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingQuickBooksCustomerProducts
     * @name IntegrationsQuickbooksCustomerProducts
     * @request POST:/api/purchasing/integrations/quickbooks/customerProducts
     */
    integrationsQuickbooksCustomerProducts: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationQuickBooksCustomerProductsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationQuickBooksCustomerProductsAPIResponse, any>({
        path: `/api/purchasing/integrations/quickbooks/customerProducts`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingQuickBooksUpdateItemLink
     * @name IntegrationsQuickbooksItemsUpdateLinks
     * @request POST:/api/purchasing/integrations/quickbooks/items/updateLinks
     */
    integrationsQuickbooksItemsUpdateLinks: (
      apiRequest: MainStemAPIControllersPurchasingIntegrationQuickBooksItemsUpdateItemLinksAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingIntegrationQuickBooksItemsUpdateItemLinksAPIResponse, any>({
        path: `/api/purchasing/integrations/quickbooks/items/updateLinks`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingReceiveDashboard
     * @name ReceivingDashboard
     * @request POST:/api/purchasing/receiving/dashboard
     */
    receivingDashboard: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingReceivingDashboardAPIResponse, any>({
        path: `/api/purchasing/receiving/dashboard`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingReportsLineItem
     * @name ReportsLineItems
     * @request POST:/api/purchasing/reports/line-items
     */
    reportsLineItems: (
      apiRequest: MainStemAPIControllersPurchasingReportsLineItemAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingReportsLineItemAPIResponse, any>({
        path: `/api/purchasing/reports/line-items`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingReportsOpenOrders
     * @name ReportsOpenOrders
     * @request POST:/api/purchasing/reports/open-orders
     */
    reportsOpenOrders: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingReportsOpenOrdersAPIResponse, any>({
        path: `/api/purchasing/reports/open-orders`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingReportsOrders
     * @name ReportsOrders
     * @request POST:/api/purchasing/reports/orders
     */
    reportsOrders: (apiRequest: MainStemAPIControllersPurchasingReportsOrdersAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingReportsOrdersAPIResponse, any>({
        path: `/api/purchasing/reports/orders`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingReportsPunchOuts
     * @name ReportsPunchOuts
     * @request POST:/api/purchasing/reports/punch-outs
     */
    reportsPunchOuts: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingReportsPunchOutsAPIResponse, any>({
        path: `/api/purchasing/reports/punch-outs`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRequisitionApproveDetails
     * @name RequisitionApproveDetails
     * @request POST:/api/purchasing/requisition/approve/details
     */
    requisitionApproveDetails: (
      request: MainStemAPIControllersPurchasingRequisitionApproveDetailsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingRequisitionApproveDetailsAPIResponse, any>({
        path: `/api/purchasing/requisition/approve/details`,
        method: 'POST',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRequisitionApproveSubmit
     * @name RequisitionApproveSubmit
     * @request POST:/api/purchasing/requisition/approve/submit
     */
    requisitionApproveSubmit: (
      apiRequest: MainStemAPIControllersPurchasingRequisitionApproveSubmitAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingRequisitionApproveSubmitAPIResponse, any>({
        path: `/api/purchasing/requisition/approve/submit`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRequisitionList
     * @name RequisitionList
     * @request POST:/api/purchasing/requisition/list
     */
    requisitionList: (
      apiRequest: MainStemAPIControllersPurchasingRequisitionListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingRequisitionListAPIResponse, any>({
        path: `/api/purchasing/requisition/list`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQAddCategory
     * @name RfqAddCategory
     * @request POST:/api/purchasing/rfq/addCategory
     */
    rfqAddCategory: (
      apiRequest: MainStemAPIControllersPurchasingRFQAddCategoryAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/rfq/addCategory`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQAddFileAttachment
     * @name RfqAddFile
     * @request POST:/api/purchasing/rfq/addFile
     */
    rfqAddFile: (
      query: {
        /** @format int32 */
        rfqID: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingRFQAddFileAttachmentAPIResponse, any>({
        path: `/api/purchasing/rfq/addFile`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQAddLineItem
     * @name RfqAddLineItem
     * @request POST:/api/purchasing/rfq/addLineItem
     */
    rfqAddLineItem: (
      apiRequest: MainStemAPIControllersPurchasingRFQAddLineItemAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingRFQAddLineItemAPIResponse, any>({
        path: `/api/purchasing/rfq/addLineItem`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQAddPreferredPayment
     * @name RfqAddPreferredPayment
     * @request POST:/api/purchasing/rfq/addPreferredPayment
     */
    rfqAddPreferredPayment: (
      apiRequest: MainStemAPIControllersPurchasingRFQAddPreferredPaymentAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/rfq/addPreferredPayment`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQAddSuppliers
     * @name RfqAddSuppliers
     * @request POST:/api/purchasing/rfq/addSuppliers
     */
    rfqAddSuppliers: (
      apiRequest: MainStemAPIControllersPurchasingRFQAddRFQSupplierAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/rfq/addSuppliers`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQBidDecisionUpdate
     * @name RfqBidsDecisionUpdate
     * @request POST:/api/purchasing/rfq/bids/decisionUpdate
     */
    rfqBidsDecisionUpdate: (
      apiRequest: MainStemAPIControllersPurchasingRFQBidsBidDecisionUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/rfq/bids/decisionUpdate`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQBidList
     * @name RfqBidsList
     * @request GET:/api/purchasing/rfq/bids/list
     */
    rfqBidsList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingRFQBidsListAPIResponse, any>({
        path: `/api/purchasing/rfq/bids/list`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQBids
     * @name RfqBids
     * @request GET:/api/purchasing/rfq/bids
     */
    rfqBids: (
      query: {
        /** @format int32 */
        rfqID: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingRFQBidsRFQBidsAPIResponse, any>({
        path: `/api/purchasing/rfq/bids`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQCancel
     * @name RfqCancel
     * @request POST:/api/purchasing/rfq/cancel
     */
    rfqCancel: (apiRequest: MainStemAPIControllersPurchasingRFQCancelAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/rfq/cancel`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQCreate
     * @name RfqCreate
     * @request POST:/api/purchasing/rfq/create
     */
    rfqCreate: (apiRequest: MainStemAPIControllersPurchasingRFQCreateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingRFQCreateAPIResponse, any>({
        path: `/api/purchasing/rfq/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQCreateAndInviteSupplier
     * @name RfqSupplierInviteAndCreate
     * @request POST:/api/purchasing/rfq/supplier/inviteAndCreate
     */
    rfqSupplierInviteAndCreate: (
      apiRequest: MainStemAPIControllersPurchasingRFQCreateAndInviteSupplierAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingRFQCreateAndInviteSupplierAPIResponse, any>({
        path: `/api/purchasing/rfq/supplier/inviteAndCreate`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQDetails
     * @name RfqDetails
     * @request GET:/api/purchasing/rfq/details
     */
    rfqDetails: (
      query: {
        /** @format int32 */
        id: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingRFQDetailsAPIResponse, any>({
        path: `/api/purchasing/rfq/details`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQDuplicate
     * @name RfqDuplicate
     * @request POST:/api/purchasing/rfq/duplicate
     */
    rfqDuplicate: (apiRequest: MainStemAPIControllersPurchasingRFQDuplicateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingRFQDuplicateAPIResponse, any>({
        path: `/api/purchasing/rfq/duplicate`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQFinalize
     * @name RfqFinalize
     * @request POST:/api/purchasing/rfq/finalize
     */
    rfqFinalize: (apiRequest: MainStemAPIControllersPurchasingRFQFinalizeAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/rfq/finalize`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQGeneratedOrdersList
     * @name RfqGeneratedOrders
     * @request POST:/api/purchasing/rfq/generatedOrders
     */
    rfqGeneratedOrders: (
      apiRequest: MainStemAPIControllersPurchasingRFQGeneratedOrdersListAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingRFQGeneratedOrdersListAPIResponse, any>({
        path: `/api/purchasing/rfq/generatedOrders`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQInitiate
     * @name RfqInitiate
     * @request POST:/api/purchasing/rfq/initiate
     */
    rfqInitiate: (apiRequest: MainStemAPIControllersPurchasingRFQInitiateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/rfq/initiate`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQList
     * @name RfqList
     * @request GET:/api/purchasing/rfq/list
     */
    rfqList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingRFQListAPIResponse, any>({
        path: `/api/purchasing/rfq/list`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQReloadLineItems
     * @name RfqReloadLineItems
     * @request POST:/api/purchasing/rfq/reloadLineItems
     */
    rfqReloadLineItems: (
      apiRequest: MainStemAPIControllersPurchasingRFQReloadLineItemsAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingRFQReloadLineItemsAPIResponse, any>({
        path: `/api/purchasing/rfq/reloadLineItems`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQRemoveCategories
     * @name RfqRemoveCategories
     * @request POST:/api/purchasing/rfq/removeCategories
     */
    rfqRemoveCategories: (
      apiRequest: MainStemAPIControllersPurchasingRFQRemoveCategoriesAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/rfq/removeCategories`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQRemoveFile
     * @name RfqRemoveFile
     * @request POST:/api/purchasing/rfq/removeFile
     */
    rfqRemoveFile: (
      apiRequest: MainStemAPIControllersPurchasingRFQRemoveFileAttachmentAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/rfq/removeFile`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQRemoveLineItem
     * @name RfqRemoveLineItem
     * @request POST:/api/purchasing/rfq/removeLineItem
     */
    rfqRemoveLineItem: (
      apiRequest: MainStemAPIControllersPurchasingRFQRemoveLineItemAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/rfq/removeLineItem`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQRemovePreferredPayment
     * @name RfqRemovePreferredPayment
     * @request POST:/api/purchasing/rfq/removePreferredPayment
     */
    rfqRemovePreferredPayment: (
      apiRequest: MainStemAPIControllersPurchasingRFQRemovePreferredPaymentAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/rfq/removePreferredPayment`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQRemoveSuppliers
     * @name RfqRemoveSuppliers
     * @request POST:/api/purchasing/rfq/removeSuppliers
     */
    rfqRemoveSuppliers: (
      apiRequest: MainStemAPIControllersPurchasingRFQRemoveSuppliersAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/rfq/removeSuppliers`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQSupplierList
     * @name RfqSuppliersList
     * @request GET:/api/purchasing/rfq/suppliersList
     */
    rfqSuppliersList: (
      query: {
        /** @format int32 */
        id: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingRFQSuppliersListAPIResponse, any>({
        path: `/api/purchasing/rfq/suppliersList`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQSupplierMessageCreate
     * @name RfqSupplierMessageCreate
     * @request POST:/api/purchasing/rfq/supplierMessage/create
     */
    rfqSupplierMessageCreate: (
      apiRequest: MainStemAPIControllersPurchasingRFQSupplierMessagesCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingRFQSupplierMessagesCreateAPIResponse, any>({
        path: `/api/purchasing/rfq/supplierMessage/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQSupplierMessageList
     * @name RfqSupplierMessageList
     * @request GET:/api/purchasing/rfq/supplierMessage/list
     */
    rfqSupplierMessageList: (
      query: {
        /** @format int32 */
        rfqID: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingRFQSupplierMessagesListAPIResponse, any>({
        path: `/api/purchasing/rfq/supplierMessage/list`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQUpdate
     * @name RfqUpdate
     * @request POST:/api/purchasing/rfq/update
     */
    rfqUpdate: (apiRequest: MainStemAPIControllersPurchasingRFQUpdateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/rfq/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingRFQUpdateLineItem
     * @name RfqUpdateLineItem
     * @request POST:/api/purchasing/rfq/updateLineItem
     */
    rfqUpdateLineItem: (
      apiRequest: MainStemAPIControllersPurchasingRFQUpdateLineItemAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingRFQUpdateLineItemAPIResponse, any>({
        path: `/api/purchasing/rfq/updateLineItem`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingSearch
     * @name Search
     * @request GET:/api/purchasing/search
     */
    search: (
      query?: {
        'apiRequest.query'?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingSearchAPIResponse, any>({
        path: `/api/purchasing/search`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingSendPrivateSupplierInviteEmail
     * @name RfqSendPrivateSupplierEmail
     * @request POST:/api/purchasing/rfq/sendPrivateSupplierEmail
     */
    rfqSendPrivateSupplierEmail: (
      apiRequest: MainStemAPIControllersPurchasingRFQSendPrivateSupplierInviteEmailAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/rfq/sendPrivateSupplierEmail`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingShipmentsDetails
     * @name ShipmentsDetails
     * @request GET:/api/purchasing/shipments/details
     */
    shipmentsDetails: (
      query?: {
        'apiRequest.shipmentId'?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingShipmentsDetailsAPIResponse, any>({
        path: `/api/purchasing/shipments/details`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingShipmentsList
     * @name ShipmentsList
     * @request GET:/api/purchasing/shipments/list
     */
    shipmentsList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingShipmentsListAPIResponse, any>({
        path: `/api/purchasing/shipments/list`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingSuppliersDetails
     * @name SuppliersDetails
     * @request POST:/api/purchasing/suppliers/details
     */
    suppliersDetails: (
      query?: {
        /** @format int32 */
        'apiRequest.vendorId'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingSuppliersDetailsAPIResponse, any>({
        path: `/api/purchasing/suppliers/details`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingSuppliersDetailsOrders
     * @name VendorsDetailsOrders
     * @request GET:/api/purchasing/vendors/details/orders
     */
    vendorsDetailsOrders: (
      query?: {
        /** @format int32 */
        'apiRequest.vendorId'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingSuppliersDetailsOrdersAPIResponse, any>({
        path: `/api/purchasing/vendors/details/orders`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingSuppliersDetailsProductLists
     * @name VendorsDetailsProductLists
     * @request GET:/api/purchasing/vendors/details/productLists
     */
    vendorsDetailsProductLists: (
      query?: {
        /** @format int32 */
        'apiRequest.vendorId'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingSuppliersDetailsProductListsAPIResponse, any>({
        path: `/api/purchasing/vendors/details/productLists`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingSuppliersDetailsProducts
     * @name VendorsDetailsProducts
     * @request GET:/api/purchasing/vendors/details/products
     */
    vendorsDetailsProducts: (
      query?: {
        /** @format int32 */
        'apiRequest.vendorId'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingSuppliersDetailsProductsAPIResponse, any>({
        path: `/api/purchasing/vendors/details/products`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingSuppliersDetailsProductsYouMayLike
     * @name VendorsDetailsProductsYouMayLike
     * @request GET:/api/purchasing/vendors/details/productsYouMayLike
     */
    vendorsDetailsProductsYouMayLike: (
      query?: {
        /** @format int32 */
        'apiRequest.vendorId'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingSuppliersDetailsProductsYouMayLikeAPIResponse, any>({
        path: `/api/purchasing/vendors/details/productsYouMayLike`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingSuppliersDetailsShipments
     * @name VendorsDetailsShipments
     * @request GET:/api/purchasing/vendors/details/shipments
     */
    vendorsDetailsShipments: (
      query?: {
        /** @format int32 */
        'apiRequest.vendorId'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingSuppliersDetailsShipmentsAPIResponse, any>({
        path: `/api/purchasing/vendors/details/shipments`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingSuppliersList
     * @name SuppliersList
     * @request POST:/api/purchasing/suppliers/list
     */
    suppliersList: (APIRequest: MainStemAPIControllersPurchasingSuppliersListAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingSuppliersListAPIResponse, any>({
        path: `/api/purchasing/suppliers/list`,
        method: 'POST',
        body: APIRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingSupplyAuditGenerate
     * @name SupplyAuditGenerate
     * @request POST:/api/purchasing/supply-audit/generate
     */
    supplyAuditGenerate: (
      apiRequest: MainStemAPIControllersPurchasingSupplyAuditGenerateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingSupplyAuditGenerateAPIResponse, any>({
        path: `/api/purchasing/supply-audit/generate`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingSupplyAuditSwap
     * @name SupplyAuditSwap
     * @request POST:/api/purchasing/supply-audit/swap
     */
    supplyAuditSwap: (
      apiRequest: MainStemAPIControllersPurchasingSupplyAuditSwapAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingSupplyAuditSwapAPIResponse, any>({
        path: `/api/purchasing/supply-audit/swap`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingTechList
     * @name TechList
     * @request POST:/api/purchasing/tech/list
     */
    techList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingTechListAPIResponse, any>({
        path: `/api/purchasing/tech/list`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingTechLocationAdd
     * @name TechLocationAdd
     * @request POST:/api/purchasing/tech/location/add
     */
    techLocationAdd: (
      apiRequest: MainStemAPIControllersPurchasingTechLocationAddAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingTechLocationAddAPIResponse, any>({
        path: `/api/purchasing/tech/location/add`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingTechLocationRemove
     * @name TechLocationRemove
     * @request POST:/api/purchasing/tech/location/remove
     */
    techLocationRemove: (
      apiRequest: MainStemAPIControllersPurchasingTechLocationRemoveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingTechLocationRemoveAPIResponse, any>({
        path: `/api/purchasing/tech/location/remove`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingUserPermissionCreate
     * @name UserPermissionCreate
     * @request POST:/api/purchasing/userPermission/create
     */
    userPermissionCreate: (
      apiRequest: MainStemAPIControllersPurchasingUserPermissionsCreateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingUserPermissionsCreateAPIResponse, any>({
        path: `/api/purchasing/userPermission/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingUserPermissionDelete
     * @name UserPermissionDelete
     * @request POST:/api/purchasing/userPermission/delete
     */
    userPermissionDelete: (
      apiRequest: MainStemAPIControllersPurchasingUserPermissionsDeleteAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/userPermission/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingUserPermissionUpdate
     * @name UserPermissionUpdate
     * @request POST:/api/purchasing/userPermission/update
     */
    userPermissionUpdate: (
      apiRequest: MainStemAPIControllersPurchasingUserPermissionsUpdateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingUserPermissionsUpdateAPIResponse, any>({
        path: `/api/purchasing/userPermission/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingUsersCreate
     * @name UsersCreate
     * @request POST:/api/purchasing/users/create
     */
    usersCreate: (apiRequest: MainStemAPIControllersPurchasingUsersCreateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingUsersCreateAPIResponse, any>({
        path: `/api/purchasing/users/create`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingUsersDelete
     * @name UsersDelete
     * @request POST:/api/purchasing/users/delete
     */
    usersDelete: (apiRequest: MainStemAPIControllersPurchasingUsersDeleteAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingUsersDeleteAPIResponse, any>({
        path: `/api/purchasing/users/delete`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingUsersDetails
     * @name UsersDetails
     * @request GET:/api/purchasing/users/details
     */
    usersDetails: (
      query?: {
        'apiRequest.uuid'?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingUsersDetailsAPIResponse, any>({
        path: `/api/purchasing/users/details`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingUsersDetailsOrders
     * @name UsersDetailsOrders
     * @request POST:/api/purchasing/users/details/orders
     */
    usersDetailsOrders: (
      query?: {
        'apiRequest.uuid'?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingUsersDetailsOrdersAPIResponse, any>({
        path: `/api/purchasing/users/details/orders`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingUsersDetailsProducts
     * @name UsersDetailsProducts
     * @request GET:/api/purchasing/users/details/products
     */
    usersDetailsProducts: (
      query?: {
        'apiRequest.uuid'?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingUsersDetailsProductsAPIResponse, any>({
        path: `/api/purchasing/users/details/products`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingUsersDetailsResetPassword
     * @name UsersDetailsResetPassword
     * @request POST:/api/purchasing/users/details/reset-password
     */
    usersDetailsResetPassword: (
      apiRequest: MainStemAPIControllersPurchasingUsersDetailsResetPasswordAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingUsersDetailsResetPasswordAPIResponse, any>({
        path: `/api/purchasing/users/details/reset-password`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingUsersDetailsVendors
     * @name UsersDetailsVendors
     * @request GET:/api/purchasing/users/details/vendors
     */
    usersDetailsVendors: (
      query?: {
        'apiRequest.uuid'?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingUsersDetailsVendorsAPIResponse, any>({
        path: `/api/purchasing/users/details/vendors`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingUsersDuplicate
     * @name UsersDuplicate
     * @request POST:/api/purchasing/users/duplicate
     */
    usersDuplicate: (
      apiRequest: MainStemAPIControllersPurchasingUsersDuplicateAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingUsersDuplicateAPIResponse, any>({
        path: `/api/purchasing/users/duplicate`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingUsersList
     * @name UsersList
     * @request GET:/api/purchasing/users/list
     */
    usersList: (params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingUsersListAPIResponse, any>({
        path: `/api/purchasing/users/list`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingUsersLocationsAdd
     * @name UsersLocationsAdd
     * @request POST:/api/purchasing/users/locations/add
     */
    usersLocationsAdd: (
      apiRequest: MainStemAPIControllersPurchasingUsersLocationsAddAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemAPIControllersPurchasingUsersLocationsAddAPIResponse, any>({
        path: `/api/purchasing/users/locations/add`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingUsersLocationsRemove
     * @name UsersLocationsRemove
     * @request POST:/api/purchasing/users/locations/remove
     */
    usersLocationsRemove: (
      apiRequest: MainStemAPIControllersPurchasingUsersLocationsRemoveAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/users/locations/remove`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingUsersLocationsSetDefault
     * @name UsersLocationsSetDefault
     * @request POST:/api/purchasing/users/locations/set-default
     */
    usersLocationsSetDefault: (
      apiRequest: MainStemAPIControllersPurchasingUsersLocationsSetDefaultAPIRequest,
      params: RequestParams = {}
    ) =>
      this.request<MainStemSharpModelsBaseResponse, any>({
        path: `/api/purchasing/users/locations/set-default`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags PurchasingUsersUpdate
     * @name UsersUpdate
     * @request POST:/api/purchasing/users/update
     */
    usersUpdate: (apiRequest: MainStemAPIControllersPurchasingUsersUpdateAPIRequest, params: RequestParams = {}) =>
      this.request<MainStemAPIControllersPurchasingUsersUpdateAPIResponse, any>({
        path: `/api/purchasing/users/update`,
        method: 'POST',
        body: apiRequest,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
}

export const MainStemApi = new Api()
